import isObject from 'lodash/isObject'

const CLASS_PROPERTY = 'class'
const COMPONENT_PROPERTY_VALUE = 'component'
const FUNCTION_PROPERTY_VALUE = 'function'
const COMPONENT_FUNCTION_PREFIX = 'bc.'

/**
 * Returns true if object is BRL component
 * @param {Object} brl
 * @returns {Boolean}
 */
function isComponent (brl) {
  return isObject(brl) && CLASS_PROPERTY in brl && brl[CLASS_PROPERTY] === COMPONENT_PROPERTY_VALUE
}

/**
 * Returns true if object is BRL function
 * @param {Object} brl
 * @returns {Boolean}
 */
function isFunction (brl) {
  return isObject(brl) && CLASS_PROPERTY in brl && brl[CLASS_PROPERTY] === FUNCTION_PROPERTY_VALUE
}

export {
  COMPONENT_FUNCTION_PREFIX,
  isComponent,
  isFunction
}
