import claimsServices from '@/modules/claims/services/jsonapi'
import integrationsService from './integrations'

export default {
  get claims () {
    return claimsServices
  },
  get integrations () {
    return integrationsService
  }
}
