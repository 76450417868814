import {COMPONENT_EDITOR_STORE_PATH} from '../constants'
import {addNamespace} from '@/store/helpers'
import { MUTATIONS as RuleEditorContextMutations } from '@/store/rules/componentEditorStore/ruleEditorContextStore/mutation-types'

// To be used only inside vuex modules
export const _mutations = {
  SET_COMPONENT: 'setComponent',
  START_LOADING_COMPONENT: 'startLoadingComponent',
  STOP_LOADING_COMPONENT: 'stopLoadingComponent',
  SET_ERROR_LOADING_COMPONENT: 'setErrorLoadingComponent',
  RESET: 'resetComponentEditorState',
  SET_TEMPLATE_ARGUMENTS: 'setTemplateArguments',
  SET_ACTIVE_BR_COMPONENT_INPUT: 'setActiveBRComponentInput',
  SET_COMPILED_COMPONENT: 'setCompiledComponent',
  START_LOADING_COMPILED_COMPONENT: 'startLoadingCompiledComponent',
  STOP_LOADING_COMPILED_COMPONENT: 'stopLoadingCompiledComponent',
  SET_ERROR_LOADING_COMPILED_COMPONENT: 'setErrorLoadingCompiledComponent',
  CLEAR_COMPILED_COMPONENT_ERRORS: 'clearCompiledComponentErrors',
  SET_PARENT_NAMESPACES: 'setParentNamespaces',
  INCREMENT_FORMULA_EDITOR_PROCESSING: 'incrementFormulaEditorProcessing',
  DECREMENT_FORMULA_EDITOR_PROCESSING: 'decrementFormulaEditorProcessing',
  SET_COMPONENT_UNSAVED: 'setComponentUnsaved',
  SET_COMPONENT_AVAILABLE_VERSIONS: 'setComponentAvailableVersions'
}

// To be used publicly outside vuex modules
export const MUTATIONS = {
  ...addNamespace(
    COMPONENT_EDITOR_STORE_PATH,
    _mutations
  ),
  RULE_EDITOR_CONTEXT: RuleEditorContextMutations
}
