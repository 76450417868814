import {_assignClaimsMutations} from './mutation-types'
import moment from 'moment'

export default {
  // Clear State
  [_assignClaimsMutations.CLEAR_STATE] (state) {
    state.pendingClaims = []
    state.pendingClaimsPagination.page = 1
    state.pendingClaimsPagination.total = 0

    state.filters = {}
    state.statuses = null
    state.lobs = null
    state.coverageTypes = null
    state.lossTypes = null
  },

  // Clear Claims
  [_assignClaimsMutations.CLEAR_CLAIMS] (state) {
    state.pendingClaims = []
    state.pendingClaimsPagination.page = 1
    state.pendingClaimsPagination.total = 0
  },

  // Set Claims Pagination
  [_assignClaimsMutations.SET_PENDING_CLAIMS] (state, pendingClaims) {
    state.pendingClaims = pendingClaims
  },
  [_assignClaimsMutations.SET_PENDING_CLAIMS_TOTAL] (state, total) {
    state.pendingClaimsPagination.total = total
  },
  [_assignClaimsMutations.SET_PENDING_CLAIMS_PAGE] (state, page) {
    state.pendingClaimsPagination.page = page
  },

  // Filters
  [_assignClaimsMutations.ADD_FILTER] (state, {value}) {
    if (value.field['pickerType'] === 'daterange') {
      state.filters['lossDateFrom'] = moment(value.value.minDate).format(
        'YYYY-MM-DD'
      )
      state.filters['lossDateTo'] = moment(value.value.maxDate).format(
        'YYYY-MM-DD'
      )
    } else {
      state.filters[value.field.name] = value.value
    }
  },
  [_assignClaimsMutations.REMOVE_FILTER] (state, field) {
    if (field['pickerType'] === 'daterange') {
      delete state.filters['lossDateFrom']
      delete state.filters['lossDateTo']
    } else {
      delete state.filters[field.name]
    }
  },
  [_assignClaimsMutations.SET_CLAIM_STATUSES] (state, statuses) {
    state.statuses = statuses
  },
  [_assignClaimsMutations.SET_LOBS] (state, lobs) {
    state.lobs = lobs
  },
  [_assignClaimsMutations.SET_COVERAGE_TYPES] (state, coverageTypes) {
    state.coverageTypes = coverageTypes
  },
  [_assignClaimsMutations.SET_LOSS_TYPES] (state, lossTypes) {
    state.lossTypes = lossTypes
  }
}
