const PURPOSE_TAG_CATEGORY = 'ComponentUsage'
const LABEL_TAG_CATEGORY = 'Label'

const COMPONENT_TYPES = {
  COMPONENT: 'component',
  MODEL: 'model',
  RULE: 'rule',
  TEMPLATE: 'template',
  TEST: 'test',
  TOOL: 'tool',
  VALUES_SET: 'values-set'
}

const COMPONENTS = {
  [COMPONENT_TYPES.COMPONENT]: {
    id: COMPONENT_TYPES.COMPONENT,
    label: 'Component',
    documentation: 'Generic component.',
    effective_date: false,
    tag: {
      category: PURPOSE_TAG_CATEGORY,
      value: COMPONENT_TYPES.COMPONENT
    },
    abstract: false
  },
  [COMPONENT_TYPES.MODEL]: {
    id: COMPONENT_TYPES.MODEL,
    label: 'Model',
    documentation: 'A model represents an objects of the business logic.',
    effective_date: false,
    tag: {
      category: PURPOSE_TAG_CATEGORY,
      value: COMPONENT_TYPES.MODEL
    },
    abstract: false
  },
  [COMPONENT_TYPES.RULE]: {
    id: COMPONENT_TYPES.RULE,
    label: 'Rule',
    documentation: 'A rule is the main element in BriteRules where you can define the condition to run and the action it does.',
    effective_date: true,
    tag: {
      category: PURPOSE_TAG_CATEGORY,
      value: COMPONENT_TYPES.RULE
    },
    abstract: false
  },
  [COMPONENT_TYPES.TEMPLATE]: {
    id: COMPONENT_TYPES.TEMPLATE,
    label: 'Template',
    documentation: 'A pre-defined rule with a set of arguments that can be configured.',
    effective_date: false,
    tag: {
      category: PURPOSE_TAG_CATEGORY,
      value: COMPONENT_TYPES.TEMPLATE
    },
    abstract: true
  },
  [COMPONENT_TYPES.TEST]: {
    id: COMPONENT_TYPES.TEST,
    label: 'Test',
    documentation: 'Tests are to validate rules.',
    effective_date: false,
    tag: {
      category: PURPOSE_TAG_CATEGORY,
      value: COMPONENT_TYPES.TEST
    },
    abstract: false
  },
  [COMPONENT_TYPES.TOOL]: {
    id: COMPONENT_TYPES.TOOL,
    label: 'Tool',
    documentation: 'Tools are used in templates and rules to create conditions or actions.',
    effective_date: false,
    tag: {
      category: PURPOSE_TAG_CATEGORY,
      value: COMPONENT_TYPES.TOOL
    },
    abstract: false
  },
  [COMPONENT_TYPES.VALUES_SET]: {
    id: COMPONENT_TYPES.VALUES_SET,
    label: 'Values Set',
    documentation: 'Values sets are a list of values.',
    effective_date: false,
    tag: {
      category: PURPOSE_TAG_CATEGORY,
      value: COMPONENT_TYPES.VALUES_SET
    },
    abstract: false
  }
}

const FRONTEND_TO_BACKEND_COMPONENT_MAPPING = {
  [COMPONENT_TYPES.COMPONENT]: 'component',
  [COMPONENT_TYPES.MODEL]: 'java',
  [COMPONENT_TYPES.RULE]: 'rule',
  [COMPONENT_TYPES.TEMPLATE]: 'rule',
  [COMPONENT_TYPES.TEST]: 'test',
  [COMPONENT_TYPES.TOOL]: 'fragment',
  [COMPONENT_TYPES.VALUES_SET]: 'java'
}

const BACKEND_TO_FRONTEND_COMPONENT_MAPPING = {
  'component': [COMPONENT_TYPES.COMPONENT],
  'java': [COMPONENT_TYPES.MODEL, COMPONENT_TYPES.VALUES_SET],
  'rule': [COMPONENT_TYPES.RULE, COMPONENT_TYPES.TEMPLATE],
  'test': [COMPONENT_TYPES.TEST],
  'fragment': [COMPONENT_TYPES.TOOL]
}

const COMPONENT_STATES = {
  ENABLED: 'enabled',
  DISABLED: 'disabled'
}

const COMPONENT_ACTION_TYPES = {
  DELETE: 'delete',
  CLONE: 'clone',
  ENABLE: 'enable',
  DISABLE: 'disable',
  MOVE: 'move',
  RENAME: 'remame'
}

const PROJECT_ACTION_TYPES = {
  TEST: 'test',
  VALIDATE: 'validate',
  EXPORT: 'export',
  EXPORT_WITH_DEPENDENCIES: 'export_with_dependencies',
  SYNC: 'sync',
  DELETE_PROJECT: 'delete_project'
}

const SIMPLE_COMPONENTS = [
  COMPONENT_TYPES.COMPONENT,
  COMPONENT_TYPES.MODEL,
  COMPONENT_TYPES.RULE,
  COMPONENT_TYPES.TEMPLATE,
  COMPONENT_TYPES.TEST,
  COMPONENT_TYPES.TOOL,
  COMPONENT_TYPES.VALUES_SET
]

const COMPONENT_ACTIONS = {
  [COMPONENT_ACTION_TYPES.DELETE]: {
    isVisible: (row) => {
      return SIMPLE_COMPONENTS.includes(row.type)
    },
    display: 'Delete',
    id: COMPONENT_ACTION_TYPES.DELETE,
    icon: 'trash'
  },
  [COMPONENT_ACTION_TYPES.CLONE]: {
    isVisible: (row) => {
      return SIMPLE_COMPONENTS.includes(row.type)
    },
    display: 'Clone',
    id: COMPONENT_ACTION_TYPES.CLONE,
    icon: 'copy'
  },
  [COMPONENT_ACTION_TYPES.ENABLE]: {
    isVisible: (row) => {
      return SIMPLE_COMPONENTS.includes(row.type) && row.disabled
    },
    display: 'Enable',
    id: COMPONENT_ACTION_TYPES.ENABLE,
    icon: 'check'
  },
  [COMPONENT_ACTION_TYPES.DISABLE]: {
    isVisible: (row) => {
      return SIMPLE_COMPONENTS.includes(row.type) && !row.disabled
    },
    display: 'Disable',
    id: COMPONENT_ACTION_TYPES.DISABLE,
    icon: 'times'
  },
  [COMPONENT_ACTION_TYPES.MOVE]: {
    isVisible: (row) => {
      return true
    },
    display: 'Move...',
    id: COMPONENT_ACTION_TYPES.MOVE,
    icon: 'file-alt'
  },
  [COMPONENT_ACTION_TYPES.RENAME]: {
    isVisible: (row) => {
      return row.type === 'folder'
    },
    display: 'Rename...',
    id: COMPONENT_ACTION_TYPES.RENAME,
    icon: 'pencil'
  }
}

const DATE_FORMAT = 'YYYYMMDDHHmm'
const DATEPICKER_DATE_FORMAT = 'yyyyMMddHHmm'
const EN_US_DATE_FORMAT = 'M/D/YYYY'
const DATEPICKER_EN_US_DATE_FORMAT = 'MM/dd/yyyy'

export {
  COMPONENT_TYPES,
  COMPONENT_STATES,
  COMPONENT_ACTION_TYPES,
  COMPONENTS,
  FRONTEND_TO_BACKEND_COMPONENT_MAPPING,
  BACKEND_TO_FRONTEND_COMPONENT_MAPPING,
  COMPONENT_ACTIONS,
  PROJECT_ACTION_TYPES,
  PURPOSE_TAG_CATEGORY,
  LABEL_TAG_CATEGORY,
  DATE_FORMAT,
  EN_US_DATE_FORMAT,
  DATEPICKER_DATE_FORMAT,
  DATEPICKER_EN_US_DATE_FORMAT
}
