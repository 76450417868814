import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL

/**
 * Renames the given documents API resource
 * @param {string} url - URL to send the request
 * @returns {Promise<Object>} Promise object that receives the API payload
 */
const _renameResource = function (url, newName) {
  return new Promise((resolve, reject) => {
    axios.patch(url, {name: newName}).then(response => {
      resolve(response.data.data)
    }).catch(function (error) {
      reject(error.response)
    })
  })
}
/**
 * Returns the id of the root folder
 * @returns {Promise<Object>} Promise object that receives the id of a root folder
 */
const _getRootFolderId = function () {
  const foldersUrl = `${API_URL}/documents/folders/`
  return new Promise((resolve, reject) => {
    axios.get(foldersUrl)
    .then(response => {
      const folders = response.data.data.results
      const rootFolder = folders.filter((g) => g.name === 'root').pop()
      resolve(rootFolder.id)
    })
  })
}

const documentsService = {
  copyObject: (object, newFolder) => {
    let urlPath = ''
    switch (object.type) {
      case 'document_template': urlPath = 'templates'; break
      case 'imported_document': urlPath = 'imported-documents'; break
      case 'asset': urlPath = 'assets'; break
      default: break
    }
    const url = `${API_URL}/documents/${urlPath}/${object.id}/copy/`
    const params = {
      folder: newFolder.id
    }
    return new Promise((resolve, reject) => {
      axios.post(url, params).then(response => {
        resolve(response)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  /**
   * Loads the template tree starting with the root Folder.
   * @returns {Promise<Object>} Promise object that receives the template tree.
   */
  getTemplateTree: (versions = true) => {
    return new Promise((resolve, reject) => {
      _getRootFolderId()
      .then(rootFolderId => {
        const folderUrl = `${API_URL}/documents/folders/` + rootFolderId + `/folder-tree/`
        const params = {
          versions: versions
        }
        axios.get(folderUrl, { params }).then(folderResponse => {
          resolve(folderResponse.data.data)
        }).catch(function (error) {
          reject(error.response)
        })
      })
      .catch(error => {
        reject(error.response)
      })
    })
  },
  /**
   * Returns the id of the root folder
   * @returns {Promise<Object>} Promise object that receives the id of a root folder
   */
  getRootFolderId: () => {
    return new Promise((resolve, reject) => {
      _getRootFolderId()
      .then(rootFolderId => {
        resolve(rootFolderId)
      })
      .catch(error => {
        reject(error.response)
      })
    })
  },
  /**
   * Fetches the base64 encoded data of an asset
   * @param assetId
   * @returns {Promise<Object>} Promise object that receives the encoded data
   */
  getAssetData: (assetId) => {
    const url = `${API_URL}/documents/assets/${assetId}/asset-data/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  /**
   * Fetches the html data of a template's version.
   * @param documentId
   * @param versionName
   * @param versionEffectiveDate
   * @returns {Promise<Object>} Promise object that receives the html data
   */
  getTemplateVersionData: (data) => {
    const url = `${API_URL}/documents/templates/${data.documentId}/template-data/`
    const params = {
      version_name: data.versionName,
      effective_date: data.versionEffectiveDate,
      version_id: data.versionId
    }
    return new Promise((resolve, reject) => {
      axios.get(url, {params: params}).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  /**
   * Fetches the pdf data of an ImportedDocument's version.
   * @param documentId
   * @param versionName
   * @param versionEffectiveDate
   * @returns {Promise<Object>} Promise object that receives the html data
   */
  getImportedDocumentVersionData: (data) => {
    const claimData = JSON.parse(window.localStorage.getItem('claim'))
    const quoteData = JSON.parse(window.localStorage.getItem('quote'))
    const policyData = JSON.parse(window.localStorage.getItem('policy'))
    const url = `${API_URL}/documents/imported-documents/${data.documentId}/document-data/`
    const params = {
      version_id: data.versionId,
      version_name: data.versionName,
      effective_date: data.versionEffectiveDate
    }
    if (claimData) {
      params['claim_id'] = claimData['previewId']
    }
    if (quoteData) {
      params['quote_number'] = quoteData['previewId']
    }
    if (policyData) {
      params['policy_id'] = policyData['previewId']
      params['revision_id'] = policyData['revisionId']
      params['invoice_id'] = policyData['invoiceId']
    }

    return new Promise((resolve, reject) => {
      axios.get(url, {params: params}).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  getComponentData: (data) => {
    const url = `${API_URL}/documents/components/${data.id}/component-data/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  /**
   * Fetches most recent named/productions versions
   * @param templateId
   * @returns {Promise<Array>} Promise object that receives an array of versions
  */
  getTemplateVersions: (templateId) => {
    const url = `${API_URL}/documents/templates/${templateId}/document-versions/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  getImportedDocumentVersions: (templateId) => {
    const url = `${API_URL}/documents/imported-documents/${templateId}/document-versions/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  /**
   * Creates a DocumentTemplate.
   * @param {Object} template
   * @param {string} template.name - Template's name
   * @param {string} template.html - Template's content
   * @param {string} template.description - A description of the template's purpose
   * @returns {Promise<Object>} Promise object that receives an the newly created DocumentTemplate
   */
  createTemplate: (template) => {
    const url = `${API_URL}/documents/templates/`
    const params = {
      name: template.name,
      folder: template.folder,
      html: template.html,
      description: template.description,
      tags: template.tags
    }
    return new Promise((resolve, reject) => {
      axios.post(url, params).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  /**
   * Creates a ComponentTemplate.
   * @param {Object} component
   * @param {string} component.name - Component's name
   * @param {string} component.folder - Component's folder
   * @param {string} component.html - Component's content
   * @param {string} component.description - A description of the component's purpose
   * @returns {Promise<Object>} Promise object that receives an the newly created ComponentTemplate
   */
  createComponentTemplate: (component) => {
    const url = `${API_URL}/documents/components/`
    const params = {
      name: component.name,
      folder: component.folder,
      html: component.html,
      description: component.description
    }
    return new Promise((resolve, reject) => {
      axios.post(url, params).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  /**
   * Creates a Folder.
   * @param folderName
   * @param parentFolderId
   * @returns {Promise<Object>} Promise object that receives an the newly created Folder
   */
  createFolder: (folderName, parentFolderId) => {
    const url = `${API_URL}/documents/folders/`
    const params = {
      name: folderName,
      folder: parentFolderId
    }
    return new Promise((resolve, reject) => {
      axios.post(url, params).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  /**
   * Creates a DocumentGroupService.
   * @param folderId
   * @param serviceSchemaEndpoint
   * @returns {Promise<Object>} Promise object that receives an the newly created DocumentGroupService
   */
  createService: (folderId, serviceSchemaEndpoint) => {
    const url = `${API_URL}/documents/services/`
    const params = {
      folder: folderId,
      schema_endpoint: serviceSchemaEndpoint
    }
    return new Promise((resolve, reject) => {
      axios.post(url, params).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  /**
   * Updates a DocumentTemplate.
   * @param templateId
   * @param templateName
   * @param folderId
   * @param templateHtml
   * @param templateDescription
   * @returns {Promise<Object>} Promise object that receives an the updated DocumentTemplate
   */
  updateTemplate: (templateData) => {
    let url = ''
    if (templateData.version_id && templateData.type !== 'component') {
      url = `${API_URL}/documents/versions/${templateData.version_id}/`
    } else if (templateData.type === 'component') {
      url = `${API_URL}/documents/components/${templateData.id}/`
    } else {
      url = `${API_URL}/documents/templates/${templateData.id}/`
    }
    return new Promise((resolve, reject) => {
      axios.patch(url, templateData).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
    /**
   * Creates a DocumentTemplate.
   * @param templateId
   * @param templateName
   * @param templateDescription
   * @param effectiveDate
   * @param versionId
   * @returns {Promise<Object>} Promise object that receives an the updated DocumentTemplate
   */
  createProductionVersion: (templateData) => {
    const url = `${API_URL}/documents/production-documents/`
    return new Promise((resolve, reject) => {
      templateData.version = templateData.version_id
      templateData.document_id = templateData.id
      axios.post(url, templateData).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  updateProductionVersion: (templateData) => {
    const url = `${API_URL}/documents/production-documents/${templateData.production_document_id}/`
    return new Promise((resolve, reject) => {
      templateData.version = templateData.version_id
      axios.patch(url, templateData).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  /**
   * Updates an ImportedDocument.
   * @param templateId
   * @param templateName
   * @param folderId
   * @param templateDescription
   * @returns {Promise<Object>} Promise object that receives an the updated ImportedDocument
   */
  updateImportedDocument: (importedDocumentData) => {
    const url = `${API_URL}/documents/imported-documents/${importedDocumentData.id}/`
    return new Promise((resolve, reject) => {
      axios.patch(url, importedDocumentData).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  /**
   * Updates a TemplateAsset.
   * @param {Object} asset
   * @param {string} asset.id - The id of the asset to be updated
   * @param {string} asset.name - The name of the asset
   * @param {string} asset.folder - The folder where the asset is located
   * @returns {Promise<Object>} Promise object that receives an the updated TemplateAsset
   */
  updateAsset: (asset) => {
    const url = `${API_URL}/documents/assets/${asset.id}/`
    const params = {
      name: asset.name,
      folder: asset.folder
    }
    return new Promise((resolve, reject) => {
      axios.put(url, params).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  /**
   * Updates a DocumentGroup.
   * @param folderId
   * @param folderName
   * @param parentFolderId
   * @returns {Promise<Object>} Promise object that receives an the updated DocumentGroup
   */
  updateFolder: (folderId, folderName, parentFolderId) => {
    const url = `${API_URL}/documents/folders/${folderId}/`
    const params = {
      name: folderName,
      folder: parentFolderId
    }
    return new Promise((resolve, reject) => {
      axios.put(url, params).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  /**
   * Updates a FormField.
   * @param fieldId
   * @param fieldName
   * @param fieldMapping
   * @param versionId
   * @returns {Promise<Object>} Promise object that receives an the updated FormField
   */
  updateFormField: (data) => {
    const url = `${API_URL}/documents/form-fields/${data.fieldId}/`
    const params = {
      name: data.fieldName,
      mapping: data.fieldMapping,
      edition: data.editionId
    }
    return new Promise((resolve, reject) => {
      axios.put(url, params).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  /**
   * Updates a DocumentGroupService.
   * @param serviceId
   * @param serviceSchemaEndpoint
   * @returns {Promise<Object>} Promise object that receives an the updated DocumentGroupService
   */
  updateService: (serviceId, serviceSchemaEndpoint) => {
    const url = `${API_URL}/documents/services/${serviceId}/`
    const params = {
      schema_endpoint: serviceSchemaEndpoint
    }
    return new Promise((resolve, reject) => {
      axios.put(url, params).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  /**
   * Returns the URL used to upload an asset
   * @returns {String} The Documents API URL for asset upload
   */
  assetUploadUrl: () => {
    return `${API_URL}/documents/assets/`
  },
  /**
   * Returns the URL used to upload a pdf
   * @returns {String} The Documents API URL for asset upload
   */
  pdfUploadUrl: () => {
    return `${API_URL}/documents/imported-documents/`
  },
  /**
   * Deletes the given Asset
   * @param {string} assetId - Asset's unique identifier
   * @returns {Promise<Object>} Promise object that receives the API payload
   */
  deleteAsset: (assetId) => {
    const url = `${API_URL}/documents/assets/${assetId}/`
    return new Promise((resolve, reject) => {
      axios.delete(url).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  /**
   * Deletes the given Document
   * @param {string} documentId - Documents's unique identifier
   * @returns {Promise<Object>} Promise object that receives the API payload
   */
  deleteDocument: (documentId) => {
    const url = `${API_URL}/documents/templates/${documentId}/`
    return new Promise((resolve, reject) => {
      axios.delete(url).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  deleteImportedDocument: (documentId) => {
    const url = `${API_URL}/documents/imported-documents/${documentId}/`
    return new Promise((resolve, reject) => {
      axios.delete(url).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  deleteImportedDocumentEdition: (editionId) => {
    const url = `${API_URL}/documents/form-editions/${editionId}/`
    return new Promise((resolve, reject) => {
      axios.delete(url).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  /**
   * Returns a PDF version of the document content
   * @param {String} documentId The document unique identifier
   * @returns {String} The rendered PDF content
   */
  documentPreview: (documentId, versionName, versionId, templateType) => {
    const claimData = JSON.parse(window.localStorage.getItem('claim'))
    const quoteData = JSON.parse(window.localStorage.getItem('quote'))
    const policyData = JSON.parse(window.localStorage.getItem('policy'))
    let url = `${API_URL}/documents/templates/${documentId}/preview-pdf/`
    if (templateType === 'component') {
      url = `${API_URL}/documents/components/${documentId}/preview-pdf/`
    }
    const params = {
      version_name: versionName,
      version_id: versionId
    }
    if (claimData) {
      params['claim_id'] = claimData['previewId']
    }
    if (quoteData) {
      params['quote_number'] = quoteData['previewId']
    }
    if (policyData) {
      params['policy_id'] = policyData['previewId']
      params['revision_id'] = policyData['revisionId']
      params['invoice_id'] = policyData['invoiceId']
    }
    return new Promise((resolve, reject) => {
      axios.get(url, { params }).then(response => {
        resolve(response)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  /**
   * Deletes the given Folder
   * @param {string} folderId - Group's unique identifier
   * @returns {Promise<Object>} Promise object that receives the API payload
   */
  deleteFolder: (folderId) => {
    const url = `${API_URL}/documents/folders/${folderId}/`
    return new Promise((resolve, reject) => {
      axios.delete(url).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  /**
   * Renames the given document
   * @param {string} documentId - Document's unique identifier
   * @returns {Promise<Object>} Promise object that receives the API payload
   */
  renameDocument: (documentId, newName) => {
    const url = `${API_URL}/documents/templates/${documentId}/`

    return _renameResource(url, newName)
  },
  /**
   * Renames the given folder
   * @param {string} folderId - Folder's unique identifier
   * @returns {Promise<Object>} Promise object that receives the API payload
   */
  renameFolder: (folderId, newName) => {
    const url = `${API_URL}/documents/folders/${folderId}/`

    return _renameResource(url, newName)
  },
  /**
   * Renames the given imported document
   * @param {string} importedDocumentId - Imported documents's unique identifier
   * @returns {Promise<Object>} Promise object that receives the API payload
   */
  renameImportedDocument: (importedDocumentId, newName) => {
    const url = `${API_URL}/documents/imported-documents/${importedDocumentId}/`

    return _renameResource(url, newName)
  },
  /**
   * Renames the given asset
   * @param {string} assetId - Asset's unique identifier
   * @returns {Promise<Object>} Promise object that receives the API payload
   */
  renameAsset: (assetId, newName) => {
    const url = `${API_URL}/documents/assets/${assetId}/`

    return _renameResource(url, newName)
  },
  fetchAppSyncData: () => {
    const url = `${API_URL}/documents/data-points/`
    const params = {
      format: 'tree'
    }
    return new Promise((resolve, reject) => {
      axios.get(url, { params }).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  fetchHelperFunctionsData: () => {
    const url = `${API_URL}/documents/helper-functions/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  fetchSettings: () => {
    const url = `${API_URL}/documents/settings/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  updateSetting: (setting, params) => {
    const url = `${API_URL}/documents/settings/${setting}/`
    return new Promise((resolve, reject) => {
      axios.patch(url, params).then(response => {
        resolve(response.data.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  fetchPreview: (id) => {
    const url = `${API_URL}/documents/previews/${id}/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },
  storePreviewData: (identifier, parameterName) => {
    const url = `${API_URL}/documents/preview-data/`
    const params = {identifier: identifier, parameter_name: parameterName}
    return new Promise((resolve, reject) => {
      axios.post(url, params).then(response => {
        resolve(response)
      }).catch(function (error) {
        reject(error)
      })
    })
  }
}

export default documentsService
