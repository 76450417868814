const FORM_LOAD_RULES_ACTION = 'form-load'
const FORM_SUBMIT_RULES_ACTION = 'form-submit'
const PAGE_SUBMIT_RULES_ACTION = 'page-submit'

const IN_PROGRESS = 'In Progress'
const IN_REVIEW = 'In Review'
const APPROVED = 'Approved'
const DECLINED = 'Declined'
const ABANDONED = 'Abandoned'
const QUOTE_STATUSES = {
  [IN_PROGRESS]: {
    color: 'u-text--info',
    rulesHeading: 'The quote will require underwriter review for the following reasons:'
  },
  [IN_REVIEW]: {
    color: 'u-text--warning',
    reviewPanelAlert: {
      title: 'Underwriter review is pending.',
      subtitle: '',
      type: 'info'
    },
    rulesHeading: 'This quote has been sent to the underwriter for the following reasons:'
  },
  [APPROVED]: {
    color: 'u-text--success',
    reviewPanelAlert: {
      title: 'Congratulations! Your quote has been approved by underwriting.',
      subtitle: '',
      type: 'success'
    },
    rulesHeading: 'Underwriting issues:'
  },
  [DECLINED]: {
    color: 'u-text--danger',
    reviewPanelAlert: {
      title: 'This quote has been declined by underwriting.',
      subtitle: '',
      type: 'error'
    },
    rulesHeading: 'This quote has been declined for the following reasons:'
  },
  [ABANDONED]: {
    color: 'u-text--muted',
    rulesHeading: 'Underwriting issues:'
  }
}
const UNKNOWN_STATUS_COLOR = 'u-text--info'
const QUOTE_PAGE_SIZE = 20
const BILLING_PAYMENT_TYPE_INITIAL = 'initial'
const BILLING_PAYMENT_TYPE_RECURRING = 'recurring'
const PAYMENT_METHOD_MANUAL_PAY = 'CASH'
const PAYMENT_METHOD_CREDIT_CARD = 'CC'
const PAYMENT_METHOD_ACH = 'ACH'
const ACTION_SHOW_ADD_PAYMENT_METHOD_FORM = 'show_add_payment_method_form'
const QUOTE_TRANSACTION_TYPE_NEW_BUSINESS = 'new_business'
const QUOTE_TRANSACTION_TYPE_ENDORSEMENT = 'endorsement'
const QUOTE_TRANSACTION_TYPE_RENEWAL = 'renewal'
const QUOTE_TRANSACTION_TYPE_REWRITE = 'rewrite'
const ACH_ACCOUNT_TYPES = [
  { label: 'Personal Checking', value: 'Personal Checking' },
  { label: 'Personal Savings', value: 'Personal Savings' },
  { label: 'Personal Loan', value: 'Personal Loan' },
  { label: 'Business Savings', value: 'Business Savings' },
  { label: 'Business Checking', value: 'Business Checking' },
  { label: 'Business Loan', value: 'Business Loan' }
]
const CREDIT_CARD_TYPES = [
  { label: 'American Express', value: 'American Express' },
  { label: 'VISA', value: 'VISA' },
  { label: 'Discover', value: 'Discover' },
  { label: 'MasterCard', value: 'MasterCard' }
]
const BC_ENDPOINT_ADD_PAYMENT_METHOD = '/api/v2/payments/add_payment_method'
const BC_ENDPOINT_GET_INSTALLMENTS_PREVIEW = '/api/v2/billing/get_installments_preview'
const BC_ENDPOINT_GET_INSTALLMENTS_PREVIEW_MID_TERM = '/api/v2/billing/get_installments_preview_mid_term'
const BC_ENDPOINT_GET_RENEWAL_INSTALLMENTS_PREVIEW = '/api/v2/billing/get_renewal_installments_preview'
const REVIEW_PANEL_QUOTE_FLOW_COMPONENT_TYPE = 'reviewPanel'
const RISK_EDIT_QUOTE_FLOW_COMPONENT_TYPE = 'riskEdit'
const GROUPED_RISK_EDIT_COMPONENT_TYPE = 'groupedRiskEdit'
const FOOTER_TOOLBAR_COMPONENT_TYPE = 'footerToolbar'
const MESSAGE_TYPES = {
  success: 'success',
  warning: 'warning',
  info: 'info',
  error: 'error'
}
const RISK_TYPE_STATE_MINIMAL = 'minimal'
const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY'
const ISO_DATE_FORMAT = 'YYYY-MM-DD'
const ITEM_TYPE_ENDORSEMENT = 'endorsement'
const ITEM_DELETED_KEY = 'deleted_at'

const ATTACHMENT_SOURCES = {
  USER: 'user',
  INTEGRATION: 'integration',
  BRITECORE: 'britecore'
}

const BRITECORE_TERMS = [
  { id: 'three_year', name: '3 Years', units: 3, granularity: 'years' },
  { id: 'eighteen_month', name: '18 Months', units: 18, granularity: 'months' },
  { id: 'one_year', name: '1 Year', units: 1, granularity: 'years' },
  { id: 'nine_month', name: '9 Months', units: 9, granularity: 'months' },
  { id: 'six_month', name: '6 Months', units: 6, granularity: 'months' },
  { id: 'three_month', name: '3 Months', units: 3, granularity: 'months' }
]

const FIELD_TYPE_STRING = 'string'
const FIELD_TYPE_NUMBER = 'number'
const FIELD_TYPE_DATE = 'date'
const FIELD_TYPE_DATE_TIME = 'datetime'
const FIELD_TYPE_ENUM = 'enum'

const FIELD_TYPE_VALIDATORS = {
  LENGTH_RANGE: 'length_range',
  NUMBER_RANGE: 'number_range',
  REGEX: 'regex',
  DECIMAL_PLACES: 'decimal_places',
  RELATIVE_DATE_RANGE: 'relative_date_range'
}

export {
  FORM_LOAD_RULES_ACTION,
  FORM_SUBMIT_RULES_ACTION,
  PAGE_SUBMIT_RULES_ACTION,
  IN_REVIEW,
  APPROVED,
  DECLINED,
  QUOTE_STATUSES,
  UNKNOWN_STATUS_COLOR,
  QUOTE_PAGE_SIZE,
  BILLING_PAYMENT_TYPE_INITIAL,
  BILLING_PAYMENT_TYPE_RECURRING,
  PAYMENT_METHOD_MANUAL_PAY,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_ACH,
  ACTION_SHOW_ADD_PAYMENT_METHOD_FORM,
  QUOTE_TRANSACTION_TYPE_NEW_BUSINESS,
  QUOTE_TRANSACTION_TYPE_ENDORSEMENT,
  QUOTE_TRANSACTION_TYPE_RENEWAL,
  QUOTE_TRANSACTION_TYPE_REWRITE,
  ACH_ACCOUNT_TYPES,
  CREDIT_CARD_TYPES,
  BC_ENDPOINT_ADD_PAYMENT_METHOD,
  BC_ENDPOINT_GET_INSTALLMENTS_PREVIEW,
  BC_ENDPOINT_GET_INSTALLMENTS_PREVIEW_MID_TERM,
  BC_ENDPOINT_GET_RENEWAL_INSTALLMENTS_PREVIEW,
  REVIEW_PANEL_QUOTE_FLOW_COMPONENT_TYPE,
  RISK_EDIT_QUOTE_FLOW_COMPONENT_TYPE,
  GROUPED_RISK_EDIT_COMPONENT_TYPE,
  FOOTER_TOOLBAR_COMPONENT_TYPE,
  MESSAGE_TYPES,
  RISK_TYPE_STATE_MINIMAL,
  DEFAULT_DATE_FORMAT,
  ISO_DATE_FORMAT,
  ITEM_TYPE_ENDORSEMENT,
  ITEM_DELETED_KEY,
  IN_PROGRESS,
  ATTACHMENT_SOURCES,
  BRITECORE_TERMS,
  FIELD_TYPE_STRING,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_DATE,
  FIELD_TYPE_DATE_TIME,
  FIELD_TYPE_ENUM,
  FIELD_TYPE_VALIDATORS
}
