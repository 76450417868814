const BRITERULES_CONNECTOR_STATE = {
  INITIAL: 'inital',
  RUNNING: 'running',
  DONE: 'done',
  FAILED_EXECUTION: 'failed_execution'
}

export {
  BRITERULES_CONNECTOR_STATE
}
