import {_claimMutations} from './mutation-types'
import {DEFAULT_FNOL_STEPS, DEFAULT_LAST_STEP} from '@/modules/claims/store/modules/claim/constants'

/**
 * Private methods declarations
*/

export default {
  [_claimMutations.SET_CLAIM] (state, claim) {
    state.claim = claim
  },
  [_claimMutations.SET_CLAIM_LOSS_LOCATION] (state, lossLocation) {
    state.claim.loss_location = lossLocation
  },
  [_claimMutations.SET_CLAIM_TAGS] (state, tags) {
    state.claim.tags = tags
  },
  [_claimMutations.SET_PRODUCT] (state, product) {
    state.product = product
  },
  [_claimMutations.SET_CURRENT_USER] (state, user) {
    state.currentUser = user
  },
  [_claimMutations.SET_REPORTER_ID] (state, partyId) {
    state.claim.reporter_id = partyId
  },
  [_claimMutations.SET_EXPOSURE] (state, exposure) {
    state.exposure = exposure
  },
  [_claimMutations.SET_EXPOSURE_TAGS] (state, exposureTags) {
    state.exposure.tags = exposureTags
  },
  [_claimMutations.SET_EXPOSURES] (state, exposures) {
    state.claim.exposures = exposures
  },
  [_claimMutations.REMOVE_EXPOSURE] (state, id) {
    state.claim.exposures = state.claim.exposures.filter(exposure => exposure.id !== id)
  },
  [_claimMutations.CLEAR_SUMMARY_STATE] (state) {
    state.claim = null
    state.exposure = null
    state.availableLabels = []
    state.product = null
    state.fnolSteps = [...DEFAULT_FNOL_STEPS]
  },
  [_claimMutations.CLEAR_EXPOSURE_STATE] (state) {
    state.exposure = null
  },
  [_claimMutations.SET_CLAIMANT_ACTIVE_TAB] (state, tab) {
    state.claimantActiveTab = tab
  },
  [_claimMutations.SET_INJURY_PERMISSION] (state, value) {
    state.injuryPermission = value
  },
  [_claimMutations.SET_ACCOUNTING_BREADCRUMB] (state, breadcrumbs) {
    state.accountingBreadcrumbs = breadcrumbs
  },
  [_claimMutations.SET_PARTIES] (state, parties) {
    state.parties = parties
  },
  [_claimMutations.SET_RISK_GROUPS] (state, items) {
    state.riskGroups = items
  },
  [_claimMutations.SET_ATTACHMENTS] (state, attachments) {
    state.attachments = attachments
  },
  [_claimMutations.CLEAR_ATTACHMENTS] (state) {
    state.attachments = null
  },

  // FNOL
  [_claimMutations.ADD_FNOL_STEPS] (state, steps) {
    state.fnolSteps = [...DEFAULT_FNOL_STEPS]
    state.fnolSteps.push(...steps)
    state.fnolSteps.push(...DEFAULT_LAST_STEP)
  },
  [_claimMutations.SET_FNOL_SAVING] (state, loading) {
    state.fnolSaving = loading
  },
  [_claimMutations.INCREMENT_CLAIM_POLICY_RISKS_COUNT] (state) {
    state.claim.policy_risks_count += 1
  },
  [_claimMutations.DECREMENT_CLAIM_POLICY_RISKS_COUNT] (state) {
    state.claim.policy_risks_count -= 1
  },
  [_claimMutations.INCREMENT_CLAIM_UNLISTED_RISKS_COUNT] (state) {
    state.claim.unlisted_risks_count += 1
  },
  [_claimMutations.DECREMENT_CLAIM_UNLISTED_RISKS_COUNT] (state) {
    state.claim.unlisted_risks_count -= 1
  },
  // contact
  [_claimMutations.SET_GENDERS] (state, genders) {
    state.genders = genders
  },
  [_claimMutations.SET_ADDRESS_TYPES] (state, addressTypes) {
    state.addressTypes = addressTypes
  },
  [_claimMutations.SET_ALLOW_DUPLICATE_SSN] (state, allowDuplicateSSN) {
    state.allowDuplicateSSN = allowDuplicateSSN
  }
}
