import {pickBy as _pickBy, isEmpty as _isEmpty} from 'lodash'
import {CLAIMS_ACTIVITY, CLAIM_OUTBOUND_EMAIL, NOTES} from './constants'

export default {
  namespaced: true,
  state: {
    activitySidebarActiveModule: null,
    activitySidebarModules: {
      [NOTES]: {
        icon: 'sticky-note',
        label: 'Notes',
        isEnabled: false,
        isHidden: true,
        expandByDefault: false,
        portalName: NOTES
      },
      [CLAIMS_ACTIVITY]: {
        icon: 'history',
        label: 'Claims Activity',
        isEnabled: false,
        isHidden: true,
        expandByDefault: true,
        portalName: 'claims-activity'
      },
      [CLAIM_OUTBOUND_EMAIL]: {
        icon: 'envelope',
        label: 'New Email',
        isEnabled: false,
        isHidden: true,
        expandByDefault: false,
        portalName: 'outbound-email'
      }
    },
    isActivitySidebarOpen: false,
    activitySidebarExpanded: false
  },
  getters: {
    activitySidebarModule: ({activitySidebarModules}) => (name) => activitySidebarModules[name],
    activitySidebarModulesNames: ({activitySidebarModules}) => Object.keys(activitySidebarModules),
    isSidebarActive: (state, {enabledSidebarModules}) => !_isEmpty(enabledSidebarModules),
    enabledSidebarModules: ({activitySidebarModules}) => _pickBy(activitySidebarModules, value => value.isEnabled)
  },
  mutations: {
    toggleActivitySidebar (state, forceValue) {
      if (forceValue === undefined) {
        state.isActivitySidebarOpen = !state.isActivitySidebarOpen
      } else {
        state.isActivitySidebarOpen = forceValue
      }
    },
    unsetActivitySidebarActiveModule (state) {
      state.activitySidebarActiveModule = null
    },
    setActivitySidebarActiveModule (state, activeModule) {
      state.activitySidebarActiveModule = activeModule
      Object.keys(state.activitySidebarModules).map(moduleName => {
        state.activitySidebarModules[moduleName].isHidden = moduleName !== activeModule
      })
    },
    toggleActivitySidebarExpanded (state, forceValue) {
      if (forceValue === undefined) {
        state.activitySidebarExpanded = !state.activitySidebarExpanded
      } else {
        state.activitySidebarExpanded = forceValue
      }
    },
    enableModule (state, name) {
      state.activitySidebarModules[name].isEnabled = true
    },
    disableModule (state, name) {
      state.activitySidebarModules[name].isEnabled = false
    },
    disableAllModules (state) {
      Object.keys(state.activitySidebarModules).forEach(key => {
        state.activitySidebarModules[key].isEnabled = false
      })
    }
  },
  actions: {
    toggleNotesSidebar ({commit}, forceValue) {
      commit('setActivitySidebarActiveModule', NOTES)
      commit('toggleActivitySidebar', forceValue)
    }
  }
}
