import axios from 'axios'

export default class AccessPolicyValidation {
  constructor () {
    this.url = `/api/access/policies/validations`
  }

  async validate (data) {
    try {
      let response = await axios.post(`${this.url}`, data)
      return (response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
