import {_mutations} from './mutation-types'
import Vue from 'vue'

export const mutations = {
  [_mutations.UPDATE_ACTIVE_PROJECT] (state, project) {
    Vue.set(state.activeProject, 'value', project)
    Vue.set(state.activeProject, 'loaded', true)
    Vue.set(state.activeProject, 'loading', false)
  },
  [_mutations.CLEAR_ACTIVE_PROJECT] (state) {
    mutations[_mutations.RESET_ACTIVE_PROJECT](state)
    mutations[_mutations.RESET_COMPONENTS](state)
    mutations[_mutations.UPDATE_SELECTED_CRITERIA](state, null)
    mutations[_mutations.RESET_AVAILABLE_FILTER_CRITERIA](state)
    mutations[_mutations.RESET_UNCOMMITTED_COMPONENTS_ERROR](state)
  },
  [_mutations.RESET_ACTIVE_PROJECT] (state) {
    Vue.set(state.activeProject, 'value', null)
    Vue.set(state.activeProject, 'error', null)
    Vue.set(state.activeProject, 'loaded', false)
    Vue.set(state.activeProject, 'loading', true)
  },
  [_mutations.START_LOADING_PROJECT] (state) {
    Vue.set(state.activeProject, 'error', null)
    Vue.set(state.activeProject, 'loaded', false)
    Vue.set(state.activeProject, 'loading', true)
  },
  [_mutations.SET_ERROR_LOADING_PROJECT] (state, error) {
    Vue.set(state.activeProject, 'error', error)
    Vue.set(state.activeProject, 'loaded', false)
    Vue.set(state.activeProject, 'loading', false)
  },
  [_mutations.SET_COMPONENTS] (state, components) {
    Vue.set(state.components, 'value', components)
  },
  [_mutations.RESET_COMPONENTS] (state) {
    Vue.set(state.components, 'value', [])
    Vue.set(state.components, 'error', null)
    Vue.set(state.components, 'loaded', false)
    Vue.set(state.components, 'loading', false)
  },
  [_mutations.START_LOADING_COMPONENTS] (state) {
    Vue.set(state.components, 'error', null)
    Vue.set(state.components, 'loaded', false)
    Vue.set(state.components, 'loading', true)
  },
  [_mutations.STOP_LOADING_COMPONENTS] (state) {
    Vue.set(state.components, 'loaded', true)
    Vue.set(state.components, 'loading', false)
  },
  [_mutations.SET_ERROR_LOADING_COMPONENTS] (state, error) {
    Vue.set(state.components, 'error', error)
  },
  [_mutations.UPDATE_SELECTED_CRITERIA] (state, criteria) {
    Vue.set(state.componentsFilter, 'selectedCriteria', criteria)
  },
  [_mutations.SET_AVAILABLE_FILTER_CRITERIA] (state, value) {
    Vue.set(state.componentsFilter.availableFilterCriteria, 'loading', false)
    Vue.set(state.componentsFilter.availableFilterCriteria, 'loaded', true)
    Vue.set(state.componentsFilter.availableFilterCriteria, 'value', value)
    Vue.set(state.componentsFilter.availableFilterCriteria, 'error', null)
  },
  [_mutations.SET_ERROR_BUILDING_AVAILABLE_FILTER_CRITERIA] (state, error) {
    Vue.set(state.componentsFilter.availableFilterCriteria, 'loading', false)
    Vue.set(state.componentsFilter.availableFilterCriteria, 'loaded', false)
    Vue.set(state.componentsFilter.availableFilterCriteria, 'value', null)
    Vue.set(state.componentsFilter.availableFilterCriteria, 'error', error)
  },
  [_mutations.RESET_AVAILABLE_FILTER_CRITERIA] (state) {
    Vue.set(state.componentsFilter.availableFilterCriteria, 'loading', true)
    Vue.set(state.componentsFilter.availableFilterCriteria, 'loaded', false)
    Vue.set(state.componentsFilter.availableFilterCriteria, 'value', null)
    Vue.set(state.componentsFilter.availableFilterCriteria, 'error', null)
  },
  [_mutations.RESET_UNCOMMITTED_COMPONENTS] (state) {
    state.uncommittedComponents.loading = false
    state.uncommittedComponents.loaded = false
    state.uncommittedComponents.value = null
    state.uncommittedComponents.error = null
  },
  [_mutations.START_LOADING_UNCOMMITTED_COMPONENTS] (state) {
    state.uncommittedComponents.loading = true
    state.uncommittedComponents.error = null
  },
  [_mutations.SET_UNCOMMITTED_COMPONENTS] (state, uncommittedComponents) {
    state.uncommittedComponents.value = uncommittedComponents
    state.uncommittedComponents.loaded = true
    state.uncommittedComponents.loading = false
  },
  [_mutations.SET_UNCOMMITTED_COMPONENTS_ERROR] (state, error) {
    state.uncommittedComponents.error = error
    state.uncommittedComponents.loading = false
    state.uncommittedComponents.loaded = false
  },
  [_mutations.RESET_UNCOMMITTED_COMPONENTS_ERROR] (state) {
    state.uncommittedComponents.value = null
    state.uncommittedComponents.error = null
    state.uncommittedComponents.loading = false
    state.uncommittedComponents.loaded = false
  },
  [_mutations.OPEN_PROJECT_TEST_DIALOG] (state) {
    state.testProject.dialogVisible = true
  },
  [_mutations.CLOSE_PROJECT_TEST_DIALOG] (state) {
    state.testProject.dialogVisible = false
  },
  [_mutations.OPEN_PROJECT_VALIDATE_DIALOG] (state) {
    state.validateProject.dialogVisible = true
  },
  [_mutations.CLOSE_PROJECT_VALIDATE_DIALOG] (state) {
    state.validateProject.dialogVisible = false
  },
  [_mutations.START_VALIDATE_PROJECT] (state) {
    state.validateProject.running = true
    state.validateProject.validationErrors = []
    state.validateProject.serviceError = false
  },
  [_mutations.SET_VALIDATION_RESULT] (state, result) {
    state.validateProject.running = false
    state.validateProject.validationErrors = result && result.errors || []
    state.validateProject.serviceError = false
  },
  [_mutations.SET_VALIDATION_SERVICE_ERROR] (state, result) {
    state.validateProject.running = false
    state.validateProject.validationErrors = []
    state.validateProject.serviceError = true
  },
  [_mutations.RUN_PROJECT_TESTS] (state) {
    state.testProject.jobRunner.run('runProjectTests', [state.activeProject.value.projectId])
  },
  [_mutations.STOP_PROJECT_TESTS_EXECUTION] (state) {
    state.testProject.jobRunner.stop()
  }
}
