<template>
  <el-dialog
    title="BriteCore Support"
    :visible="value"
    @update:visible="$emit('input', $event)"
    append-to-body>
    <h4 class="u-text--bold">Need help with something?</h4>

    Go to the new
    <a
      target="_blank"
      href="https://help.britecore.com">
      BriteCore Help
    </a> site.

    <p class="u-text--sm">Press <kbd>?</kbd> to see a list of keyboard shortcuts</p>

    <hr>

    <h4 class="u-text--bold">Still having problems?</h4>

    <el-button
      @click="$emit('submit-support-ticket-clicked')"
      type="text"
      class="u-block">
      Submit a Support Ticket
    </el-button>
  </el-dialog>
</template>

<script>
export default {
  name: 'GetHelpModal',
  props: {
    value: { type: Boolean, required: true }
  }
}
</script>
