import Vue from 'vue'
import { _mutationTypes } from './mutationTypes'

export const mutations = {
  [_mutationTypes.SET_LINE] (state, line) {
    state.line = line
  },
  [_mutationTypes.SET_PRODUCT] (state, product) {
    state.product = product
  },
  [_mutationTypes.RESET_PRODUCT] (state) {
    state.product = {}
  },
  [_mutationTypes.SET_RISK_TYPE] (state, riskType) {
    state.riskType = riskType
  },
  [_mutationTypes.SET_VERSIONS] (state, versions) {
    state.versions = versions
  },
  [_mutationTypes.SET_VERSION] (state, version) {
    state.versions.unshift(version)
  },
  [_mutationTypes.UPDATE_VERSION] (state, version) {
    const index = state.versions.findIndex(item => item.id === version.id)
    Vue.set(state.versions, index, version)
    if (state.activeVersion && state.activeVersion.id === version.id) {
      state.activeVersion = version
    }
  },
  [_mutationTypes.SET_ACTIVE_VERSION] (state, version) {
    state.activeVersion = version
  },
  [_mutationTypes.SET_LINE_OVERVIEW_ACTIVE_PRODUCT] (state, productId) {
    state.lineOverviewActiveProducts.push(productId)
  },
  [_mutationTypes.SET_LINE_OVERVIEW_ACTIVE_PRODUCTS] (state, productsIds) {
    state.lineOverviewActiveProducts = productsIds
  },
  [_mutationTypes.SET_FIELD_FORMATS] (state, fieldFormats) {
    state.fieldFormats = fieldFormats
  },
  [_mutationTypes.SET_BREADCRUMBS] (state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs
  },
  [_mutationTypes.SET_PRODUCT_IMPORTS] (state, productImports) {
    state.productImports = productImports
  },
  [_mutationTypes.SET_PRODUCT_IMPORT] (state, { index, productImport }) {
    Vue.set(state.productImports, index, productImport)
  },
  [_mutationTypes.REMOVE_PRODUCT_IMPORT] (state, { index }) {
    state.productImports.splice(index, 1)
  },
  [_mutationTypes.REMOVE_ENTITY_FROM_RISK_TYPE] (state, { entityKey, entityIndex }) {
    Vue.delete(state.riskType[entityKey], entityIndex)
  },
  [_mutationTypes.SET_GEN2_BUSINESS_LOCATIONS] (state, gen2BusinessLocations) {
    state.gen2BusinessLocations = gen2BusinessLocations
  }
}
