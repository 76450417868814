import _ from 'lodash'

const convertType = function (type) {
  if (type === 'imported_document' || type === 'document_template') {
    return 'document'
  }

  return type
}

export const mutations = {
  templateTree (state, templateTree) {
    state.templateTree = templateTree
  },
  template (state, template) {
    state.template = template
  },

  ADD_VERSION: (state, version) => {
    state.template = {
      ...state.template,
      versions: [...state.template.versions, version]
    }
  },

  importedDocument (state, importedDocument) {
    state.importedDocument = importedDocument
  },
  asset (state, asset) {
    state.asset = asset
  },
  addOpenTemplate (state, template) {
    let index = _.findIndex(state.openTemplates, function (t) {
      return t.id === template.id
    })
    if (index > -1) {
      state.openTemplates[index] = template
    } else {
      state.openTemplates.push(template)
    }
    state.template = template
  },
  closeTemplate (state, template) {
    var index = _.findIndex(state.openTemplates, (t) => {
      return t.id === template.id
    })
    state.openTemplates.splice(index, 1)
    if (template.id === state.template.id) {
      if (state.openTemplates.length > 0) {
        state.template = state.openTemplates[0]
      } else {
        state.template = null
      }
    }
  },
  activeVersion (state, activeVersion) {
    state.activeVersion = activeVersion
  },
  assets (state, assets) {
    state.assets = assets
    state.assetIds = Object.keys(assets)
  },
  settings (state, settings) {
    state.settings = settings
    state.settingIds = Object.keys(settings)
  },
  documents (state, documents) {
    state.documents = documents
    state.documentIds = Object.keys(documents)
  },
  folders (state, folders) {
    state.folders = folders
    state.folderIds = Object.keys(folders)
  },
  rename (state, payload) {
    const stateGroup = `${convertType(payload.type)}s`
    state[stateGroup][payload.objectId].name = payload.newName
  },
  delete (state, payload) {
    const idGroup = `${convertType(payload.type)}Ids`
    const stateGroup = `${convertType(payload.type)}s`
    const index = state[idGroup].indexOf(payload.objectId)

    if (index !== -1) {
      delete state[stateGroup][payload.objectId]
      state[idGroup].splice(index, 1)
    }
  },
  updateAsset (state, payload) {
    state.assets[payload.id] = payload
  },
  deleteTemplate (state, payload) {
    state.template = null
  },
  // We should probably define a generic update
  // mutation for all modules instead of this one
  updateActiveVersion (state, propertiesToUpdate) {
    Object.keys(propertiesToUpdate).forEach(property => {
      state.activeVersion[property] = propertiesToUpdate[property]
    })
  },
  templateIsValid (state, valid) {
    state.templateIsValid = valid
  },
  searchType (state, searchType) {
    state.searchType = searchType
  },
  previewClaimData (state, data) {
    state.previewClaimData = data
  },
  previewQuoteData (state, data) {
    state.previewQuoteData = data
  },
  previewPolicyData (state, data) {
    state.previewPolicyData = data
  },
  currentDocumentId (state, id) {
    state.currentDocumentId = id
  }
}
