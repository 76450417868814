import RemoteResourceRepository from './repositories/RemoteResourceRepository'

 /**
*  Instantiates the correct repository given the repository mode
* @param {Object} config the configuration object passed down to the repositories
* @returns {Repository} the instantiated repository
*/
const create = function (resource, config) {
  switch (process.env.VUE_APP_ACCESS_REPOSITORY_STRATEGY) {
    case 'remote':
      return new RemoteResourceRepository(resource, config)
    default:
      return new RemoteResourceRepository(resource, config)
  }
}

export default create
