<template>
  <div class="c-nav-sub-sidebar">
    <h3 class="c-nav-sidebar__header u-mb3">
      <span v-if="sidebarHeaderTitle">{{ sidebarHeaderTitle }} </span>
      <span v-else>{{ activeModule }} </span>
    </h3>
    <portal-target name="module_nav"></portal-target>
    <hr class="c-nav-sidebar__divider">
    <portal-target name="page_nav"></portal-target>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState(['activeModule', 'sidebarHeaderTitle'])
  }
}
</script>
