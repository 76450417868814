import {addNamespace} from '@/store/helpers'

export const mutationTypes = {
  SET_ACCESS_POLICIES: 'setAccessPolicies',
  SET_CURRENT_ACCESS_POLICY: 'setCurrentAccessPolicy',
  SET_ROLES: 'setRoles',
  SET_USERS: 'setUsers',
  SET_CURRENT_PAGE: 'setCurrentPage',
  RESET_ACCESS_POLICIES: 'resetAccessPolicies',
  RESET_ACCESS_POLICY: 'resetAccessPolicy',
  SET_PAGINATION_TOTAL: 'setAccessPoliciesTotal',
  SET_ACCESS_POLICY_STATEMENTS: 'setAccessPolicyStatements',
  SET_ACCESS_POLICY_ASSIGNMENTS: 'setAccessPolicyAssignments',
  SET_LOCAL_ACCESS_POLICY_ASSIGNMENTS: 'setLocalAccessPolicyAssignments',
  SET_ASSIGNMENTS_WERE_MODIFIED: 'setAssignmentsWereModified',
  SET_EFFECTIVE_ROLE: 'setEffectiveRole',
  SET_ROLE: 'setRole',
  SET_GROUPS: 'setGroups',
  SET_LOCAL_ROLE: 'setLocalRole',
  SET_LOCAL_ROLES_ASSIGNMENTS: 'setLocalRolesAssignments',
  SET_ROLE_ASSIGNMENTS: 'setRolesAssignments',
  RESET_ROLE: 'resetRole',
  RESET_ROLES_LIST: 'resetRolesList',
  RESET_USERS_LIST: 'resetUsersList',
  RESET_GROUPS_LIST: 'resetGroupsList',
  RESET_ASSIGNMENTS_PAGINATION: 'resetAssignmentsPagination',
  SET_CURRENT_ROLES_PAGE: 'setCurrentRolesPage',
  SET_CURRENT_USERS_PAGE: 'setCurrentUsersPage',
  SET_CURRENT_GROUPS_PAGE: 'setCurrentGroupsPage',
  SET_ROLES_PAGINATION_TOTAL: 'setRolesTotal',
  SET_USERS_PAGINATION_TOTAL: 'setUsersTotal',
  SET_GROUPS_PAGINATION_TOTAL: 'setGroupsTotal',
  SET_USER_ROLES: 'setUserRoles',
  SET_ASSIGNED_USERS_GROUPS_RECORDS: 'setAssignedUsersGroupsRecords',
  SET_EVALUATE_RESULT: 'setEvaluationsResult',
  CLEAR_EVALUATE_RESULT: 'clearEvaluationsResult'
}

export default addNamespace('access/', mutationTypes)
