import {ACTIVE_PROJECT_STORE_PATH} from '../constants'
import {addNamespace} from '@/store/helpers'

// To be used only inside vuex modules
export const _mutations = {
  SET_COMPONENTS: 'setComponents',
  RESET_COMPONENTS: 'resetComponents',
  START_LOADING_COMPONENTS: 'startLoadingComponents',
  STOP_LOADING_COMPONENTS: 'stopLoadingComponents',
  SET_ERROR_LOADING_COMPONENTS: 'setErrorLoadingComponents',
  UPDATE_SELECTED_CRITERIA: 'updateSelectedCriteria',
  UPDATE_SEARCH_QUERY: 'updateSearchQuery',
  SET_AVAILABLE_FILTER_CRITERIA: 'setAvailableFilterCriteria',
  SET_ERROR_BUILDING_AVAILABLE_FILTER_CRITERIA: 'setErrorBuildingAvailableFilterCriteria',
  RESET_AVAILABLE_FILTER_CRITERIA: 'resetAvailableFilterCriteria',
  START_LOADING_PROJECT: 'startLoadingProject',
  UPDATE_ACTIVE_PROJECT: 'updateActiveProject',
  RESET_ACTIVE_PROJECT: 'resetActiveProject',
  CLEAR_ACTIVE_PROJECT: 'clearActiveProject',
  SET_ERROR_LOADING_PROJECT: 'setErrorLoadingProject',
  START_LOADING_UNCOMMITTED_COMPONENTS: 'startLoadingUncommittedComponents',
  RESET_UNCOMMITTED_COMPONENTS: 'resetUncommittedComponents',
  SET_UNCOMMITTED_COMPONENTS: 'setUncommittedComponentsLoading',
  SET_UNCOMMITTED_COMPONENTS_ERROR: 'setUncommittedComponentsError',
  RESET_UNCOMMITTED_COMPONENTS_ERROR: 'resetUncommittedComponentsError',
  OPEN_PROJECT_TEST_DIALOG: 'openProjectTestDialog',
  CLOSE_PROJECT_TEST_DIALOG: 'closeProjectTestDialog',
  OPEN_PROJECT_VALIDATE_DIALOG: 'openProjectValidateDialog',
  CLOSE_PROJECT_VALIDATE_DIALOG: 'closeProjectValidateDialog',
  START_VALIDATE_PROJECT: 'startValidateProject',
  SET_VALIDATION_RESULT: 'setValidationResult',
  SET_VALIDATION_SERVICE_ERROR: 'setValidationServiceError',
  RUN_PROJECT_TESTS: 'runProjectTests',
  STOP_PROJECT_TESTS_EXECUTION: 'stopProjectTestsExecution'
}

// To be used publicly outside vuex modules
export const MUTATIONS = addNamespace(
  ACTIVE_PROJECT_STORE_PATH,
  _mutations
)
