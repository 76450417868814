import Promise from 'bluebird'
import {JSO, Fetcher, Popup} from 'jso/dist/jso'
import UserManagementService from '@/api/userManagement'

const CALLBACK_URI = process.env.VUE_APP_CALLBACK_URI
const GOOGLE_CLIENT_ID = process.env.VUE_APP_GOOGLE_CLIENT_ID

function getGoogleOauthClient () {
  const SCOPE = 'https://www.googleapis.com/auth/userinfo.profile'
  const AUTHORIZATION_URI = 'https://accounts.google.com/o/oauth2/auth'
  const USER_DETAILS_URI = 'https://www.googleapis.com/oauth2/v1/userinfo'

  const client = new JSO({
    providerID: 'google',
    client_id: GOOGLE_CLIENT_ID,
    authorization: AUTHORIZATION_URI,
    scopes: {request: [SCOPE]}
  })

  return { client, USER_DETAILS_URI }
}

const idpToConfigMapping = {
  'Google': getGoogleOauthClient
}

export const actions = {
  async fetchUserDetails (_, {client, USER_DETAILS_URI}) {
    return new Promise((resolve, reject) => {
      const fetcher = new Fetcher(client)
      fetcher.fetch(USER_DETAILS_URI, {})
      .then(data => data.json())
      .then((data) => {
        if (data.id) resolve(data.id)
        else reject(new Error('Sorry, but we could not retrieve your info from social account to link'))
      })
      .catch(err => reject(err))
    })
  },
  linkAccountWith ({ commit, dispatch }, identityProvider) {
    const { client, USER_DETAILS_URI } = idpToConfigMapping[identityProvider]()

    client.setLoader(Popup)

    client.getToken({ redirect_uri: CALLBACK_URI })
    .then(() => {
      commit('isAccountLinking', true)
      return dispatch('fetchUserDetails', {client, USER_DETAILS_URI})
    })
    .then((userId) => {
      // once user id retrieved, remove tokens from local storage
      localStorage.removeItem(`tokens-google`)
      return dispatch('linkSocialAccountIdWithUser', {userId, provider: identityProvider})
    })
    .then((success) => {
      commit('accountLinkingSuccess', success)
    })
    .catch((error) => {
      commit('accountLinkingError', error.message)
    })
  },
  async linkSocialAccountIdWithUser ({ rootState }, {userId, provider}) {
    return new Promise((resolve, reject) => {
      const { username } = rootState.authentication.currentUser
      UserManagementService.linkToProvider(username, provider, userId)
      .then(() => {
        resolve('Account linked successfully. Now you can login using linked account!')
      })
      .catch((error) => {
        reject(error)
      })
    })
  }
}
