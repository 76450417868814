export default {
  // Get Selected Claims
  selectedClaims (state) {
    return state.pendingClaims.filter(claim => claim.$selected)
  },

  // Count Selected Claims
  countSelectedClaims (state) {
    return state.pendingClaims.filter(claim => claim.$selected).length
  }
}
