export const mutations = {
  userPoolSettings (state, settings) {
    state.userPoolSettings = settings
  },
  setBreadcrumbs (state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs
  },
  setUsers (state, users) {
    state.users = users
  },
  appendUsers (state, users) {
    state.users = [...state.users, ...users]
  },
  setGroups (state, groups) {
    state.groups = groups
  },
  appendGroups (state, groups) {
    state.groups = [...state.groups, ...groups]
  },
  updateUsersPagination (state, next) {
    state.usersPagination.next = next
  },
  updateGroupsPagination (state, next) {
    state.groupsPagination.next = next
  },
  updatePasswordPolicy (state, passwordPolicy) {
    state.passwordPolicy = passwordPolicy
  },
  updatePasswordPolicyExpiration (state, passwordPolicyExpiration) {
    state.passwordPolicyExpiration = passwordPolicyExpiration
  }
}
