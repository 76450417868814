export const makeEvaluationKey = (resource, action, record = null, context = null) => {
  let key = `${resource}::${action}`
  if (record) {
    key = `${key}.${record}`
  }
  if (context) {
    context.forEach(subContext => { key = `${key}.${subContext.selector}` })
  }
  return key
}
