
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ProductBaseFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Product"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"label"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"parentProductId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"settings"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"renumberAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"terms"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"baseTerm"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":137}};
    doc.loc.source = {"body":"fragment ProductBaseFragment on Product {\n  id\n  name\n  label\n  parentProductId\n  settings {\n    renumberAt\n    terms\n    baseTerm\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
