import { state } from './state'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'

const documentsModule = {
  namespaced: true,
  state () {
    return Object.assign({}, state)
  },
  getters: getters,
  actions: actions,
  mutations: mutations
}

export default documentsModule
