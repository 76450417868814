import {Message} from '@britecore/bc-design-system'

export const errorMiddleware = {
  name: 'error-middleware',
  error: (error) => {
    let errorMessage
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2x
      switch (error.response.status) {
        case 400:
          errorMessage = error.response.data.errors[0].detail
          break
        case 401:
          errorMessage = 'Authorization Required.'
          break
        case 403:
          errorMessage = 'Forbidden resource can\'t be accessed.'
          break
        case 404:
          errorMessage = 'The requested resource not found.'
          break
        case 500:
          errorMessage = 'Internal Server Error.'
          break
        default:
          errorMessage = 'Service unavailable'
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser
      // "Network Error" will fall here
      errorMessage = error.message
    } else {
      // Something happened in setting up the request that triggered an Error
      throw error.message
    }
    Message.error(errorMessage)
  }
}
