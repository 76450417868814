<template>
  <el-dialog
    :title="dialogTitle"
    :visible="value"
    @update:visible="$emit('input', $event)"
    max-width="600px"
    top="30px"
    :close-on-click-modal="false"
    lock-scroll
    append-to-body
    @keydown.native.prevent.up="navigateUpSearchResults"
    @keydown.native.prevent.down="navigateDownSearchResults"
    @keydown.native.prevent.enter="openSearchItem">

    <!-- start search bar -->
    <div class="u-mb5">
      <div
        v-if="searchType !== 'Contact' || searchInput.length"
        class="sub-title u-text--sm u-mb2"
        v-html="searchInputHelpText">
      </div>
      <el-input
        ref="searchInput"
        v-model="searchInput"
        @keydown.native.prevent.tab="updateSearchFieldWithPlaceHolder"
        :placeholder="searchInputPlaceholder">
      </el-input>
    </div>
    <!-- end search bar -->

    <!-- start search results -->
    <div>
      <h5 class="c-heading__sub">{{ searchResultsTitle }}</h5>
      <div class="sub-title u-text--sm u-mb3">
        Use ↑ and ↓ to navigate results.
      </div>

      <div v-loading="fetchingSearchResults">
        <div
          v-if="isNoSearchResults"
          class="u-text--info">
          <fa-icon icon="info-circle"></fa-icon>
          No matching {{ pluralizedSearchType }} found.
        </div>
        <ul
          v-else
          class="search-results">
          <li
            v-for="(searchResult, index) in currentSearchResults"
            :key="index">
            <component
              :is="searchItemComponent"
              :data="searchResult"
              :class="{ 'search-results__search-item--active': activeSearchItemIndex === index }">
            </component>
          </li>
        </ul>
      </div>
    </div>
    <!-- end search results -->

  </el-dialog>
</template>

<script>
import QuickSearchPolicyItem from './QuickSearchPolicyItem'
import QuickSearchClaimItem from './QuickSearchClaimItem'
import QuickSearchContactItem from './QuickSearchContactItem'
import { mapState, mapActions } from 'vuex'
import { debounce } from 'lodash'

export default {
  name: 'QuickSearchModal',
  components: {
    QuickSearchPolicyItem,
    QuickSearchClaimItem,
    QuickSearchContactItem
  },
  props: {
    value: { type: Boolean, required: true },
    searchType: { type: String, required: true }
  },
  data () {
    return {
      activeSearchItemIndex: 0,
      searchInput: '',
      fetchingSearchResults: false
    }
  },
  computed: {
    ...mapState('bcClassic', [
      'recentPolicies',
      'recentClaims',
      'recentContacts',
      'searchResults',
      'metaData'
    ]),
    recentSearches () {
      if (this.searchType === 'Policy') return this.recentPolicies
      if (this.searchType === 'Claim') return this.recentClaims
      if (this.searchType === 'Contact') return this.recentContacts
      return []
    },
    currentSearchResults () {
      return this.searchInput.length ? this.searchResults : this.recentSearches
    },
    pluralizedSearchType () {
      let searchType

      if (this.searchType === 'Policy') searchType = 'Policies'
      else searchType = `${this.searchType}s`

      return searchType
    },
    dialogTitle () {
      return `${this.searchType} Quick Search`
    },
    searchResultsTitle () {
      return this.searchInput.length ? `Matching ${this.pluralizedSearchType}` : `Recently Viewed ${this.pluralizedSearchType}`
    },
    searchInputHelpText () {
      return this.searchInput ? `<strong>↵ Enter</strong> selects a ${this.searchType.toLowerCase()}.` : '<strong>Tab</strong> fills in the prefix.'
    },
    searchInputPlaceholder () {
      let placeholder = ''
      const date = new Date()
      const { policyPrefix, claimPrefix } = this.metaData

      if (this.searchType === 'Policy') placeholder = policyPrefix ? `${policyPrefix}-${date.getFullYear()}-` : `${date.getFullYear()}-`
      if (this.searchType === 'Claim') placeholder = claimPrefix ? `${claimPrefix}-${date.getFullYear()}-` : `${date.getFullYear()}-`

      return placeholder
    },
    searchItemComponent () {
      let component

      if (this.searchType === 'Policy') component = 'QuickSearchPolicyItem'
      if (this.searchType === 'Claim') component = 'QuickSearchClaimItem'
      if (this.searchType === 'Contact') component = 'QuickSearchContactItem'

      return component
    },
    redirectURL () {
      let url

      if (this.searchType === 'Policy') url = '/britecore/policies/information?policyId='
      if (this.searchType === 'Claim') url = '/britecore/claims/information?claimId='
      if (this.searchType === 'Contact') url = '/britecore/contacts/information?id='

      return url
    },
    isNoSearchResults () {
      return this.searchInput.length > 1 && this.searchResults.length === 0 && !this.fetchingSearchResults
    }
  },
  methods: {
    ...mapActions('bcClassic', ['getQuickSearchResults']),
    navigateUpSearchResults () {
      this.activeSearchItemIndex = this.activeSearchItemIndex === 0 ? this.currentSearchResults.length - 1 : this.activeSearchItemIndex - 1
    },
    navigateDownSearchResults () {
      this.activeSearchItemIndex = this.activeSearchItemIndex === this.currentSearchResults.length - 1 ? 0 : this.activeSearchItemIndex + 1
    },
    updateSearchFieldWithPlaceHolder () {
      if (this.searchType === 'Contact' || this.searchInput.length > 0) return
      this.searchInput = this.searchInputPlaceholder
    },
    openSearchItem () {
      if (this.currentSearchResults.length === 0) return
      if (this.currentSearchResults[this.activeSearchItemIndex].dbPolicyId) {
        window.location = `${this.redirectURL}${this.currentSearchResults[this.activeSearchItemIndex].dbPolicyId}`
      } else {
        window.location = `${this.redirectURL}${this.currentSearchResults[this.activeSearchItemIndex].id}`
      }
    }
  },
  watch: {
    searchInput: debounce(async function (newVal) {
      if (newVal.length > 1) {
        this.activeSearchItemIndex = 0
        this.fetchingSearchResults = true
        await this.getQuickSearchResults({
          searchInput: newVal,
          searchType: this.searchType
        })
        this.fetchingSearchResults = false
      }
    }, 250),
    searchType (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.activeSearchItemIndex = 0
        this.searchInput = ''
        setTimeout(() => {
          this.$refs.searchInput.focus()
        }, 1)
      }
    }
  }
}
</script>
