import {JsonApiService} from '@/modules/claims/services/api'

class AuditLogApi extends JsonApiService {
  constructor () {
    super('audit-log')
  }
}

class AuditLogActionApi extends JsonApiService {
  constructor () {
    super('audit-log-action')
  }
}

class AuditLogCategoryApi extends JsonApiService {
  constructor () {
    super('audit-log-category')
  }
}

export {
  AuditLogApi,
  AuditLogActionApi,
  AuditLogCategoryApi
}
