import { mapState } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

const requiredMemberCheckerFor = function (mixinName, requiredMembersKey) {
  const requiredMemberChecker = function () {
    const requiredMembers = this.$data[requiredMembersKey]
    for (const [requiredMember, validityChecker] of Object.entries(requiredMembers)) {
      const member = this[requiredMember]
      if (!validityChecker(member)) {
        console.error(
          `The ${requiredMember} member required by the ${mixinName} was either undefined or invalid.`
        )
      }
    }
  }

  return requiredMemberChecker
}

export const AttributesMixin = {
  methods: {
    getUpdatedAtAttribute: function () {
      return {
        Name: 'updated_at',
        Value: moment().unix().toString()
      }
    }
  }
}

/*
  ProfileUpdateMixin has common stuff for updating user
  profile for both my-account and admin user edit page.
*/
export const ProfileUpdateMixin = {
  data () {
    return {
      briteauthUserAttributes: {},
      // Keeps a reference to the phone number before editing
      knownUserPhoneNumber: null,
      updatingUserAttributes: false,
      gen2Contact: null,
      // These members must be defined by the implementer.
      $_profileUpdate_requiredMembers: {
        refreshUserProfile: _.isFunction,
        updateUserProfile: _.isFunction,
        phoneChangedWhenSmsMfaEnabledPrompt: _.isString,
        smsMfaEnabled: _.isBoolean,
        isUserEditMode: _.isBoolean
      }

    }
  },
  async mounted () {
    this.$_profileUpdate_checkForRequiredMembers()
    await this.refreshUserProfile()
    this.showLoader = false
  },
  computed: {
    ...mapState({
      currentUser: state => state.authentication.currentUser,
      session: state => state.authentication.session
    })
  },
  methods: {
    saveUserProfile: async function (attributesToUpdate) {
      if (attributesToUpdate && attributesToUpdate.has('picture')) {
        // bypass validation if picture is updated since picture updated as single attribute
        await this.updateUserProfile(attributesToUpdate)
        this.showLoader = false
      } else {
        try {
          await this.$refs.userProfile.isValid()
          this.updateUserAttributes(attributesToUpdate)
        } catch (err) {
          this.$message.error('We\'re sorry, but some information is missing.')
        }
      }
    },

    updateBriteauthUserAttributesProp: function (attr, value) {
      this.$set(this.briteauthUserAttributes, attr, value)
    },

    updateUserAttributes: async function (attributesToUpdate) {
      let proceedWithUpdate = true
      if (this.smsMfaEnabled && this.knownUserPhoneNumber !== this.briteauthUserAttributes.phone) {
        try {
          await this.confirmPhoneNumberChange()
          await this.$refs.securitySettings.disableSMSMFAMethod(true)
          this.$message.info('SMS based MFA has been disabled.')
        } catch (error) {
          proceedWithUpdate = false
          if (error !== 'cancel') {
            this.$message.error(
              `Unable to disable SMS MFA. Your updates have not been saved ` +
              `to avoid loss of access to account.`
            )
          }
        }
      }

      if (proceedWithUpdate) {
        this.updatingUserAttributes = true

        try {
          await this.updateUserProfile(attributesToUpdate)
          await this.refreshUserProfile()
        } catch (err) {
          try {
            this.$addJsonAPIErrors(err.response.data.errors, true)
          } catch (e) {
            // This exception should only occur when response data is not present
            console.error(e)
          } finally {
            this.$message.error('We\'re sorry, but we were unable to save your changes.')
          }
        } finally {
          this.updatingUserAttributes = false
          this.showLoader = false
        }
      }
    },
    confirmPhoneNumberChange: function () {
      return this.$confirm(
        this.phoneChangedWhenSmsMfaEnabledPrompt, 'Warning', {
          confirmButtonText: 'Continue',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })
    },
    $_profileUpdate_checkForRequiredMembers: requiredMemberCheckerFor('ProfileUpdateMixin', '$_profileUpdate_requiredMembers')
  }
}

/*
  SecuritySettingsMixin has common stuff for updating user's
  security settings for both my-account and admin user edit page.
*/
export const SecuritySettingsMixin = {
  data () {
    return {
      updatingUserAttributes: false,
      // These members must be defined by the implementer.
      $_securitySettings_requiredMembers: {
        setUserMFAPreference: _.isFunction,
        mfaMethods: _.isArray,
        mfaTypes: _.isObject
      }

    }
  },
  mounted () {
    this.$_securitySettings_checkForRequiredMembers()
  },
  methods: {
    disableMFAMethod: async function (methodType, forced) {
      let mfaPreference = {
        [methodType]: {
          preferred: false,
          enabled: false
        }
      }

      await this.setUserMFAPreference(mfaPreference, forced)
    },

    disableSMSMFAMethod: async function (forced) {
      await this.disableMFAMethod(this.mfaTypes.SMS, forced)
    },

    disableAllMFAMethods: async function (enabledMfaList) {
      let mfaPreference = {}

      for (let method of this.mfaMethods) {
        if (enabledMfaList && _.includes(enabledMfaList, method.type) || !enabledMfaList && method.enabled) {
          mfaPreference[method.type] = {
            preferred: false,
            enabled: false
          }
        }
      }

      await this.setUserMFAPreference(mfaPreference)
    },

    convertMethodPreferenceForCognito: function (methodPreference) {
      if (methodPreference) {
        return {
          PreferredMfa: methodPreference.preferred,
          Enabled: methodPreference.enabled
        }
      } else {
        return null
      }
    },

    setPreferredMFAMethod: async function (methodType) {
      const mfaPreference = {
        [methodType]: { enabled: true, preferred: true }
      }
      await this.setUserMFAPreference(mfaPreference)
    },

    confirmDisableMFAMethod: async function (mfaMethod) {
      try {
        await this.$confirm(`This will disable ${mfaMethod.label} MFA. Are you sure?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        await this.disableMFAMethod(mfaMethod.type)
        this.$message.success(`${mfaMethod.label} disabled`)
      } catch (_) {}
    },
    $_securitySettings_checkForRequiredMembers: requiredMemberCheckerFor('SecuritySettingsMixin', '$_securitySettings_requiredMembers')
  }
}
