import { sortBy } from 'lodash'

export const getters = {
  /**
   * Makes message attributes available as an array sorted by keys for convenient access
   * @returns { [message_id]: [{ key, value }, { key, value }] }
   */
  messageAttributes (state) {
    const ret = {}
    Object.values(state.events.subscriptionMessages).map(item => item.data).forEach(messages => {
      messages.forEach(message => {
        ret[message.id] = sortBy(Object.entries(message.attributes).map(([key, value]) => ({ key, value })), 'key')
      })
    })
    return ret
  },
  pluginAttributes (state) {
    const ret = {}
    Object.values(state.plugins.registered).forEach(plugin => {
      ret[plugin.id] = sortBy(Object.entries(plugin.attributes).map(([key, value]) => ({ key, value })), 'key')
    })
    return ret
  },
}
