export const clickOutside = {
  bind (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (el !== event.target && !el.contains(event.target)) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('mouseup', el.clickOutsideEvent)
    document.body.addEventListener('touchend', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('mouseup', el.clickOutsideEvent)
    document.body.removeEventListener('touchend', el.clickOutsideEvent)
    delete el.clickOutsideEvent
  }
}
