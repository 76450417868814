const UsersAndGroupsList = () => import('@/pages/users/UsersAndGroupsList')
const AdminSettings = () => import('@/pages/users/AdminSettings')
const EditUser = () => import('@/pages/users/EditUser')

export default [
  {
    path: 'manage/:inviteUserContactId?',
    name: 'users:manage',
    component: UsersAndGroupsList,
    props: true
  },
  {
    path: 'admin-settings',
    name: 'users:admin-settings',
    component: AdminSettings
  },
  {
    path: 'edit/:username',
    name: 'users:edit',
    component: EditUser
  }
]
