<template>
  <div
    class="c-nav-sidebar__wrapper"
    v-click-outside="handleClickOutside">

    <get-help-modal
      @submit-support-ticket-clicked="submitSupportTicket"
      v-model="getHelpModal.visible">
    </get-help-modal>

    <submit-support-ticket-modal v-model="submitSupportTicketModal.visible"></submit-support-ticket-modal>

    <nav
      ref="sidebar"
      class="c-nav-sidebar l-flex-grid--no-wrap l-flex-grid__column">

      <router-link
        :to="{ path: '/', query: { pageReload: true } }"
        class="c-nav-main-sidebar-britecore">

        <img src="../../../assets/britecore_logo.svg"
          class="c-nav-main-sidebar-britecore__image"/>

        <h3
          class="c-nav-main-sidebar__link__text c-nav-main-sidebar-britecore__text">
          <div class="c-nav-main-sidebar__link__text-content">BriteCore</div>
        </h3>
      </router-link>

      <div class="c-nav-main-sidebar">
        <router-link
          :to="{ path: '/', query: { pageReload: true } }"
          :class="[
            'c-nav-main-sidebar__link',
            'c-nav-main-sidebar__client-dashboard-link',
            {'c-nav-main-sidebar__link--active': 'All Modules' === activeModule},
            {'c-nav-main-sidebar__link--not-selected': 'All Modules' !== activeModule}
          ]">
          <fa-icon
            icon="home"
            size="lg"
            fixed-width>
          </fa-icon>
          <div class="c-nav-main-sidebar__link__text">Dashboard</div>
        </router-link>

        <template v-if="!isLoading">
          <router-link
            :key="module.label"
            v-for="module in currentModuleLinks"
            :to="module.location"
            :class="[
              'c-nav-main-sidebar__link',
              {'c-nav-main-sidebar__link--active': module.label === activeModule},
              {'c-nav-main-sidebar__link--not-selected': activeModule !== 'All Modules' && module.label !== activeModule},
              {'c-nav-main-sidebar__link--bottom': module.moduleName === 'all-modules'}
            ]"
            @click.native="handleSidebarClose">

              <fa-icon
                :icon="module.icon"
                size="lg"
                class="c-nav-main-sidebar__icon"
                fixed-width>
              </fa-icon>

              <div
                class="c-nav-main-sidebar__link__text"
                v-text="module.label"></div>

          </router-link>
        </template>

        <fa-icon
          v-if="isLoading"
          :icon="['far', 'spinner-third']"
          size="lg"
          class="icon--spin-fast c-nav-main-sidebar__loading-icon"
          fixed-width
          spin
        />

        <div
          class="c-nav-sidebar__help-btn"
          @click="getHelpModal.visible = true">
          <fa-icon
            icon="question-circle"
            class="u-mr1">
          </fa-icon>
          Get help with BriteCore
        </div>

      </div>
    </nav>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GetHelpModal from './GetHelpModal'
import SubmitSupportTicketModal from './SubmitSupportTicketModal'
import { BriteAccessMixin } from '@/pages/access/mixin'
import { ACCESS_MODULES_NAMES, ACCESS_MODULES_ACCESS_ATTEMPTS } from '@/pages/access/constants'

export default {
  components: {
    GetHelpModal,
    SubmitSupportTicketModal
  },
  mixins: [BriteAccessMixin],
  data: function () {
    return {
      getHelpModal: {
        visible: false
      },
      submitSupportTicketModal: {
        visible: false
      }
    }
  },
  async mounted () {
    await this.$access.evaluate({
      accessAttempts: ACCESS_MODULES_ACCESS_ATTEMPTS
    })
  },
  computed: {
    ...mapState(['activeModule', 'sidebarVisible']),
    isAdmin: function () {
      return this.$store.getters['authentication/isAdmin']
    },
    releaseDate: function () {
      return this.$store.getters['bcClassic/releaseDate']
    },
    currentModuleLinks () {
      return this.$store.getters['moduleLinks/currentModuleLinks']
        .filter(module => module.topNav)
        .filter(module => {
          // allow modules that are not mapped yet
          if (!ACCESS_MODULES_NAMES.includes(module.accessModuleName)) {
            return true
          }
          return this.$access({ resource: `bc.${module.accessModuleName}.britecoreui`, action: 'ViewModule' })
        }).map(module => {
          if (module.preferGen2) return { ...module, ...module.gen2Meta }
          return module
        })
    },
    isLoading () {
      return !this.$access.ready
    }
  },
  methods: {
    submitSupportTicket () {
      this.getHelpModal.visible = false
      this.submitSupportTicketModal.visible = true
    },
    handleSidebarClose () {
      if (!this.sidebarVisible) return
      this.$store.commit('toggleSidebar', 'hide')
    },
    handleClickOutside (evt) {
      const togglerEl = document.querySelector('.main-sidebar-toggler')
      if (togglerEl === event.target || togglerEl.contains(event.target)) return
      this.handleSidebarClose()
    }
  }
}
</script>
