export default class RepositoryInterface {
  /**
   * Creates a new entry on the repository
   * @param {Object} obj object is used to provide named arguments to this method
   *  @param {Object} data the data to create an entity record
   */
  createRecord ({data}) {

  }

  /**
   * Reads a collection and lists the result applying the provided queries
   * @param {Object} obj object is used to provide named arguments to this method
   *  @param {Object} queries contains queries to be performed on the collection
   *  @param {Object} options configuration object for the read method
   */
  listRecords ({queries = null, options = null}) {

  }

  /**
   * Reads an entity record identified by the id provided
   * @param {Object} obj object is used to provide named arguments to this method
   *  @param {String} id used to identify the entity record
   */
  readRecord ({id}) {

  }

  /**
   * Updates an entity record with the provided id
   * @param {Object} obj object is used to provide named arguments to this method
   *  @param {String} id used to identify the entity record
   *  @param {Object} data the data to update an entity record
   *  @param {Boolean} partial controls if the update is partial or not
   */
  updateRecord ({id, data, partial = false}) {

  }

  /**
   * Deletes an entity record with the provided id
   * @param {Object} obj object is used to provide named arguments to this method
   *  @param {String} id used to identify the entity record
   */
  deleteRecord ({id}) {

  }
}
