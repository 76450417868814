import {_adminSetupMutations} from './mutation-types'

export default {
  [_adminSetupMutations.SET_BREADCRUMB] (state, breadcrumb) {
    state.breadcrumbs = breadcrumb
  },
  [_adminSetupMutations.SET_ADMIN_SETUP_MODULES] (state, modules) {
    state.adminSetupModules = modules
  },
  [_adminSetupMutations.UPDATE_MODULES_SEARCH_QUERY] (state, query) {
    state.modulesSearchQuery = query
  }
}
