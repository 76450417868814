import productExportsService from '@/api/lines/productexports'
import { _actionTypes } from './actionTypes'
import { _mutationTypes } from './mutationTypes'

export const actions = {
  [_actionTypes.INITIALIZE_EXPORT] ({ dispatch }, { productName, versionId }) {
    return new Promise((resolve, reject) => {
      productExportsService.exportProduct(
        productName,
        versionId
      ).then((exportData) => {
        resolve(exportData)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [_actionTypes.LOAD_EXPORT] ({ dispatch }, { productName, exportId, versionId }) {
    return new Promise((resolve, reject) => {
      productExportsService.getExport(
        productName,
        exportId,
        versionId
      ).then((exportData) => {
        resolve(exportData)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [_actionTypes.LOAD_EXPORTS] ({ dispatch }, { productName, versionId }) {
    return new Promise((resolve, reject) => {
      productExportsService.getExports(
        productName,
        versionId
      ).then((exports) => {
        dispatch(_actionTypes.SET_EXPORTS, exports)
        resolve(exports)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [_actionTypes.SET_EXPORT] ({ commit }, exportData) {
    commit(_mutationTypes.SET_EXPORT, exportData)
  },
  [_actionTypes.SET_EXPORTS] ({ commit }, exports) {
    commit(_mutationTypes.SET_EXPORTS, exports)
  }
}
