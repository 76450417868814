/**
 * This component encapsulates the role select for Access
 *
 * */

import { mapState, mapActions, mapMutations } from 'vuex'
import accessActions from '@/store/access/actionTypes'
import accessMutations from '@/store/access/mutationTypes'
import { ACCESS_MODULES_ACCESS_ATTEMPTS } from '@/pages/access/constants'
import { isGen2Route } from '@/router/utils'
import sortBy from 'lodash/sortBy'

export default {
  name: 'AccessRolesSelect',
  data () {
    return {
      loading: false,
      selectedRole: ''
    }
  },
  props: {
    redirectTo: {
      type: String
    },
    forceReload: {
      type: Boolean,
      default: false
    }
  },
  async mounted () {
    try {
      this.loading = true
      await this.fetchUserRoles({username: this.currentUser.username, usergroups: this.currentUserGroups})
      this.selectedRole = this.effectiveRole || localStorage.getItem('EffectiveRole')
    } catch (error) {
      this.$message.error(error.message)
    } finally {
      this.loading = false
    }
  },
  computed: {
    ...mapState('access', [
      'userRoles', 'effectiveRole'
    ]),
    ...mapState('authentication', [
      'currentUser',
      'currentUserGroups'
    ]),
    noRolesAvailable () {
      return !this.loading && this.userRoles.length === 0
    }
  },
  methods: {
    ...mapActions({
      fetchUserRoles: accessActions.FETCH_USER_ROLES
    }),
    ...mapActions(['setWindowLocation']),
    ...mapActions('authentication', [
      'signOut'
    ]),
    ...mapMutations({
      setEffectiveRole: accessMutations.SET_EFFECTIVE_ROLE
    }),
    async setRole (selectedRoleName) {
      this.loading = true
      const selectedRoleIndex = this.userRoles.findIndex((userRole) => {
        return userRole.name === selectedRoleName
      })
      const selectedRole = this.userRoles[selectedRoleIndex]
      this.setEffectiveRole(selectedRole.name)
      await this.$store.dispatch(accessActions.SELECT_USER_ROLE, selectedRole.name)
      await this.$store.dispatch('bcClassic/setRole', selectedRole.gen2_role)

      if (this.redirectTo && isGen2Route(this.redirectTo)) {
        await this.$store.dispatch(accessActions.EVALUATE_ACCESS_ATTEMPTS, ACCESS_MODULES_ACCESS_ATTEMPTS)
      }

      this.loading = false

      if (this.redirectTo && isGen2Route(this.redirectTo)) {
        this.setWindowLocation(this.redirectTo)
      }

      if (this.forceReload) {
        /**
         * Reloads page when switching roles if prop is true
         */
        if (this.$route.name === 'forbidden') {
          this.$router.go(-1)
        } else {
          this.$router.go()
        }
      }
      this.$router.replace(this.redirectTo || {name: 'all-modules'})
    },
    async logOut () {
      this.loading = true
      await this.signOut()
      this.loading = false
    }
  },
  render () {
    return this.$scopedSlots.default({
      userRoles: sortBy(this.userRoles, role => role.name),
      selectedRole: this.effectiveRole || this.selectedRole,
      roleSelected: this.setRole,
      loading: this.loading,
      noRolesAvailable: this.noRolesAvailable,
      logOut: this.logOut
    })
  }
}
