import get from 'lodash/get'
import find from 'lodash/find'
const RuleEditorDefault = () => import('../rule-editor/RuleEditorDefault')
const QuotingRuleEditor = () => import('../rule-editor/custom-rule-editor/quoting/QuotingRuleEditor')
const FormInclusionRuleEditor = () => import('../rule-editor/custom-rule-editor/form-inclusion/FormInclusionRuleEditor')
const SubtemplateEditorDefault = () => import('../rule-editor/SubtemplateEditorDefault')
const SearchRiskToolEditor = () => import('../rule-editor/custom-rule-editor/quoting/tools/conditions/SearchRiskToolEditor')
const SearchAllToolEditor = () => import('../rule-editor/custom-rule-editor/quoting/tools/conditions/SearchAllToolEditor')
const NoRiskToolEditor = () => import('../rule-editor/custom-rule-editor/quoting/tools/conditions/NoRiskToolEditor')
const CountToolEditor = () => import('../rule-editor/custom-rule-editor/quoting/tools/conditions/CountToolEditor')
const AssertThat = () => import('../rule-editor/custom-rule-editor/quoting/tools/conditions/AssertThatToolEditor')
const FilterCurrentToolEditor = () => import('../rule-editor/custom-rule-editor/quoting/tools/conditions/FilterCurrentToolEditor')
const ShowToolEditor = () => import('../rule-editor/custom-rule-editor/quoting/tools/actions/ShowToolEditor')
const HideToolEditor = () => import('../rule-editor/custom-rule-editor/quoting/tools/actions/HideToolEditor')
const ResetToolEditor = () => import('../rule-editor/custom-rule-editor/quoting/tools/actions/ResetToolEditor')
const EnableOrDisableToolEditor = () => import('../rule-editor/custom-rule-editor/quoting/tools/actions/EnableOrDisableToolEditor')
const ErrorToolEditor = () => import('../rule-editor/custom-rule-editor/quoting/tools/actions/ErrorToolEditor')
const SetFieldValueToolEditor = () => import('../rule-editor/custom-rule-editor/quoting/tools/actions/SetFieldValueToolEditor')
const AddRemoveItemToolEditor = () => import('../rule-editor/custom-rule-editor/quoting/tools/actions/AddRemoveItemToolEditor')
const ReferToolEditor = () => import('../rule-editor/custom-rule-editor/quoting/tools/actions/ReferToolEditor')
const SetFieldOptionsToolEditor = () => import('../rule-editor/custom-rule-editor/quoting/tools/actions/SetFieldOptionsToolEditor')
const RestoreFieldOptionsToolEditor = () => import('../rule-editor/custom-rule-editor/quoting/tools/actions/RestoreFieldOptionsToolEditor')
import { COMPONENT_FUNCTION_PREFIX } from '@/pages/rules/component-editor/utils/formula'

const ARRAY_IDENTIFIER = 'array::'

/**
 * Returns true if string value matches with array type
 * @param String value
 */
const isArrayType = (value) => {
  return value && (value === 'array' || value.startsWith(ARRAY_IDENTIFIER))
}

/**
 * Returns the type of the array from the string representation of the type
 * @param String value
 */
const getArrayType = (value) => {
  if (isArrayType(value)) {
    return value.substring(ARRAY_IDENTIFIER.length)
  }
  return 'object'
}

/**
 * Returns true if the value is not null and not undefined
 * @param {*} value
 */
const isDefined = (value) => {
  return value !== null && value !== undefined
}

const overrideInputTypeIfIsNotDefined = (schema) => {
  if (!schema['input-type'] && isArrayType(schema.type)) {
    schema['input-type'] = {
      type: 'array',
      subtype: getArrayType(schema.type)
    }
  }
}

const getSchemaArgument = (argumentItem, schemaArgument) => {
  let schema = {
    name: schemaArgument.name,
    label: get(argumentItem, 'label', get(schemaArgument, 'label', get(schemaArgument, 'name', ''))),
    documentation: get(argumentItem, 'documentation', get(schemaArgument, 'documentation', get(schemaArgument, 'description'))),
    type: get(argumentItem, 'type', get(schemaArgument, 'type')),
    'input-type': get(argumentItem, 'input-type', get(schemaArgument, 'input-type', {})),
    defaultValue: get(argumentItem, 'default', get(schemaArgument, 'default')),
    value: schemaArgument.value
  }
  overrideInputTypeIfIsNotDefined(schema)
  return schema
}

const mergeNamespaces = (from, to, readonly) => {
  from = from || []
  from.forEach((namespace) => {
    if (namespace) {
      var foundNamespace = find(to, {name: namespace.name})
      if (!foundNamespace) {
        foundNamespace = {
          name: namespace.name,
          items: []
        }
        to.push(foundNamespace)
      }
      if (namespace.value && Array.isArray(namespace.value)) {
        namespace.value.forEach((value) => {
          var foundValue = find(foundNamespace.items, { value })
          if (!foundValue) {
            foundNamespace.items.push({
              value,
              readonly
            })
          }
        })
      }
    }
  })
}

const buildAutocompleteOption = (suggestion) => {
  let autocompleteOption = {
    caption: `${suggestion.name}`,
    meta: `${suggestion.label} | ${suggestion.suggestionType}`
  }
  switch (suggestion.suggestionType) {
    case 'component':
      let splittedName = suggestion.name.split('.')
      let componentShortName = splittedName[splittedName.length - 1]
      if (suggestion.componentDetails && suggestion.componentDetails.arguments) {
        let argumentsDisplayedToUser = suggestion.componentDetails.arguments.filter((arg) => {
          return !isDefined(arg.value)
        })
        let snippetArgs = argumentsDisplayedToUser.map((arg, index) => {
          return `\${${index + 1}:${arg.name}}`
        })
        autocompleteOption.snippet = `${COMPONENT_FUNCTION_PREFIX}${componentShortName}(${snippetArgs.join(', ')})`
        autocompleteOption.caption = `${COMPONENT_FUNCTION_PREFIX}${componentShortName}(${argumentsDisplayedToUser.map(a => a.name).join(', ')})`
      } else {
        autocompleteOption.snippet = componentShortName
        autocompleteOption.caption = componentShortName
      }
      break
    case 'value':
      autocompleteOption.value = suggestion.value
      break
  }
  return autocompleteOption
}

const ruleEditorTemplateMapping = {
  'forms-commons.rules.templates.attach-forms-to-document': FormInclusionRuleEditor,
  'quoting.templates.quoting-template': QuotingRuleEditor
}

const getRuleEditorComponent = (templateName) => {
  return ruleEditorTemplateMapping[templateName] || RuleEditorDefault
}

const isCustomEditor = (templateName) => {
  return !!ruleEditorTemplateMapping[templateName]
}

const toolEditorMapping = {
  'quoting.tools.search-risk': SearchRiskToolEditor,
  'quoting.tools.search-all': SearchAllToolEditor,
  'quoting.tools.filter-current': FilterCurrentToolEditor,
  'quoting.tools.no-risk': NoRiskToolEditor,
  'framework.rules.search.count': CountToolEditor,
  'framework.rules.search.assert-that': AssertThat,
  'quoting.actions.show': ShowToolEditor,
  'quoting.actions.hide': HideToolEditor,
  'quoting.actions.enable-or-disable': EnableOrDisableToolEditor,
  'quoting.actions.error': ErrorToolEditor,
  'quoting.actions.set-field-value': SetFieldValueToolEditor,
  'quoting.actions.add-remove-item': AddRemoveItemToolEditor,
  'quoting.actions.refer': ReferToolEditor,
  'quoting.actions.reset': ResetToolEditor,
  'quoting.actions.set-field-options': SetFieldOptionsToolEditor,
  'quoting.actions.restore-field-options': RestoreFieldOptionsToolEditor
}

const getCustomToolEditor = (toolName) => {
  return toolEditorMapping[toolName] || SubtemplateEditorDefault
}

const isStringQuoted = (text) => {
  return text && text[0] === '"' && text[text.length - 1] === '"'
}

const unQuote = (text) => {
  return isStringQuoted(text) && isDefined(text) ? text.substring(1, text.length - 1) : text
}

const quote = (text) => {
  return isStringQuoted(text) || !isDefined(text) ? text : (text[0] === '"') ? `${text}"` : text[text.length - 1] === '"' ? `"${text}` : `"${text}"`
}

const getFirstMustachedVariable = (text) => {
  if (!isDefined(text)) {
    return undefined
  }
  let moustacheOpenPosition = text.indexOf('{{')
  let moustacheClosePosition = text.indexOf('}}', moustacheOpenPosition)
  if (moustacheOpenPosition >= 0 && moustacheClosePosition >= moustacheOpenPosition) {
    return text.substring(moustacheOpenPosition, moustacheClosePosition + 2)
  } else {
    return undefined
  }
}

const getComponentNameFromMustache = (mustache) => {
  return mustache ? mustache.split('.')[1] : undefined
}

export {
  isArrayType,
  getArrayType,
  getSchemaArgument,
  isDefined,
  mergeNamespaces,
  buildAutocompleteOption,
  ARRAY_IDENTIFIER,
  isCustomEditor,
  getRuleEditorComponent,
  getCustomToolEditor,
  quote,
  unQuote,
  isStringQuoted,
  getFirstMustachedVariable,
  getComponentNameFromMustache
}
