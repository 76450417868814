import _ from 'lodash'

/*
* Prepends namespace to every value in an object
*/
export function addNamespace (namespace, types) {
  return _.reduce(types, (typeObj, typeValue, typeName) => {
    typeObj[typeName] = namespace + typeValue
    return typeObj
  }, {})
}
/**
* Creates a reset function that iterates over the state reseting it to the initial state
*
* @param {Object} initState a function to return the initial state
* @returns {function(Object, Object)} a mutation that accepts an array of keys to be ignored by the reset
*   @returns {Object} state - default mutation first parameter
*   @returns {Object} options - ignore options such as ignore fields
*     @returns {Array} options.ignore - ignore array of fields to be ignored
*/

export const reset = (initState) => (state, {ignore} = {ignore: []}) => {
  const preservedState = _.pick(state, ignore)
  const initial = initState()
  _.mapKeys(initial, (value, key) => {
    let shouldPreserve = preservedState[key] !== undefined
    state[key] = shouldPreserve ? preservedState[key] : initial[key]
  })
}
