// REST
import {
  EavApi
} from '@/modules/claims/services/eav'

// JSON:API
import {
  AuditLogApi,
  AuditLogActionApi,
  AuditLogCategoryApi
} from '@/modules/claims/services/auditLog'

import {
  LimitConsumptionApi,
  RecoveryApi,
  RecoveryReserveApi,
  ReserveApi,
  ReserveCategoryApi,
  ReserveCategoryTypeApi,
  TransactionApi,
  TransactionTypeApi,
  SummaryClaimantApi,
  SummaryRecoveryApi,
  SummaryReserveApi
} from '@/modules/claims/services/accounting'

import {
  ClaimApi,
  ClaimEventApi,
  ClaimEventTypeApi,
  ClaimStatusApi,
  ClaimStatusChangeReasonApi,
  LossDateTimezoneApi,
  OnSiteServiceApi,
  OnSiteServiceTypeApi,
  PerilApi,
  ReportedMethodApi,
  TimezoneApi
} from '@/modules/claims/services/claim'
import {LocationApi} from '@/modules/claims/services/common'
import {
  DeductibleOverrideReasonApi,
  ExposureApi,
  ExposureStatusApi,
  ExposureStatusChangeReasonApi
} from '@/modules/claims/services/exposure'
import {
  ClaimPartyApi,
  ClaimPartyRoleApi,
  PartyApi,
  RelatedRiskApi
} from '@/modules/claims/services/party'
import {RiskApi, RiskTypeApi} from '@/modules/claims/services/risk'
import {
  PaymentApi,
  PaymentPreProcessStatusApi,
  PaymentTypeApi,
  PayCodeApi,
  PaymentMethodApi,
  PaymentBatchApi
} from '@/modules/claims/services/payment'
import {TagApi} from '@/modules/claims/services/label'

const api = {
  auditLog: new AuditLogApi(),
  auditLogAction: new AuditLogActionApi(),
  auditLogCategory: new AuditLogCategoryApi(),
  claim: new ClaimApi(),
  claimEvent: new ClaimEventApi(),
  claimEventType: new ClaimEventTypeApi(),
  claimParty: new ClaimPartyApi(),
  claimPartyRole: new ClaimPartyRoleApi(),
  claimStatus: new ClaimStatusApi(),
  claimStatusChangeReason: new ClaimStatusChangeReasonApi(),
  deductibleOverrideReason: new DeductibleOverrideReasonApi(),
  exposure: new ExposureApi(),
  exposureStatus: new ExposureStatusApi(),
  exposureStatusChangeReason: new ExposureStatusChangeReasonApi(),
  limitConsumption: new LimitConsumptionApi(),
  location: new LocationApi(),
  lossDateTimezone: new LossDateTimezoneApi(),
  timezone: new TimezoneApi(),
  onSiteService: new OnSiteServiceApi(),
  onSiteServiceType: new OnSiteServiceTypeApi(),
  party: new PartyApi(),
  payment: new PaymentApi(),
  paymentPreProcessStatus: new PaymentPreProcessStatusApi(),
  paymentType: new PaymentTypeApi(),
  payCode: new PayCodeApi(),
  paymentMethod: new PaymentMethodApi(),
  paymentBatch: new PaymentBatchApi(),
  peril: new PerilApi(),
  recovery: new RecoveryApi(),
  recoveryReserve: new RecoveryReserveApi(),
  relatedRisk: new RelatedRiskApi(),
  reserve: new ReserveApi(),
  reserveCategory: new ReserveCategoryApi(),
  reserveCategoryType: new ReserveCategoryTypeApi(),
  reportedMethod: new ReportedMethodApi(),
  risk: new RiskApi(),
  riskType: new RiskTypeApi(),
  tag: new TagApi(),
  transaction: new TransactionApi(),
  transactionType: new TransactionTypeApi(),
  summaryClaimant: new SummaryClaimantApi(),
  summaryRecovery: new SummaryRecoveryApi(),
  summaryReserve: new SummaryReserveApi()
}

export {api as default, api, EavApi}
