import {ASSIGNMENTS_PAGINATION_INITIAL_STATE} from './constants'

export default {
  // lists
  accessPolicyList: [],
  accessPolicyListSystem: [],
  accessPolicyListCustom: [],
  assignments: [],
  localAssignments: [],
  roles: [],
  users: [],
  groups: [],
  localRoleUserGroupAssignments: [],
  roleAssignments: [],
  userRoles: [],
  assignedUsersGroupsRecords: [],

  // resources
  accessPolicy: {
    name: '',
    description: '',
    version: '0.0.0',
    date_added: '',
    statements: []
  },
  currentSearch: '',
  effectiveRole: '',
  role: {
    name: '',
    gen2Role: ''
  },

  // pagination
  policyPagination: {
    currentPage: 1,
    total: 0,
    pageSize: 15
  },
  localRole: {
    name: '',
    gen2Role: ''
  },
  rolePagination: {
    currentPage: 1,
    total: 0,
    pageSize: 15
  },
  userPagination: {
    currentPage: 1,
    total: 0,
    pageSize: 15
  },
  groupPagination: {
    currentPage: 1,
    total: 0,
    pageSize: 10000
  },
  accessPolicyAssignmentsPagination: {...ASSIGNMENTS_PAGINATION_INITIAL_STATE},
  roleAccessPolicyAssignmentsPagination: {...ASSIGNMENTS_PAGINATION_INITIAL_STATE},
  roleGroupAssignmentsPagination: {...ASSIGNMENTS_PAGINATION_INITIAL_STATE},
  roleUserAssignmentsPagination: {...ASSIGNMENTS_PAGINATION_INITIAL_STATE},
  accessPoliciesPagination: {
    currentPage: 1,
    total: 0,
    pageSize: 15
  },

  /**
   * `evaluations` contains the result of access evaluations using BriteAccess.
   * The resource, action and record are used to make a key with the utility
   * function makeEvaluationKey. The value of the attribute will be the result
   * of the evaluation, could be allowed, denied_implicit or denied_explicit.
   *
   * i.e {'bc.briteaccess.role::RetrieveDetails.xyz': 'allowed'} where the
   * resource is 'bc.briteaccess.role' the action is 'RetrieveDetails' and
   * the record is 'xyz'
   */
  evaluations: {},

  // flags
  assignmentsWereModified: false
}
