import state from './modules/state'
import getters from './modules/getters'
import actions from './modules/actions'
import mutations from './modules/mutations'
import claimStore from './modules/claim'
import adminSetupStore from './modules/admin-setup'
import assignClaimsStore from './modules/assign-claims'

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: {
    claim: claimStore,
    adminSetup: adminSetupStore,
    assignClaims: assignClaimsStore
  }
}
