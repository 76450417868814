import {mutationTypes} from '@/store/access/mutationTypes'
import {actionTypes} from '@/store/access/actionTypes'
import accessRepositoryFactory from '@/api/access/accessRepositoryFactory'
import AccessPolicyValidation from '@/api/access/AccessPolicyValidation'
import {makeEvaluationKey} from '@/store/access/utils'

const repositoryDefaultConfig = {
  trailingSlash: false
}

const ACCESS_RESOURCES = {
  POLICY: 'access/policies',
  ROLES: 'access/roles',
  ROLE_SELECT: 'access/role-select',
  USERS: 'access/users',
  GROUPS: 'access/groups',
  POLICY_ASSIGNMENTS: 'access/policy-assignments',
  ROLE_USER_ASSIGNMENTS: 'access/role-user-name-assignments',
  ROLE_GROUP_ASSIGNMENTS: 'access/role-group-name-assignments',
  EVALUATIONS: 'access/evaluations',
  IMPORT: 'access/import',
  EXPORT: 'access/export'
}

const accessPolicyRepository = accessRepositoryFactory(ACCESS_RESOURCES.POLICY, repositoryDefaultConfig)
const rolesRepository = accessRepositoryFactory(ACCESS_RESOURCES.ROLES, repositoryDefaultConfig)
const roleSelectRepository = accessRepositoryFactory(ACCESS_RESOURCES.ROLE_SELECT, repositoryDefaultConfig)
const usersRepository = accessRepositoryFactory(ACCESS_RESOURCES.USERS, repositoryDefaultConfig)
const groupsRepository = accessRepositoryFactory(ACCESS_RESOURCES.GROUPS, repositoryDefaultConfig)
const accessPolicyAssignmentRepository = accessRepositoryFactory(ACCESS_RESOURCES.POLICY_ASSIGNMENTS, repositoryDefaultConfig)
const roleUserAssignmentsRepository = accessRepositoryFactory(ACCESS_RESOURCES.ROLE_USER_ASSIGNMENTS, repositoryDefaultConfig)
const roleGroupAssignmentsRepository = accessRepositoryFactory(ACCESS_RESOURCES.ROLE_GROUP_ASSIGNMENTS, repositoryDefaultConfig)
const accessPolicyValidation = new AccessPolicyValidation()
const evaluationsRepository = accessRepositoryFactory(ACCESS_RESOURCES.EVALUATIONS, repositoryDefaultConfig)
const importRepository = accessRepositoryFactory(ACCESS_RESOURCES.IMPORT, repositoryDefaultConfig)
const exportRepository = accessRepositoryFactory(ACCESS_RESOURCES.EXPORT, repositoryDefaultConfig)

const actions = {
  async [actionTypes.FETCH_ACCESS_POLICIES] ({state, commit}, queries = {}) {
    if (queries.freshFetch) {
      commit(mutationTypes.SET_CURRENT_PAGE, 1)
    }
    let _queries = {
      pagination: state.policyPagination,
      type: queries.type,
      name: queries.search
    }

    let response

    if (queries.fetchAll) {
      /* Increase the page size to reduce the no of times API calls are made
         to fetch all records
      */
      _queries.pagination = {
        currentPage: 1,
        pageSize: 100
      }
      response = await accessPolicyRepository.listAllRecords({queries: _queries})
    } else {
      response = await accessPolicyRepository.listRecords({queries: _queries})
    }

    commit(mutationTypes.SET_PAGINATION_TOTAL, response.pagination.total)
    commit(mutationTypes.SET_ACCESS_POLICIES, {
      accessPolicyList: response.data,
      type: queries.type
    })
  },
  async [actionTypes.SAVE_ACCESS_POLICIES] ({commit}, AccessPolicies) {
    const createdAccessPolicies = await accessPolicyRepository.createRecord(AccessPolicies)
    commit(mutationTypes.SET_ACCESS_POLICIES, createdAccessPolicies)
  },
  async [actionTypes.FETCH_ACCESS_POLICY_BY_ID] ({commit}, accessPolicyId) {
    const accessPolicy = await accessPolicyRepository.readRecord({id: accessPolicyId})
    if (accessPolicy) {
      commit(mutationTypes.SET_CURRENT_ACCESS_POLICY, accessPolicy)
    }
    return accessPolicy
  },
  async [actionTypes.CREATE_ACCESS_POLICY] ({state}, accessPolicy) {
    return await accessPolicyRepository.createRecord({data: accessPolicy})
  },
  async [actionTypes.UPDATE_ACCESS_POLICY] ({state}) {
    return await accessPolicyRepository.updateRecord({id: state.accessPolicy.id, data: state.accessPolicy})
  },
  async [actionTypes.DELETE_ACCESS_POLICY] ({state}) {
    return await accessPolicyRepository.deleteRecord({id: state.accessPolicy.id})
  },
  async [actionTypes.SAVE_ACCESS_POLICY_ASSIGNMENT] ({state}, assignments) {
    await Promise.all(assignments.map(async assignment => {
      await accessPolicyAssignmentRepository.createRecord({data: assignment})
    }))
  },
  async [actionTypes.FETCH_ACCESS_POLICY_ASSIGNMENTS] ({state, commit}) {
    const queries = {
      filters: {
        policy_id: state.accessPolicy.id
      },
      pagination: state.accessPolicyAssignmentsPagination
    }
    const assignments = await accessPolicyAssignmentRepository.listAllRecords({queries})
    commit(mutationTypes.SET_ACCESS_POLICY_ASSIGNMENTS, assignments.data)
  },
  async [actionTypes.FETCH_ROLE_ACCESS_POLICY_ASSIGNMENTS] ({state, commit}) {
    const queries = {
      filters: {
        role: state.role.name
      },
      pagination: state.roleAccessPolicyAssignmentsPagination
    }
    const assignments = await accessPolicyAssignmentRepository.listAllRecords({queries})
    commit(mutationTypes.SET_ACCESS_POLICY_ASSIGNMENTS, assignments.data)
  },
  async [actionTypes.DELETE_ACCESS_POLICY_ASSIGNMENTS] ({state}, assignments) {
    await Promise.all(assignments.map(async assignment => {
      await accessPolicyAssignmentRepository.deleteRecord({id: assignment.id, data: assignment})
    }))
  },
  async [actionTypes.VALIDATE_ACCESS_POLICY] ({state}, accessPolicy) {
    return await accessPolicyValidation.validate(accessPolicy)
  },
  async [actionTypes.FETCH_ROLES] ({state, commit}, queries = {}) {
    if (queries.freshFetch) {
      commit(mutationTypes.SET_CURRENT_ROLES_PAGE, 1)
    }
    const _queries = {
      pagination: state.rolePagination,
      name: queries.search
    }

    let response

    if (queries.fetchAll) {
      /* Increase the page size to reduce the no of times API calls are made
         to fetch all records
      */
      _queries.pagination = {
        currentPage: 1,
        pageSize: 100
      }
      response = await rolesRepository.listAllRecords({queries: _queries})
    } else {
      response = await rolesRepository.listRecords({queries: _queries})
    }

    commit(mutationTypes.SET_ROLES_PAGINATION_TOTAL, response.pagination.total)
    response.data.forEach(role => { role.type = 'Role' })
    commit(mutationTypes.SET_ROLES, {roles: response.data})
  },
  async [actionTypes.FETCH_ROLE_BY_ID] ({commit}, roleId) {
    const role = await rolesRepository.readRecord({id: roleId})
    const formattedRole = {
      name: role.name,
      gen2Role: role.gen2_role,
      id: role.id
    }
    commit(mutationTypes.SET_ROLE, formattedRole)
  },
  async [actionTypes.CREATE_ROLE] ({commit}, role) {
    const formattedRole = {
      name: role.name,
      gen2_role: role.gen2Role,
      id: role.id
    }
    return await rolesRepository.createRecord({data: formattedRole})
  },
  async [actionTypes.SELECT_USER_ROLE] ({commit}, role) {
    const formattedEffectiveRole = {
      effective_role: role,
    }
    return await roleSelectRepository.createRecord({data: formattedEffectiveRole})
  },
  async [actionTypes.DELETE_ROLE] ({commit, state}) {
    return await rolesRepository.deleteRecord({id: state.role.id, data: state.role})
  },
  async [actionTypes.UPDATE_ROLE] ({commit, state}, role) {
    const formattedRole = {
      name: role.name,
      gen2_role: role.gen2Role,
      id: role.id
    }
    return await rolesRepository.updateRecord({id: state.role.id, data: formattedRole})
  },
  async [actionTypes.SAVE_ROLE_ASSIGNMENTS] ({commit}, assignments) {
    await Promise.all(assignments.map(async assignment => {
      assignment.group ? await roleGroupAssignmentsRepository.createRecord({data: assignment}) : await roleUserAssignmentsRepository.createRecord({data: assignment})
    }))
  },
  async [actionTypes.FETCH_ROLE_ASSIGNMENTS] ({state, commit}) {
    const userQueries = {
      filters: {
        role: state.role.id
      },
      pagination: state.roleUserAssignmentsPagination
    }
    const groupQueries = {
      filters: {
        role: state.role.id
      },
      pagination: state.roleGroupAssignmentsPagination
    }
    const groupAssignments = await roleGroupAssignmentsRepository.listAllRecords({queries: groupQueries})
    const userAssignments = await roleUserAssignmentsRepository.listAllRecords({queries: userQueries})
    commit(mutationTypes.SET_ROLE_ASSIGNMENTS, [...groupAssignments.data, ...userAssignments.data])
  },
  async [actionTypes.DELETE_ROLES_ASSIGNMENTS] ({state}, assignments) {
    await Promise.all(assignments.map(async assignment => {
      assignment.group ? await roleGroupAssignmentsRepository.deleteRecord({id: assignment.id, data: assignment}) : await roleUserAssignmentsRepository.deleteRecord({id: assignment.id, data: assignment})
    }))
  },
  async [actionTypes.FETCH_USERS] ({state, commit}, queries = {}) {
    if (queries.freshFetch) {
      commit(mutationTypes.RESET_USERS_LIST)
      commit(mutationTypes.SET_CURRENT_USERS_PAGE, 1)
    }
    const _queries = {
      pagination: {
        currentPage: 1,
        pageSize: 500
      }
    }
    const users = await usersRepository.listRecords({queries: _queries})
    commit(mutationTypes.SET_USERS_PAGINATION_TOTAL, users.pagination.total)
    users.data.forEach(role => { role.type = 'User' })
    commit(mutationTypes.SET_USERS, users.data)
  },
  async [actionTypes.FETCH_GROUPS] ({state, commit}, queries = {}) {
    if (queries.freshFetch) {
      commit(mutationTypes.RESET_GROUPS_LIST)
      commit(mutationTypes.SET_CURRENT_GROUPS_PAGE, 1)
    }
    const _queries = {
      pagination: {
        currentPage: 1,
        pageSize: 500
      }
    }
    const groups = await groupsRepository.listRecords({queries: _queries})
    commit(mutationTypes.SET_GROUPS_PAGINATION_TOTAL, groups.pagination.total)
    groups.data.forEach(user => { user.type = 'Group' })
    commit(mutationTypes.SET_GROUPS, groups.data)
  },
  async [actionTypes.FETCH_GROUP_BY_ID] ({commit}, groupId) {
    const group = await groupsRepository.readRecord({id: groupId})
    return group
  },
  async [actionTypes.FETCH_USER_BY_ID] ({commit}, userId) {
    const user = await usersRepository.readRecord({id: userId})
    return user
  },
  async [actionTypes.FETCH_USER_ROLES] ({state, commit}, queries = {}) {
    const _queries = {
      filters: {
        rel_username: queries.username,
        ...(queries.usergroups && {rel_usergroups: queries.usergroups.join(',')})
      },
      pagination: {
        currentPage: 1,
        pageSize: 10
      }
    }
    const _options = {
      noEffectiveRoleHeader: true
    }
    const userRoles = await rolesRepository.listAllRecords({queries: _queries, options: _options})
    commit(mutationTypes.SET_USER_ROLES, userRoles.data)
  },
  async [actionTypes.FETCH_ASSIGNED_USERS_GROUPS_RECORDS] ({state, commit}) {
    const assignedUsersGroupsRecords = await Promise.all(state.roleAssignments.map(async assignment => {
      if (assignment.group) {
        return await groupsRepository.readRecord({id: assignment.group})
      } else {
        return await usersRepository.readRecord({id: assignment.user})
      }
    }))
    commit(mutationTypes.SET_ASSIGNED_USERS_GROUPS_RECORDS, assignedUsersGroupsRecords)
  },
  async [actionTypes.EVALUATE_ACCESS_ATTEMPTS] ({state, commit}, accessAttempts) {
    const notEvaluatedAccessAttempts = accessAttempts.filter(attempt => {
      const key = makeEvaluationKey(attempt.resource, attempt.action, attempt.record, attempt.context)
      return !state.evaluations[key]
    })
    if (notEvaluatedAccessAttempts.length > 0) {
      try {
        const payload = {requests: notEvaluatedAccessAttempts}
        const response = await evaluationsRepository.createRecord({data: payload})
        response.results.forEach((result, index) => {
          const {resource, action, record, context} = notEvaluatedAccessAttempts[index]
          const evaluation = result.evaluation
          commit(mutationTypes.SET_EVALUATE_RESULT, {resource, action, record, context, evaluation})
        })
      } catch (error) {
        console.error(error)
      }
    }
  },
  async [actionTypes.IMPORT_DATA] ({state, commit}, data) {
    return await importRepository.uploadRecord(data)
  },
  async [actionTypes.EXPORT_DATA] ({state, commit}, format) {
    const _queries = {
      file_format: format
    }
    return await exportRepository.downloadRecord({queries: _queries})
  }
}
export default actions
