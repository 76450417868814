import _ from 'lodash'
import store from '@/store'
import { ACCESS_MODULES } from '@/pages/access/constants'
import { makeEvaluationKey } from '@/store/access/utils'

// Method to consistently format all paths with a leading `/`
const formatPath = path => `/${_.trim(path, '/')}`

export const formatRoutePath = route => ({ ...route, path: formatPath(route.path) })

export const updateQueryStringParameter = (uri, key, value) => {
  // source: https://gist.github.com/niyazpk/f8ac616f181f6042d1e0#file-pquery-js

  const hashStartingIndex = uri.indexOf('#')
  const hasHash = hashStartingIndex !== -1
  const hash = hasHash ? uri.substr(hashStartingIndex) : ''

  if (hasHash) {
    uri = uri.substr(0, hashStartingIndex)
  }

  /*
    Explanation of re: ([?&])key=.*?(&|$)
      This regex is used to replace the original value of key with value

      1st Capturing Group ([?&])
      Match a single character present in the group.  '?' or '&'

      key= matches the value of 'key' variable in current scope
      .*? Lazy quantifier. Matches as few characters as possible

      2nd Capturing Group (&|$)
      Either '&' or '$' - Optional
  */
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i')
  if (uri.match(re)) {
    uri = uri.replace(re, `$1${key}=${value}$2`)
  } else {
    const separator = uri.indexOf('?') !== -1 ? '&' : '?'
    uri = `${uri}${separator}${key}=${value}`
  }

  return uri + hash
}

export const isAuthorized = async (toPath) => {
  const module = ACCESS_MODULES.find(module => toPath.includes(module.path))
  if (!module) {
    // TODO: at some point we shouldn't bypass anything that is unknown by briteaccess, forbidden by default is safer.
    return true
  }
  const resource = `bc.${module.name}.britecoreui`
  const action = 'ViewModule'
  await store.dispatch('access/evaluateAccessAttempts', [{resource, action}])
  const isAllowed = store.getters['access/accessAttemptIsAllowed'](resource, action)
  if (isAllowed === null) {
    console.error(`There is no evaluation available for the resource '${makeEvaluationKey(resource, action)}`)
  }
  return isAllowed
}

export const isGen2Route = (path) => {
  return path.startsWith('/britecore') || path.startsWith('/agent') || path === '/' || path.startsWith('/?')
}
