import {COMPONENT_EDITOR_STORE_PATH} from '../constants'
import {addNamespace} from '@/store/helpers'

// To be used only inside vuex modules
export const _actions = {
  GET_COMPONENT: 'getComponent',
  GET_COMPILED_COMPONENT: 'getCompiledComponent',
  RESET_COMPONENT: 'resetComponent'
}

// To be used publicly outside vuex modules
export const ACTIONS = addNamespace(
  COMPONENT_EDITOR_STORE_PATH,
  _actions
)
