import {ACTIVE_PROJECT_STORE_PATH} from '../constants'
import {addNamespace} from '@/store/helpers'

// To be used only inside vuex modules
export const _actions = {
  GET_COMPONENTS: 'getComponents',
  BUILD_AVAILABLE_FILTER_CRITERIA: 'buildAvailableFilterCriteria',
  SET_ACTIVE_PROJECT: 'setActiveProject',
  REFRESH_ACTIVE_PROJECT: 'refreshActiveProject',
  GET_UNCOMMITTED_COMPONENTS: 'getUncommittedComponents',
  VALIDATE_PROJECT: 'validateProject'
}

// To be used publicly outside vuex modules
export const ACTIONS = addNamespace(
  ACTIVE_PROJECT_STORE_PATH,
  _actions
)
