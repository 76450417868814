import store from '@/store'
import BriteRulesConnector from '.'
import get from 'lodash/get'

const handlers = {
  setVisible ({viewId, elementId, parameters}) {
    store.commit(`${BriteRulesConnector.moduleName}/setVisible`, {viewId, elementId, value: get(parameters, 'value')})
  },
  setReadonly ({viewId, elementId, parameters}) {
    store.commit(`${BriteRulesConnector.moduleName}/setReadonly`, {viewId, elementId, value: get(parameters, 'value')})
  },
  setMandatory ({viewId, elementId, parameters}) {
    store.commit(`${BriteRulesConnector.moduleName}/setMandatory`, {viewId, elementId, value: get(parameters, 'value')})
  },
  setValid ({viewId, elementId, parameters}) {
    store.commit(`${BriteRulesConnector.moduleName}/setValid`, {viewId, elementId})
  },
  setInvalid ({viewId, elementId, parameters}) {
    store.commit(`${BriteRulesConnector.moduleName}/setInvalid`, {viewId, elementId, message: get(parameters, 'message')})
  },
  setValue ({viewId, elementId, parameters}) {
    store.commit(`${BriteRulesConnector.moduleName}/setValue`, {viewId, elementId, value: get(parameters, 'value')})
  },
  setWhitelist ({viewId, elementId, parameters}) {
    store.commit(`${BriteRulesConnector.moduleName}/setWhitelist`, {viewId, elementId, options: get(parameters, 'options')})
  },
  setBlacklist ({viewId, elementId, parameters}) {
    store.commit(`${BriteRulesConnector.moduleName}/setBlacklist`, {viewId, elementId, options: get(parameters, 'options')})
  }
}

const actionHandler = (action) => {
  if (handlers[action.name]) {
    handlers[action.name](action)
  } else {
    console.warn(`Unhandled action '${action.name}' sent from BriteRules. Check documentation for supported actions.`)
  }
}

export default actionHandler
