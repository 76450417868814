const EXCEPTIONS = {
  UserNotFound: 'UserNotFoundException',
  NotAuthorized: 'NotAuthorizedException',
  UsernameExists: 'UsernameExistsException',
  PasswordResetRequired: 'PasswordResetRequiredException',
  InvalidParameter: 'InvalidParameterException',
  ExpiredCode: 'ExpiredCodeException',
  InvalidCode: 'CodeMismatchException',
  LimitExceeded: 'LimitExceededException'
}

export {
  EXCEPTIONS
}
