<template>
  <a
    :href="`/britecore/claims/information?claimId=${data.id}`"
    class="search-results__search-item">
    <div class="u-mb1">
      {{ claimNumber }}
    </div>

    <div class="search-item__details">
      <div class="l-flex-grid l-flex-grid--v-center">
        <fa-icon
          class="u-mr2"
          size="sm"
          icon="user"></fa-icon>
        <span>{{ namedInsured }}</span>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'QuickSearchClaimItem',
  props: {
    data: { type: Object, required: true }
  },
  computed: {
    claimNumber () {
      return this.data.name || this.data.details.claimNumber
    },
    namedInsured () {
      return this.data.contact || this.data.details.namedInsured || 'N/A'
    }
  }
}
</script>
