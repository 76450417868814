const SecuritySettings = () => import('@/pages/my-account/SecuritySettings')
const TOTPSettings = () => import('@/pages/my-account/TOTPSettings')
const SMSSettings = () => import('@/pages/my-account/SMSSettings')

export default [
  {
    path: 'security',
    name: 'my-account:security',
    component: SecuritySettings
  },

  {
    path: 'security/configure-mfa/software-token',
    name: 'my-account:configure-mfa-software',
    component: TOTPSettings
  },

  {
    path: 'security/configure-mfa/sms',
    name: 'my-account:configure-mfa-sms',
    component: SMSSettings
  }
]
