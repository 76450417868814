import _ from 'lodash'
import { linesClient } from '@/apollo'
import { LINES_GQL_CLIENT } from '@/pages/lines/constants'
import GetLine from '@/pages/lines/queries/GetLine.gql'
import GetRiskType from '@/pages/lines/queries/GetRiskType.gql'
import GetProduct from '@/pages/lines/queries/GetProduct.gql'
import GetLineLatestProduct from '@/pages/lines/queries/GetLineLatestProduct.gql'
import { _actionTypes } from './actionTypes'
import { _mutationTypes } from './mutationTypes'
import router from '@/router'
import linesService from '@/api/lines/lines'

export const actions = {
  [_actionTypes.GOTO_RISK_TYPE] (context, { hash }) {
    router.push({
      name: 'lines:risk-type-details',
      params: {
        lineName: context.state.line.name,
        productName: context.state.product.name,
        riskTypeName: context.state.riskType.name
      },
      hash: hash
    })
  },
  [_actionTypes.LOAD_PRODUCT_VERSIONS] ({ commit }, { productName }) {
    return new Promise((resolve, reject) => {
      linesService.getProductVersions(
        productName
      ).then((versions) => {
        commit(_mutationTypes.SET_VERSIONS, versions)
        resolve(versions)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [_actionTypes.GET_LINE_LATEST_PRODUCT] (_, { lineName }) {
    return new Promise((resolve) => {
      linesClient.query({
        client: LINES_GQL_CLIENT,
        query: GetLineLatestProduct,
        fetchPolicy: 'network-only',
        variables: {
          lineName: lineName
        }
      }).then(({ data: { line } }) => {
        resolve(line.latestProduct)
      })
    })
  },
  [_actionTypes.LOAD_LINE] ({ commit }, { lineName, versionId }) {
    return new Promise((resolve) => {
      linesClient.query({
        client: LINES_GQL_CLIENT,
        query: GetLine,
        fetchPolicy: 'network-only',
        variables: {
          lineName: lineName,
          versionId: versionId
        }
      }).then(({ data: { line } }) => {
        commit(_mutationTypes.SET_LINE, line)
        resolve(line)
      })
    })
  },
  [_actionTypes.LOAD_PRODUCT] ({ commit }, { productName, versionId }) {
    return new Promise((resolve) => {
      linesClient.query({
        client: LINES_GQL_CLIENT,
        query: GetProduct,
        fetchPolicy: 'no-cache',
        variables: {
          productName: productName,
          versionId: versionId
        }
      }).then(({ data: { product } }) => {
        commit(_mutationTypes.SET_PRODUCT, product)
        resolve(product)
      })
    })
  },
  [_actionTypes.GOTO_LINES_OVERVIEW] ({ state }, { versionId }) {
    const queryParams = router.history.current.query || {}
    if (router.query && router.query.addProduct) {
      queryParams['addProduct'] = 1
    }
    if (versionId) {
      queryParams['version_id'] = versionId
    }
    router.push({
      name: 'lines:line-overview',
      params: {
        lineName: state.line.name
      },
      query: queryParams
    })
  },
  [_actionTypes.SET_ACTIVE_VERSION_ID_QUERY_PARAM] ({ state }) {
    const activeVersion = state.activeVersion
    if (activeVersion && activeVersion.id) {
      router.replace({
        query: Object.assign({}, router.history.current.query, { version_id: activeVersion.id }),
        hash: router.currentRoute.hash
      })
    }
  },
  [_actionTypes.LOAD_FIELD_FORMATS] ({ commit }) {
    return new Promise((resolve, reject) => {
      linesService.getFieldFormats()
        .then((response) => {
          commit(_mutationTypes.SET_FIELD_FORMATS, response)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  [_actionTypes.LOAD_RISK_TYPE] ({ state, commit }, { riskTypeName } = {}) {
    return new Promise((resolve) => {
      linesClient.query({
        client: LINES_GQL_CLIENT,
        query: GetRiskType,
        fetchPolicy: 'network-only',
        variables: {
          versionId: state.activeVersion.id,
          productId: state.product.id,
          riskTypeName: riskTypeName || state.riskType.name
        }
      }).then(({ data: { riskType } }) => {
        commit(_mutationTypes.SET_RISK_TYPE, riskType)
        resolve(riskType)
      })
    })
  },
  [_actionTypes.ADD_TO_PRODUCT_IMPORTS] ({ state, commit }, { productImport }) {
    const index = state.productImports.findIndex(pi => pi.id === productImport.id)
    if (index >= 0) {
      commit(_mutationTypes.SET_PRODUCT_IMPORT, { index: index, productImport: productImport })
    } else {
      commit(_mutationTypes.SET_PRODUCT_IMPORTS, [productImport, ...state.productImports])
    }
  },
  [_actionTypes.UPDATE_PRODUCT_IMPORT] ({ state, commit }, { productImport }) {
    const index = state.productImports.findIndex(pi => pi.id === productImport.id)
    commit(_mutationTypes.SET_PRODUCT_IMPORT, { index: index, productImport: productImport })
  },
  [_actionTypes.REMOVE_FROM_PRODUCT_IMPORTS] ({ state, commit }, { productImportId }) {
    const index = state.productImports.findIndex(pi => pi.id === productImportId)
    commit(_mutationTypes.REMOVE_PRODUCT_IMPORT, { index: index })
  },
  [_actionTypes.REMOVE_ENTITY_FROM_RISK_TYPE] ({ state, commit }, { entityKey, entityId }) {
    const index = state.riskType[entityKey].findIndex(entity => entity.id === entityId)
    commit(_mutationTypes.REMOVE_ENTITY_FROM_RISK_TYPE, { entityKey: entityKey, entityIndex: index })
  },
  [_actionTypes.LOAD_GEN2_BUSINESS_LOCATIONS] ({ commit }) {
    return new Promise((resolve, reject) => {
      linesService.getGen2BusinessLocations()
        .then((response) => {
          const businessLocations = response.filter(businessLocation => {
            return businessLocation['checked']
          }).map(businessLocation => _.pick(businessLocation, ['locationId', 'name']))
          commit(_mutationTypes.SET_GEN2_BUSINESS_LOCATIONS, businessLocations)
          resolve(businessLocations)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  [_actionTypes.LOAD_PRODUCTS] () {
    return new Promise((resolve, reject) => {
      linesService.getProducts()
        .then(response => {
          const products = response.data.results
          resolve(products)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
