<template>
  <div>
    <vue-extend-layout></vue-extend-layout>
    <Analytics />
  </div>
</template>

<script>
import VueExtendLayout from '@/components/ui/vue-extend-layout'
import Analytics from '@/components/analytics/Analytics'

export default {
  name: 'App',
  components: {
    VueExtendLayout,
    Analytics
  }
}
</script>

<style lang="scss">
@import "~client-styles/variables";
@import "~@britecore/bc-design-system/packages/theme-chalk/src/index";
@import "./sass/main.scss";
</style>
