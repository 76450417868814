import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import filter from 'lodash/filter'
import first from 'lodash/first'
import reject from 'lodash/reject'
import moment from 'moment'
import { DATE_FORMAT } from '@/pages/rules/constants'
import JobRunner from '@/pages/rules/utils/JobRunner'

export const getters = {
  activeProject (state) {
    return get(state, 'activeProject.value', {})
  },
  activeProjectId (state) {
    return get(state, 'activeProject.value.id', null)
  },
  activeProjectStatus (state) {
    return {
      loaded: get(state, 'activeProject.loaded', false),
      loading: get(state, 'activeProject.loading', false),
      error: get(state, 'activeProject.error', null)
    }
  },
  getActiveProjectProdVersion (state, getters) {
    return getters.activeProject.prod
  },
  activeProjectVersionsMapByEffectiveDate (state, getters) {
    return keyBy(getters.activeProject.versions, (o) => o.effective_date)
  },
  activeProjectVersions (state, getters) {
    return getters.activeProject.versions
  },
  activeProjectLatestVersion (state, getters) {
    return first(getters.activeProjectNonArchivedVersions)
  },
  activeProjectNonArchivedVersions (state, getters) {
    return reject(getters.activeProjectVersions, {status: 'archived'}).sort((v1, v2) => {
      return moment(v2.effective_date, DATE_FORMAT).diff(moment(v1.effective_date, DATE_FORMAT))
    })
  },
  activeProjectEffectiveDate (state, getters) {
    if (!getters.activeProject.effectiveDateId) {
      return getters.activeProjectLatestVersion
    } else {
      return first(filter(getters.activeProject.versions, {id: getters.activeProject.effectiveDateId}))
    }
  },
  components (state) {
    return get(state, 'components.value', {})
  },
  componentsStatus (state) {
    return {
      loaded: get(state, 'components.loaded', false),
      loading: get(state, 'components.loading', false),
      error: get(state, 'components.error', null)
    }
  },
  initialFilterCriteria (state, getters) {
    const lastFilterCriteria = JSON.parse(JSON.stringify(getters.lastSelectedCriteria))
    return lastFilterCriteria || null
  },
  availableFilterCriteria (state) {
    return get(state, 'componentsFilter.availableFilterCriteria.value', [])
  },
  availableFilterCriteriaStatus (state) {
    return {
      loading: get(state, 'componentsFilter.availableFilterCriteria.loading', true),
      loaded: get(state, 'componentsFilter.availableFilterCriteria.loaded', false),
      error: get(state, 'componentsFilter.availableFilterCriteria.error')
    }
  },
  lastSelectedCriteria (state) {
    return get(state, 'componentsFilter.selectedCriteria')
  },
  uncommittedComponentsCount (state, getters) {
    return get(getters.uncommittedComponents, 'length', 0)
  },
  uncommittedComponentsMapById (state) {
    return keyBy(state.uncommittedComponents, (c) => c.id)
  },
  uncommittedComponents (state) {
    const changes = state.uncommittedComponents.value
    if (!changes) {
      return []
    }
    let components = [
      ...changes.added.map((c) => ({
        ...c,
        change: 'added'
      })),
      ...changes.updated.map((c) => ({
        ...c,
        change: 'updated'
      })),
      ...changes.deleted.map((c) => ({
        ...c,
        change: 'deleted'
      })),
    ]
    return components
  },
  uncommittedComponentsStatus (state) {
    return {
      loaded: state.uncommittedComponents.loaded,
      loading: state.uncommittedComponents.loading,
      error: state.uncommittedComponents.error
    }
  },
  isValidateProjectRunning (state) {
    return state.validateProject.running
  },
  isValidateProjectDialogVisible (state) {
    return state.validateProject.dialogVisible
  },
  validationErrors (state) {
    return state.validateProject.validationErrors
  },
  validationServiceError (state) {
    return state.validateProject.serviceError
  },
  isTestProjectRunning (state) {
    return state.testProject.jobRunner.isRunning
  },
  isTestProjectDialogVisible (state) {
    return state.testProject.dialogVisible
  },
  testProjectJobRunner (state) {
    return state.testProject.jobRunner
  },
  isValidTestRun (state) {
    return state.testProject.jobRunner.job.resultStatus === JobRunner.resultStatus.SUCCESS
  },
  testsResult (state) {
    return state.testProject.jobRunner.job.result || []
  },
  failedTests (state, getters) {
    return getters.testsResult.filter((t) => !t.testPassed)
  },
  failedTestsCount (state, getters) {
    return getters.failedTests.length
  },
  successfulTests (state, getters) {
    return getters.testsResult.filter((t) => t.testPassed)
  },
  testResultDescription (state, getters) {
    var total = `${getters.testsResult.length > 1 ? `${getters.testsResult.length} tests` : '1 test'}`
    return (getters.failedTestsCount > 0)
      ? `${getters.failedTestsCount} of ${total} failed`
      : `${getters.successfulTests.length} of ${total} passed`
  }
}
