
import policyService from '@/api/policy'
import contactsService from '@/api/contacts'
import utils from './utils'

export const actions = {
  async loadRevisionInfo ({ commit, state, dispatch, rootGetters }, {
    policyId, revisionId, policyTermId
  }) {
    commit('setPolicyPageReadyToShow', false)
    commit('setRevisionId', revisionId)
    contactsService.getRevisionContacts(revisionId).then(r => {
      commit(
          'contacts/setRevisionContactsInfo',
          { revisionId, contactsInfo: r.data },
          { root: true }
        )
      rootGetters['contacts/revisionContacts'](revisionId).map(c => {
        contactsService.getContact(c.contactId).then(data => {
          commit(
              'contacts/setContactsInfo',
              data,
              { root: true }
            )
          commit('setPolicyPageReadyToShow', true)
        }, error => {
          console.log(error)
        })
      })
    }, error => {
      console.log(error.data)
    })
    dispatch('fetchBillingInfo', {policyId, policyTermId})

    return state.revisions.find(rev => rev.quoteNumber !== null) !== null // returns true if is Gen3 Policy
  },
  async loadPolicyInfoPage ({ commit, dispatch, state, rootGetters }, policyId) {
    policyService.getPolicyInfoScreenFlow(policyId).then(data => {
      if (data.data.length) {
        commit('setPolicyInfoScreenFlowInfo', data.data[0])
      } else {
        commit('setScreenFlowInfoError', 'No ScreenFlowInfo available')
      }
    }, error => {
      commit('setScreenFlowInfoError', error)
    })
    policyService.getPolicy(policyId).then(data => {
      dispatch('setPolicyInfo', data)
    })

    dispatch('getPolicyTransactions', policyId)
    dispatch('getFailedRenewalTransactionsCount', policyId)
    dispatch('getInProgressTransactions', policyId)
    dispatch('getFailedNonDismissedTransaction', policyId)
    const revisions = await policyService.getRevisions(
      policyId,
      rootGetters['authentication/gen2EffectiveRole'].toLowerCase(),
      rootGetters['bcClassic/getContactInfo'].contactId
    )
    const urls = revisions.data.data.map(revision => utils.declarationURL(policyId, revision.id, rootGetters['authentication/isAgentOrAgency']))
    const responses = await Promise.all(urls)
    revisions.data.data.forEach((revision, index) => {
      revision['decURL'] = responses[index]
    })
    commit('setRevisionInfo', revisions.data.data)
    const revisionId = state.revisionId
    const policyTermId = revisions.data.data[0].relationships.policy_term.data.id

    return dispatch('loadRevisionInfo', {policyId, revisionId, policyTermId})
  },
  async getRisksData ({commit, state}, { tabTitle, tabIndex, revisionId }) {
    const risks = await policyService.getRisks(revisionId, tabIndex)
    commit('setRiskInfo', {riskData: risks.data.data, revisionId, tabTitle})
  },
  endorsePolicy ({ commit }, { effectiveDate, quoteNumber, productName }) {
    return policyService.endorsePolicy(effectiveDate, quoteNumber, productName)
      .then(d => d.data)
  },
  async fetchBillingInfo ({ commit }, { policyId, policyTermId }) {
    let data = await policyService.getBillingInfo(policyId, policyTermId)
    commit('setBillingInfo', data.data.data)
  },
  async getOpenQuotes ({ commit }, { policyId }) {
    const data = await policyService.getOpenQuotes(policyId)
    const quotes = data.data.data
    const endorsement = quotes.find(quote => quote.attributes.transaction_type === 'endorsement')
    const renewal = quotes.find(quote => quote.attributes.transaction_type === 'renewal')
    commit('setOpenQuotes', { endorsement, renewal })
  },
  async discardOpenQuote ({ dispatch }, { quoteNumber }) {
    try {
      await policyService.discardOpenQuote(quoteNumber)
    } catch (error) {
      const hasProp = (obj, key) => typeof (obj) === 'object' && Object.prototype.hasOwnProperty.call(obj, key)
      let errorMsg = 'Error discarding quote'
      if (hasProp(error, 'response') &&
          hasProp(error.response, 'data') &&
          hasProp(error.response.data, 'errors') &&
          hasProp(error.response.data.errors, '__all__') &&
          error.response.data.errors.__all__.length > 0 &&
          hasProp(error.response.data.errors.__all__[0], 'message')) {
        errorMsg = error.response.data.errors.__all__[0].message
      }
      throw errorMsg
    }
  },
  setPolicyInfo ({ commit }, data) {
    commit('setPolicyInfo', data)
  },
  async getInProgressTransactions ({commit}, policyId) {
    const transactions = await policyService.getPolicyTransactions(policyId, {status: 'in_progress'})
    commit('setInProgressTransactions', transactions.data.data.length > 0)
  },
  async getFailedRenewalTransactionsCount ({commit}, policyId) {
    const {data} = await policyService.getPolicyTransactions(policyId, {status: 'failed', type: 'renewal'})
    commit('setFailedRenewalTransactionsCount', data.meta.pagination.count)
  },
  async getFailedNonDismissedTransaction ({commit}, policyId) {
    const transactions = await policyService.getPolicyTransactions(policyId, {status: 'failed', reasonDismissed: false})
    commit('setFailedNonDismissedTransactions', transactions.data.data)
  },
  async getPolicyTransactions ({commit}, policyId) {
    const transactions = await policyService.getPolicyTransactions(policyId, {status: 'completed'})
    commit('setPolicyTransactionInfo', transactions.data.data)
  },
  async getCancellationInfo ({state, commit, getters}) {
    let latestRevision = getters.committedRevisions[0]
    if (!latestRevision.policyStatus.startsWith('Canceled')) {
      await policyService.getCancellationInfo(latestRevision.revisionId).then(data => {
        commit('setCancellationInfo', data.data)
      })
    }
  },
  getLinkedPolicies ({commit}, policyId, policyNumber) {
    commit('setLinkedPoliciesLoading', true)
    return new Promise((resolve, reject) => {
      policyService.getLinkedPolicies(policyId, policyNumber)
        .then(({data}) => {
          commit('setLinkedPolicies', data)
          resolve(data)
        })
        .catch(_ => {
          reject('The system was unable to retrieve the linked policies')
        })
        .finally(_ => commit('setLinkedPoliciesLoading', false))
    })
  },
  getSearchedLinkablePolicies ({commit}, {policyId, currentPage, searchString}) {
    commit('setLinkedPoliciesSearchLoading', true)
    return new Promise((resolve, reject) => {
      policyService.getSearchedLinkablePolicies(policyId, currentPage, searchString)
      .then(response => {
        commit('setSearchedLinkablePolicies', response.data)
        resolve(response)
      })
      .catch(_ => {
        reject('The system was unable to perform the policy search')
      })
      .finally(_ => commit('setLinkedPoliciesSearchLoading', false))
    })
  },
  addLinkedPolicy ({ commit }, {data, policy}) {
    return new Promise((resolve, reject) => {
      policyService.addLinkedPolicy(data)
        .then(response => {
          if (response.data.success) {
            commit('setLinkedPolicies', { groupId: response.data.groupId })
            commit('addLinkedPolicy', policy)
            resolve(response.data.message)
          } else {
            reject(response.data)
          }
        })
        .catch(_ => {
          reject('The system was unable to link the policy')
        })
    })
  },
  removeLinkedPolicy ({commit}, {groupId, policyId, parentPolicyId}) {
    commit('setLinkedPoliciesLoading', true)
    return new Promise((resolve, reject) => {
      policyService.removeLinkedPolicy(groupId, policyId, parentPolicyId)
      .then(_ => {
        commit('removeLinkedPolicy', {id: policyId, currentPolicyId: parentPolicyId})
        resolve('Policy link removed successfully')
      })
      .catch(_ => {
        reject('The system was unable to remove the linked policy')
      })
      .finally(_ => commit('setLinkedPoliciesLoading', false))
    })
  }
}
