import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import metaPresets from './metaPresets'
import moment from 'moment'

import routerConfig from './config'
import { updateQueryStringParameter, isAuthorized } from './utils'

Vue.use(Router)

const router = new Router(routerConfig)

router.beforeEach((to, from, next) => {
  if (to.query && to.query.pageReload) {
    store.dispatch('setWindowLocation', to.fullPath.replace(/(\?|&)pageReload=true/g, ''))
    return
  }

  if (to.path === '/logout') {
    // The logout path is "special" and no API calls should be made
    // other than those to destroy the session. This check is specifically made
    // to avoid dispatching the actions below
    next()
    return
  }

  // eslint-disable-next-line complexity
  store.dispatch('authentication/refreshSession').then(async () => {
    // Starting with a new object, merge the default metadata set followed by
    // metadata in parent --> child order. The net effect will be inheritance
    // with child precedence.
    // NOTE:
    //   - This assumes route matches are ordered parent --> chid as is
    //     current indicated here: https://router.vuejs.org/en/api/route-object.html
    const mergedMetaData = Object.assign(
      {},
      metaPresets.default,
      ...to.matched.map(route => route.meta)
    )

    // Update the 'to' metadata to match the merge done above.
    // Any data on 'to' itself should always have been preserved.
    Object.assign(to.meta, mergedMetaData)

    const requiresAuth = mergedMetaData.requiresAuth
    const isAuthenticated = store.getters['authentication/isAuthenticated']
    const onlyAllowUnauthenticatedAccess = mergedMetaData.onlyAllowUnauthenticatedAccess
    const ignoreAuthorization = mergedMetaData.ignoreAuthorization
    const hasSelectedARole = !!store.getters['access/effectiveRole']

    if (isAuthenticated) {
      const isSessionExpired = (store.state.authentication.sessionTimeout < moment())
      const isBeingLoggedOut = store.state.authentication.isBeingLoggedOut
      if (isSessionExpired && !isBeingLoggedOut) {
        await store.dispatch('authentication/signOut', from.query.redirect || to.fullPath)
      }
    }

    const checkAuthorization = (
      hasSelectedARole &&
      requiresAuth &&
      isAuthenticated &&
      !ignoreAuthorization &&
      process.env.VUE_APP_ENABLED_MODULE_ACCESS
    )
    if (checkAuthorization && !(await isAuthorized(to.path))) {
      next({name: 'forbidden'})
    } else if (requiresAuth && isAuthenticated) {
      store.dispatch('authentication/updateGen2EffectiveRoleThenProceedToRoute', to)

      if (from.query.redirect && from.query.redirect !== to.fullPath) {
        store.dispatch('setWindowLocation', updateQueryStringParameter(from.query.redirect, 'pageReload', true))
      } else {
        next()
      }
    } else if (requiresAuth && !isAuthenticated) {
      const redirectionUrl = to.fullPath !== '/' ? to.fullPath : undefined

      next({
        name: 'login',
        query: {
          redirect: redirectionUrl
        }
      })
    } else if (isAuthenticated && onlyAllowUnauthenticatedAccess) {
      const redirect = to.query && to.query.redirect

      store.dispatch('setWindowLocation', redirect || '/')
    } else {
      next()
    }
  }).catch((error) => {
    console.error('Unable to refresh session:', error)
    store.dispatch('authentication/signOut', from.query.redirect || to.fullPath)
  })
})

export default router
