import Vue from 'vue'

import { _mutationTypes } from './mutationTypes'

export const mutations = {
  [_mutationTypes.SET_EXPORT] (state, exportData) {
    const index = state.exports.findIndex(_export => _export.id === exportData.id)
    Vue.set(state.exports, index, exportData)
  },
  [_mutationTypes.SET_EXPORTS] (state, exports) {
    state.exports = exports
  }
}
