import BaseService from '@/api/base'

const jsonApiConfig = {
  headers: {
    'Accept': 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json'
  }
}

class UserManagementService extends BaseService {
  constructor () {
    super('user-management')
  }

  /**
   * Returns user's own attributes
   * @returns {Promise<*>}
   */
  getOwnUser () {
    return this.get('users/me', jsonApiConfig)
  }

  /**
   * Updates user's own attributes using username and attributes received in params
   * @param {string} userName
   * @param {string} attribute
   * @returns {Promise<*>}
   */
  updateOwnUser (userName, attributes) {
    return this.patch('users/me', {
      'data': {
        'type': 'user',
        'id': userName,
        'attributes': attributes
      }
    }, jsonApiConfig)
  }

  /**
   * Verifies user's own attribute using the verification code received in params
   * @param {string} attribute
   * @param {string} verificationCode
   * @returns {Promise<*>}
   */
  verifyUserOwnAttribute (attribute, verificationCode) {
    return this.post('users/me/verify-attribute', {
      'data': {
        'type': 'verify_user_own_attribute',
        'attributes': {
          attribute,
          verification_code: verificationCode
        }
      }
    }, jsonApiConfig)
  }

  /**
   * A group's information returned by BriteAuth's JSON:API endpoint.
   * @typedef {{type: 'group', id: String, attributes: Object}} GroupData
   */
  /**
   * getGroups returns the info of groups fetched from BriteAuth according to query parameters.
   * @param {string} params - Stringified query parameters to be sent to the endpoint.
   * @returns {{next: ?string, groups: Array.<GroupData>}} - The requested group data, together with the link to the next page.
   */
  async getGroups (params) {
    const response = await this.get(`groups?${params}`, jsonApiConfig)
    const nextLink = (response.links || {}).next

    return {
      next: nextLink ? this.processNextPageLink(nextLink) : null,
      groups: response.data || []
    }
  }

  /**
   * getAllGroups returns the info of all groups fetched from BriteAuth.
   * @returns {{next: null, groups: Array.<GroupData>}} - The requested group data
   */
  async getAllGroups () {
    let result = []
    let nextLink = null

    do {
      const response = await this.get(
        nextLink
          ? this.processNextPageLink(nextLink)
          : 'groups?page[size]=60',
        jsonApiConfig
      )

      if (response.data) {
        result = [...result, ...response.data]
      }

      nextLink = (response.links || {}).next
    } while (nextLink)

    return {
      next: null,
      groups: result
    }
  }

  /**
   * createGroup sends a request to create a new group with provided attributes
   * @param {Object} attributes
   * @returns {Promise<*>}
   */
  createGroup (attributes) {
    return this.post(`groups`, {
      'data': {
        'type': 'group',
        'attributes': attributes
      }
    }, jsonApiConfig)
  }

  async migrateBritecoreContact (credentials) {
    return await this.post(`migrate_gen2_contact`, credentials, jsonApiConfig)
  }

  /**
   * updateGroup sends a request to update a group with provided attributes
   * @param {string} groupName
   * @param {Object} attributes
   * @returns {Promise<*>}
   */
  updateGroup (groupName, attributes) {
    return this.patch(`groups/${groupName}`, {
      'data': {
        'type': 'group',
        // There is a small inconsistency in Django REST Framework JSON:API that requires a groupName as "id" field.
        // Read more: https://github.com/IntuitiveWebSolutions/BriteAuth/pull/692#pullrequestreview-456960189
        'id': groupName,
        'attributes': attributes
      }
    }, jsonApiConfig)
  }

  /**
   * deleteGroup sends a request to delete the provided group
   * @param {string} groupName
   * @returns {Promise<*>}
   */
  deleteGroup (groupName) {
    return this.delete(`groups/${groupName}`, jsonApiConfig)
  }

  /**
   * processNextPageLink strips off the prefix of a next page link returned by JSON:API endpoint.
   * @param {string}
   * @returns {string}
   */
  processNextPageLink (link) {
    const decodedUriLink = decodeURIComponent(link)
    return decodedUriLink.replace(/https:\/\/api\..*\.britecore\.com\/user-management\//gm, '')
  }

  linkToProvider (userName, providerName, providerUserId) {
    const payload = {
      'provider_name': providerName,
      'provider_user_id': providerUserId
    }

    return this.post(`users/${userName}/actions/link-to-provider`, payload)
  }

  /**
   * A user's information returned by BriteAuth's JSON:API endpoint.
   * @typedef {{type: 'user', id: String, attributes: Object}} UserData
   */
  /**
   * getUsers returns the info of users fetched from BriteAuth according to query parameters.
   * @param {string} params - Stringified query parameters to be sent to the endpoint.
   * @returns {{next: ?string, users: Array.<UserData>}} - The requested user data, together with the link to the next page.
   */
  async getUsers (params) {
    const response = await this.get(`users?${params}`, jsonApiConfig)
    const nextLink = (response.links || {}).next

    return {
      next: nextLink ? this.processNextPageLink(nextLink) : null,
      users: response.data || []
    }
  }

  /**
   * getAllUsers returns the info of all users fetched from BriteAuth.
   * @returns {{next: null, users: Array.<UserData>}} - The requested user data
   */
  async getAllUsers () {
    let result = []
    let nextLink = null

    do {
      const response = await this.get(
        nextLink
          ? this.processNextPageLink(nextLink)
          : 'users?page[size]=200',
        jsonApiConfig
      )

      if (response.data) {
        result = [...result, ...response.data]
      }

      nextLink = (response.links || {}).next
    } while (nextLink)

    return {
      next: null,
      users: result
    }
  }

  /**
   * Get a user's attributes
   * @param {string} userName
   * @returns {Promise<{data: UserData}>}
   */
  getUser (userName) {
    return this.get(`users/${userName}`, jsonApiConfig)
  }

  /**
   * createUser sends a request to create a new user with provided attributes
   * @param {Object} attributes
   * @returns {Promise<{data: UserData}>}
   */
  createUser (attributes) {
    return this.post(`users`, {
      'data': {
        'type': 'user',
        'attributes': attributes
      }
    }, jsonApiConfig)
  }

  /**
   * Update a user's attributes
   * @param {string} userName
   * @param {string} attribute
   * @returns {Promise<{data: UserData}>}
   */
  updateUser (userName, attributes) {
    return this.patch(`users/${userName}`, {
      'data': {
        'type': 'user',
        'id': userName,
        'attributes': attributes
      }
    }, jsonApiConfig)
  }

  async migrateGen2ContactForgotPassword (userName) {
    // TODO: This config should be promoted once we switch to JSON API
    const config = {
      headers: {
        'Content-Type': 'application/vnd.api+json'
      }
    }
    const payload = {
      'username': userName
    }
    return await this.post('migrate_gen2_contact_forgot_password', payload, config)
  }

  /**
   * addUserToGroups sends a request to add a user to a list of groups
   * @param {string} userName
   * @param {Array<string>} groupIds
   * @returns {Promise<*>}
   */
  addUserToGroups (userName, groupIds) {
    return this.post(`users/${userName}/relationships/groups`, {
      'data': groupIds.map((groupId) => ({
        'type': 'group',
        'id': groupId
      }))
    }, jsonApiConfig)
  }

  /**
   * removeUserFromGroups sends a request to remove a user from a list of groups
   * @param {string} userName
   * @param {Array<string>} groupIds
   * @returns {Promise<*>}
   */
  removeUserFromGroups (userName, groupIds) {
    const data = {
      'data': groupIds.map((groupId) => ({
        'type': 'group',
        'id': groupId
      }))
    }
    return this.delete(`users/${userName}/relationships/groups`, {...jsonApiConfig, data: data})
  }

  /**
   * listUserGroups returns the list of groups a user is assigned to in BriteAuth.
   * @param {string} userName
   * @returns {users: Array.<GroupData>} - The requested users data.
   */
  async listUserGroups (userName) {
    const response = await this.get(`users/${userName}/relationships/groups`, jsonApiConfig)
    return response.data || []
  }

  /**
   * listUsersInGroup returns the info of users assigned to a group fetched from BriteAuth.
   * @param {string} groupName
   * @returns {users: Array.<UserData>} - The requested users data.
   */
  async listUsersInGroup (groupName) {
    const response = await this.get(`groups/${groupName}/relationships/members`, jsonApiConfig)
    return response.data || []
  }

  /**
   * enableUser sends request to enable a specified user.
   * @param {string} userName
   * @returns {Promise<*>}
   */
  enableUser (userName) {
    return this.patch(`users/${userName}`, {
      'data': {
        'type': 'user',
        'id': userName,
        'attributes': {
          'is_active': true
        }
      }
    }, jsonApiConfig)
  }

  /**
   * disableUser sends request to disable a specified user.
   * @param {string} userName
   * @returns {Promise<*>}
   */
  disableUser (userName) {
    return this.patch(`users/${userName}`, {
      'data': {
        'type': 'user',
        'id': userName,
        'attributes': {
          'is_active': false
        }
      }
    }, jsonApiConfig)
  }

  /**
   * resetUserPassword sends request to reset a user's password.
   * @param {string} userName
   * @returns {Promise<*>}
   */
  resetUserPassword (userName) {
    return this.post(`users/${userName}/reset-password`, {}, jsonApiConfig)
  }

  /**
   * setTemporaryPassword sends request to set a temporary password for a user.
   * @param {string} userName
   * @param {string} password
   * @returns {Promise<*>}
   */
  setTemporaryPassword (userName, password) {
    return this.post(`users/${userName}/set-temporary-password`, {
      data: {
        type: 'user',
        id: userName,
        meta: {
          temporary_password: password
        }
      }
    }, jsonApiConfig)
  }

  /**
   * createAppClient sends request to create an app client.
   * @param {string} clientName
   * @returns {{clientId: string, clientName: string, clientSecret: string}} - details of the newly created app client
   */
  async createAppClient (clientName) {
    return await this.post(`app-clients`, {
      data: {
        type: 'app-client',
        attributes: {
          name: clientName
        }
      }
    }, jsonApiConfig)
  }
}

export default new UserManagementService()
