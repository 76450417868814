import moment from 'moment'
import utils from '@/store/policies/utils'
import Vue from 'vue'

export const mutations = {
  setPolicyPageReadyToShow (state, value) {
    state.policyPageReadyToShow = value
  },
  setPolicyInfo (state, policyInfo) {
    state.policyNumber = policyInfo.data.attributes.policy_number
    state.policyTerms = policyInfo.included.sort((a, b) =>
      utils.parseDate(a.attributes.effective_date) < utils.parseDate(b.attributes.effective_date) ? 1 : -1
    )
    state.inProgressOOSTransaction = policyInfo.data.relationships.in_progress_oos_transaction.data
    state.policyId = policyInfo.data.id
  },
  setRevisionInfo (state, revisions) {
    state.revisions = revisions.map(revision => ({
      revisionId: revision.id,
      decURL: revision.decURL,
      britelinesProductName: revision.attributes.britelines_product_name,
      policyTypeName: revision.attributes.policy_type_name,
      quoteNumber: revision.attributes.quote_number,
      productVersion: revision.attributes.product_version,
      revisionDate: moment(
        revision.attributes.revision_date
      ).format('M/D/YYYY'),
      revisionState: revision.attributes.revision_state,
      policyStatus: revision.attributes.policy_status,
      active: revision.attributes.policy_status === 'Active',
      description: revision.attributes.description,
      commissionRate: revision.attributes.commission_rate,
      writtenPremium: revision.attributes.written_premium,
      annualPremium: revision.attributes.annual_premium,
      policyTermId: revision.relationships.policy_term.data.id,
      writtenPremiumDelta: revision.attributes.written_premium_delta,
      fullPolicyNumber: revision.attributes.full_policy_number,
      nonRenewNumberDaysPrior: revision.attributes.non_renew_number_days_prior
    }))
    state.revisionId = state.revisions.find(r => r.revisionState === 'committed').revisionId
  },
  setRevisionId (state, revisionId) {
    state.revisionId = revisionId
  },
  setPolicyInfoScreenFlowInfo (state, screenflow) {
    state.riskTabs = screenflow.attributes.configuration.riskTabs
  },
  setRiskInfo (state, {riskData, revisionId, tabTitle}) {
    Vue.set(state.riskData, `${revisionId},${tabTitle}`, riskData)
  },
  setBillingInfo (state, billingInfo) {
    state.billingInfo = billingInfo
  },
  setOpenQuotes (state, {endorsement, renewal}) {
    state.openEndorsement = endorsement
    state.openRenewal = renewal
  },
  setScreenFlowInfoError (state, screenflowError) {
    state.screenFlowInfoError = screenflowError
  },
  setPolicyTransactionInfo (state, transactions) {
    state.policyTransactions = transactions.map(transaction => ({
      id: transaction.id,
      description: transaction.attributes.description,
      type: transaction.attributes.type,
      policyId: transaction.relationships.policy.data.id,
      revisionId: transaction.relationships.revision.data ? transaction.relationships.revision.data.id : '',
      policyTermId: transaction.relationships.policy_term.data ? transaction.relationships.policy_term.data.id : '',
      transactionDate: moment(utils.parseDate(transaction.attributes.transaction_date_time)).format('MM/DD/YYYY'),
      creationDate: moment(transaction.attributes.date_created),
      user: transaction.attributes.username,
      oosStatus: transaction.attributes.oos_status,
      triggeringId: transaction.relationships.triggering_oos_transaction.data
        ? transaction.relationships.triggering_oos_transaction.data.id
        : null
    })
    )
  },
  setInProgressTransactions (state, inProgressTransactions) {
    state.inProgressTransactions = inProgressTransactions
  },
  setFailedRenewalTransactionsCount (state, failedRenewalTransactionsCount) {
    state.failedRenewalTransactionsCount = failedRenewalTransactionsCount
  },
  setFailedNonDismissedTransactions (state, failedNonDismissedTransactions) {
    state.failedNonDismissedTransactions = failedNonDismissedTransactions
  },
  setCancellationInfo (state, cancellationInfo) {
    state.cancellationInfo = cancellationInfo
  },
  setSearchedLinkablePolicies (state, policyResults) {
    state.linkedPolicies.searchResults = policyResults
  },
  setLinkedPoliciesLoading (state, value) {
    state.linkedPolicies.loading = value
  },
  setLinkedPoliciesSearchLoading (state, value) {
    state.linkedPolicies.searchResults = { ...state.linkedPolicies.searchResults, loading: value }
  },
  setLinkedPolicies (state, linkedPoliciesData) {
    state.linkedPolicies = {
      ...state.linkedPolicies, ...linkedPoliciesData
    }
  },
  addLinkedPolicy (state, policy) {
    const policies = state.linkedPolicies.policies
    policies.push(policy)
  },
  removeLinkedPolicy (state, { id, currentPolicyId }) {
    const policies = state.linkedPolicies.policies
    const policyToRemove = policies.find(policy => policy.policyId === id)

    if (policyToRemove) {
      if (policyToRemove.policyId === currentPolicyId) {
        // Remove all except current if the current policy is removed from the policy group
        state.linkedPolicies.policies = [policyToRemove]
      } else {
        const index = policies.indexOf(policyToRemove)
        policies.splice(index, 1)
      }
    }
  }
}
