import { addNamespace } from '@/store/helpers'
import { PRODUCT_MAPPING_STORE_PATH } from './constants'

// To be used only inside vuex modules
export const _actionTypes = {
  LOAD_PRODUCT_RISK_TYPES: 'loadProductRiskTypes',
  LOAD_PRODUCT_DIFFERENCE: 'loadProductDifference',
  SET_PRODUCT_DIFFERENCE: 'setProductDifference',
  SET_ACTIVE_PRODUCT_DIFFERENCE: 'setActiveProductDifference',
  SET_RISK_TYPE_DIFFERENCE: 'setRiskTypeDifference',
  SET_RISK_TYPES_TREE: 'setRiskTypesTree',
  SET_ACTIVE_RISK_TYPES_TREE_NODE_ID: 'setActiveRiskTypesTreeNodeId',
  LOAD_PRODUCT_MAPPING: 'loadProductMapping',
  SET_PRODUCT_MAPPING: 'setProductMapping',
  UPDATE_PRODUCT_MAPPING: 'updateProductMapping'
}

// To be used publicly outside vuex modules
export const actionTypes = addNamespace(PRODUCT_MAPPING_STORE_PATH, _actionTypes)
