import {JsonApiService} from './api'

class RiskApi extends JsonApiService {
  constructor () {
    super('risk')
  }
}

class RiskTypeApi extends JsonApiService {
  constructor () {
    super('risk-type')
  }
}

export {RiskApi, RiskTypeApi}
