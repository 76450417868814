var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isComponentOnly)?_c('div',{directives:[{name:"loading",rawName:"v-loading.body.lock",value:(_vm.loading),expression:"loading",modifiers:{"body":true,"lock":true}}],staticClass:"layout__container",class:Object.assign({}, {'layout__container--full-page': _vm.isFullPage,
    'layout__container--has-live-site-warning': _vm.isLiveSite && _vm.isEmployee},
    _vm.agencyClasses)},[_c('div',{staticClass:"layout__sidebar",class:{
      'layout__sidebar--hidden-mobile': !_vm.sidebarVisible
    }},[_c('main-nav-sidebar')],1),(!_vm.isFullPage)?[_c('div',{staticClass:"layout__sub-nav-mobile"},[_c('sub-nav-mobile')],1),_c('div',{staticClass:"layout__sub-sidebar",class:{ 'layout__sub-sidebar--hidden-mobile': !_vm.subSidebarVisibleMobile }},[_c('sub-nav-sidebar')],1)]:_vm._e(),_c('div',{staticClass:"layout__main-nav"},[_c('main-top-nav')],1),(_vm.isLiveSite && _vm.isEmployee)?_c('el-alert',{staticClass:"layout__live-site-warning",attrs:{"type":"error","title":"","show-icon":false}},[_c('div',{staticClass:"u-text--center"},[_c('fa-icon',{staticClass:"u-mr2 u-mt-auto u-mb-auto",attrs:{"icon":"times-circle"}}),_c('span',{staticClass:"u-mt-auto u-mb-auto"},[_vm._v(" Be careful, this is a "),_c('strong',[_vm._v("Live")]),_vm._v(" site ")])],1)]):_vm._e(),_c('main',{staticClass:"layout__page-content",class:{
      'layout__page-content--no-padding': _vm.noContainerPadding,
      'layout__page-content--pushed-mobile': _vm.subSidebarVisibleMobile
    }},[_c('portal-target',{attrs:{"name":"module_header"}}),_c('router-view')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isNotesSidebarActive),expression:"isNotesSidebarActive"}],staticClass:"layout__activity-sidebar"},[_c('activity-nav-sidebar')],1),_c('keyboard-shortcuts-modal',{model:{value:(_vm.keyboardShortcutsModalVisible),callback:function ($$v) {_vm.keyboardShortcutsModalVisible=$$v},expression:"keyboardShortcutsModalVisible"}}),_c('quick-search-modal',{attrs:{"searchType":_vm.searchType},model:{value:(_vm.quickSearchModalVisible),callback:function ($$v) {_vm.quickSearchModalVisible=$$v},expression:"quickSearchModalVisible"}}),_c('bc-plugin-slot',{staticClass:"hide",attrs:{"plugin-slot-name":"britecore:root:markup","handler-component":"bc-plugin-markup","plugins-url":_vm.pluginsUrl,"integrations-url":_vm.integrationsUrl,"access-token":_vm.accessToken}})],2):_c('router-view')}
var staticRenderFns = []

export { render, staticRenderFns }