import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL

const productExportsService = {
  /**
   * Schedules an asynchronous Product Template export.

   * @param {String} productName
   * @param {String} versionId
   * @returns {Promise<Object>} Export details.
   */
  exportProduct: (productName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/exports/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, null, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Retrieves existing Product Template export for the current user.

   * @param {String} productName
   * @param {String} exportId
   * @param {String} versionId
   * @returns {Promise<Object>} Export details.
   */
  getExport: (productName, exportId, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/exports/${exportId}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.get(url, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Retrieves existing Product Template exports for the current user.

   * @param {String} productName
   * @param {String} versionId
   * @returns {Promise<Object>} A list of exports details.
   */
  getExports: (productName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/exports/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.get(url, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  }
}

export default productExportsService
