<script>
/* istanbul ignore file */

import { mapState, mapGetters } from 'vuex'
import Pendo from './pendo'

export default {
  name: 'Analytics',

  computed: {
    ...mapState({
      role: state => state.authentication.gen2EffectiveRole
    }),
    ...mapState('bcClassic', ['carrier', 'metaData', 'contact']),
    ...mapState('authentication', ['isEmployee', 'currentUser']),
    ...mapGetters('authentication', ['isLiveSite'])
  },

  methods: {
    initializePendo (userAttributesArr) {
      if (!userAttributesArr || !userAttributesArr.length) return
      const carrierName = this.carrier && this.carrier.name
      Pendo.initialize(userAttributesArr, carrierName, this.role, this.contact.contactId)
    }
  },

  watch: {
    async role (newRole, oldRole) {
      const { analyticsEnabled, trackAgentsInPendo, whitelistedForPendo } = this.metaData
      const { isLiveSite, isEmployee, currentUser } = this

      if (!analyticsEnabled) return // return if analytics is off
      if (!trackAgentsInPendo && newRole !== 'Administrator') return // return if agent and not tracking agent
      if (!isLiveSite && !whitelistedForPendo) return // return if it's a non-whitelisted demo site

      const shouldTrack = (isLiveSite && !isEmployee) || whitelistedForPendo

      if (!oldRole && shouldTrack) {
        currentUser.getUserAttributesAsync().then(userAttributesArr => {
          this.initializePendo(userAttributesArr)
        })
      }
    }
  },

  render: () => null
}
</script>
