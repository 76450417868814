import axios from 'axios'
import {
  BC_ENDPOINT_GET_INSTALLMENTS_PREVIEW,
  BC_ENDPOINT_GET_INSTALLMENTS_PREVIEW_MID_TERM,
  BC_ENDPOINT_GET_RENEWAL_INSTALLMENTS_PREVIEW,
  BC_ENDPOINT_ADD_PAYMENT_METHOD,
  RISK_TYPE_STATE_MINIMAL
} from '@/pages/quote/constants'
import _ from 'lodash'

const API_URL = process.env.VUE_APP_API_URL

const quoteService = {
  BRITEQUOTE_API_URL: `${API_URL}/quote`,
  /**
   * Get a list of Products that are available for quoting.
   * @param {String} effectiveBefore Date string in YYYY-MM-DD format.
   * @returns {Promise<Array>} Promise object that receives the list of Products.
   */
  getQuotableProducts: (effectiveBefore) => {
    const url = `${API_URL}/quote/quotable-products/?effective_before=${effectiveBefore}`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },
  /**
   * Get BriteCore Policy Types
   * @param {String} effectiveDate Date String in YYYY-MM-DD format
   * @return {Promise<Object>} Promise object that receives the response data.
   * Response data contains an empty `data` array if Policy Types are not found.
   */
  getPolicyTypes: (effectiveDate) => {
    const url = '/api/v2/lines/find_effective_date'
    const payload = { date: effectiveDate }
    return new Promise((resolve, reject) => {
      axios.post(url, payload).then(response => {
        const { success, messages = [] } = response.data
        // We don't want to throw errors if no policy types are found.
        const errorMessagesToIgnore = 'Cannot find effective date'
        if (success || _.includes(messages, errorMessagesToIgnore)) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },
  /**
   * Get a Quote.
   * @param quoteNumber
   * @returns {Promise<Object>} Promise object that receives a Quote object.
   */
  getQuote: (quoteNumber) => {
    const url = `${API_URL}/quote/${quoteNumber}/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Updates a Quote.
   * @param quoteNumber {String}
   * @param payload {Object}
   * @returns {Promise<Object>} Update request promise.
   */
  updateQuote: (quoteNumber, payload) => {
    const url = `${API_URL}/quote/${quoteNumber}/`
    return new Promise((resolve, reject) => {
      axios.put(url, payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Delete a Quote.
   * @param quoteNumber
   * @returns {Promise<Object>} Delete request promise.
   */
  deleteQuote: (quoteNumber) => {
    const url = `${API_URL}/quote/${quoteNumber}/`
    return new Promise((resolve, reject) => {
      axios.delete(url).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Get a list of all Quotes.
   * @param searchQuery {String}
   * @param pageSize {Number}
   * @param pageNumber {Number}
   * @param filters {Object} Object mapping filter names to filter values.
   * @returns {Promise<Object>} Promise object that receives a list of Quote objects.
   */
  getQuotes: (searchQuery, pageSize, pageNumber, filters = {}) => {
    let url = `${API_URL}/quote/?include=agents,agency,named_insureds,owner`
    if (searchQuery) {
      url = url + `&search=${searchQuery}`
    }
    if (pageSize) {
      url = url + `&page[size]=${pageSize}`
    }
    if (pageNumber) {
      url = url + `&page[number]=${pageNumber}`
    }
    for (const [filterName, filterValue] of Object.entries(filters)) {
      url = url + `&filter[${filterName}]=${filterValue}`
    }
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },
  /**
   * Assign a Contact as the owner of a Quote.
   * @param quoteNumber
   * @param contactId
   */
  assignOwner: (quoteNumber, contactId) => {
    const url = `${API_URL}/quote/${quoteNumber}/assign/${contactId}/`
    return new Promise((resolve, reject) => {
      axios.post(url).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },
  /**
   * Get a Quote Flow.
   * @param quoteNumber
   * @returns {Promise<Object>} Promise object that receives a QuoteFlow object.
   */
  getQuoteFlow: (quoteNumber) => {
    const url = `${API_URL}/quote/${quoteNumber}/flow/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response)
      }).catch(error => {
        reject({
          ...error.response.data,
          status: error.response.status
        })
      })
    })
  },
  /**
   * Get a RiskQuote and its children.
   * @param riskQuoteId
   * @returns {Promise<Object>} Promise object that receives a RiskQuote object.
   */
  getRiskQuote: (riskQuoteId) => {
    const url = `${API_URL}/quote/risks/${riskQuoteId}/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },
  /**
   * Get Billing details.
   * @param quoteNumber
   * @returns {Promise<Object>} Get Billing details request promise.
   */
  getBillingDetails: (quoteNumber) => {
    const url = `${API_URL}/quote/${quoteNumber}/billing/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },
  /**
   * Get Billing config.
   * @param quoteNumber
   * @returns {Promise<Object>} Get Billing config request promise.
   */
  getBillingConfig: (quoteNumber) => {
    const url = `${API_URL}/quote/${quoteNumber}/billing/config/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },
  /**
   * Save Billing.
   * @param quoteNumber
   * @param formData
   * @returns {Promise<Object>} Save Billing request promise.
   */
  saveBilling: (quoteNumber, formData) => {
    const url = `${API_URL}/quote/${quoteNumber}/billing/`
    const config = {
      headers: {
        'Content-Type': 'application/vnd.api+json'
      }
    }
    return new Promise((resolve, reject) => {
      axios.post(url, formData, config).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  /**
   * Creates a Quote.
   * @param formData
   * @returns {Promise<Object>} Create request promise.
   */
  createQuote: (formData) => {
    const url = `${API_URL}/quote/`
    return new Promise((resolve, reject) => {
      axios.post(url, formData).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },
  /**
   * Create a Policy in BriteCore.
   * @param {Object} payload
   * @returns {Promise<Object>} Create request promise.
   */
  createPolicy: (payload) => {
    const url = '/api/v2/policies/create_policy'
    return new Promise((resolve, reject) => {
      axios.post(url, payload).then(response => {
        if (response.data.success) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Creates a Risk Quote.
   * @param quoteId
   * @param parentRiskQuoteId
   * @param riskTypeName
   * @param dryRun
   * @param rulesAction
   * @param generatedBy
   * @returns {Promise<Object>} Create request promise.
   */
  createRiskQuote: (
    quoteId,
    parentRiskQuoteId,
    riskTypeName,
    dryRun = false,
    rulesAction = null,
    generatedBy = '') => {
    const url = `${API_URL}/quote/risks/`
    const data = {
      quote: quoteId,
      parent_risk_quote: parentRiskQuoteId,
      risk_type_name: riskTypeName,
      generated_by: generatedBy
    }
    const params = { dry_run: dryRun }

    if (rulesAction) {
      params.rules_action = rulesAction
    }

    return new Promise((resolve, reject) => {
      axios.post(url, data, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Updates a Risk Quote.
   * @param riskQuoteId
   * @param riskState
   * @returns {Promise<Object>} Update request promise.
   */
  updateRiskQuote: (riskQuoteId, riskState, config) => {
    const url = `${API_URL}/quote/risks/${riskQuoteId}/`
    const data = { risk_state: riskState }
    return new Promise((resolve, reject) => {
      axios.put(url, data, config).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        if (!axios.isCancel(error)) {
          reject(error.response.data)
        }
      })
    })
  },

  /**
   * Deletes Risk Quote.
   * @param riskQuoteId
   * @param rulesAction
   * @returns {Promise<Object>} Delete request promise.
   */
  deleteRiskQuote: (riskQuoteId, rulesAction) => {
    const url = `${API_URL}/quote/risks/${riskQuoteId}/`
    const params = { rules_action: rulesAction }
    return new Promise((resolve, reject) => {
      axios.delete(url, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Evaluates a Risk Quote.
   * @param riskQuoteId
   * @param rulesAction
   * @returns {Promise<Object>} Evaluate request promise.
   */
  evaluateRiskQuote: (riskQuoteId, rulesAction) => {
    const url = `${API_URL}/quote/risks/${riskQuoteId}/evaluate/`
    const params = { rules_action: rulesAction }
    return new Promise((resolve, reject) => {
      axios.post(url, null, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        if (!axios.isCancel(error)) {
          reject(error.response.data)
        }
      })
    })
  },

  /**
   * Submits Quote.
   * @param quoteNumber
   * @returns {Promise<Object>} Submit Quote request promise.
   */
  submitQuote: (quoteNumber) => {
    const url = `${API_URL}/quote/${quoteNumber}/submit/`
    return new Promise((resolve, reject) => {
      axios.post(url).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Binds Quote.
   * @param quoteNumber
   * @param isAsync
   * @returns {Promise<Object>} Bind Quote request promise.
   */
  bindQuote: (quoteNumber) => {
    const url = `${API_URL}/quote/${quoteNumber}/bind_requested/`
    return new Promise((resolve, reject) => {
      axios.post(url).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Validates Risk Quote Field answer.
   * @param riskQuoteId
   * @param riskFieldName
   * @param answer
   * @param fieldOverrides
   * @returns {Promise<Object>} Validation request promise.
   */
  validateRiskQuoteFieldAnswer: (riskQuoteId, riskFieldName, answer, fieldOverrides, config) => {
    const url = `${API_URL}/quote/risks/${riskQuoteId}/fields/${riskFieldName}/validate-answer/`
    const data = {
      answer: answer,
      field_overrides: fieldOverrides
    }
    return new Promise((resolve, reject) => {
      axios.post(url, data, config).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        if (!axios.isCancel(error)) {
          reject(error.response.data)
        }
      })
    })
  },
  /**
   * Adds an item to Risk Quote.
   * @param riskQuoteId
   * @param riskItemName
   * @returns {Promise<Object>} Create request promise.
   */
  addRiskItem: (riskQuoteId, riskItemName) => {
    const url = `${API_URL}/quote/items/`
    const data = {
      risk_quote: riskQuoteId,
      risk_item_name: riskItemName
    }
    return new Promise((resolve, reject) => {
      axios.post(url, data).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },
  /**
   * Removes an item from Risk Quote.
   * @param riskQuoteId
   * @param riskItemName
   * @returns {Promise<Object>} Delete request promise.
   */
  removeRiskItem: (riskQuoteId, riskItemName) => {
    const url = `${API_URL}/quote/items/${riskItemName}/`
    const data = { risk_quote: riskQuoteId }
    return new Promise((resolve, reject) => {
      axios.delete(url, { data: data }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Finalizes Risk Quote.
   * @param riskQuoteId
   * @returns {Promise<Object>} Finalize request promise.
   */
  finalizeRiskQuote: (riskQuoteId) => {
    const url = `${API_URL}/quote/risks/${riskQuoteId}/finalize/`
    return new Promise((resolve, reject) => {
      axios.post(url).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Create an Attachment with a Presigned S3 Upload URL.
   * @param quoteNumber
   * @param fileName
   * @param contentType
   * @param size
   * @param {Object} source
   * @returns {Promise<Object>} Attachment promise with Presigned S3 URL.
   */
  createPresignedURL: (quoteNumber, fileName, contentType, size, source) => {
    const url = `${API_URL}/quote/${quoteNumber}/attachments/`
    const data = {
      name: fileName,
      content_type: contentType,
      size: size,
      source: source
    }
    return new Promise((resolve, reject) => {
      axios.post(url, data).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Upload a file to a Presigned S3 URL.
   * @param url
   * @param fields
   * @param file
   * @returns {Promise<Object>} Upload request promise.
   */
  uploadToPresignedURL: (url, fields, file) => {
    if (url.startsWith('/')) {
      url = `${API_URL}${url}`
    }
    const formData = new FormData()
    for (const key in fields) {
      formData.append(key, fields[key])
    }
    formData.append('file', file.raw)

    return new Promise((resolve, reject) => {
      axios.post(url, formData, { excludeAuthHeader: true })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /**
   * Create Presigned URL and upload file to S3.
   * @param quoteNumber
   * @param file
   * @param {Object} source
   * @returns {Promise<Object>} Upload request promise.
   */
  uploadFile: async (quoteNumber, file, source) => {
    const attachment = await quoteService.createPresignedURL(quoteNumber, file.name, file.raw.type, file.size, source)
    return quoteService.uploadToPresignedURL(attachment.file_location.url, attachment.file_location.fields, file)
  },

  /**
   * Get list of attachments on a Quote.
   * @param quoteNumber
   * @returns {Promise<Object>} Promise object that receives the list of Attachment for a Quote.
   */
  getQuoteAttachments: (quoteNumber) => {
    const url = `${API_URL}/quote/${quoteNumber}/attachments/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Delete an attachment.
   * @param quoteNumber
   * @param attachmentId
   * @returns {Promise<Object>} Delete request promise.
   */
  deleteAttachment: (quoteNumber, attachmentId) => {
    const url = `${API_URL}/quote/${quoteNumber}/attachments/${attachmentId}/`
    return new Promise((resolve, reject) => {
      axios.delete(url).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Get list of available quote status triggers.
   * @param quoteNumber
   * @returns {Promise<Object>} Get quote status triggers request promise.
   */
  getQuoteStatusTriggers: (quoteNumber) => {
    const url = `${API_URL}/quote/${quoteNumber}/status/triggers/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Execute specified trigger on specified quote.
   * @param quoteNumber
   * @param triggerName
   * @returns {Promise<Object>} Execute quote status trigger request promise.
   */
  executeQuoteStatusTrigger: (quoteNumber, triggerName) => {
    const url = `${API_URL}/quote/${quoteNumber}/status/triggers/${triggerName}/`
    return new Promise((resolve, reject) => {
      axios.post(url).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Request Document from BriteDocs.
   * @param quoteNumber {String}
   * @param documentName {String}
   * @param email {Object}
   * @returns {Promise<Object>} Request document promise.
   */
  requestDocument: (quoteNumber, documentName, email = undefined) => {
    const url = `${API_URL}/quote/${quoteNumber}/documents/`
    const data = {
      data: {
        type: 'documents',
        attributes: {
          name: documentName,
          email
        }
      }
    }
    const config = {
      headers: {
        'Content-Type': 'application/vnd.api+json'
      }
    }
    return new Promise((resolve, reject) => {
      axios.post(url, data, config).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Get list of saved rules for a given quote.
   * @param quoteNumber
   * @returns {Promise<Object>} Get quote rules request promise.
   */
  getQuoteRules: (quoteNumber) => {
    const url = `${API_URL}/quote/${quoteNumber}/rules/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Add Payment Method.
   * @param {Object} payload
   * @returns {Promise<Object>} Add Payment Method request promise.
   */
  addPaymentMethod: (payload) => {
    return new Promise((resolve, reject) => {
      axios.post(BC_ENDPOINT_ADD_PAYMENT_METHOD, payload).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  /**
   * Get installments preview.
   * @param {Array} scheduleIds
   * @param {string} effectiveDate
   * @param {Number} premium
   * @param {string} paymentMethod
   * @returns {Promise<Object>} Get installments preview request promise.
   */
  getInstallmentsPreview: (scheduleIds, effectiveDate, premium, paymentMethod = '') => {
    const payload = {
      billing_schedule_ids: scheduleIds,
      effective_date: effectiveDate,
      premium: premium,
      payment_method: paymentMethod
    }
    return new Promise((resolve, reject) => {
      axios.post(BC_ENDPOINT_GET_INSTALLMENTS_PREVIEW, payload).then(response => {
        if (response.data.success) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Get installments preview for mid term changes.
   * @param {Array} scheduleIds
   * @param {string} effectiveDate
   * @param {Number} proratedPremium
   * @param {string} paymentMethod
   * @returns {Promise<Object>} Get installments preview request promise.
   */
  getInstallmentsPreviewMidTerm: (scheduleIds, policyId, effectiveDate, proratedPremium, paymentMethod = '') => {
    const payload = {
      billing_schedule_ids: scheduleIds,
      policy_id: policyId,
      revision_effective_date: effectiveDate,
      prorated_premium: proratedPremium,
      payment_method: paymentMethod
    }
    return new Promise((resolve, reject) => {
      axios.post(BC_ENDPOINT_GET_INSTALLMENTS_PREVIEW_MID_TERM, payload).then(response => {
        if (response.data.success) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Get renewal installments preview.
   * @param {Array} scheduleIds
   * @param {string} effectiveDate
   * @param {Number} premium
   * @param {string} paymentMethod
   * @returns {Promise<Object>} Get renewal installments preview request promise.
   */
  getRenewalInstallmentsPreview: (scheduleIds, effectiveDate, premium, paymentMethod = '') => {
    const payload = {
      billing_schedule_ids: scheduleIds,
      effective_date: effectiveDate,
      premium: premium,
      payment_method: paymentMethod
    }
    return new Promise((resolve, reject) => {
      axios.post(BC_ENDPOINT_GET_RENEWAL_INSTALLMENTS_PREVIEW, payload).then(response => {
        if (response.data.success) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Get minimal Risk Type State for given Product Name, Product version and Risk Type Name.
   * @param {string} productName
   * @param {string} productVersion
   * @param {string} riskTypeName
   * @returns {Promise<Object>} Get risk type state request promise.
   */
  getRiskTypeState: (productName, productVersion, riskTypeName) => {
    const params = `?version_id=${productVersion}&type=${RISK_TYPE_STATE_MINIMAL}`
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/state/${params}`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Rate Quote.
   * @param quoteNumber
   * @returns {Promise<Object>} Rate Quote request promise.
   */
  rateQuote: (quoteNumber) => {
    const url = `${API_URL}/quote/${quoteNumber}/rate/`
    return new Promise((resolve, reject) => {
      axios.post(url).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },
  /**
   * Get BriteCore Contact by Cognito username
   * @param {String} cognitoUsername Cognito Username
   * @return {Promise<Object>} Promise object that receives the response data.
   */
  getContactByCognitoUsername: (cognitoUsername) => {
    const url = '/api/v2/contacts/get_contact_by_cognito_username'
    const payload = { cognito_username: cognitoUsername }
    return new Promise((resolve, reject) => {
      axios.post(url, payload).then(response => {
        if (response.data.success) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },
  /**
   * Get BriteCore Related Contacts by Cognito username
   * @param {String} cognitoUsername Cognito Username
   * @return {Promise<Object>} Promise object that receives the response data.
   */
  getRelatedContacts: (cognitoUsername) => {
    const url = '/api/v2/contacts/retrieve_related_contacts'
    const payload = {
      cognito_username: cognitoUsername,
      result_structure: 'flattened',
      attributes_required: 'id,name',
      relations_required: [
        'agencies'
      ]
    }
    return new Promise((resolve, reject) => {
      axios.post(url, payload).then(response => {
        if (response.data.success) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },
  /**
   * Get Quoting Permissions for a contact
   * @param {String} contactId Contact ID for which the permissions will be retrieved.
   * @return {Promise<Object>} Promise object that receives the response data.
   */
  retrieveQuotingPermissions: (contactId) => {
    const url = '/api/v2/contacts/retrieve_quoting_permissions'
    const payload = { contact_id: contactId }
    return new Promise((resolve, reject) => {
      axios.post(url, payload).then(response => {
        if (response.data.success) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },
  rateQuotePreview: (quote, unifiedRiskState, product) => {
    const url = `${API_URL}/rating/rate-quote/`
    const payload = {
      unified_risk_state: unifiedRiskState,
      product: { name: quote.product_name, base_term: product.settings.base_term },
      version: { id: quote.product_version },
      rating_date: quote.policy_state.term.effective_date,
      policy_term_effective_date: quote.policy_state.policy.term_effective_date,
      policy_inception_date: quote.policy_state.policy.inception_date,
      term: quote.term,
      transaction_type: quote.transaction_type
    }
    return new Promise((resolve, reject) => {
      axios.post(url, payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },
  evaluateQuoteComputedFields: (quote, unifiedRiskState, product) => {
    const url = `${API_URL}/rating/evaluate-quote-computed-fields/`
    const payload = {
      unified_risk_state: unifiedRiskState,
      product: { name: quote.product_name, base_term: product.settings.base_term },
      version: { id: quote.product_version },
      rating_date: quote.policy_state.term.effective_date,
      policy_term_effective_date: quote.policy_state.policy.term_effective_date,
      policy_inception_date: quote.policy_state.policy.inception_date,
      term: quote.term,
      transaction_type: quote.transaction_type
    }
    return new Promise((resolve, reject) => {
      axios.post(url, payload).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },
  /**
   * Get product information.
   * @param productName {String}
   * @param productVersion {String}
   * @returns {Promise<Object>} Get product request promise.
   */
  getProduct: (productName, productVersion) => {
    const url = `${API_URL}/lines/products/${productName}/?version_id=${productVersion}`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },
  /**
   * Delete quote alert
   * @param quoteNumber {String}
   * @param alertId {String}
   * @returns {Promise<Object>} Delete request promise.
   */
  removeQuoteAlert: (quoteNumber, alertId) => {
    const url = `${API_URL}/quote/${quoteNumber}/alerts/${alertId}/`
    return new Promise((resolve, reject) => {
      axios.delete(url).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },
}

export default quoteService
