import { RULES_ROOT_STORE_PATH } from './constants'
import { addNamespace } from '@/store/helpers'
import { MUTATIONS as ComponentEditorMutations } from '@/store/rules/componentEditorStore/mutation-types'
import { MUTATIONS as ActiveProjectMutations } from '@/store/rules/activeProjectStore/mutation-types'

// To be used only inside vuex modules
export const _mutations = {
  SET_PROJECTS: 'setProjects',
  SET_PACKAGES: 'setPackages',
  RESET_PROJECTS: 'resetProjects',
  RESET_PACKAGES: 'resetPackages',
  OPEN_COMMIT_CHANGES_DIALOG: 'openCommitChangesDialog',
  CLOSE_COMMIT_CHANGES_DIALOG: 'closeCommitChangesDialog',
  OPEN_PROJECT_DEPLOYMENT_DIALOG: 'openProjectDeploymentDialog',
  CLOSE_PROJECT_DEPLOYMENT_DIALOG: 'closeProjectDeploymentDialog',
  START_LOADING_PACKAGE_CATALOG: 'startLoadingPackageCatalog',
  SET_PACKAGE_CATALOG: 'setPackageCatalog',
  RESET_PACKAGE_CATALOG: 'resetPackageCatalog',
  SET_ERROR_LOADING_PACKAGE_CATALOG: 'setErrorLoadingPackageCatalog',
  START_LOADING_PACKAGE: 'startLoadingPackage',
  UPDATE_ACTIVE_PACKAGE: 'updateActivePackage',
  SET_ERROR_LOADING_PACKAGE: 'setErrorLoadingPackage',
  SET_SUGGESTIONS: 'setSuggestions'
}

// To be used publicly outside vuex modules
export const MUTATIONS = {
  ...addNamespace(
    RULES_ROOT_STORE_PATH,
    _mutations
  ),
  COMPONENT_EDITOR: ComponentEditorMutations,
  ACTIVE_PROJECT: ActiveProjectMutations
}
