
export const state = {
  line: {},
  product: {},
  riskType: {},
  versions: [],
  activeVersion: {},
  lineOverviewActiveProducts: [],
  fieldFormats: [],
  breadcrumbs: [],
  productImports: [],
  gen2BusinessLocations: []
}
