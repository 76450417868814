import axios from 'axios'
import { PURPOSE_TAG_CATEGORY } from '@/pages/rules/constants'
import uuid from 'uuid'
import get from 'lodash/get'
import qs from 'qs'
import concat from 'lodash/concat'

const API_URL = process.env.VUE_APP_API_URL

class RulesAPIError extends Error {
  constructor (errorData) {
    super('Rules API Error')
    this.name = 'Rules API Error'
    this.apiError = errorData
  }
}

const rulesAPIErrorHandler = (error) => {
  throw new RulesAPIError(error && error.response && error.response.data)
}

const RulesService = {
  async getProjects () {
    let response = await axios.get(`${API_URL}/rules/projects/`)
    return response.data.results
  },
  async getProject (id) {
    let response = await axios.get(`${API_URL}/rules/projects/${id}/`)
    return response.data
  },
  runProjectTests (projectId) {
    const url = `${API_URL}/rules/projects/${projectId}/build/async-test/`
    return new Promise((resolve, reject) => {
      axios.post(url).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  validateProject (projectId) {
    const url = `${API_URL}/rules/projects/${projectId}/build/validate/`
    return new Promise((resolve, reject) => {
      axios.post(url).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async syncWithLines (projectId) {
    const response = await axios.post(`${API_URL}/rules/projects/${projectId}/sync/`)
    return response.data
  },
  async deleteProject (projectId) {
    const response = await axios.delete(`${API_URL}/rules/projects/${projectId}/`)
    return response.data
  },
  runComponentTest (projectId, componentName) {
    const url = `${API_URL}/rules/projects/${projectId}/build/async-test/${componentName}/`
    return new Promise((resolve, reject) => {
      axios.post(url).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async getPackages () {
    let response = await axios.get(`${API_URL}/rules/package/`)
    return response.data.results
  },
  async getPackage (id) {
    let {data} = await axios.get(`${API_URL}/rules/package/${id}/`)
    return data
  },
  getPackageDependencies (id) {
    const url = `${API_URL}/rules/package/${id}/dependencies/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getPackageTags (packageId) {
    const url = `${API_URL}/rules/package-tag/summary/?packageId=${packageId}`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async getPackageTagComponents (packageTagId) {
    let response = await axios.get(`${API_URL}/rules/package-tag/${packageTagId}/summary/`)
    return response.data
  },
  async getPackageTag (packageTagId) {
    let response = await axios.get(`${API_URL}/rules/package-tag/${packageTagId}/`)
    return response.data
  },
  getAllPackageTags () {
    const url = `${API_URL}/rules/package-tag/summary/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async deletePackageTag (packageTagId) {
    let response = await axios.delete(`${API_URL}/rules/package-tag/${packageTagId}/`)
    return response.data
  },
  addDependency (fromPackageId, toPackageId, version) {
    const url = `${API_URL}/rules/package/${fromPackageId}/dependency/${toPackageId}/${version}/`
    return new Promise((resolve, reject) => {
      axios.post(url).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  removeDependency (fromPackageId, toPackageId, version) {
    const url = `${API_URL}/rules/package/${fromPackageId}/dependency/${toPackageId}/${version}/`
    return new Promise((resolve, reject) => {
      axios.delete(url).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getComponentsByEffectiveDate (projectId, effectiveDateId) {
    const url = `${API_URL}/rules/projects/${projectId}/effective-dates/${effectiveDateId}/components/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getEffectiveDateByComponent (projectId, componentId) {
    const url = `${API_URL}/rules/brl-components/projects/${projectId}/components/${componentId}/effective-dates/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data.map((item) => {
          return {
            date_added: item.effective_date.date_added,
            date_modified: item.effective_date.date_modified,
            description: item.effective_date.description,
            effective_date: item.effective_date.effective_date,
            id: item.effective_date.id,
            name: item.effective_date.name,
            project: item.effective_date.project,
            status: item.effective_date.state.toLowerCase(),
            componentId: item.component_id
          }
        }))
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getComponents (packageId) {
    const url = `${API_URL}/rules/package/${packageId}/summary/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async getUncommittedComponents (projectId) {
    const {data} = await axios.get(`${API_URL}/rules/brl-components/projects/${projectId}/uncommittedcomponents/`)
    return data
  },
  async getComponent (componentId, effectiveDateId) {
    let url = `${API_URL}/rules/brl-components/${componentId}/`
    if (effectiveDateId) {
      url += `effective-date/${effectiveDateId}/`
    }
    let response = await axios.get(url)
    return response.data
  },
  async moveComponent (packageId, componentPath, componentName, toFolder) {
    let qs = [
      `package_id=${packageId}`,
      `full_name=${concat(componentPath, componentName).filter(item => !!item).join('.')}`,
      `name=${componentName}`,
      `folder=${toFolder.join('.')}`
    ].join('&')
    let {data} = await axios.put(`${API_URL}/rules/brl-components/move_component/?${qs}`)
    return data
  },
  async moveFolder (packageId, fromFolderPath, toFolderPath) {
    let qs = [
      `package_id=${packageId}`,
      `from_folder=${fromFolderPath.join('.')}`,
      `to_folder=${toFolderPath.join('.')}`
    ].join('&')
    let {data} = await axios.put(`${API_URL}/rules/brl-components/move_components/?${qs}`)
    return data
  },
  async removeComponentById (componentId) {
    const response = await axios.delete(`${API_URL}/rules/brl-components/${componentId}/`)
    return response.data
  },
  async removeComponentByEffectiveDate (componentId, effectiveDateId) {
    const response = await axios.delete(`${API_URL}/rules/brl-components/${componentId}/effective-date/${effectiveDateId}/`)
    return response.data
  },
  async getComponentCompiled (componentId, effectiveDateId) {
    let componentUrl = `${API_URL}/rules/brl-components/${componentId}/`
    if (effectiveDateId) {
      componentUrl += `effective-date/${effectiveDateId}/`
    }
    let response = await axios.get(componentUrl + `compiled/`).catch(rulesAPIErrorHandler)
    return response.data
  },
  saveComponent (componentId, component) {
    const url = `${API_URL}/rules/brl-components/${componentId}/`
    return axios.put(url, component).then(response => {
      return response.data
    })
  },
  saveComponentByEffectiveDate (componentId, effectiveDateId, data) {
    const url = `${API_URL}/rules/brl-components/${componentId}/effective-date/${effectiveDateId}/`
    return axios.put(url, data).then(response => {
      return response.data
    })
  },
  addComponentToPackage (packageId, component, effectiveDateId) {
    let addComponentUrl = `${API_URL}/rules/brl-components/`
    if (effectiveDateId) {
      addComponentUrl += `effective-date/${effectiveDateId}/`
    }
    return axios.post(addComponentUrl, component).then(newComponent => {
      const mapComponentToPackageUrl = `${API_URL}/rules/package/${packageId}/component/${newComponent.data.id}/`
      return axios.post(mapComponentToPackageUrl, component).then(response => {
        return newComponent.data
      })
    })
  },
  async createTag (packageId) {
    let response = await axios.post(`${API_URL}/rules/package-tag/`, {
      'package_id': packageId
    })
    return response.data
  },
  async createDeployableEffectiveDate (packageId, effectiveDateId) {
    let response = await axios.post(`${API_URL}/rules/package-tag/`, {
      'package_id': packageId,
      'effective_date_id': effectiveDateId
    })
    return response.data
  },
  deploy (environment, projectId, version) {
    const url = `${API_URL}/rules/projects/${projectId}/build/async-deploy/${environment}/${version}/`
    return new Promise((resolve, reject) => {
      axios.post(url).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error && error.response && error.response.data)
      })
    })
  },
  commit (environment, projectId) {
    const url = `${API_URL}/rules/projects/${projectId}/build/async-commit/${environment}/`
    return new Promise((resolve, reject) => {
      axios.post(url).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error && error.response && error.response.data)
      })
    })
  },
  async getComponentsByTag (packageId, tag) {
    const response = await axios.get(`${API_URL}/rules/brl-components/?tags=${tag.category}.${tag.value}&package-id=${packageId}`)
    response.data = response.data || []
    return response.data.map((item) => ({
      id: item.id,
      label: item.label || item.name,
      name: item.folder ? `${item.folder}.${item.name}` : item.name,
      componentType: item.component_type
    }))
  },
  getComponentByFullname (fullname) {
    const url = `${API_URL}/rules/brl-components/by-full-name/${fullname}/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async addTagToComponent (componentId, tag) {
    let response = await axios.post(`${API_URL}/rules/brl-components/${componentId}/tags/`, {
      category: tag.category,
      value: tag.value,
      priority: tag.priority || 0
    })
    return response.data
  },
  async removeTagFromComponent (componentId, tag) {
    let response = await axios.delete(`${API_URL}/rules/brl-components/${componentId}/tags/`, {
      data: {
        category: tag.category,
        value: tag.value
      }
    })
    return response.data
  },
  async editTagName (tagId, newName) {
    let response = await axios.put(`${API_URL}/rules/package-tag/${tagId}/set-name/${newName}/`)
    return response.data
  },
  getJob (id) {
    const url = `${API_URL}/rules/job/${id}/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getNamespaces (packageUuid) {
    const url = `${API_URL}/rules/package/${packageUuid}/namespaces/master/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getPurposes () {
    const url = `${API_URL}/rules/tags/${PURPOSE_TAG_CATEGORY}/get_values/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async getLabelValues () {
    const {data} = await axios.get(`${API_URL}/rules/tags/Label/get_values/`)
    return data
  },
  getVisualEditorSuggestions (context) {
    const url = `${API_URL}/rules/ontology/get-suggestions/`
    return new Promise((resolve, reject) => {
      axios.post(url, context).then(response => {
        resolve(response.data.suggestions)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async getFunctionsCatalog (packageId) {
    const query = packageId ? `?package_id=${packageId}` : ''
    const {data} = await axios.get(`${API_URL}/rules/brl-components/structure/${query}`)
    return data
  },
  async getComponentSchema (fullname) {
    let component = await RulesService.getComponentByFullname(fullname)
    let schema = (await axios.get(`${API_URL}/rules/brl-components/${component.body.name}/structure/`)).data[0]
    schema.componentId = component.id
    schema.packageUuid = component.package
    return schema
  },
  getVersions (projectId) {
    const nonArchivedVersionUrl = `${API_URL}/rules/projects/${projectId}/effective-dates/`
    const archivedVersionUrl = `${API_URL}/rules/projects/${projectId}/effective-dates/?state=archived`
    return new Promise((resolve, reject) => {
      Promise.all([
        axios.get(nonArchivedVersionUrl),
        axios.get(archivedVersionUrl)
      ]).then(response => {
        resolve([
          ...response[0].data,
          ...response[1].data
        ].map((item) => {
          return {
            date_added: item.date_added,
            date_modified: item.date_modified,
            description: item.description,
            effective_date: item.effective_date,
            id: item.id,
            name: item.name,
            project: item.project,
            status: item.state.toLowerCase()
          }
        }))
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async getVersionByEffectiveDateId (projectId, effectiveDateId) {
    let versions = await this.getVersions(projectId)
    return versions.find(effectiveDate => effectiveDate.id === effectiveDateId)
  },
  newProjectVersion (projectId, versionData) {
    const url = `${API_URL}/rules/projects/${projectId}/effective-dates/`
    return new Promise((resolve, reject) => {
      axios.post(url, versionData).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  updateVersionStatus (projectId, versionId, action) {
    const url = `${API_URL}/rules/projects/${projectId}/effective-dates/${versionId}/${action}/`
    return new Promise((resolve, reject) => {
      axios.put(url).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  updateProjectVersion (projectId, versionId, versionData) {
    const url = `${API_URL}/rules/projects/${projectId}/effective-dates/${versionId}/`
    return new Promise((resolve, reject) => {
      axios.put(url, versionData).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async formulaToBrl (formula, packageId) {
    let response = await axios.post(`${API_URL}/rules/ontology/formula-to-brl/`, {
      formula,
      package_id: packageId
    })
    if (get(response, 'data.errors.length')) {
      throw get(response, 'data.errors')
    }
    return get(response, 'data.brl')
  },
  async brlToFormula (brl) {
    let response = await axios.post(`${API_URL}/rules/ontology/brl-to-formula/`, { brl })
    if (get(response, 'data.errors.length')) {
      throw get(response, 'data.errors')
    }
    return get(response, 'data.formula')
  },
  async changeComponentState (componentId, state) {
    let response = await axios.post(`${API_URL}/rules/brl-components/${componentId}/set-state/${state}/`)
    return response.data
  },
  async changeComponentStateByEffectiveDate (componentId, effectiveDateId, state) {
    let response = await axios.post(`${API_URL}/rules/brl-components/${componentId}/effective-date/${effectiveDateId}/set-state/${state}/`)
    return response.data
  },
  async exportProject (projectId, includeDependencies) {
    let query = includeDependencies ? '?include-dependencies=true' : ''
    let response = await axios.get(`${API_URL}/rules/projects/${projectId}/async-export/${query}`)
    return response.data
  },
  async uploadFileUsingPresignedURL (file) {
    let uploadURLInfo = (await axios.get(`${API_URL}/rules/projects/${`import-ui-${uuid.v4()}`}/import/presigned_post_url/`)).data
    let form = new FormData()
    Object.keys(uploadURLInfo.fields).forEach((key) => form.append(key, uploadURLInfo.fields[key]))
    form.append('file', file)
    await axios.post(uploadURLInfo.url, form, { excludeAuthHeader: true })
    let filePath = uploadURLInfo.fields.key.replace(/^(media\/)/, '')
    return filePath
  },
  async importProject (file) {
    let filePath = await RulesService.uploadFileUsingPresignedURL(file)
    let response = await axios.post(`${API_URL}/rules/projects/async-import/`, { file: filePath })
    return response.data
  },
  /**
   *
   * @param {*} packageId UUID of the package
   * @param {*} params Json object representing query
   */
  async brlooQuery (packageId, params = {}) {
    let response = await axios.get(`${API_URL}/rules/brloo/packages/${packageId}/query/`, {
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'comma', encode: false })
      }
    })
    return response.data
  },
  async getProjectArchetypes () {
    let response = await axios.get(`${API_URL}/rules/project-archetype/`)
    return response.data.results
  },
  async createProjectFromArchetype (projectId, params = {}) {
    let response = await axios.post(`${API_URL}/rules/project-archetype/${projectId}/create/`, params)
    return response.data
  },
}

export default RulesService

export {
  RulesAPIError,
  RulesService
}
