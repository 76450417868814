import Vue from 'vue'
import _ from 'lodash'

export const mutations = {
  quote (state, quote) {
    state.quote = quote
  },
  quotes (state, data) {
    state.quotes = data.data
    state.contacts = _.filter(data.included, contact => {
      return contact.type === 'contacts' || contact.type === 'quote-contacts'
    })
    state.contactMap = _.keyBy(state.contacts, 'id')
    state.quotesSearchMeta = data.meta
  },
  quoteFlow (state, quoteFlow) {
    state.quoteFlow = quoteFlow
  },
  activePageGroup (state, pageGroup) {
    state.activePageGroup = pageGroup
  },
  activePage (state, page) {
    state.activePage = page
  },
  completeActivePage (state) {
    state.activePage.visited = true
  },
  rootRiskQuote (state, riskQuote) {
    state.rootRiskQuote = riskQuote
  },
  riskTypes (state, riskTypes) {
    state.riskTypes = riskTypes
  },
  riskTypeState (state, { riskTypeName, riskTypeState, riskTypeStateCacheKey }) {
    const cachedRiskTypeStates = _.get(state.riskTypeStates, riskTypeStateCacheKey, {})

    state.riskTypeStates = {
      ...state.riskTypeStates,
      [riskTypeStateCacheKey]: {
        ...cachedRiskTypeStates,
        [riskTypeName]: riskTypeState
      }
    }
  },
  addRiskQuote (state, { parentRiskQuote, riskQuote }) {
    parentRiskQuote.risk_quotes.push(riskQuote)
  },
  updateRiskQuote (state, { parentRiskQuote, index, riskQuote }) {
    Vue.set(parentRiskQuote.risk_quotes, index, riskQuote)
  },
  deleteRiskQuote (state, { parentRiskQuote, index }) {
    parentRiskQuote.risk_quotes.splice(index, 1)
  },
  rulesVersion (state, rulesVersion) {
    state.rulesVersion = rulesVersion
  },
  underwriterContact (state, underwriterContact) {
    state.underwriterContact = underwriterContact
  },
  editQuoteState (state, requestId) {
    state.quoteState.isProcessingRequest = true
    state.quoteState.lastRequestId = requestId
  },
  releaseQuoteState (state, requestId) {
    // Prevent release if this is for a request older than the request which acquired the lock.
    if (requestId >= state.quoteState.lastRequestId) {
      state.quoteState.isProcessingRequest = false
    }
  },
  setQuoteIsBinding (state, isBinding) {
    state.quoteIsBinding = isBinding
    if (!(isBinding && state.quoteLastBindRequest > 0)) {
      state.quoteLastBindRequest = isBinding ? new Date().getTime() : 0
    }
  }
}
