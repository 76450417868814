import { RULE_EDITOR_CONTEXT_STORE_PATH } from '../../constants'
import { addNamespace } from '@/store/helpers'

// To be used only inside vuex modules
export const _mutations = {
  SET_SCOPE: 'setScope',
  SET_QUOTE_FLOW_STEPS: 'setQuoteFlowSteps',
  SET_TRANSACTION_TYPES: 'setTransactionTypes',
  SET_VARIABLE: 'setVariable',
  SET_VARIABLE_POSITION: 'setVariablePosition',
  REMOVE_VARIABLE: 'removeVariable',
  REMOVE_VARIABLES_CREATED_BY: 'removeVariablesCreatedBy',
  CLEAR_CONTEXT: 'clearContext'
}

// To be used publicly outside vuex modules
export const MUTATIONS = addNamespace(
    RULE_EDITOR_CONTEXT_STORE_PATH,
    _mutations
)
