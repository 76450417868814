import {ADMIN_SETUP_PATH} from '../constants'
import {addNamespace} from '@/store/helpers'

export const _adminSetupActions = {
  GET_ADMIN_SETUP_MODULES: 'getAdminSetupModules',
  GET_CLAIM_PRODUCTS: 'getClaimProducts',
  CREATE_CLAIM_PRODUCTS: 'createClaimProducts'
}

export const ADMIN_SETUP_ACTIONS = addNamespace(
  ADMIN_SETUP_PATH,
  _adminSetupActions
)
