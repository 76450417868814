import { addNamespace } from '@/store/helpers'
import { QUOTE_STORE_PATH } from './constants'

export const _actionTypes = {
  LOAD_QUOTE: 'loadQuote',
  GET_QUOTE: 'getQuote',
  UPDATE_QUOTE: 'updateQuote',
  GET_QUOTES: 'getQuotes',
  GET_QUOTE_FLOW: 'getQuoteFlow',
  GET_ROOT_RISK_QUOTE: 'getRootRiskQuote',
  GET_RISK_TYPES_BY_PRODUCT: 'getRiskTypesByProduct',
  FETCH_RISK_TYPE_STATE: 'fetchRiskTypeState',
  INITIALIZE_NAVIGATION: 'initializeNavigation',
  UPDATE_NAVIGATION_STATE: 'updateNavigationState',
  NAVIGATE_TO_STEP: 'navigateToStep',
  NAVIGATE_TO_NEXT_STEP: 'navigateToNextStep',
  NAVIGATE_TO_PREVIOUS_STEP: 'navigateToPreviousStep',
  NAVIGATE_TO_STEP_BY_COMPONENT_TYPE: 'navigateToStepByComponentType',
  SUBMIT_PAGE: 'submitPage',
  ADD_OR_UPDATE_RISK_QUOTE: 'addOrUpdateRiskQuote',
  DELETE_RISK_QUOTE: 'deleteRiskQuote',
  EXECUTE_QUOTE_STATUS_TRIGGER: 'executeQuoteStatusTrigger',
  GET_UNDERWRITER_CONTACT: 'getUnderwriterContact',
  BIND_QUOTE: 'bindQuote'
}

// To be used publicly outside vuex modules
export const actionTypes = addNamespace(QUOTE_STORE_PATH, _actionTypes)
