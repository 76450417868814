import ApiService from './api'

export default class ContactsApi extends ApiService {
  constructor () {
    super('claims/integrations/contacts')
  }

  getContacts (filters) {
    return this.get('', {params: {...filters}})
  }

  getContactById (contactId) {
    return this.get(`${contactId}/`)
  }

  createContact (data) {
    return this.post('', data)
  }

  updateContact (contactId, data) {
    return this.put(`${contactId}/`, data)
  }

  getNotes (contactId, filters) {
    return this.get(`${contactId}/notes/`, {params: {...filters}})
  }

  addressByZip (filters) {
    return this.get('address-by-zip/', {params: {...filters}})
  }

  validateSSN (ssn) {
    return this.get('validate-ssn/', {params: {'ssn': ssn}})
  }

  allowDuplicateSSN () {
    return this.get('allow-duplicate-ssn/')
  }

  getGenders () {
    return this.get('genders/')
  }

  getAddressTypes () {
    return this.get('address-types/')
  }

  getRelations (contactId) {
    return this.get(`${contactId}/relations/`)
  }
}
