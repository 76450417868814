import { addNamespace } from '@/store/helpers'
import { PREVIEW_STORE_PATH } from './constants'

// To be used only inside vuex modules
export const _actionTypes = {
  NEW_SCENARIO: 'newScenario',
  LOAD_SCENARIO: 'loadScenario',
  REMOVE_SCENARIO: 'removeScenario',
  UPDATE_SCENARIO: 'updateScenario',
  COPY_SCENARIO: 'copyScenario',
  LOAD_SCENARIOS: 'loadScenarios',
  CREATE_RISK: 'createRisk',
  RESET_SCENARIO: 'resetScenario',
  REFRESH_SCENARIO: 'refreshScenario',
  LOAD_RISK_STATE: 'loadRiskState',
  LOAD_RISK_TYPE_STATE: 'loadRiskTypeState',
  RATE_RISK_STATE: 'rateRiskState',
  SAVE_RISK_STATE: 'saveRiskState',
  RATE_SCENARIO: 'rateScenario',
  REMOVE_RISK: 'removeRisk',
  ADD_ITEM_TO_RISK_STATE: 'addItemToRiskState',
  REMOVE_ITEM_FROM_RISK_STATE: 'removeItemFromRiskState',
  UPDATE_RISK_IN_SCENARIO: 'updateRiskInScenario',
  GET_RISK_TYPES_BY_PRODUCT: 'getRiskTypesByProduct',
  CLEAR_SCENARIO_RISK: 'clearScenarioRisk'
}

// To be used publicly outside vuex modules
export const actionTypes = addNamespace(PREVIEW_STORE_PATH, _actionTypes)
