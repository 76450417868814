import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL

let contactsService = {
  /**
   * Get a Contact by id.
   * @param {String} contactId
   * @returns {Promise<Object>} Promise object that receives the Contact.
   */
  getContact: (contactId) => {
    var url = `${API_URL}/policies/contacts/${contactId}/`
    return new Promise((resolve, reject) =>
      axios.get(url).then(response => {
        resolve(response.data)
      }, error => {
        reject(error.response.data)
      })
    )
  },
  /**
   * Get a Contact by Revision id.
   * @param {String} revisionId
   * @returns {Promise<Object>} Promise object that receives the Revision's Contacts.
   */
  getRevisionContacts: (revisionId) => axios.get(
    `${API_URL}/policies/revisions-contacts/?filter[revision_id]=${revisionId}`
  )
}

export default contactsService
