<template>
  <a
    :href="`/britecore/contacts/information?id=${data.id}`"
    class="search-results__search-item">
    <div class="u-mb1">
      {{ name }}
    </div>

    <div class="search-item__details">
      <div class="l-flex-grid l-flex-grid--v-center">
        {{ address }}
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'QuickSearchContactItem',
  props: {
    data: { type: Object, required: true }
  },
  computed: {
    address () {
      return this.data.address || this.data.details.address || 'N/A'
    },
    name () {
      return this.data && this.data.name || this.data.details && this.data.details.name
    }
  }
}
</script>
