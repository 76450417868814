const CHANGE_TYPE_ADDED = 'added'
const CHANGE_TYPE_REMOVED = 'removed'

const ENTITY_GROUP_FIELDS = 'fields'
const ENTITY_GROUP_ITEMS = 'items'

const ENTITY_GROUPS = {
  [ENTITY_GROUP_FIELDS]: 'Data Fields',
  [ENTITY_GROUP_ITEMS]: 'Items'
}

const ENTITY_TYPE_FIELD = 'field'
const ENTITY_TYPE_RISK_TYPE = 'riskType'

const OLD_VALUE_KEY = 'old_value'
const NEW_VALUE_KEY = 'new_value'
const FIELD_OPTIONS_KEY = 'options'

const TOTAL_REMOVED_CHANGES = 'total'

const OPERATION_DO_NOT_MAP = 'do_not_map'
const OPERATION_MAP = 'map'

export {
  CHANGE_TYPE_ADDED,
  CHANGE_TYPE_REMOVED,
  ENTITY_GROUP_FIELDS,
  ENTITY_GROUP_ITEMS,
  ENTITY_GROUPS,
  ENTITY_TYPE_FIELD,
  ENTITY_TYPE_RISK_TYPE,
  FIELD_OPTIONS_KEY,
  NEW_VALUE_KEY,
  OLD_VALUE_KEY,
  OPERATION_MAP,
  OPERATION_DO_NOT_MAP,
  TOTAL_REMOVED_CHANGES
}
