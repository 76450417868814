const MFA_TYPES = {
  SMS: 'SMS_MFA',
  SOFTWARE_TOKEN: 'SOFTWARE_TOKEN_MFA'
}

export const state = {
  isAccountLinking: false,
  accountLinkingResult: {},
  mfaTypes: MFA_TYPES,
  mfaMethods: [
    {
      type: MFA_TYPES.SMS,
      label: 'Text Message (SMS)',
      description: 'Verification codes will be sent via text message to a number you specify.',
      enabled: false
    },
    {
      type: MFA_TYPES.SOFTWARE_TOKEN,
      label: 'Authenticator App',
      description: 'Verification codes will be generated via any authenticator application or device.',
      enabled: false
    }
  ]
}
