<template>
  <el-dialog
    :title="title"
    :visible="visible"
    @close="onClose">
    <span>{{message}}</span>
    <el-table
      height="250"
      class="u-mt2"
      :data="failures">
      <el-table-column
        v-for="field in fields"
        :prop="field.prop"
        :label="field.label"
        :key="field.label" >
      </el-table-column>
      <el-table-column
        prop="errorMessage"
        label="Error Message">
      </el-table-column>
    </el-table>
    <el-button
      class="u-mt2"
      type="primary"
      @click="onClose">
      Ok
    </el-button>
  </el-dialog>
</template>

<script>
  export default {
    name: 'ActionsFailureDialog',
    props: {
      title: { type: String, default: 'Batch Action Failed' },
      visible: { type: Boolean, default: false },
      selectedRowsCount: { type: Number, required: true },
      failures: { type: Array, required: true },
      fields: { type: Array, required: false },
      getMessage: {
        type: Function,
        default: function (failuresCount, selectedRowsCount) {
          return `We are sorry, batch action failed for ${failuresCount} items out of ${selectedRowsCount}`
        }},
      onClose: { type: Function, required: false }
    },
    computed: {
      message: function () {
        return this.getMessage(this.failures.length, this.selectedRowsCount)
      }
    }
  }
</script>
