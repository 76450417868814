import {_actions} from './action-types'
import { ACTIONS } from '../action-types'
import {_mutations} from './mutation-types'
import RulesService from '@/api/rules'
import { getNamespacesAvailableFilterCriteria, getLabelFilterCriteria, getPurposeStateAndVersionFilterCriteria } from '@/pages/rules/utils/componentsFilterUtils'

export const actions = {
  async [_actions.GET_COMPONENTS] ({commit}, { projectId, effectiveDateId }) {
    commit(_mutations.START_LOADING_COMPONENTS)
    try {
      let components = await RulesService.getComponentsByEffectiveDate(projectId, effectiveDateId)
      commit(_mutations.SET_COMPONENTS, components)
    } catch (error) {
      commit(_mutations.SET_ERROR_LOADING_COMPONENTS, error)
    }
    commit(_mutations.STOP_LOADING_COMPONENTS)
  },
  async [_actions.BUILD_AVAILABLE_FILTER_CRITERIA] ({commit}, { packageId, projectId, effectiveDateId }) {
    commit(_mutations.RESET_AVAILABLE_FILTER_CRITERIA)
    try {
      const [namespaces, purposes, currentVersion, labels] = await Promise.all([
        RulesService.getNamespaces(packageId),
        RulesService.getPurposes(),
        RulesService.getVersionByEffectiveDateId(projectId, effectiveDateId),
        RulesService.getLabelValues()
      ])
      commit(_mutations.SET_AVAILABLE_FILTER_CRITERIA, [
        ...getNamespacesAvailableFilterCriteria(namespaces),
        ...getLabelFilterCriteria(labels),
        ...getPurposeStateAndVersionFilterCriteria(purposes, currentVersion),
      ])
    } catch (error) {
      commit(_mutations.SET_ERROR_BUILDING_AVAILABLE_FILTER_CRITERIA, error)
    }
  },
  async [_actions.SET_ACTIVE_PROJECT] ({commit, getters, dispatch, rootGetters}, { projectId, effectiveDateId, force }) {
    if (force || !getters.activeProjectStatus.loaded || getters.activeProject.id !== projectId || getters.activeProject.effectiveDateId !== effectiveDateId) {
      commit(_mutations.START_LOADING_PROJECT)
      try {
        const [project, versions] = await Promise.all([
          RulesService.getProject(projectId),
          RulesService.getVersions(projectId)
        ])
        await dispatch(ACTIONS.SET_ACTIVE_PACKAGE, { packageUuid: project.mainPackage }, { root: true })
        project.mainPackageId = rootGetters['rules/activePackageId']
        project.versions = versions
        project.effectiveDateId = effectiveDateId
        // TODO load components
        commit(_mutations.UPDATE_ACTIVE_PROJECT, project)
        dispatch(_actions.GET_UNCOMMITTED_COMPONENTS)
      } catch (error) {
        commit(_mutations.SET_ERROR_LOADING_PROJECT, error)
      }
    }
  },
  async [_actions.REFRESH_ACTIVE_PROJECT] ({state, dispatch}) {
    return await dispatch(_actions.SET_ACTIVE_PROJECT, {
      projectId: state.activeProject.value.id,
      force: true
    })
  },
  async [_actions.GET_UNCOMMITTED_COMPONENTS] ({commit, getters}) {
    commit(_mutations.START_LOADING_UNCOMMITTED_COMPONENTS)
    try {
      const uncommittedComponents = await RulesService.getUncommittedComponents(getters.activeProject.projectId)
      commit(_mutations.SET_UNCOMMITTED_COMPONENTS, uncommittedComponents)
    } catch (error) {
      commit(_mutations.SET_UNCOMMITTED_COMPONENTS_ERROR, error)
    }
  },
  async [_actions.VALIDATE_PROJECT] ({commit, getters}) {
    commit(_mutations.START_VALIDATE_PROJECT)
    try {
      const result = await RulesService.validateProject(getters.activeProject.projectId)
      commit(_mutations.SET_VALIDATION_RESULT, result)
    } catch (error) {
      commit(_mutations.SET_VALIDATION_SERVICE_ERROR, error)
    }
  }
}
