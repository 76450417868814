import { includes } from 'lodash'
import { GEN2, GEN3 } from './constants'

const currentModuleLinks = (state, getters, rootState, rootGetters) => {
  const { permissions } = rootState['bcClassic']
  const moduleLinks = rootGetters['authentication/isAgentOrAgency'] ? state.agentModuleLinks : state.adminModuleLinks

  const links = moduleLinks.map(link => {
    // update links `destination` attribute
    if (link.availableDestinations.length === 1) {
      link.destination = link.availableDestinations[0]
    } else {
      link.destination = includes(state.enabledModules, link.moduleName) ? GEN3 : GEN2
    }

    // add Gen2 specific data to help with listing Gen2 modules in the UI
    link.gen2Meta = permissions && permissions[link.moduleName] ? {
      location: {
        path: permissions[link.moduleName],
        query: { pageReload: true }
      },
      label: link.label
    } : null

    // update links `visible` attribute
    if (link.isCoreModule) {
      // Currently used for Manage Users, My Account, and All Products
      link.enabled = true
    } else if (link.destination === GEN2) {
      if (link.gen2Meta) {
        Object.assign(link, link.gen2Meta)
        link.enabled = true
      }
    } else { // destination is Gen3
      link.enabled = includes(state.enabledModules, link.moduleName)
    }

    return link
  })
  return links.filter(link => link.enabled)
}

export default {
  currentModuleLinks
}
