import { addNamespace } from '@/store/helpers'
import { LINES_STORE_PATH } from './constants'

// To be used only inside vuex modules
export const _actionTypes = {
  GOTO_RISK_TYPE: 'goToRiskType',
  LOAD_PRODUCT_VERSIONS: 'loadProductVersions',
  LOAD_LINE: 'loadLine',
  LOAD_PRODUCT: 'loadProduct',
  GOTO_LINES_OVERVIEW: 'gotoLinesOverview',
  GET_LINE_LATEST_PRODUCT: 'getLineLatestProduct',
  SET_ACTIVE_VERSION_ID_QUERY_PARAM: 'setActiveVersionIdQueryParam',
  LOAD_FIELD_FORMATS: 'loadFieldFormats',
  LOAD_RISK_TYPE: 'loadRiskType',
  ADD_TO_PRODUCT_IMPORTS: 'addToProductImports',
  UPDATE_PRODUCT_IMPORT: 'updateProductImport',
  REMOVE_FROM_PRODUCT_IMPORTS: 'removeFromProductImports',
  REMOVE_ENTITY_FROM_RISK_TYPE: 'removeEntityFromRiskType',
  LOAD_GEN2_BUSINESS_LOCATIONS: 'loadGen2BusinessLocations',
  LOAD_PRODUCTS: 'loadProducts'
}

// To be used publicly outside vuex modules
export const actionTypes = addNamespace(LINES_STORE_PATH, _actionTypes)
