import {makeEvaluationKey} from '@/store/access/utils'
import Cookies from 'js-cookie'

const getters = {
  accessPolicyById: (state) => id => {
    let accessPolicy = state.accessPolicyList.find(accessPolicy => accessPolicy.id === id)
    return accessPolicy
  },
  isNewAccessPolicy: state => {
    if (state.accessPolicy.id) {
      return false
    } else {
      return true
    }
  },
  newAccessPolicyHasBeenModified: ({accessPolicy}) => {
    return accessPolicy.name || accessPolicy.description || (accessPolicy.statements && accessPolicy.statements.length)
  },
  isNewRole: state => {
    if (state.role.id) {
      return false
    } else {
      return true
    }
  },
  isSystemPolicy: state => {
    return state.accessPolicy.type === 'system'
  },
  effectiveRole: state => {
    return state.effectiveRole || localStorage.getItem('EffectiveRole') || Cookies.get('EffectiveRole')
  },
  accessAttemptIsAllowed: state => (resource, action, record, context) => {
    const key = makeEvaluationKey(resource, action, record, context)
    if (!state.evaluations[key]) {
      // TODO: Revisit this approach once briteccess is enabled for all modules
      // silently indicates that the evaluation is not available and
      // the user should have dispatched evaluateAccessAttempts first
      return null
    }
    return state.evaluations[key].includes('allowed')
  }
}
export default getters
