export default {
  roles: [],
  permissions: null,
  version: {
    tag: null,
    timestamp: null
  },
  carrier: {
    name: process.env.VUE_APP_CLIENT_NAME,
    website: null
  },
  metaData: {
    liveStatus: null,
    claimPrefix: null,
    policyPrefix: null,
    analyticsEnabled: false,
    trackAgentsInPendo: false,
    whitelistedForPendo: false
  },
  contact: {
    contactId: null,
    name: null,
    roles: [],
    addresses: [],
    phones: [],
    emails: [],
    website: null,
    dob: null,
    agencies: []
  },
  recentPolicies: [],
  recentContacts: [],
  recentClaims: [],
  searchResults: [],
  effectiveDates: [],
  lines: [],
  businessLocations: [],
  massDeliverablesTable: {}
}
