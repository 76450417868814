import { state } from './state'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { componentEditorStore } from './componentEditorStore'
import { activeProjectStore } from './activeProjectStore'

const rulesModule = {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
  modules: {
    componentEditor: componentEditorStore,
    activeProjectModule: activeProjectStore
  }
}

export default rulesModule
