const LoginForm = () => import('@/pages/login/LoginForm')
const ResetPasswordForm = () => import('@/pages/login/ResetPasswordForm')
const ForgotPasswordForm = () => import('@/pages/login/ForgotPassword')
const ResetPasswordWithCodeForm = () => import('@/pages/login/ResetPasswordWithCode')
const MFAChallenge = () => import('@/pages/login/MFAChallenge')

import store from '@/store'

export default [
  {
    path: '',
    name: 'login',
    alias: 'ko_login',
    component: LoginForm
  },
  {
    path: 'reset-password',
    name: 'reset-password',
    meta: { header: 'Reset your Password' },
    component: ResetPasswordForm,
    props: (route) => ({
      cognitoUser: route.params.cognitoUser,
      userAttributes: route.params.userAttributes,
      redirectPath: route.query.redirectPath
    })
  },
  {
    path: 'forgot-password',
    name: 'forgot-password',
    meta: { header: 'Reset your Password' },
    component: ForgotPasswordForm,
    props: true
  },
  {
    path: 'force-reset-password',
    name: 'force-reset-password',
    meta: {
      header: 'Reset your Password',
      message: 'For security reasons, you will need to reset your password.'
    },
    component: ForgotPasswordForm,
    props: true
  },
  {
    path: 'reset-password-with-code',
    name: 'reset-password-with-code',
    meta: { header: 'Reset your password' },
    component: ResetPasswordWithCodeForm,
    props: true
  },
  {
    path: 'mfa',
    name: 'mfa',
    component: MFAChallenge,
    props: (route) => ({
      username: route.query.username,
      userSession: route.query.userSession,
      redirectPath: route.query.redirectPath,
      challenge: {
        medium: route.query.medium,
        destination: route.query.destination
      }
    })
  },

  {
    path: 'idpresponse',
    name: 'idpresponse',
    component: LoginForm,
    beforeEnter: (to, from, next) => {
      store.dispatch('authentication/parseAndUseOAuthAuthorizationCode')
      next()
    }
  }
]
