import {ASSIGN_CLAIMS_PATH} from '../constants'
import {addNamespace} from '@/store/helpers'

// To be used only inside vuex modules
export const _assignClaimsActions = {
  GET_PENDING_CLAIMS: 'getPendingClaims',
  SUBMIT_ALL_PENDING_CLAIMS: 'submitAllAssignPendingClaims',
  GET_PENDING_CLAIMS_NEXT_PAGE: 'getPendingClaimsNextPage',

  // Filters
  UPDATE_FILTER: 'updateFilter',
  GET_CLAIM_EXPOSURES: 'getClaimExposures',
  GET_CLAIM_STATUSES: 'getClaimStatuses',
  GET_COVERAGE_TYPES: 'getCoverageTypes',
  GET_LOSS_TYPES: 'getLossTypes',
  GET_LOBS: 'getLobs'
}

// To be used publicly outside vuex modules
export const ASSIGN_CLAIMS_ACTIONS = addNamespace(
  ASSIGN_CLAIMS_PATH,
  _assignClaimsActions
)
