import isFunction from 'lodash/isFunction'
import { isPromise } from './utils'

const POLLING_INTERVAL_MILLISECONDS = 3000
const POLLING_TIMEOUT_MILLISECONDS = 60000 * 15

export default class Poller {
  constructor (options) {
    this.options = options || {}
    this.options.interval = this.options.interval || POLLING_INTERVAL_MILLISECONDS
    this.options.timeout = this.options.timeout || POLLING_TIMEOUT_MILLISECONDS
    this.pollingIntervalId = null
    this.pollingTimeoutId = null
    this.checkFn = null
    this.isFinishedFn = null
    this.onEachUpdateFn = () => {}
    this.onTimeoutFn = () => {}
    this.onFinishedFn = () => {}
    this.onErrorFn = () => {}
    this.stopped = true
    this.lastCheckResolved = true
    this.start()
  }

  start () {
    if (this.stopped) {
      this.stopped = false
      this.pollingFn()
      this.pollingTimeoutId = setTimeout(() => {
        this.stop()
        this.onTimeoutFn()
      }, this.options.timeout)
    }
    return this
  }

  pollingFn () {
    this.check()
    this.pollingIntervalId = setTimeout(this.pollingFn.bind(this), this.options.interval)
  }

  stop () {
    clearTimeout(this.pollingIntervalId)
    clearTimeout(this.pollingTimeoutId)
    this.stopped = true
  }

  async check () {
    if (isFunction(this.checkFn) && this.lastCheckResolved) {
      this.lastCheckResolved = false
      try {
        let checkFnPromise = this.checkFn()
        if (isPromise(checkFnPromise)) {
          let data = await checkFnPromise
          if (!this.stopped) {
            this.onEachUpdateFn(data)
            if (isFunction(this.isFinishedFn) && this.isFinishedFn(data)) {
              this.stop()
              this.onFinishedFn(data)
            }
          }
        } else {
          throw new Error(`The function 'fn' set in onCheck(fn) should return a Promise`)
        }
      } catch (error) {
        this.stop()
        this.onErrorFn(error)
      } finally {
        this.lastCheckResolved = true
      }
    }
  }

  /**
   * @param {*} fn Function that will be called on each check. Should return a Promise
   */
  onCheck (checkFn) {
    this.checkFn = checkFn
    return this
  }

  /**
   * @param {*} fn Implement this function if you want to stop the poller under some condition.
   * fn will receive the data as parameter and should return boolean. Returning TRUE stops the poller
   */
  isFinished (fn) {
    this.isFinishedFn = fn
    return this
  }

  /**
   * @param {*} fn Function that will be called after we get the result of each check.
   * The function will receive a param with the data.
   */
  onEachUpdate (fn) {
    this.onEachUpdateFn = fn
    return this
  }

  /**
   * @param {*} fn Function that will be called when there is a timeout.
   */
  onTimeout (fn) {
    this.onTimeoutFn = fn
    return this
  }

  /**
   * If you want to finish by some condition analizing the data you should implement the isFinished function
   * and set the function that does the logic operation.
   * @param {*} fn Function that will be called when the function isFinished in the poller returns true.
   */
  onFinished (fn) {
    this.onFinishedFn = fn
    return this
  }

  /**
   * @param {*} fn Function that will be called when there is an error
   */
  onError (fn) {
    this.onErrorFn = fn
    return this
  }
}
