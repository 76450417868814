import {addNamespace} from '@/store/helpers'

export const actionTypes = {
  FETCH_ACCESS_POLICIES: 'fetchAccessPolicies',
  FETCH_ACCESS_POLICY_BY_ID: 'fetchAccessPolicyById',
  CREATE_ACCESS_POLICY: 'createAccessPolicy',
  UPDATE_ACCESS_POLICY: 'updateAccessPolicy',
  DELETE_ACCESS_POLICY: 'deleteAccessPolicy',
  FETCH_ROLES: 'fetchRoles',
  FETCH_USERS: 'fetchUsers',
  FETCH_GROUPS: 'fetchGroups',
  FETCH_GROUP_BY_ID: 'fetchGroupById',
  FETCH_USER_BY_ID: 'fetchUserById',
  SET_PAGINATION_TOTAL: 'setAccessPoliciesTotal',
  SAVE_ACCESS_POLICY_ASSIGNMENT: 'assignAccessPolicy',
  FETCH_ACCESS_POLICY_ASSIGNMENTS: 'fetchAccessPolicyAssignments',
  FETCH_ROLE_ACCESS_POLICY_ASSIGNMENTS: 'fetchRoleAccessPolicyAssignments',
  VALIDATE_ACCESS_POLICY: 'validateAccessPolicy',
  DELETE_ACCESS_POLICY_ASSIGNMENTS: 'deleteAccessPolicyAssignments',
  FETCH_ROLE_BY_ID: 'fetchRoleById',
  CREATE_ROLE: 'createRole',
  UPDATE_ROLE: 'updateRole',
  DELETE_ROLE: 'deleteRole',
  SAVE_ROLE_ASSIGNMENTS: 'saveRoleAssignments',
  FETCH_ROLE_ASSIGNMENTS: 'fetchRoleAssignments',
  DELETE_ROLES_ASSIGNMENTS: 'deleteRolesAssignments',
  FETCH_USER_ROLES: 'fetchUserRoles',
  SELECT_USER_ROLE: 'selectUserRole',
  FETCH_ASSIGNED_USERS_GROUPS_RECORDS: 'fetchUsersGroupsNames',
  EVALUATE_ACCESS_ATTEMPTS: 'evaluateAccessAttempts',
  IMPORT_DATA: 'importData',
  EXPORT_DATA: 'exportData'
}

export default addNamespace('access/', actionTypes)
