<template>
  <el-loading-wrapper tag="div"
      style="min-height: 75px;"
      :loading="loading"
      loading-text="Loading...">
    <div class="u-text--light u-mb3"
    v-if="noRolesAvailable">You do not have any role assignments. Please contact the system administrator.</div>
    <div v-else>
      <div v-if="currentUserRoleIsUnavailable">
        <div class="u-text--light u-mb3">Your previous role is unavailable. Please select another role to proceed:</div>
        <el-radio class="u-mb2 u-pl1"
          :label="selectedRole"
          v-if="selectedRole"
          disabled>
            {{selectedRole}}
        </el-radio>
      </div>
      <div class="u-text--light u-mb3"
      v-else>Please select a role to use for your BriteCore session:</div>
      <el-radio-group v-model="selectedRoleOption">
        <div v-for="role in userRoles"
        :key="role.id">
          <el-radio class="u-mb2 u-pl1"
          :label="role.name">
            {{role.name}}
          </el-radio>
        </div>
      </el-radio-group>
    </div>
  </el-loading-wrapper>
</template>

<script>
export default {
  name: 'AccessRoleSwitcher',
  props: {
    userRoles: {
      type: Array,
      default: () => []
    },
    selectedRole: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean
    }
  },
  computed: {
    selectedRoleOption: {
      get () {
        return this.selectedRole
      },
      set (value) {
        this.$emit('role-selected', value)
      }
    },
    currentUserRoleIsUnavailable () {
      return !this.loading && this.userRoles.length > 0 && !this.userRoles.find(role => role.name === this.selectedRole)
    },
    noRolesAvailable () {
      return !this.loading && this.userRoles.length === 0
    }
  }
}
</script>
