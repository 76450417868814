import ApiService from './api'

export default class AttachmentsApi extends ApiService {
  constructor () {
    super('claims')
  }

  getAttachments (folderId, config) {
    return this.get(`integrations/attachments/${folderId}/`, config)
  }

  createFolder (data) {
    return this.post(`integrations/attachments/`, data)
  }

  deleteAttachment (currentFolderId, data) {
    return this.delete(`integrations/attachments/${currentFolderId}`, data)
  }

  updateAttachment (attachmentId, data) {
    return this.patch(`integrations/attachments/${attachmentId}/`, data)
  }

  getFolderTree (claimId) {
    return this.get(`integrations/attachments/${claimId}/folder-tree/`)
  }

  moveAttachments (folderId, data) {
    return this.patch(`integrations/attachments/${folderId}/move-attachments/`, data)
  }
}
