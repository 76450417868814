import EventBus from './eventbus'
import store from '@/store'
import BriteRulesAPI from './api'
import actionHandler from './action-handler'
import get from 'lodash/get'

const ANY_VIEW = 'ANY_VIEW'
const ANY_ELEMENT = 'ANY_ELEMENT'
const ANY_ACTION = 'ANY_ACTION'

const getUser = () => {
  return {
    username: get(store, 'state.authentication.currentUser.username'),
    roles: [store.getters['access/effectiveRole']]
  }
}

const getEventName = ({viewId, elementId, action}) => {
  viewId = viewId || ANY_VIEW
  elementId = elementId || ANY_ELEMENT
  action = action || ANY_ACTION
  return `${viewId}.${elementId}.${action}`
}

let emitActionEvents = (action) => {
  EventBus.$emit(getEventName({viewId: action.viewId, elementId: action.elementId, action: action.name}), action)
  EventBus.$emit(getEventName({viewId: action.viewId, elementId: action.elementId}), action)
  EventBus.$emit(getEventName({viewId: action.viewId, action: action.name}), action)
  EventBus.$emit(getEventName({viewId: action.viewId}), action)
  EventBus.$emit(getEventName({elementId: action.elementId, action: action.name}), action)
  EventBus.$emit(getEventName({elementId: action.elementId}), action)
  EventBus.$emit(getEventName({action: action.name}), action)
  EventBus.$emit(getEventName({}), action)
}

const createElementIfNotExists = (viewId, propertyPath, defaultValue) => {
  const splittedPropertyPath = propertyPath && propertyPath.split('.') || []
  const elementId = splittedPropertyPath[0]
  const property = splittedPropertyPath[1]
  if (property) {
    if (!store.getters[`${BriteRulesConnector.moduleName}/getElement`](viewId, elementId)) {
      store.commit(`${BriteRulesConnector.moduleName}/addElementProperty`, {viewId, elementId, property, defaultValue})
    }
  } else {
    if (!store.getters[`${BriteRulesConnector.moduleName}/getElement`](viewId, elementId)) {
      store.commit(`${BriteRulesConnector.moduleName}/addElement`, {viewId, elementId, defaultValues: defaultValue})
    }
  }
}

const BriteRulesConnector = {
  moduleName: 'briterules-connector',
  executeRules: async ({viewId, action, viewModel, extraContext}) => {
    try {
      const user = getUser()
      store.commit(`${BriteRulesConnector.moduleName}/startExecution`, {viewId})
      let actions = await BriteRulesAPI.execute({action, viewId, user, viewModel, extraContext})
      store.commit(`${BriteRulesConnector.moduleName}/finishViewExecution`, {viewId})
      actions.forEach((action) => {
        emitActionEvents({...action, viewId})
      })
    } catch (error) {
      store.commit(`${BriteRulesConnector.moduleName}/viewExecutionFailed`, {viewId})
      store.commit(`${BriteRulesConnector.moduleName}/executionFailed`)
      EventBus.$emit('error', error)
    }
  },
  subscribe ({viewId, elementId, action}, fn) {
    EventBus.$on(getEventName({viewId, elementId, action}), fn)
  },
  unsubscribe ({viewId, elementId, action}, fn) {
    EventBus.$off(getEventName({viewId, elementId, action}), fn)
  },
  onError (fn) {
    EventBus.$on('error', fn)
  },
  unsubscribeFromError (fn) {
    EventBus.$off('error', fn)
  },
  beforeRulesExecution (fn) {
    EventBus.$on('before-rules-execution', fn)
  },
  afterRulesExecution (fn) {
    EventBus.$on('after-rules-execution', fn)
  },
  unsubscribeFromBeforeRulesExecution (fn) {
    EventBus.$off('before-rules-execution', fn)
  },
  unsubscribeFromAfterRulesExecution (fn) {
    EventBus.$off('after-rules-execution', fn)
  },
  addElement ({viewId, elementId, defaultValues}) {
    store.commit(`${BriteRulesConnector.moduleName}/addElement`, {viewId, elementId, defaultValues})
  },
  removeElement ({viewId, elementId}) {
    store.commit(`${BriteRulesConnector.moduleName}/removeElement`, {viewId, elementId})
  },
  cleanView (viewId) {
    store.commit(`${BriteRulesConnector.moduleName}/removeView`, {viewId})
  },
  getElementProperty (viewId, propertyPath, defaultValue) {
    createElementIfNotExists(viewId, propertyPath, defaultValue)
    return store.getters[`${BriteRulesConnector.moduleName}/getElementProperty`](viewId, propertyPath)
  }
}

BriteRulesConnector.subscribe({}, actions => {
  actionHandler(actions)
  store.commit(`${BriteRulesConnector.moduleName}/finishExecution`)
})

BriteRulesConnector.onError(error => {
  store.commit(`${BriteRulesConnector.moduleName}/executionFailed`)
  console.error('BriteRulesConnector: ', error)
})

export default BriteRulesConnector

export {
  BriteRulesConnector
}
