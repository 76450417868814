import axios from 'axios'
import get from 'lodash/get'
import EventBus from './eventbus'

const API_URL = process.env.VUE_APP_API_URL
const PROJECT_NAME = 'ui-controls'
const DEFAULT_REQUEST_ACTION_TYPE = 'evaluate-ui'

const BriteRulesAPI = {
  async execute ({action, viewId, user, viewModel, extraContext}) {
    let actions = []
    let payload = {
      data: {
        actions: [
          {
            name: action || DEFAULT_REQUEST_ACTION_TYPE
          }
        ],
        context: {
          viewId,
          user,
          viewModel,
          ...extraContext
        }
      }
    }
    EventBus.$emit(`before-rules-execution`, payload)
    let response = await axios.post(`${API_URL}/rules/execution/${PROJECT_NAME}/`, payload)
    EventBus.$emit(`after-rules-execution`, response.data)
    actions = get(response, 'data.actions')
    return actions
  }
}

export default BriteRulesAPI
