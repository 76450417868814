import _ from 'lodash'

export const getters = {
  ratingInfoArgs: (state) => {
    return {
      productId: _.get(state.product, 'id'),
      versionId: _.get(state.activeVersion, 'id')
    }
  },
  isActiveVersionSet: (state) => {
    return !_.isEmpty(state.activeVersion)
  },
  isActiveVersionInDraftState (state) {
    return state.activeVersion.status === 'draft'
  },
  canEditProduct: (state, getters) => {
    return getters.isActiveVersionInDraftState
  },
  isRootProduct: (state) => {
    return !state.product.parentProductId
  },
  isLineLoaded: (state) => {
    return !_.isEmpty(state.line)
  },
  isProductLoaded: (state) => {
    return !_.isEmpty(state.product)
  },
  isRiskTypeLoaded: (state) => {
    return !_.isEmpty(state.riskType)
  },
  activeRiskTypeId: (state, getters) => {
    return getters.isRiskTypeLoaded ? state.riskType.id : null
  },
  activeRiskTypeName: (state, getters) => {
    return getters.isRiskTypeLoaded ? state.riskType.name : null
  },
  activeRiskTypeLabel: (state, getters) => {
    return getters.isRiskTypeLoaded ? state.riskType.label : null
  }
}
