import { addNamespace } from '@/store/helpers'
import { PREVIEW_STORE_PATH } from './constants'

// To be used only inside vuex modules
export const _mutationTypes = {
  SET_RISK_TYPE_DETAILS_ID: 'riskTypeDetailsId',
  SET_RISK_TYPE_DETAILS_ACTIVE_TAB: 'riskTypeDetailsActiveTab',
  SET_SCENARIO: 'scenario',
  SET_SCENARIOS: 'scenarios',
  ADD_SCENARIO_TO_SCENARIOS: 'addScenarioScenarios',
  UPDATE_SCENARIO_IN_SCENARIOS: 'updateScenarioScenarios',
  REMOVE_SCENARIO_FROM_SCENARIOS: 'removeScenarioScenarios',
  SET_SCENARIO_UNIFIED_RISK_STATE: 'scenario.unified_risk_state',
  SET_RISK_STATE: 'riskState',
  SET_RISK_TYPE_STATE: 'riskTypeState',
  SET_RISK_META: 'riskMeta'
}

// To be used publicly outside vuex modules
export const mutationTypes = addNamespace(PREVIEW_STORE_PATH, _mutationTypes)
