import {addNamespace} from '@/store/helpers'
import {MOCK_POLICIES_MAINTENANCE_PATH} from '../../constants'

export const _mockPoliciesMaintenanceMutations = {
  SET_POLICIES: 'setPolicies',
  SET_POLICY: 'policy',
  CLEAR_POLICIES: 'clearPolicies',
  SET_POLICY_TYPES: 'setPolicyTypes',
  SET_INDIVIDUAL_CONTACTS: 'setIndividualContacts',
  SET_AGENCIES: 'setAgencies',
  SET_AGENTS: 'setAgents',
  SET_POLICY_STATUSES: 'setPolicyStatuses',
  SET_DOCUMENT_TYPES: 'setDocumentTypes',
  SET_QUERY_TYPE: 'setQueryType',
  SET_QUERY: 'setQuery',
  SET_INITIAL_QUERY_TYPE: 'setInitialQueryType'
}

export const MOCK_POLICIES_MAINTENANCE_MUTATIONS = addNamespace(
  MOCK_POLICIES_MAINTENANCE_PATH,
  _mockPoliciesMaintenanceMutations
)
