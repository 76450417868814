import { find, get } from 'lodash'
import moment from 'moment'

export const getters = {
  isAuthenticated: (state) => {
    let isAuthenticated = false
    if (state.session != null) {
      isAuthenticated = state.session.isValid()
    }
    return isAuthenticated
  },
  accessTokenExpiry: (state) => {
    return (state.session) ? moment.unix(state.session.getAccessToken().decodePayload().exp) : null
  },
  isAdmin: (state) => {
    return state.isAdmin || state.isEmployee
  },
  providerIsEnabled: (state) => (providerName) => {
    return !!find(state.oauthProviders, ['name', providerName])
  },
  accessToken: (state) => {
    if (state.currentUser && state.currentUser.signInUserSession) {
      return state.currentUser.signInUserSession.getAccessToken().getJwtToken()
    }
  },
  oauthProviders: (state) => {
    return state.oauthProviders
  },
  isAgentOrAgency (state) {
    if (state.gen2EffectiveRole) {
      return !!state.gen2EffectiveRole.match(/Agent|Agency/)
    }
  },
  isLiveSite (state, getters, rootState) {
    return get(rootState, 'bcClassic.metaData.liveStatus.status') === 1
  },
  gen2EffectiveRole: state => state.gen2EffectiveRole
}
