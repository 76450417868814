import Vue from 'vue'
import get from 'lodash/get'
import assign from 'lodash/assign'
import { BRITERULES_CONNECTOR_STATE } from './constants'

const getDefaultElement = () => {
  return {}
}

const createViewIfNotExists = (state, viewId) => {
  if (!state.views[viewId]) {
    Vue.set(state.views, viewId, {
      elements: {},
      status: BRITERULES_CONNECTOR_STATE.INITIAL
    })
  }
}

const createElementIfNotExists = (state, viewId, elementId) => {
  createViewIfNotExists(state, viewId)
  if (!state.views[viewId].elements[elementId]) {
    Vue.set(state.views[viewId].elements, elementId, getDefaultElement())
  }
}

const setElementProperty = (state, viewId, elementId, property, value) => {
  createElementIfNotExists(state, viewId, elementId)
  Vue.set(state.views[viewId].elements[elementId], property, value)
}

const updateViewStatus = (state, viewId, status) => {
  createViewIfNotExists(state, viewId)
  state.views[viewId].status = status
}

let Store = {
  namespaced: true,
  state: {
    views: {},
    status: BRITERULES_CONNECTOR_STATE.INITIAL
  },
  getters: {
    getElement: state => (viewId, elementId) => get(state, `views.${viewId}.elements.${elementId}`),
    getElementProperty: state => (viewId, propertyPath) => get(state, `views.${viewId}.elements.${propertyPath}`),
    getView: state => viewId => get(state, `views.${viewId}`),
    ready: state => {
      return state.status === BRITERULES_CONNECTOR_STATE.DONE ||
        state.status === BRITERULES_CONNECTOR_STATE.FAILED_EXECUTION
    },
    viewReady: state => viewId => {
      let view = get(state, `views.${viewId}`)
      return view && (view.status === BRITERULES_CONNECTOR_STATE.DONE ||
        view.status === BRITERULES_CONNECTOR_STATE.FAILED_EXECUTION)
    },
    status: state => {
      return state.status
    }
  },
  mutations: {
    addElement: (state, {viewId, elementId, defaultValues}) => {
      createElementIfNotExists(state, viewId, elementId)
      let element = state.views[viewId].elements[elementId]
      element = assign({}, defaultValues, element)
      Vue.set(state.views[viewId].elements, elementId, element)
    },
    addElementProperty: (state, {viewId, elementId, property, defaultValue}) => {
      setElementProperty(state, viewId, elementId, property, defaultValue)
    },
    removeElement: (state, {viewId, elementId}) => {
      let view = state.views[viewId]
      if (view && view.elements) {
        let element = view.elements[elementId]
        if (element) {
          Vue.delete(view.elements, elementId)
        }
      }
    },
    removeView: (state, {viewId}) => {
      let view = state.views[viewId]
      if (view) {
        Vue.delete(state.views, viewId)
      }
    },
    setReadonly: (state, {viewId, elementId, value}) => {
      setElementProperty(state, viewId, elementId, 'readonly', value)
    },
    setVisible: (state, {viewId, elementId, value}) => {
      setElementProperty(state, viewId, elementId, 'visible', value)
    },
    setMandatory: (state, {viewId, elementId, value}) => {
      setElementProperty(state, viewId, elementId, 'mandatory', value)
    },
    setValid: (state, {viewId, elementId}) => {
      setElementProperty(state, viewId, elementId, 'isValid', true)
      setElementProperty(state, viewId, elementId, 'validationMessage', '')
    },
    setInvalid: (state, {viewId, elementId, message}) => {
      setElementProperty(state, viewId, elementId, 'isValid', false)
      setElementProperty(state, viewId, elementId, 'validationMessage', message)
    },
    setValue: (state, {viewId, elementId, value}) => {
      setElementProperty(state, viewId, elementId, 'value', value)
    },
    setWhitelist: (state, {viewId, elementId, options}) => {
      setElementProperty(state, viewId, elementId, 'optionsWhitelist', options)
    },
    setBlacklist: (state, {viewId, elementId, options}) => {
      setElementProperty(state, viewId, elementId, 'optionsBlacklist', options)
    },
    startExecution: (state, {viewId}) => {
      state.status = BRITERULES_CONNECTOR_STATE.RUNNING
      updateViewStatus(state, viewId, BRITERULES_CONNECTOR_STATE.RUNNING)
    },
    finishExecution: (state) => {
      state.status = BRITERULES_CONNECTOR_STATE.DONE
    },
    finishViewExecution: (state, {viewId}) => {
      updateViewStatus(state, viewId, BRITERULES_CONNECTOR_STATE.DONE)
    },
    executionFailed: (state) => {
      state.status = BRITERULES_CONNECTOR_STATE.FAILED_EXECUTION
    },
    viewExecutionFailed: (state, {viewId}) => {
      updateViewStatus(state, viewId, BRITERULES_CONNECTOR_STATE.FAILED_EXECUTION)
    }
  }
}

export default Store
