import _ from 'lodash'

import { linesClient } from '@/apollo'
import { LINES_GQL_CLIENT } from '@/pages/lines/constants'
import { PAGE_SUBMIT_RULES_ACTION } from '@/pages/quote/constants'
import GetRiskTypesByProduct from '@/pages/lines/queries/GetRiskTypesByProduct.gql'
import GetProductByName from '@/pages/lines/queries/GetProductByName.gql'

import router from '@/router'
import quoteService from '@/api/quote/quote'
import { _actionTypes } from './actionTypes'
import { completeStep, initializeNavigationState, resetNavigationSteps } from '@/api/quote/navigation'

export const actions = {
  async [_actionTypes.LOAD_QUOTE] ({ dispatch, getters }, quoteNumber) {
    const quote = await dispatch(_actionTypes.GET_QUOTE, quoteNumber)
    const productName = quote.product_name
    const productVersion = quote.product_version

    await dispatch(_actionTypes.GET_ROOT_RISK_QUOTE, quote.root_risk_quote_id)
    await dispatch(_actionTypes.GET_RISK_TYPES_BY_PRODUCT, { productName, productVersion })
  },
  [_actionTypes.GET_QUOTE] (context, quoteNumber) {
    return new Promise((resolve, reject) => {
      const requestTime = new Date().getTime()
      quoteService.getQuote(quoteNumber).then(({ data }) => {
        const quoteFirstLoaded = _.isEmpty(context.state.quote)
        context.commit('quote', data)
        if (quoteFirstLoaded || context.getters.isQuoteBindingDebounced(requestTime)) {
          context.commit('setQuoteIsBinding', data.is_binding)
        }
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  [_actionTypes.UPDATE_QUOTE] (context, { quoteNumber, payload }) {
    return new Promise((resolve, reject) => {
      quoteService.updateQuote(quoteNumber, payload).then(data => {
        context.commit('quote', data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  [_actionTypes.GET_QUOTES] (context, { searchQuery, pageSize, pageNumber, filters }) {
    return new Promise((resolve, reject) => {
      quoteService.getQuotes(searchQuery, pageSize, pageNumber, filters).then(({ data }) => {
        context.commit('quotes', data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  [_actionTypes.EXECUTE_QUOTE_STATUS_TRIGGER] ({ commit, dispatch }, { quoteNumber, triggerName }) {
    return new Promise((resolve, reject) => {
      quoteService.executeQuoteStatusTrigger(quoteNumber, triggerName).then(() => {
        dispatch(_actionTypes.GET_QUOTE, quoteNumber).then((updatedQuote) => {
          resolve(updatedQuote)
        })
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [_actionTypes.GET_QUOTE_FLOW] (context, quoteNumber) {
    return new Promise((resolve, reject) => {
      quoteService.getQuoteFlow(quoteNumber).then(({ data }) => {
        initializeNavigationState(quoteNumber, data, context.getters.isQuoteBound)
        context.commit('quoteFlow', data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  [_actionTypes.GET_ROOT_RISK_QUOTE] (context, rootRiskQuoteId) {
    return new Promise((resolve, reject) => {
      quoteService.getRiskQuote(rootRiskQuoteId).then(({ data }) => {
        context.commit('rootRiskQuote', data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  [_actionTypes.GET_RISK_TYPES_BY_PRODUCT] (context, { productName, productVersion }) {
    return new Promise((resolve) => {
      linesClient.query({
        client: LINES_GQL_CLIENT,
        query: GetProductByName,
        variables: {
          name: productName,
          version: productVersion
        }
      }).then(({ data: { product } }) => {
        linesClient.query({
          client: LINES_GQL_CLIENT,
          query: GetRiskTypesByProduct,
          fetchPolicy: 'network-only',
          variables: {
            productId: product.id
          }
        }).then(({ data: { riskTypes } }) => {
          context.commit('riskTypes', riskTypes)
          resolve(riskTypes)
        })
      })
    })
  },
  [_actionTypes.FETCH_RISK_TYPE_STATE] ({ state, getters, commit }, { riskTypeName }) {
    const quote = state.quote
    const productName = quote.product_name
    const productVersion = quote.product_version
    const cachedRiskTypeState = getters.getRiskTypeState(riskTypeName)
    const riskTypeStateCacheKey = getters.getRiskTypeStateCacheKey(productName, productVersion)

    return new Promise((resolve, reject) => {
      if (cachedRiskTypeState) {
        resolve(cachedRiskTypeState)
        return
      }
      quoteService.getRiskTypeState(productName, productVersion, riskTypeName)
        .then((riskTypeState) => {
          commit('riskTypeState', { riskTypeName, riskTypeState, riskTypeStateCacheKey })
          resolve(riskTypeState)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  [_actionTypes.INITIALIZE_NAVIGATION] ({ dispatch, getters, state }, { resetSteps = false } = {}) {
    dispatch(_actionTypes.UPDATE_NAVIGATION_STATE, {
      pageGroupName: getters.firstPageGroup.name
    }).then(() => {
      router.push({
        name: 'quote:page',
        params: {
          pageGroupName: getters.activePageGroup.name,
          pageName: getters.activePage.name
        },
        query: getters.sharedQueryParams
      })
      if (resetSteps) {
        resetNavigationSteps(getters.quoteNumber, state.quoteFlow)
      }
    })
  },
  [_actionTypes.UPDATE_NAVIGATION_STATE] ({ commit, getters }, { pageGroupName, pageName }) {
    return new Promise((resolve) => {
      commit('activePageGroup', getters.getPageGroupByName(pageGroupName))

      if (_.isUndefined(pageName)) {
        pageName = getters.firstPage.name
      }
      commit('activePage', getters.getPageByName(pageName))
      resolve()
    })
  },
  [_actionTypes.NAVIGATE_TO_STEP] ({ dispatch, getters }, { pageGroupName, pageName, dontPushRoute }) {
    if (_.isUndefined(pageName)) {
      pageName = getters.firstPageOf(pageGroupName).name
    }

    if (!getters.canAccessStep(pageGroupName, pageName)) {
      // prevent users bypassing steps by typing an unlocked step URL directly
      pageGroupName = getters.firstPageGroup.name
      pageName = getters.firstPageOf(pageGroupName).name
    }

    dispatch(_actionTypes.UPDATE_NAVIGATION_STATE, {
      pageGroupName: pageGroupName,
      pageName: pageName
    }).then(() => {
      // if it is the last step then mark this step as completed
      if (!getters.hasNextStep) {
        completeStep(getters.quoteNumber, getters.activePageGroup.name, getters.activePage.name)
      }

      // if user is editing a risk, the route should be updated
      const editingRiskRoutes = ['quote:add-risk', 'quote:edit-risk']
      const isEditingARisk = editingRiskRoutes.includes(router.history.current.name)

      const routeNeedsUpdate = (
        isEditingARisk ||
        router.history.current.params.pageGroupName !== pageGroupName ||
        router.history.current.params.pageName !== pageName
      )
      if ((dontPushRoute || !routeNeedsUpdate) && !_.isUndefined(pageName)) {
        return
      }
      router.push({
        name: 'quote:page',
        params: {
          pageGroupName: getters.activePageGroup.name,
          pageName: getters.activePage.name
        },
        query: getters.sharedQueryParams
      })
    })
  },
  [_actionTypes.NAVIGATE_TO_NEXT_STEP] ({ dispatch, getters }) {
    const step = {
      pageGroupName: getters.activePageGroup.name,
      pageName: null
    }
    if (getters.hasMorePagesInActivePageGroup) {
      step.pageName = getters.nextPage.name
    } else {
      step.pageGroupName = getters.nextPageGroup.name
      step.pageName = _.get(getters.nextPageGroup, 'pages[0].name', {})
    }

    completeStep(getters.quoteNumber, getters.activePageGroup.name, getters.activePage.name)

    dispatch(_actionTypes.NAVIGATE_TO_STEP, step)
  },
  [_actionTypes.NAVIGATE_TO_PREVIOUS_STEP] ({ dispatch, getters }) {
    dispatch(_actionTypes.NAVIGATE_TO_STEP, getters.previousStep)
  },
  [_actionTypes.NAVIGATE_TO_STEP_BY_COMPONENT_TYPE] ({ dispatch, getters }, componentType) {
    const step = {
      pageGroupName: getters.getPageGroupByComponentType(componentType).name,
      pageName: getters.getPageByComponentType(componentType).name
    }

    completeStep(getters.quoteNumber, getters.activePageGroup.name, getters.activePage.name)
    dispatch(_actionTypes.NAVIGATE_TO_STEP, step)
  },
  [_actionTypes.SUBMIT_PAGE] ({ dispatch, getters, state }, targetPage) {
    if (getters.isQuoteLocked) {
      return true
    }
    const riskQuoteId = state.rootRiskQuote.id
    const riskState = state.rootRiskQuote.risk_state
    const pageName = `${getters.activePageGroup.name}/${getters.activePage.name}`
    const config = {
      params: {
        rules_action: PAGE_SUBMIT_RULES_ACTION,
        page_name: pageName,
        target_page_name: `${targetPage.pageGroupName}/${targetPage.pageName}`
      }
    }

    return new Promise((resolve, reject) => {
      return quoteService.updateRiskQuote(riskQuoteId, riskState, config)
        .then((riskQuote) => {
          dispatch(
            _actionTypes.ADD_OR_UPDATE_RISK_QUOTE,
            { riskQuote: riskQuote, parentRiskQuote: state.rootRiskQuote }
          )
          resolve(riskQuote)
        })
        .catch((response) => {
          reject(response)
        })
    })
  },

  [_actionTypes.ADD_OR_UPDATE_RISK_QUOTE] ({ commit, getters, state }, { riskQuote, parentRiskQuote }) {
    if (riskQuote.risk_state.type.name === 'policy') {
      commit('rootRiskQuote', riskQuote)
      return
    }

    const parent = getters.findRiskQuoteInStateById(state.rootRiskQuote, parentRiskQuote.id)
    const indexInParent = parent.risk_quotes.findIndex(rq => rq.id === riskQuote.id)
    if (indexInParent < 0) {
      commit('addRiskQuote', { parentRiskQuote: parent, riskQuote: riskQuote })
    } else {
      commit('updateRiskQuote', { parentRiskQuote: parent, index: indexInParent, riskQuote: riskQuote })
    }
  },
  [_actionTypes.DELETE_RISK_QUOTE] ({ commit, getters, state }, riskQuote) {
    return new Promise((resolve, reject) => {
      quoteService.deleteRiskQuote(riskQuote.id).then((response) => {
        const parent = getters.findRiskQuoteParentInState(state.rootRiskQuote, riskQuote.id)
        const indexInParent = parent.risk_quotes.findIndex(rq => rq.id === riskQuote.id)
        commit('deleteRiskQuote', { parentRiskQuote: parent, index: indexInParent })
        resolve(riskQuote)
      }).catch(error => {
        reject(error)
      })
    })
  },
  [_actionTypes.GET_UNDERWRITER_CONTACT] ({ commit, dispatch }, username) {
    return new Promise((resolve, reject) => {
      dispatch('bcClassic/retrieveGen2Contact', username, { root: true })
        .then(contact => {
          commit('underwriterContact', contact)
          resolve(contact)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  [_actionTypes.BIND_QUOTE] ({ commit, state, getters }) {
    return new Promise((resolve, reject) => {
      commit('setQuoteIsBinding', true)
      quoteService.bindQuote(state.quote.quote_number).then((data) => {
        return resolve(data)
      }).catch(err => {
        commit('setQuoteIsBinding', false)
        return reject(err)
      })
    })
  }
}
