var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleClickOutside),expression:"handleClickOutside"}],staticClass:"c-nav-sidebar__wrapper"},[_c('get-help-modal',{on:{"submit-support-ticket-clicked":_vm.submitSupportTicket},model:{value:(_vm.getHelpModal.visible),callback:function ($$v) {_vm.$set(_vm.getHelpModal, "visible", $$v)},expression:"getHelpModal.visible"}}),_c('submit-support-ticket-modal',{model:{value:(_vm.submitSupportTicketModal.visible),callback:function ($$v) {_vm.$set(_vm.submitSupportTicketModal, "visible", $$v)},expression:"submitSupportTicketModal.visible"}}),_c('nav',{ref:"sidebar",staticClass:"c-nav-sidebar l-flex-grid--no-wrap l-flex-grid__column"},[_c('router-link',{staticClass:"c-nav-main-sidebar-britecore",attrs:{"to":{ path: '/', query: { pageReload: true } }}},[_c('img',{staticClass:"c-nav-main-sidebar-britecore__image",attrs:{"src":require("../../../assets/britecore_logo.svg")}}),_c('h3',{staticClass:"c-nav-main-sidebar__link__text c-nav-main-sidebar-britecore__text"},[_c('div',{staticClass:"c-nav-main-sidebar__link__text-content"},[_vm._v("BriteCore")])])]),_c('div',{staticClass:"c-nav-main-sidebar"},[_c('router-link',{class:[
          'c-nav-main-sidebar__link',
          'c-nav-main-sidebar__client-dashboard-link',
          {'c-nav-main-sidebar__link--active': 'All Modules' === _vm.activeModule},
          {'c-nav-main-sidebar__link--not-selected': 'All Modules' !== _vm.activeModule}
        ],attrs:{"to":{ path: '/', query: { pageReload: true } }}},[_c('fa-icon',{attrs:{"icon":"home","size":"lg","fixed-width":""}}),_c('div',{staticClass:"c-nav-main-sidebar__link__text"},[_vm._v("Dashboard")])],1),(!_vm.isLoading)?_vm._l((_vm.currentModuleLinks),function(module){return _c('router-link',{key:module.label,class:[
            'c-nav-main-sidebar__link',
            {'c-nav-main-sidebar__link--active': module.label === _vm.activeModule},
            {'c-nav-main-sidebar__link--not-selected': _vm.activeModule !== 'All Modules' && module.label !== _vm.activeModule},
            {'c-nav-main-sidebar__link--bottom': module.moduleName === 'all-modules'}
          ],attrs:{"to":module.location},nativeOn:{"click":function($event){return _vm.handleSidebarClose($event)}}},[_c('fa-icon',{staticClass:"c-nav-main-sidebar__icon",attrs:{"icon":module.icon,"size":"lg","fixed-width":""}}),_c('div',{staticClass:"c-nav-main-sidebar__link__text",domProps:{"textContent":_vm._s(module.label)}})],1)}):_vm._e(),(_vm.isLoading)?_c('fa-icon',{staticClass:"icon--spin-fast c-nav-main-sidebar__loading-icon",attrs:{"icon":['far', 'spinner-third'],"size":"lg","fixed-width":"","spin":""}}):_vm._e(),_c('div',{staticClass:"c-nav-sidebar__help-btn",on:{"click":function($event){_vm.getHelpModal.visible = true}}},[_c('fa-icon',{staticClass:"u-mr1",attrs:{"icon":"question-circle"}}),_vm._v(" Get help with BriteCore ")],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }