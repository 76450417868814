import {CLAIM_STORE_PATH} from '../constants'
import {addNamespace} from '@/store/helpers'

// To be used only inside vuex modules
export const _claimActions = {
  GET_CLAIM: 'getClaim',
  GET_CLAIM_BY_NUMBER: 'getClaimByNumber',
  UPDATE_CLAIM_TAGS: 'updateClaimTags',
  GET_CLAIM_TAGS: 'getClaimTags',
  SET_CLAIM: 'setClaim',
  UPDATE_CLAIM: 'updateClaim',
  CREATE_FNOL: 'createFnol',
  GET_FNOL_BY_NUMBER: 'getFnolByNumber',
  GET_PRODUCT_BY_ID: 'getProductById',
  UPDATE_FNOL: 'updateFnol',
  DELETE_EXPOSURE: 'deleteExposure',
  GET_EXPOSURE_BY_NUMBER: 'getExposureByNumber',
  GET_EXPOSURES_BY_CLAIM_NUMBER: 'getExposuresByClaimNumber',
  UPDATE_EXPOSURE_TAGS: 'updateExposureTags',
  GET_EXPOSURE_TAGS: 'getExposureTags',
  GET_PAYMENTS: 'getPayments',
  UPDATE_LOSS_LOCATION: 'updateLossLocation',
  CREATE_LOSS_LOCATION: 'createLossLocation',
  GET_RISK_GROUPS: 'getRiskGroups',
  UPDATE_EXPOSURE: 'updateExposure',
  GET_PARTIES: 'getParties',
  GET_ATTACHMENTS: 'getAttachments',
  GET_CURRENT_USER: 'getCurrentUser',
  GET_GENDERS: 'getGenders',
  GET_ADDRESS_TYPES: 'getAddressTypes',
  GET_ALLOW_DUPLICATE_SSN: 'getAllowDuplicateSSN'
}

// To be used publicly outside vuex modules
export const CLAIM_ACTIONS = addNamespace(CLAIM_STORE_PATH, _claimActions)
