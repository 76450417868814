import {_mockPoliciesMaintenanceMutations} from './mutation-types'

export default {
  [_mockPoliciesMaintenanceMutations.SET_POLICIES] (state, policies) {
    state.policies = policies
  },
  [_mockPoliciesMaintenanceMutations.CLEAR_POLICIES] (state) {
    state.policies = null
  },
  [_mockPoliciesMaintenanceMutations.SET_POLICY_TYPES] (state, policyTypes) {
    state.policyTypes = policyTypes
  },
  [_mockPoliciesMaintenanceMutations.SET_INDIVIDUAL_CONTACTS] (state, individualContacts) {
    state.individualContacts = individualContacts
  },
  [_mockPoliciesMaintenanceMutations.SET_AGENCIES] (state, agencies) {
    state.agencies = agencies
  },
  [_mockPoliciesMaintenanceMutations.SET_AGENTS] (state, agents) {
    state.agents = agents
  },
  [_mockPoliciesMaintenanceMutations.SET_POLICY_STATUSES] (state, policyStatuses) {
    state.policyStatuses = policyStatuses
  },
  [_mockPoliciesMaintenanceMutations.SET_DOCUMENT_TYPES] (state, documentTypes) {
    state.documentTypes = documentTypes
  },
  [_mockPoliciesMaintenanceMutations.SET_POLICY] (state, policy) {
    state.policy = policy
  },
  [_mockPoliciesMaintenanceMutations.SET_QUERY_TYPE] (state, queryType) {
    state.searchPolicy.queryType = queryType
  },
  [_mockPoliciesMaintenanceMutations.SET_QUERY] (state, query) {
    state.searchPolicy.query = query
  },
  [_mockPoliciesMaintenanceMutations.SET_INITIAL_QUERY_TYPE] (state, queryType) {
    state.searchPolicy.defaultSearchType = queryType
  }
}
