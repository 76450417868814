import pluralize from 'pluralize'
import JsonApi from 'devour-client'
import models from './models'
import axios from 'axios'
import {errorMiddleware} from './middlewares'
import {get, isNil} from 'lodash'

const getDeprecatedPath = ({modelName, id = null, isResource = true}) => {
  // The path resolution for the deprecated shortcut to access claims JSON:API endpoints,
  // via src/api/services/index.js

  // 1. If this is a related field, no further processing required.
  if (isResource && isNil(id)) {
    return modelName
  }

  // 2. To ensure we do not accidentally pluralize related fields, we only pluralize for resource path only (with id)
  const paths = []
  const collectionPath = pluralize(modelName)
  // 3. If the model is not claims, prepend the claims path
  if (collectionPath !== 'claims') paths.unshift(collectionPath)
  // 4. If it has id, append to the paths
  if (!isNil(id)) paths.push(encodeURIComponent(id))
  // 5. Return the API URL path
  return paths.join('/')
}
const removePrependClaimsPath = (path) => {
  // Temporary resolution as we prepend claims path in apiUrl.
  // We can revert the apiUrl once we obsolete the deprecated shortcut to access claims JSON:API endpoints.
  const paths = path.split('/')
  paths.shift()
  return paths.join('/')
}

const jsonApi = new JsonApi({
  // Temporary we prepend claims path in the apiUrl.
  // We can revert the apiUrl once we obsolete the deprecated shortcut to access claims JSON:API endpoints.
  apiUrl: `${process.env.VUE_APP_API_URL}/claims`,
  trailingSlash: {resource: true, collection: true}
})
jsonApi.collectionPathFor = (modelName) => {
  const collectionPath = get(jsonApi.models[modelName], 'options.collectionPath')
  return collectionPath
    ? removePrependClaimsPath(collectionPath)
    : getDeprecatedPath({modelName, isResource: false})
}
jsonApi.resourcePathFor = (modelName, id) => {
  const collectionPath = get(jsonApi.models[modelName], 'options.collectionPath')
  return collectionPath
    ? removePrependClaimsPath(`${collectionPath}/${encodeURIComponent(id)}`)
    : getDeprecatedPath({modelName, id})
}
// Temporary we handle trailing slash manually
// We can remove this override once we obsolete the deprecated shortcut to access claims JSON:API endpoints.
jsonApi.collectionUrlFor = (modelName) => {
  let collectionPath = jsonApi.collectionPathFor(modelName)
  let trailingSlash = ''
  if (collectionPath.length > 0) {
    trailingSlash = jsonApi.trailingSlash['collection'] ? '/' : ''
  }
  return `${jsonApi.apiUrl}/${collectionPath}${trailingSlash}`
}
jsonApi.resourceUrlFor = (modelName, id) => {
  let resourcePath = jsonApi.resourcePathFor(modelName, id)
  let trailingSlash = ''
  if (resourcePath.length > 0) {
    trailingSlash = jsonApi.trailingSlash['resource'] ? '/' : ''
  }
  return `${jsonApi.apiUrl}/${resourcePath}${trailingSlash}`
}

jsonApi.replaceMiddleware('errors', errorMiddleware)
jsonApi.axios = axios

models.forEach(m => jsonApi.define(m.name, m.definition, m.options))
export default jsonApi
