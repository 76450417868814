import _ from 'lodash'

import {
  TRANSACTION_TYPE_NEW_BUSINESS,
  TRANSACTION_TYPE_RENEWAL,
  TRANSACTION_TYPE_REWRITE
} from '@/pages/lines/constants'

export const getters = {
  storageKey (state, getters, rootState) {
    return _.join([
      'LinePreview',
      rootState.lines.line.id,
      rootState.lines.product.id
    ], ':')
  },
  shouldShowProRata: (state) => (transactionType = null) => {
    if (!transactionType) {
      transactionType = state.scenario.transaction_type
    }
    return ![TRANSACTION_TYPE_NEW_BUSINESS, TRANSACTION_TYPE_RENEWAL, TRANSACTION_TYPE_REWRITE].includes(transactionType)
  }
}
