
export const state = {
  projects: null,
  packages: null,
  commitChangesDialogVisible: false,
  projectDeploymentDialogVisible: false,
  activePackage: {
    loading: false,
    loaded: false,
    value: null,
    error: null
  },
  packageCatalog: {
    loading: false,
    loaded: false,
    value: undefined,
    error: null
  },
  suggestions: null,
}
