<template>
  <div class="c-nav-sub-mobile">

    <el-button
      type="text"
      class="u-mr3 u-p0"
      :class="{ 'fa-flip-horizontal': subSidebarVisibleMobile }"
      :title="subSidebarVisibleMobile ? 'Collapse sidebar' : 'Expand sidebar'"
      @click="toggleSidebar">
      <img src="../../../assets/icons/sub-nav-sidebar-toggle.svg">
    </el-button>

    <div class="u-text--bold u-text--lg">
      {{ activeModule }}
    </div>

    <portal-target
      class="u-ml-auto"
      name="module_subnav_mobile" />

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['activeModule', 'subSidebarVisibleMobile'])
  },
  methods: {
    toggleSidebar () {
      this.$store.commit('toggleSubSidebarMobile', this.subSidebarVisibleMobile ? 'hide' : 'show')
    }
  }
}
</script>
