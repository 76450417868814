import JsonApi from 'devour-client'
import axios from 'axios'
import models from './models'

const api = new JsonApi({
  apiUrl: process.env.VUE_APP_INTEGRATIONS_URL,
  trailingSlash: { resource: false, collection: true },
})

api.axios = axios

models.forEach(model => api.define(model.name, model.definition, model.options))
export default api
