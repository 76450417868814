import { _actions } from './action-types'
import { _mutations } from './mutation-types'
import RulesService from '@/api/rules'

export const actions = {
  async [_actions.INIT_RULES_MODULE] ({commit, dispatch}) {
    commit('activeModule', 'Rules', { root: true })
    return await Promise.all([
      dispatch(_actions.LOAD_PROJECTS),
      dispatch(_actions.LOAD_PACKAGES)
    ])
  },
  async [_actions.LOAD_PROJECTS] ({commit, state}) {
    if (!state.projects) {
      commit(_mutations.SET_PROJECTS, await RulesService.getProjects())
    }
  },
  async [_actions.RELOAD_PROJECTS] ({commit}) {
    commit(_mutations.RESET_PROJECTS)
    commit(_mutations.SET_PROJECTS, await RulesService.getProjects())
  },
  async [_actions.LOAD_PACKAGES] ({commit, state}) {
    if (!state.packages) {
      commit(_mutations.SET_PACKAGES, await RulesService.getPackages())
    }
  },
  async [_actions.RELOAD_PACKAGES] ({commit}) {
    commit(_mutations.RESET_PACKAGES)
    commit(_mutations.SET_PACKAGES, await RulesService.getPackages())
  },
  async [_actions.LOAD_PACKAGE_CATALOG] ({commit, getters}, packageId) {
    if (!getters.packageCatalogStatus.loaded && getters.activePackageUuid !== packageId) {
      commit(_mutations.START_LOADING_PACKAGE_CATALOG)
      try {
        commit(_mutations.SET_PACKAGE_CATALOG, await RulesService.getFunctionsCatalog(packageId))
      } catch (error) {
        commit(_mutations.SET_ERROR_LOADING_PACKAGE_CATALOG, error)
      }
    }
  },
  async [_actions.RELOAD_PACKAGE_CATALOG] ({commit, dispatch}, packageId) {
    commit(_mutations.RESET_PACKAGE_CATALOG)
    await dispatch(_actions.LOAD_PACKAGE_CATALOG, packageId)
  },
  async [_actions.SET_ACTIVE_PACKAGE] ({commit, getters, dispatch}, { packageUuid, force }) {
    if (force || !getters.activePackageStatus.loaded || getters.activePackageUuid !== packageUuid) {
      commit(_mutations.START_LOADING_PACKAGE)
      try {
        const activePackage = await RulesService.getPackage(packageUuid)
        commit(_mutations.UPDATE_ACTIVE_PACKAGE, activePackage)
        dispatch(_actions.LOAD_SUGGESTIONS, activePackage.packageId)
        dispatch(_actions.LOAD_PACKAGE_CATALOG, activePackage.packageId)
      } catch (error) {
        commit(_mutations.SET_ERROR_LOADING_PACKAGE, error)
      }
    }
  },
  async [_actions.LOAD_SUGGESTIONS] ({commit, state, getters}, packageId) {
    if (!state.suggestions || getters.activePackageId !== packageId) {
      commit(_mutations.SET_SUGGESTIONS, await RulesService.getVisualEditorSuggestions({
        type: 'none',
        path: 'none',
        context: 'none',
        package_id: packageId
      }))
    }
  }
}
