import { _mutations } from './mutation-types'
import Vue from 'vue'

export const mutations = {
  [_mutations.RESET_PROJECTS] (state) {
    state.projects = null
  },
  [_mutations.RESET_PACKAGES] (state) {
    state.packages = null
  },
  [_mutations.SET_PROJECTS] (state, projects) {
    state.projects = projects || []
  },
  [_mutations.SET_PACKAGES] (state, packages) {
    state.packages = packages || []
  },
  [_mutations.OPEN_COMMIT_CHANGES_DIALOG] (state) {
    state.commitChangesDialogVisible = true
  },
  [_mutations.CLOSE_COMMIT_CHANGES_DIALOG] (state) {
    state.commitChangesDialogVisible = false
  },
  [_mutations.OPEN_PROJECT_DEPLOYMENT_DIALOG] (state) {
    state.projectDeploymentDialogVisible = true
  },
  [_mutations.CLOSE_PROJECT_DEPLOYMENT_DIALOG] (state) {
    state.projectDeploymentDialogVisible = false
  },
  [_mutations.SET_PACKAGE_CATALOG] (state, packageCatalog) {
    Vue.set(state.packageCatalog, 'value', packageCatalog)
    Vue.set(state.packageCatalog, 'loaded', true)
    Vue.set(state.packageCatalog, 'loading', false)
  },
  [_mutations.START_LOADING_PACKAGE_CATALOG] (state) {
    Vue.set(state.packageCatalog, 'error', null)
    Vue.set(state.packageCatalog, 'loaded', false)
    Vue.set(state.packageCatalog, 'loading', true)
  },
  [_mutations.SET_ERROR_LOADING_PACKAGE_CATALOG] (state, error) {
    Vue.set(state.packageCatalog, 'error', error)
    Vue.set(state.packageCatalog, 'loaded', false)
    Vue.set(state.packageCatalog, 'loading', false)
  },
  [_mutations.RESET_PACKAGE_CATALOG] (state) {
    Vue.set(state.packageCatalog, 'value', null)
    Vue.set(state.packageCatalog, 'loaded', false)
    Vue.set(state.packageCatalog, 'loading', false)
    Vue.set(state.packageCatalog, 'error', null)
  },
  [_mutations.UPDATE_ACTIVE_PACKAGE] (state, activePackage) {
    Vue.set(state.activePackage, 'value', activePackage)
    Vue.set(state.activePackage, 'loaded', true)
    Vue.set(state.activePackage, 'loading', false)
  },
  [_mutations.START_LOADING_PACKAGE] (state) {
    Vue.set(state.activePackage, 'error', null)
    Vue.set(state.activePackage, 'loaded', false)
    Vue.set(state.activePackage, 'loading', true)
  },
  [_mutations.SET_ERROR_LOADING_PACKAGE] (state, error) {
    Vue.set(state.activePackage, 'error', error)
    Vue.set(state.activePackage, 'loaded', false)
    Vue.set(state.activePackage, 'loading', false)
  },
  [_mutations.RESET_SUGGESTIONS] (state) {
    Vue.set(state, 'suggestions', null)
  },
  [_mutations.SET_SUGGESTIONS] (state, suggestions) {
    Vue.set(state, 'suggestions', [...suggestions])
  }
}
