import {ASSIGN_CLAIMS_PATH} from '../constants'
import {addNamespace} from '@/store/helpers'

// To be used only inside vuex modules
export const _assignClaimsMutations = {
  SET_ACTIVE_TAB: 'setActiveTab',
  SET_SEARCH_ADJUSTERS: 'setSearchAdjusters',

  APPEND_SEARCH_ADJUSTERS: 'appendSearchAdjusters',
  CLEAR_STATE: 'clearState',
  CLEAR_ADJUSTERS: 'clearAdjusters',
  CLEAR_CLAIMS: 'clearClaims',

  SET_PENDING_CLAIMS: 'setPendingClaims',
  SET_PENDING_CLAIMS_TOTAL: 'setPendingClaimsTotal',
  SET_PENDING_CLAIMS_PAGE: 'setPendingClaimsPage',

  // Filters
  ADD_FILTER: 'addFilter',
  REMOVE_FILTER: 'removeFilter',
  SET_CLAIM_STATUSES: 'setClaimStatuses',
  SET_LOBS: 'setLOBS',
  SET_COVERAGE_TYPES: 'setCoverageTypes',
  SET_LOSS_TYPES: 'setLossTypes'
}

// To be used publicly outside vuex modules
export const ASSIGN_CLAIMS_MUTATIONS = addNamespace(
  ASSIGN_CLAIMS_PATH,
  _assignClaimsMutations
)
