import BaseService from '@/api/base'

class AuthService extends BaseService {
  constructor () {
    super('auth')
  }

  getPasswordPolicy () {
    return this.get('settings/password-policy')
  }
}

export default new AuthService()
