export default [
  {
    name: 'integration',
    definition: {
      name: '',
      author: '',
      definition: '',
      category: [],
      events: [],
      status: '',
      messages: [],
      date_created: '',
      data_mappings: {
        jsonApi: 'hasMany',
        type: 'data_mapping'
      },
      steps: {
        jsonApi: 'hasMany',
        type: 'step',
      },
      plugins: {
        jsonApi: 'hasMany',
        type: 'plugin'
      }
    },
    options: {
      type: 'integration',
      collectionPath: 'integrations',
      readOnly: ['status'],
    },
  },
  {
    name: 'data_mapping',
    definition: {},
    options: {
      type: 'data_mapping',
    },
  },
  {
    name: 'step',
    definition: {},
    options: {
      type: 'step',
    },
  },
  {
    name: 'plugin',
    definition: {},
    options: {
      type: 'plugin',
    },
  }
]
