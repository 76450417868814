export const state = {
  userPoolSettings: {},
  breadcrumbs: [],
  users: [],
  groups: [],
  usersPagination: {
    limit: 60,
    next: ''
  },
  groupsPagination: {
    limit: 60,
    next: ''
  },
  passwordPolicy: null,
  passwordPolicyExpiration: null
}
