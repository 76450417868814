import {CLAIM_STORE_PATH} from '../constants'
import {addNamespace} from '@/store/helpers'

// To be used only inside vuex modules
export const _claimMutations = {
  SET_CLAIM: 'claim',
  SET_CLAIM_LOSS_LOCATION: 'setClaimLossLocation',
  SET_CLAIM_TAGS: 'setClaimTags',
  SET_PRODUCT: 'product',
  SET_CURRENT_USER: 'currentUser',
  SET_REPORTER_ID: 'setReporter',
  SET_EXPOSURE: 'exposure',
  SET_EXPOSURE_TAGS: 'setExposureTags',
  SET_EXPOSURES: 'setExposures',
  REMOVE_EXPOSURE: 'removeExposure',
  CLEAR_SUMMARY_STATE: 'clearSummaryState',
  CLEAR_EXPOSURE_STATE: 'clearExposureState',
  SET_INJURY_PERMISSION: 'setInjuryPermission',
  SET_CLAIMANT_ACTIVE_TAB: 'claimantActiveTab',
  SET_PARTIES: 'setParties',
  SET_RISK_GROUPS: 'setRiskGroups',
  SET_ATTACHMENTS: 'setAttachments',
  CLEAR_ATTACHMENTS: 'clearAttachments',
  // Risk Summary
  SET_RISK_BREADCRUMB: 'setRiskBreadcrumb',
  // Accounting
  SET_ACCOUNTING_BREADCRUMB: 'setAccountingBreadcrumb',
  // FNOL
  ADD_FNOL_STEPS: 'addSteps',
  SET_FNOL_SAVING: 'setFnolSaving',
  INCREMENT_CLAIM_POLICY_RISKS_COUNT: 'incrementClaimPolicyRisksCount',
  DECREMENT_CLAIM_POLICY_RISKS_COUNT: 'decrementClaimPolicyRisksCount',
  INCREMENT_CLAIM_UNLISTED_RISKS_COUNT: 'incrementClaimUnlistedRisksCount',
  DECREMENT_CLAIM_UNLISTED_RISKS_COUNT: 'decrementClaimUnlistedRisksCount',
  SET_GENDERS: 'setGenders',
  SET_ADDRESS_TYPES: 'setAddressTypes',
  SET_ALLOW_DUPLICATE_SSN: 'setAllowDuplicateSSN',
}

// To be used publicly outside vuex modules
export const CLAIM_MUTATIONS = addNamespace(CLAIM_STORE_PATH, _claimMutations)
