const IntegrationManager = () =>
  import('@/pages/integrations/integration-manager/index.vue')
const IntegrationDetail = () =>
  import('@/pages/integrations/integration-manager/IntegrationDetail.vue')
const IntegrationList = () =>
  import('@/pages/integrations/integration-manager/IntegrationList.vue')
const EventManager = () =>
  import('@/pages/integrations/event-manager/index.vue')
const PluginManager = () =>
  import('@/pages/integrations/plugin-manager/index.vue')
const DataMappingManager = () =>
  import('@/pages/integrations/datamapping-manager/index.vue')
const DataMappingEdit = () =>
  import('@/pages/integrations/datamapping-manager/DataMappingEdit.vue')
const Dashboard = () => import('@/pages/integrations/Dashboard.vue')
const VendorDirectory = () =>
  import('@/pages/integrations/vendor-directory-manager/index.vue')
const VendorDirectoryList = () =>
  import('@/pages/integrations/vendor-directory-manager/vendorDirectoryList.vue')
const VendorQuickBooks = () =>
  import('@/pages/integrations/vendor-directory-manager/quickbooks/index')
const VendorDocuSign = () =>
  import('@/pages/integrations/vendor-directory-manager/docusign/index')

export default [
  {
    path: 'dashboard',
    name: 'integrations:dashboard',
    component: Dashboard,
  },
  {
    path: 'events',
    name: 'integrations:events',
    component: EventManager,
  },
  {
    path: 'plugins',
    name: 'integrations:plugins',
    component: PluginManager,
  },
  {
    path: 'datamappings',
    name: 'integrations:datamappings',
    component: DataMappingManager,
  },
  {
    path: 'datamappings/new',
    name: 'integrations:mappingNew',
    component: DataMappingEdit,
  },
  {
    path: 'datamappings/edit/:mappingId?',
    name: 'integrations:mappingEdit',
    component: DataMappingEdit,
    props: true,
  },
  {
    path: '',
    name: 'integrations:dashboard',
    component: IntegrationManager,
    redirect: { name: 'integrations:list' },
    children: [
      {
        path: '',
        name: 'integrations:list',
        component: IntegrationList,
      },
      {
        path: 'new',
        name: 'integrations:new',
        component: IntegrationList,
        meta: {
          creating: true,
        },
      },
      {
        path: ':integrationId',
        name: 'integrations:details',
        component: IntegrationDetail,
      },
    ],
  },
  {
    path: 'vendor-directory',
    name: 'vendorDirectory:index',
    component: VendorDirectory,
    redirect: { name: 'vendorDirectory:list' },
    children: [
      {
        path: 'vendor-directory',
        name: 'vendorDirectory:list',
        component: VendorDirectoryList,
      },
      {
        path: 'quickbooks',
        name: 'vendorDirectory:quickbooks',
        component: VendorQuickBooks,
      },
      {
        path: 'docusign',
        name: 'vendorDirectory:docusign',
        component: VendorDocuSign,
      },
    ],
  },
]
