export async function getSuggestedContacts ({ dispatch }, { query, filters, include }) {
  const data = {
    query,
    searchParameters: {
      filters,
      include
    }
  }

  const response = await dispatch(
    'bcClassic/gatewayRequest',
    {
      filename: 'more.ajax_suggest',
      method: 'ajaxSuggestContacts',
      data
    }, { root: true }
  )

  return response.data
}

export async function refreshSuggestedContacts ({ dispatch, commit }, spec) {
  const suggestedContacts = await dispatch('getSuggestedContacts', spec)
  commit('suggestedContacts', suggestedContacts)
}

export async function getSuggestedEmails ({ dispatch }, query = '') {
  const data = {
    query
  }

  const response = await dispatch(
    'bcClassic/gatewayRequest',
    {
      filename: 'more.ajax_suggest',
      method: 'ajaxSuggestEmails',
      data
    }, { root: true }
  )

  return response.data
}
