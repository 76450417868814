// NOTE: this is the ONLY file where we allow global registrations
// of components, filters, and directives. Do not disable this rule in any other file.
/* eslint-disable rulesdir/no-global-vue-registration */

import { Validator } from 'vee-validate'
import {
  alphabetIndex,
  ordinalNumber,
  get,
  joinList,
  dateString,
  timeString,
  getYearsDifferenceFromToday, tzDateString, tzTimeString, formatPhoneNumber
} from './filters.js'
import { clickOutside } from './directives'

import ActionsFailureDialog from './ActionsFailureDialog.vue'
import DeleteButton from './DeleteButton.vue'
import MainTopNav from './nav/MainTopNav'
import MainNavSidebar from './nav/MainNavSidebar'
import SubNavSidebar from '@/components/ui/nav/SubNavSidebar'
import SubNavMobile from '@/components/ui/nav/SubNavMobile'
import ActivityNavSidebar from '@/components/ui/nav/ActivityNavSidebar'
import NavigationList from './nav/NavigationList.vue'
import NavigationSection from './nav/NavigationSection.vue'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

import PasswordValidator from '@britecore/bc-design-system/lib/utils/validators/PasswordValidator'
import PhoneNumberValidator from '@britecore/bc-design-system/lib/utils/validators/PhoneNumberValidator'
import NoSpaceValidator from './validators/NoSpaceValidator'
import NoCommaValidator from './validators/NoCommaValidator'

import store from '@/store'

const BriteCoreUI = {
  install (Vue) {
    // Components
    Vue.component('bc-actions-failure-dialog', ActionsFailureDialog)
    Vue.component('bc-delete-button', DeleteButton)
    Vue.component('bc-navigation-list', NavigationList)
    Vue.component('bc-navigation-section', NavigationSection)
    Vue.component('main-top-nav', MainTopNav)
    Vue.component('main-nav-sidebar', MainNavSidebar)
    Vue.component('sub-nav-sidebar', SubNavSidebar)
    Vue.component('sub-nav-mobile', SubNavMobile)
    Vue.component('activity-nav-sidebar', ActivityNavSidebar)
    Vue.component('fa-icon', FontAwesomeIcon)
    Vue.component('fa-layers', FontAwesomeLayers)

    // Filters
    Vue.filter('alphabetIndex', alphabetIndex)
    Vue.filter('ordinalNumber', ordinalNumber)
    Vue.filter('get', get)
    Vue.filter('joinList', joinList)
    Vue.filter('dateString', dateString)
    Vue.filter('timeString', timeString)
    Vue.filter('tzDateString', tzDateString)
    Vue.filter('tzTimeString', tzTimeString)
    Vue.filter('getYearsDifferenceFromToday', getYearsDifferenceFromToday)
    Vue.filter('formatPhoneNumber', formatPhoneNumber)

    // Directives
    Vue.directive('click-outside', clickOutside)
    // Blank test-id directive for non-test environments
    if (process.env.NODE_ENV === 'test') Vue.directive('test-id', (el, { value }) => el.setAttribute('data-test-id', value))
    else Vue.directive('test-id', {})

    // Custom vee-validate validators
    Validator.extend(
      'bc-password-validator',
      new PasswordValidator({
        store,
        getPolicyActionName: 'userManagement/getPasswordPolicy'
      })
    )

    Validator.extend('bc-phone-number', PhoneNumberValidator)

    // Provide generic validators
    Validator.extend('bc-no-space', NoSpaceValidator)
    Validator.extend('bc-no-comma', NoCommaValidator)
  }
}

export default BriteCoreUI
