import moment from 'moment'

export const hasPermission = state => module => {
  return state.permissions && state.permissions[module]
}

export const releaseDate = state => {
  const { timestamp } = state.version
  if (timestamp) {
    const releaseDate = moment.unix(timestamp)
    if (moment().diff(releaseDate, 'days') < 7) {
      return releaseDate.format('dddd (MMM. DD)')
    }
    return releaseDate.format('MMM. DD')
  }
  return null
}

export const shouldRetrieveNavLinksAndVersionFromGen2 = state => {
  return !state.permissions || !state.version.tag
}

export const getContactInfo = state => state.contact

export const allDeliverables = state => {
  const stockDeliverables = state.massDeliverablesTable.stock || []
  const customDeliverables = state.massDeliverablesTable.custom || []
  return [
    ...stockDeliverables,
    ...customDeliverables,
  ]
}
