export default [
  {
    name: 'additional_interest',
    definition: {
      name: '',
      location_data: null,
      additional_interest_type: {
        jsonApi: 'hasOne',
        type: 'additional-interest-type'
      },
      location: {
        jsonApi: 'hasOne',
        type: 'location'
      },
      risk: {
        jsonApi: 'hasOne',
        type: 'risk'
      }
    },
    options: {
      type: 'additional-interest'
    }
  },
  {
    name: 'additional_interest_type',
    definition: {
      name: '',
      label: '',
      external_id: '',
      external_name: ''
    },
    options: {
      type: 'additional-interest-type'
    }
  },
  {
    name: 'attribute',
    definition: {
      choices: null,
      created: null,
      datatype: null,
      description: null,
      effective_from: null,
      effective_to: null,
      external_id: null,
      external_name: null,
      label: null,
      modified: null,
      name: null,
      options: {},
      kwargs: null,
      display_order: null,
      required: false,
      enum_group: {
        jsonApi: 'hasOne',
        type: 'enum-group'
      }
    },
    options: {
      type: 'attribute'
    }
  },
  {
    name: 'additional-interest',
    definition: {
      additional_interest_type: {
        jsonApi: 'hasOne',
        type: 'additional_interest_type'
      },
      location: {
        jsonApi: 'hasOne',
        type: 'location'
      },
      location_data: null,
      name: '',
      risk: {
        jsonApi: 'hasOne',
        type: 'risk'
      }
    },
    options: {
      type: 'additional-interest'
    }
  },
  {
    name: 'additional-interest-type',
    definition: {
      id: null,
      external_id: null,
      external_name: '',
      label: '',
      name: ''
    },
    options: {
      type: 'additional-interest-type'
    }
  },
  {
    name: 'audit-log',
    definition: {
      timestamp: null,
      source: null,
      action: null,
      action_type: null,
      reason: null,
      title: null,
      description: null,
      diff: null,
      serialized_object: null,
      username: null,
      object_id: null,
      content_type: {
        jsonApi: 'hasOne',
        type: 'content-type'
      }
    },
    options: {
      type: 'audit-log'
    }
  },
  {
    name: 'audit-log-category',
    definition: {
      label: null,
      name: null
    },
    options: {
      collectionPath: 'claims/audit-log-categories',
      type: 'audit-log-category'
    }
  },
  {
    name: 'audit-log-action',
    definition: {
      label: null,
      name: null
    },
    options: {
      collectionPath: 'claims/audit-log-actions',
      type: 'audit-log-action'
    }
  },
  {
    name: 'claim',
    definition: {
      id: null,
      additional_insureds: {
        jsonApi: 'hasMany',
        type: 'party'
      },
      claimants: {
        jsonApi: 'hasMany',
        type: 'party'
      },
      created_by: {
        jsonApi: 'hasOne',
        type: 'claim-user'
      },
      current_fnol_step: null,
      custom_number: '',
      date_of_loss: null,
      date_of_loss_timezone: null,
      exposures: {
        jsonApi: 'hasMany',
        type: 'exposure'
      },
      exposures_folder_id: null,
      fields: null,
      latest_policy_revision_id: null,
      loss_category: {
        jsonApi: 'hasOne',
        type: 'loss-category'
      },
      loss_description: '',
      loss_location: {
        jsonApi: 'hasOne',
        type: 'location'
      },
      map_contacts_data: null,
      map_risks_data: null,
      fnol_number: null,
      number: null,
      perils: {
        jsonApi: 'hasMany',
        type: 'peril'
      },
      policy_id: null,
      policy_number: null,
      policy_revision: null,
      policy_revision_id: null,
      policy_risks_count: null,
      policy_type: null,
      primary_adjuster: {
        jsonApi: 'hasOne',
        type: 'claim-assignment'
      },
      assignments: {
        jsonApi: 'hasMany',
        type: 'claim-assignment'
      },
      primary_insured: {
        jsonApi: 'hasOne',
        type: 'party'
      },
      product: {
        jsonApi: 'hasOne',
        type: 'product'
      },
      refresh_policy_date: null,
      reported_by: {
        jsonApi: 'hasOne',
        type: 'party'
      },
      reported_date: null,
      reported_method: {
        jsonApi: 'hasOne',
        type: 'reported-method'
      },
      state: null,
      status: null,
      status_change_reason: {
        jsonApi: 'hasOne',
        type: 'claim-status-change-reason'
      },
      submitted_by: {
        jsonApi: 'hasOne',
        type: 'claim-user'
      },
      tags: {
        jsonApi: 'hasMany',
        type: 'tag'
      },
      total_incurred: null,
      unlisted_risks_count: null,
      update_latest_revision: null
    },
    options: {
      collectionPath: 'claims',
      type: 'claim'
    }
  },
  {
    name: 'claim-assignment',
    definition: {
      claim: {
        jsonApi: 'hasOne',
        type: 'claim'
      },
      date_added: '',
      user: {
        jsonApi: 'hasOne',
        type: 'claim-user'
      },
      role: {
        jsonApi: 'hasOne',
        type: 'claim-user-role'
      }
    },
    options: {
      type: 'claim-assignment'
    }
  },
  {
    name: 'claim-event',
    definition: {
      id: null,
      claim: {
        jsonApi: 'hasOne',
        type: 'claim'
      },
      event_type: {
        jsonApi: 'hasOne',
        type: 'claim-event-type'
      },
      date: null
    },
    options: {
      collectionPath: 'claims/claim-events',
      type: 'claim-event'
    }
  },
  {
    name: 'claim-event-type',
    definition: {
      id: null,
      name: '',
      label: ''
    },
    options: {
      collectionPath: 'claims/claim-event-types',
      type: 'claim-event-type'
    }
  },
  {
    name: 'claim-format',
    definition: {
      id: null,
      format: null
    },
    options: {
      type: 'claim-format'
    }
  },
  {
    name: 'claim-party',
    definition: {
      fields: null,
      party: {
        jsonApi: 'hasOne',
        type: 'party'
      },
      role: {
        jsonApi: 'hasOne',
        type: 'claim-party-role'
      }
    },
    options: {
      type: 'claim-party'
    }
  },
  {
    name: 'claim-party-role',
    definition: {
      id: null,
      name: null,
      label: null,
      can_have_injury: null,
      system_only: null,
      single_party: null,
      enable_add_party: null,
      metadata: null,
      deletable: null,
      fnol_form: null,
      fnol_detail_display: null,
      fnol_list_display: null,
      claim_form: null,
      claim_detail_display: null,
      claim_list_display: null,
      attributes: {
        jsonApi: 'hasMany',
        type: 'attribute'
      },
      party_types: {
        jsonApi: 'hasMany',
        type: 'party-type'
      },
      is_product: null
    },
    options: {
      type: 'claim-party-role'
    }
  },
  {
    name: 'claim-status',
    definition: {
      id: null,
      name: null,
      label: null
    },
    options: {
      collectionPath: 'claims/claim-statuses',
      type: 'claim-status'
    }
  },
  {
    name: 'claim-status-change-reason',
    definition: {
      id: null,
      name: null,
      label: null,
      from_status: null,
      to_status: null,
      reason_represents: null,
      order: null
    },
    options: {
      collectionPath: 'claims/claim-status-change-reasons',
      type: 'claim-status-change-reason'
    }
  },
  {
    name: 'claim-type',
    definition: {
      id: null,
      allow_multiple_perils: false,
      name: '',
      label: '',
      fnol_form: null,
      fnol_detail_display: null,
      fnol_list_display: null,
      claim_form: null,
      claim_detail_display: null,
      claim_list_display: null,
      exposure_detail_display: null,
      attributes: {
        jsonApi: 'hasMany',
        type: 'attribute'
      }
    },
    options: {
      type: 'claim-type'
    }
  },
  {
    name: 'claim-user',
    definition: {
      id: null,
      name: '',
      username: '',
      address: '',
      email: '',
      phone: '',
      settings: {
        jsonApi: 'hasOne',
        type: 'claim-user-settings'
      }
    },
    options: {
      type: 'claim-user'
    }
  },
  {
    name: 'claim-user-role',
    definition: {
      level: {
        jsonApi: 'hasOne',
        type: 'claim-user-role-level'
      },
      can_have_multiple: false,
      access_group: ''
    },
    options: {
      type: 'claim-user-role'
    }
  },
  {
    name: 'claim-user-role-level',
    definition: {
      name: '',
      label: ''
    },
    options: {
      type: 'claim-user-role-level'
    }
  },
  {
    name: 'claim-user-settings',
    definition: {
      id: null,
      claims: null,
      claim_id: null
    },
    options: {
      type: 'claim-user-settings'
    }
  },
  {
    name: 'contact',
    definition: {
      id: null,
      name: '',
      type: '',
      dob: '',
      gender: '',
      dba: '',
      ssn: '',
      fein_tax: '',
      position: '',
      is_approved_vendor: null,
      addresses: null,
      phones: null,
      emails: null
    }
  },
  {
    name: 'coverage',
    definition: {
      id: null,
      expired: false,
      name: '',
      label: '',
      description: '',
      reason_for_manual_coverage: '',
      is_manual: false,
      deductible_amount: null,
      item: {
        jsonApi: 'hasOne',
        type: 'item'
      },
      endorsements: {
        jsonApi: 'hasMany',
        type: 'endorsement'
      },
      limits: {
        jsonApi: 'hasMany',
        type: 'coverage-limit-value'
      }
    },
    options: {
      type: 'coverage'
    }
  },
  {
    name: 'coverage-group',
    definition: {
      id: null,
      name: '',
      label: '',
      type: null
    },
    options: {
      type: 'coverage-group'
    }
  },
  {
    name: 'coverage-limit-value',
    definition: {
      id: null,
      name: '',
      label: '',
      type: null,
      value: null,
      limit: {
        jsonApi: 'hasOne',
        type: 'line-limit'
      }
    },
    options: {
      type: 'coverage-limit-value'
    }
  },
  {
    name: 'coverage-type',
    definition: {
      id: null,
      name: '',
      label: '',
      reserve_categories: {
        jsonApi: 'hasMany',
        type: 'reserve-category'
      },
      items: {
        jsonApi: 'hasMany',
        type: 'item'
      }
    },
    options: {
      type: 'coverage-type'
    }
  },
  {
    name: 'damage',
    definition: {
      description: null,
      fields: {},
      risk: {
        jsonApi: 'hasOne',
        type: 'risk'
      },
      damage_type: {
        jsonApi: 'hasOne',
        type: 'damage-type'
      }
    },
    options: {
      type: 'damage'
    }
  },
  {
    name: 'damage-type',
    definition: {
      name: null,
      label: null,
      name_template: null,
      fnol_form: null,
      fnol_detail_display: null,
      fnol_list_display: null,
      claim_form: null,
      claim_detail_display: null,
      claim_list_display: null,
      attributes: {
        jsonApi: 'hasMany',
        type: 'attribute'
      }
    },
    options: {
      type: 'damage-type'
    }
  },
  {
    name: 'deductible-override-reason',
    definition: {
      id: null,
      name: '',
      label: ''
    },
    options: {
      collectionPath: 'claims/deductible-override-reasons',
      type: 'deductible-override-reason'
    }
  },
  {
    name: 'diagnosis-code',
    definition: {
      code: null,
      description: null,
      order: null,
      treatment: {
        jsonApi: 'hasOne',
        type: 'treatment'
      },
      diagnosis_code_type: {
        jsonApi: 'hasOne',
        type: 'diagnosis-code-type'
      }
    },
    options: {
      type: 'diagnosis-code'
    }
  },
  {
    name: 'diagnosis-code-type',
    definition: {
      code: null,
      name: null,
      label: null,
      order: null,
      default: false,
      diagnosis_codes: {
        jsonApi: 'hasMany',
        type: 'diagnosis-codes'
      }
    },
    options: {
      type: 'diagnosis-code-type'
    }
  },
  {
    name: 'eligible-parties',
    definition: {},
    options: {
      type: 'party'
    }
  },
  {
    name: 'endorsement',
    definition: {
      id: null,
      name: '',
      label: '',
      description: '',
      limits: null,
      item: {
        jsonApi: 'hasOne',
        type: 'item'
      }
    },
    options: {
      type: 'endorsement'
    }
  },
  {
    name: 'enum-group',
    definition: {},
    options: {
      type: 'enum-group'
    }
  },
  {
    name: 'exposure',
    definition: {
      id: null,
      adjuster_start_date: null,
      claim: {
        jsonApi: 'hasOne',
        type: 'claim'
      },
      description: '',
      deductible_applied_status: '',
      deductible_applied_transactions_str: '',
      deductible_override_amount: null,
      deductible_override_reason: {
        jsonApi: 'hasOne',
        type: 'deductible-override-reason'
      },
      display_number: '',
      exposure_name: '',
      folder_id: '',
      full_deductible_applied: null,
      expired: false,
      is_deletable: false,
      is_injury: false,
      claim_number: '',
      number: '',
      summary: null,
      adjuster: {
        jsonApi: 'hasOne',
        type: 'claim-user'
      },
      coverage: {
        jsonApi: 'hasOne',
        type: 'coverage'
      },
      party: {
        jsonApi: 'hasOne',
        type: 'party'
      },
      reserve_category_types: {
        jsonApi: 'hasMany',
        type: 'reserve-category-type'
      },
      risks: {
        jsonApi: 'hasMany',
        type: 'risk'
      },
      status: null,
      status_change_reason: {
        jsonApi: 'hasOne',
        type: 'exposure-status-change-reason'
      },
      tags: {
        jsonApi: 'hasMany',
        type: 'tag'
      },
      // creation
      coverages: {
        jsonApi: 'hasMany',
        type: 'coverage'
      },
      manual_coverages_data: null
    },
    options: {
      collectionPath: 'claims/exposures',
      type: 'exposure'
    }
  },
  {
    name: 'exposure-status',
    definition: {
      id: null,
      name: null,
      label: null
    },
    options: {
      collectionPath: 'claims/exposure-statuses',
      type: 'exposure-status'
    }
  },
  {
    name: 'exposure-status-change-reason',
    definition: {
      id: null,
      name: null,
      label: null,
      from_status: null,
      to_status: null,
      reason_represents: null,
      order: null
    },
    options: {
      collectionPath: 'claims/exposure-status-change-reasons',
      type: 'exposure-status-change-reason'
    }
  },
  {
    name: 'fault-type',
    definition: {
      name: null,
      label: null,
      default_percentage_liable: null,
      editable: true
    },
    options: {
      type: 'fault-type'
    }
  },
  {
    name: 'fault',
    definition: {
      percentage_liable: null,
      date_added: null,
      date_modified: null,
      description: null,
      decision_rationale: null,
      fault_type: {
        jsonApi: 'hasOne',
        type: 'fault-type'
      },
      risk: {
        jsonApi: 'hasOne',
        type: 'risk'
      }
    },
    options: {
      type: 'fault'
    }
  },
  {
    name: 'injury',
    definition: {
      id: null,
      name: null,
      sub_name: null,
      description: null,
      fields: {},
      party: {
        jsonApi: 'hasOne',
        type: 'party'
      },
      injury_nature: {
        jsonApi: 'hasOne',
        type: 'injury-nature'
      },
      settlement: {
        jsonApi: 'hasOne',
        type: 'settlement'
      },
      injury_type: {
        jsonApi: 'hasOne',
        type: 'injury-type'
      },
      treatment: {
        jsonApi: 'hasOne',
        type: 'treatment'
      },
      body_parts: {
        jsonApi: 'hasMany',
        type: 'injury-body-part'
      }
    },
    options: {
      type: 'injury'
    }
  },
  {
    name: 'injury-body-part',
    definition: {
      name: null,
      label: null,
      order: null,
      metadata: null
    },
    options: {
      type: 'injury-body-part'
    }
  },
  {
    name: 'injury-nature',
    definition: {
      name: null,
      order: null,
      label: null
    },
    options: {
      type: 'injury-nature'
    }
  },
  {
    name: 'injury-type',
    definition: {
      name: null,
      label: null,
      name_template: null,
      sub_name_template: null,
      fnol_form: null,
      fnol_detail_display: null,
      fnol_list_display: null,
      claim_form: null,
      claim_detail_display: null,
      claim_list_display: null,
      injury_natures: {
        jsonApi: 'hasMany',
        type: 'injury-nature'
      },
      body_parts: {
        jsonApi: 'hasMany',
        type: 'injury-body-part'
      },
      attributes: {
        jsonApi: 'hasMany',
        type: 'attribute'
      }
    },
    options: {
      type: 'injury-type'
    }
  },
  {
    name: 'insurance-information',
    definition: {
      insurance_carrier: null,
      policy_number: null,
      claim_number: null,
      contact_name: null,
      contact_phone: null,
      un_underinsured: null,
      risk: {
        jsonApi: 'hasOne',
        type: 'risk'
      }
    },
    options: {
      type: 'insurance-information'
    }
  },
  {
    name: 'item',
    definition: {
      id: null,
      name: '',
      label: '',
      external_name: '',
      item_type: null,
      special_type: '',
      coverage_type: {
        jsonApi: 'hasOne',
        type: 'coverage-type'
      },
      coverage_group: {
        jsonApi: 'hasOne',
        type: 'coverage-group'
      },
      risk_type: {
        jsonApi: 'hasOne',
        type: 'risk-type'
      },
      limits: {
        jsonApi: 'hasMany',
        type: 'line-limit'
      },
      limit_groups: {
        jsonApi: 'hasMany',
        type: 'line-limit-group'
      }
    },
    options: {
      type: 'item'
    }
  },
  {
    name: 'limit-consumption',
    definition: {
      limit_name: '',
      limit_type: '',
      exposure_display_number: '',
      amount_sum: null
    },
    options: {
      collectionPath: 'claims/limit-consumptions',
      type: 'limit-consumption'
    }
  },
  {
    name: 'location',
    definition: {
      id: null,
      line1: '',
      line2: '',
      city: '',
      country: '',
      county: '',
      state: '',
      zip: '',
      latitude: '',
      longitude: '',
      full_address: '',
      other_details: null,
      type: null
    },
    options: {
      collectionPath: 'claims/locations',
      type: 'location'
    }
  },
  {
    name: 'line-limit',
    definition: {
      id: null,
      name: '',
      label: '',
      type: null
    },
    options: {
      type: 'line-limit'
    }
  },
  {
    name: 'line-limit-group',
    definition: {
      id: null,
      name: '',
      label: '',
      type: null,
      limits: {
        jsonApi: 'hasMany',
        type: 'line-limit'
      }
    },
    options: {
      type: 'line-limit-group'
    }
  },
  {
    name: 'limit-type',
    definition: {
      id: null,
      name: '',
      label: ''
    },
    options: {
      type: 'limit-type'
    }
  },
  {
    name: 'loss-category',
    definition: {
      id: null,
      name: '',
      label: '',
      order: null,
      is_product: null,
      products: {
        jsonApi: 'hasMany',
        type: 'products'
      }
    },
    options: {
      type: 'loss-category'
    }
  },
  {
    name: 'timezone',
    definition: {
      name: null,
      label: null,
      default: false
    },
    options: {
      collectionPath: 'claims/timezones',
      type: 'timezone'
    }
  },
  {
    name: 'on-site-service',
    definition: {
      id: null,
      claim: {
        jsonApi: 'hasOne',
        type: 'claim'
      },
      service_type: {
        jsonApi: 'hasOne',
        type: 'on-site-service-type'
      },
      report_number: '',
      authority_name: '',
      contact_name: '',
      contact_phone: ''
    },
    options: {
      collectionPath: 'claims/on-site-services',
      type: 'on-site-service'
    }
  },
  {
    name: 'on-site-service-type',
    definition: {
      id: null,
      name: '',
      label: ''
    },
    options: {
      collectionPath: 'claims/on-site-service-types',
      type: 'on-site-service-type'
    }
  },
  {
    name: 'manual-item',
    definition: {
      id: null,
      name: '',
      label: '',
      limits_data: null,
      deductible_amount: null,
      coverage_type: {
        jsonApi: 'hasOne',
        type: 'coverage-type'
      },
      coverage_group: {
        jsonApi: 'hasOne',
        type: 'coverage-group'
      },
      limits: {
        jsonApi: 'hasMany',
        type: 'manual-limit'
      }
    },
    options: {
      type: 'manual-item'
    }
  },
  {
    name: 'manual-limit',
    definition: {
      id: null,
      name: '',
      label: '',
      type: null,
      value: null,
      manual_item: null
    },
    options: {
      type: 'manual-limit'
    }
  },
  {
    name: 'party',
    definition: {
      id: null,
      claim_id: null,
      injury_id: null,
      number: null,
      display_number: null,
      policy_contact: null,
      contact_id: null,
      contact: null,
      party_id: null,
      payee_role: null,
      preferred_phone: null,
      preferred_email: null,
      preferred_address: null,
      preferred_phone_id: null,
      preferred_email_id: null,
      preferred_address_id: null,
      related_party_id: null,
      refused_to_provide_dob: null,
      refused_to_provide_ssn: null,
      refused_to_provide_gender: null,
      related_parties_count: null,
      is_involved: null,
      is_approved_vendor: false,
      fields: null,
      date_added: null,
      date_modified: null,
      injury: {
        jsonApi: 'hasOne',
        type: 'injury'
      },
      party_type: {
        jsonApi: 'hasOne',
        type: 'party-type'
      },
      roles: {
        jsonApi: 'hasMany',
        type: 'claim-party-role'
      },
      related_party_roles: {
        jsonApi: 'hasMany',
        type: 'party-relation-role'
      },
      risk_roles: {
        jsonApi: 'hasMany',
        type: 'risk-party-role'
      },
      eligible_parties: {
        jsonApi: 'hasMany',
        type: 'eligible-parties'
      },
      related_parties: {
        jsonApi: 'hasMany',
        type: 'related-parties'
      },
      related_roles: {
        jsonApi: 'hasMany',
        type: 'related-roles'
      }
    },
    options: {
      type: 'party'
    }
  },
  {
    name: 'party-relation',
    definition: {
      number: null,
      fields: null,
      party: {
        jsonApi: 'hasOne',
        type: 'party'
      },
      role: {
        jsonApi: 'hasOne',
        type: 'party-relation-role'
      },
      related_party: {
        jsonApi: 'hasOne',
        type: 'party'
      }
    },
    options: {
      type: 'party-relation'
    }
  },
  {
    name: 'party-relation-role',
    definition: {
      id: null,
      name: null,
      label: null,
      metadata: null,
      enable_add_party: null,
      party_types: {
        jsonApi: 'hasMany',
        type: 'party-type'
      },
      is_product: null
    },
    options: {
      type: 'party-relation-role'
    }
  },
  {
    name: 'party-risk',
    definition: {
      fields: null,
      party: {
        jsonApi: 'hasOne',
        type: 'party'
      },
      role: {
        jsonApi: 'hasOne',
        type: 'risk-party-role'
      },
      risk: {
        jsonApi: 'hasOne',
        type: 'risk'
      }
    },
    options: {
      collectionPath: 'claims/related-risks',
      type: 'party-risk'
    }
  },
  {
    name: 'party-type',
    definition: {
      name: null,
      label: null,
      fnol_form: null,
      fnol_detail_display: null,
      fnol_list_display: null,
      claim_form: null,
      claim_detail_display: null,
      claim_list_display: null,
      injury_type: {
        jsonApi: 'hasOne',
        type: 'injury-type'
      },
      attributes: {
        jsonApi: 'hasMany',
        type: 'attribute'
      }
    },
    options: {
      type: 'party-type'
    }
  },
  {
    name: 'pay-code',
    definition: {
      id: null,
      name: null,
      label: null
    },
    options: {
      type: 'pay-code'
    }
  },
  {
    name: 'payee',
    definition: {
      id: null,
      name: null,
      dob: null,
      order: null,
      payee_type: null,
      party_id: {
        jsonApi: 'hasOne',
        type: 'party'
      },
      payment_id: null,
      party: {
        jsonApi: 'hasOne',
        type: 'party'
      }
    },
    options: {
      type: 'payee'
    }
  },
  {
    name: 'payor',
    definition: {
      id: null,
      additional_name: '',
      location: {
        jsonApi: 'hasOne',
        type: 'location'
      },
      name: '',
      party: {
        jsonApi: 'hasOne',
        type: 'party'
      }
    },
    options: {
      type: 'payor'
    }
  },
  {
    name: 'payment',
    definition: {
      id: null,
      reserve_category: {
        jsonApi: 'hasOne',
        type: 'reserve-category'
      },
      exposure: {
        jsonApi: 'hasOne',
        type: 'exposure'
      },
      comments: null,
      amount: null,
      date_added: null,
      date_modified: null,
      void_reason: null,
      action: null,
      pay_code: {
        jsonApi: 'hasOne',
        type: 'pay-code'
      },
      payment_method: {
        jsonApi: 'hasOne',
        type: 'payment-method'
      },
      pre_process_status: {
        jsonApi: 'hasOne',
        type: 'pre-process-status'
      },
      payees_data: null,
      location_data: null,
      payees: {
        jsonApi: 'hasMany',
        type: 'payee'
      },
      location: {
        jsonApi: 'hasOne',
        type: 'location'
      },
      due_date: null,
      processed_date: null,
      is_processed: null,
      transaction: {
        jsonApi: 'hasOne',
        type: 'transaction'
      },
      final_payment: null,
      in_payment_of: null,
      from_date: null,
      through_date: null,
      billed_amount: null,
      invoice_number: null,
      invoice_date: null,
      check_number: null,
      check_date: null,
      cleared_date: null,
      payment_type: {
        jsonApi: 'hasOne',
        type: 'payment-type'
      },
      claim_number: null,
      payees_display_on_check_edited: null,
      payees_display_on_check_generated: null,
      deductible_amount_before_adjustments: null,
      deductible_amount_to_pay: null
    },
    options: {
      collectionPath: 'claims/payments',
      type: 'payment'
    }
  },
  {
    name: 'payment-batch',
    definition: {
      id: null,
      payments: null,
      date_added: null,
      payments_count: null,
      number: null,
      amount: null,
      run_by: {
        jsonApi: 'hasOne',
        type: 'claim-user'
      }
    },
    options: {
      type: 'payment-batch'
    }
  },
  {
    name: 'payment-method',
    definition: {
      id: null,
      name: null,
      label: null
    },
    options: {
      type: 'payment-method'
    }
  },
  {
    name: 'payment-type',
    definition: {
      id: null,
      name: null,
      label: null
    },
    options: {
      type: 'payment-type'
    }
  },
  {
    name: 'peril',
    definition: {
      id: null,
      name: '',
      label: '',
      code: '',
      is_product: null,
      products: {
        jsonApi: 'hasMany',
        type: 'products'
      }
    },
    options: {
      collectionPath: 'claims/perils',
      type: 'peril'
    }
  },
  {
    name: 'pre-process-status',
    definition: {
      id: null,
      name: '',
      label: ''
    },
    options: {
      type: 'pre-process-status'
    }
  },
  {
    name: 'product',
    definition: {
      id: null,
      external_id: null,
      external_version_id: null,
      name: '',
      label: '',
      policy_type_name: '',
      children: {
        jsonApi: 'hasMany',
        type: 'product'
      },
      is_line: null,
      parent: {
        jsonApi: 'hasOne',
        type: 'product'
      },
      claim_format: {
        jsonApi: 'hasOne',
        type: 'claim-format'
      },
      configurations: {
        jsonApi: 'hasOne',
        type: 'product-configuration'
      },
      claim_type: {
        jsonApi: 'hasOne',
        type: 'claim-type'
      },
      injury_type: {
        jsonApi: 'hasOne',
        type: 'injury-type'
      },
      risk_types: {
        jsonApi: 'hasMany',
        type: 'risk-type'
      },
      risk_type_groups: {
        jsonApi: 'hasMany',
        type: 'risk-type-group'
      },
      loss_categories: {
        jsonApi: 'hasMany',
        type: 'loss-category'
      },
      loss_categories_all: {
        jsonApi: 'hasMany',
        type: 'loss-category'
      },
      perils: {
        jsonApi: 'hasMany',
        type: 'peril'
      },
      perils_all: {
        jsonApi: 'hasMany',
        type: 'peril'
      },
      coverage_types: {
        jsonApi: 'hasMany',
        type: 'coverage-type'
      },
      claim_party_roles: {
        jsonApi: 'hasMany',
        type: 'claim-party-role'
      },
      claim_party_roles_all: {
        jsonApi: 'hasMany',
        type: 'claim-party-role'
      },
      risk_party_roles: {
        jsonApi: 'hasMany',
        type: 'risk-party-role'
      },
      risk_party_roles_all: {
        jsonApi: 'hasMany',
        type: 'risk-party-role'
      },
      party_relation_roles: {
        jsonApi: 'hasMany',
        type: 'party-relation-role'
      },
      party_relation_roles_all: {
        jsonApi: 'hasMany',
        type: 'party-relation-role'
      },
      tags: {
        jsonApi: 'hasMany',
        type: 'tag'
      },
      tags_all: {
        jsonApi: 'hasMany',
        type: 'tag'
      },
      claim_tags_all: {
        jsonApi: 'hasMany',
        type: 'tag'
      },
      exposure_tags_all: {
        jsonApi: 'hasMany',
        type: 'tag'
      },
      is_forked: null,
      import_in_progress: false,
      effective_from: null,
      effective_to: null,
      date_added: null,
      date_modified: null
    },
    options: {
      type: 'product'
    }
  },
  {
    name: 'product-configuration',
    definition: {
      id: null,
      fnol_config: null,
      policy_config: null,
      type: null
    },
    options: {
      type: 'product-configuration'
    }
  },
  {
    name: 'related-parties',
    definition: {
      party: {
        jsonApi: 'hasOne',
        type: 'party'
      },
      role: {
        jsonApi: 'hasOne',
        type: 'party-relation-role'
      },
      related_party: {
        jsonApi: 'hasOne',
        type: 'party'
      }
    },
    options: {
      type: 'party-relation'
    }
  },
  {
    name: 'related-risks',
    definition: {
      party: {
        jsonApi: 'hasOne',
        type: 'party'
      },
      role: {
        jsonApi: 'hasOne',
        type: 'risk-party-role'
      },
      risk: {
        jsonApi: 'hasOne',
        type: 'risk'
      }
    },
    options: {
      type: 'party-risk'
    }
  },
  {
    name: 'related-roles',
    definition: {
      party: {
        jsonApi: 'hasOne',
        type: 'party'
      },
      role: {
        jsonApi: 'hasOne',
        type: 'claim-party-role'
      }
    },
    options: {
      type: 'claim-party'
    }
  },
  {
    name: 'reported-method',
    definition: {
      id: null,
      name: '',
      label: ''
    },
    options: {
      collectionPath: 'claims/reported-methods',
      type: 'reported-method'
    }
  },
  {
    name: 'recovery',
    definition: {
      id: null,
      additional_name: '',
      amount: null,
      comments: '',
      date_added: null,
      date_modified: null,
      exposure: {
        jsonApi: 'hasOne',
        type: 'exposure'
      },
      location: {
        jsonApi: 'hasOne',
        type: 'location'
      },
      party: {
        jsonApi: 'hasOne',
        type: 'party'
      },
      pay_code: {
        jsonApi: 'hasOne',
        type: 'pay-code'
      },
      payment_method: {
        jsonApi: 'hasOne',
        type: 'payment-method'
      },
      payor: {
        jsonApi: 'hasOne',
        type: 'payor'
      },
      received_date: null,
      reserve_category: {
        jsonApi: 'hasOne',
        type: 'reserve-category'
      }
    },
    options: {
      collectionPath: 'claims/recoveries',
      type: 'recovery'
    }
  },
  {
    name: 'recovery-reserve',
    definition: {
      id: null,
      amount: null,
      comments: '',
      date_added: null,
      date_modified: null,
      entry_by: {
        jsonApi: 'hasOne',
        type: 'claim-user'
      },
      exposure: {
        jsonApi: 'hasOne',
        type: 'exposure'
      },
      location: {
        jsonApi: 'hasOne',
        type: 'location'
      },
      new_amount: null,
      reserve_category: {
        jsonApi: 'hasOne',
        type: 'reserve-category'
      },
      transaction_status: {
        jsonApi: 'hasOne',
        type: 'transaction-status'
      },
      transaction_sub_type: {
        jsonApi: 'hasOne',
        type: 'transaction-sub-type'
      },
      transaction_type: {
        jsonApi: 'hasOne',
        type: 'transaction-type'
      }
    },
    options: {
      collectionPath: 'claims/recovery-reserves',
      type: 'recovery-reserve'
    }
  },
  {
    name: 'reserve',
    definition: {
      id: null,
      amount: null,
      comments: '',
      date_added: null,
      date_modified: null,
      entry_by: {
        jsonApi: 'hasOne',
        type: 'claim-user'
      },
      exposure: {
        jsonApi: 'hasOne',
        type: 'exposure'
      },
      new_amount: null,
      reserve_category: {
        jsonApi: 'hasOne',
        type: 'reserve-category'
      },
      transaction_status: {
        jsonApi: 'hasOne',
        type: 'transaction-status'
      },
      transaction_sub_type: {
        jsonApi: 'hasOne',
        type: 'transaction-sub-type'
      },
      transaction_type: {
        jsonApi: 'hasOne',
        type: 'transaction-type'
      }
    },
    options: {
      collectionPath: 'claims/reserves',
      type: 'reserve'
    }
  },
  {
    name: 'reserve-category-type',
    definition: {
      id: null,
      name: '',
      label: '',
      reserve_categories: {
        jsonApi: 'hasMany',
        type: 'reserve-category'
      },
      summary: null
    },
    options: {
      collectionPath: 'claims/reserve-category-types',
      type: 'reserve-category-type'
    }
  },
  {
    name: 'reserve-category',
    definition: {
      id: null,
      children: null,
      label: '',
      name: '',
      pay_codes: {
        jsonApi: 'hasMany',
        type: 'pay-code'
      },
      recovery: {
        jsonApi: 'hasOne',
        type: 'recovery'
      },
      reserve_category_type: {
        jsonApi: 'hasOne',
        type: 'reserve-category-type'
      },
      statutory_code: {
        jsonApi: 'hasOne',
        type: 'statutory-code'
      },
      summary: null
    },
    options: {
      collectionPath: 'claims/reserve-categories',
      type: 'reserve-category'
    }
  },
  {
    name: 'risk',
    definition: {
      external_id: null,
      expired: false,
      is_first_party_risk: null,
      party_relations_count: 0,
      name: null,
      sub_name: null,
      fields: {},
      additional_interests: {
        jsonApi: 'hasMany',
        type: 'additional-interest'
      },
      claim: {
        jsonApi: 'hasOne',
        type: 'claim'
      },
      exposures: {
        jsonApi: 'hasMany',
        type: 'exposure'
      },
      parent: {
        jsonApi: 'hasOne',
        type: 'risk'
      },
      risk_type: {
        jsonApi: 'hasOne',
        type: 'risk-type'
      },
      party_relations: {
        jsonApi: 'hasMany',
        type: 'party-relation'
      },
      damage: {
        jsonApi: 'hasOne',
        type: 'damage'
      },
      insurance_information: {
        jsonApi: 'hasOne',
        type: 'insurance-information'
      },
      coverages: {
        jsonApi: 'hasMany',
        type: 'coverage'
      },
      parties: {
        jsonApi: 'hasMany',
        type: 'party'
      },
      fault: {
        jsonApi: 'hasOne',
        type: 'fault'
      },
      eligible_parties: {
        jsonApi: 'hasMany',
        type: 'eligible-parties'
      },
      date_added: null,
      date_modified: null
    },
    options: {
      collectionPath: 'claims/risks',
      type: 'risk'
    }
  },
  {
    name: 'risk-party-role-setting',
    definition: {
      is_first_party_risk: null,
      enable_add_party: null
    },
    options: {
      type: 'risk-party-role-setting'
    }
  },
  {
    name: 'risk-party-role',
    definition: {
      id: null,
      fnol_form: null,
      fnol_detail_display: null,
      fnol_list_display: null,
      claim_form: null,
      claim_detail_display: null,
      claim_list_display: null,
      name: null,
      label: null,
      can_have_injury: null,
      single_party: null,
      enable_add_party: null,
      metadata: null,
      deletable: null,
      party_types: {
        jsonApi: 'hasMany',
        type: 'party-type'
      },
      settings: {
        jsonApi: 'hasMany',
        type: 'risk-party-role-setting'
      },
      is_product: null
    },
    options: {
      type: 'risk-party-role'
    }
  },
  {
    name: 'risk-type',
    definition: {
      name: null,
      label: null,
      name_template: null,
      sub_name_template: null,
      fnol_form: null,
      fnol_detail_display: null,
      fnol_list_display: null,
      claim_form: null,
      claim_detail_display: null,
      claim_list_display: null,
      external_name: null,
      damage_type: {
        jsonApi: 'hasOne',
        type: 'damage-type'
      },
      risk_type_group: {
        jsonApi: 'hasOne',
        type: 'risk-type-group'
      },
      attributes: {
        jsonApi: 'hasMany',
        type: 'attribute'
      },
      items: {
        jsonApi: 'hasMany',
        type: 'item'
      },
      possible_roles: {
        jsonApi: 'hasMany',
        type: 'possible-roles'
      }
    },
    options: {
      collectionPath: 'claims/risk-types',
      type: 'risk-type'
    }
  },
  {
    name: 'risk-type-group',
    definition: {
      name: null,
      label: null,
      group_type: null,
      order: null,
      fnol_form: null,
      fnol_detail_display: null,
      fnol_list_display: null,
      claim_form: null,
      claim_detail_display: null,
      claim_list_display: null,
      allow_multiple_policy_risks: null,
      allow_multiple_unlisted_risks: null,
      products: {
        jsonApi: 'hasMany',
        type: 'products'
      },
      risk_types: {
        jsonApi: 'hasMany',
        type: 'risk-type'
      }
    },
    options: {
      type: 'risk-type-group'
    }
  },
  {
    name: 'statutory-code',
    definition: {
      id: null,
      name: '',
      label: ''
    },
    options: {
      type: 'statutory-code'
    }
  },
  {
    name: 'summary-claimant',
    definition: {
      id: null,
      contact: null,
      display_number: '',
      exposures: {
        jsonApi: 'hasMany',
        type: 'exposure'
      },
      number: '',
      reserve_category_types: {
        jsonApi: 'hasMany',
        type: 'reserve-category-types'
      },
      summary: null
    },
    options: {
      collectionPath: 'claims/summary-claimants',
      type: 'summary-claimant'
    }
  },
  {
    name: 'summary-recovery',
    // mirrors reserve-category-type
    definition: {
      id: null,
      name: '',
      label: '',
      reserve_categories: {
        jsonApi: 'hasMany',
        type: 'reserve-category'
      },
      summary: null
    },
    options: {
      collectionPath: 'claims/summary-recoveries',
      type: 'summary-recovery'
    }
  },
  {
    name: 'summary-reserve',
    // mirrors reserve-category-type
    definition: {
      id: null,
      name: '',
      label: '',
      reserve_categories: {
        jsonApi: 'hasMany',
        type: 'reserve-category'
      },
      summary: null
    },
    options: {
      collectionPath: 'claims/summary-reserves',
      type: 'summary-reserve'
    }
  },
  {
    name: 'settlement',
    definition: {
      range_from: null,
      range_to: null,
      evaluation_summary: null,
      injury: {
        jsonApi: 'hasOne',
        type: 'injury'
      }
    },
    options: {
      type: 'settlement'
    }
  },
  {
    name: 'tag',
    definition: {
      name: null,
      label: null,
      rule_description: null,
      rule_link: null,
      is_system: null,
      metadata: {},
      claim: {
        jsonApi: 'hasOne',
        type: 'claim'
      },
      tag_level: {
        jsonApi: 'hasOne',
        type: 'tag-level'
      },
      is_product: null,
      products: {
        jsonApi: 'hasMany',
        type: 'products'
      }
    },
    options: {
      type: 'tag'
    }
  },
  {
    name: 'tag-level',
    definition: {
      name: null,
      label: null
    },
    options: {
      type: 'tag-level'
    }
  },
  {
    name: 'treatment',
    definition: {
      diagnosis: null,
      prognosis: null,
      treatment_plan: null,
      injury: {
        jsonApi: 'hasOne',
        type: 'injury'
      },
      diagnosis_codes: {
        jsonApi: 'hasMany',
        type: 'diagnosis-codes'
      }
    },
    options: {
      type: 'treatment'
    }
  },
  {
    name: 'transaction',
    definition: {
      id: null,
      amount: null,
      claim_number: null,
      comments: null,
      date_added: null,
      date_modified: null,
      expired: null,
      entry_by: {
        jsonApi: 'hasOne',
        type: 'claim-user'
      },
      exposure: {
        jsonApi: 'hasOne',
        type: 'exposure'
      },
      number: null,
      payment: {
        jsonApi: 'hasOne',
        type: 'payment'
      },
      posted_date: null,
      recovery: {
        jsonApi: 'hasOne',
        type: 'recovery'
      },
      reserve_category: {
        jsonApi: 'hasOne',
        type: 'reserve-category'
      },
      transaction_status: '',
      transaction_sub_type: {
        jsonApi: 'hasOne',
        type: 'transaction-sub-type'
      },
      transaction_type: {
        jsonApi: 'hasOne',
        type: 'transaction-type'
      }
    },
    options: {
      collectionPath: 'claims/transactions',
      type: 'transaction'
    }
  },
  {
    name: 'transaction-status',
    definition: {
      id: null,
      name: '',
      label: ''
    },
    options: {
      type: 'transaction-status'
    }
  },
  {
    name: 'transaction-sub-type',
    definition: {
      id: null,
      name: '',
      label: ''
    },
    options: {
      type: 'transaction-sub-type'
    }
  },
  {
    name: 'transaction-type',
    definition: {
      id: null,
      name: '',
      label: ''
    },
    options: {
      collectionPath: 'claims/transaction-types',
      type: 'transaction-type'
    }
  },
  {
    name: 'zone',
    definition: {
      id: null,
      label: '',
      zone: ''
    },
    options: {
      type: 'state'
    }
  },
  {
    name: 'state',
    definition: {
      id: null,
      state: '',
      zones: {
        jsonApi: 'hasMany',
        type: 'zone'
      }
    },
    options: {
      type: 'state'
    }
  },
  {
    name: 'import-historical-claim',
    definition: {
      id: null,
      in_progress: null,
      exception: null
    }
  }
]
