import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL

const productMappingService = {
  /**
   * Returns the product difference from previous version.
   * @param productName
   * @param versionId
   * @param includeDescendants
   * @returns {Promise<Array>} Promise object that receives differences as an object
   */
  getProductDifference: (productName, versionId, includeDescendants) => {
    const url = `${API_URL}/lines/products/${productName}/differences/`
    const params = { version_id: versionId }
    if (includeDescendants) {
      params.include_descendants = true
    }
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Returns the product mapping.
   * @param productName
   * @param versionId
   * @returns {Promise<Array>} Promise object that receives mapping as an object
   */
  getProductMapping: (productName, versionId) => {
    const url = `${API_URL}/lines/product-mappings/${productName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Updates the product mapping.
   * @param productName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Promise object that receives updated mapping as an object
   */
  updateProductMapping: (productName, versionId, formData) => {
    const url = `${API_URL}/lines/product-mappings/${productName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.put(url, formData, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  }
}

export default productMappingService
