import {JsonApiService} from './api'

class PartyApi extends JsonApiService {
  constructor () {
    super('party')
  }
}

class ClaimPartyApi extends JsonApiService {
  constructor () {
    super('claim-party')
  }
}

class ClaimPartyRoleApi extends JsonApiService {
  constructor () {
    super('claim-party-role')
  }
}

class RelatedRiskApi extends JsonApiService {
  constructor () {
    super('party-risk')
  }
}

export {ClaimPartyApi, ClaimPartyRoleApi, PartyApi, RelatedRiskApi}
