import axios from 'axios'
import { Message } from '@britecore/bc-design-system'
import { get } from 'lodash'
import services from '@/api/services'

const integrationsUrl = process.env.VUE_APP_INTEGRATIONS_URL

export function getBlankPlugin (
  name = '',
  slotName = '',
  baseUrl = '',
  path = '',
  content = '',
  configuration
) {
  return {
    attributes: {
      name,
      plugin_slots: [{ slot_name: slotName, plugin_file_path: path }],
      base_url: baseUrl,
      configuration,
      content,
    },
    type: 'plugin',
  }
}

export const actions = {
  async getSubscriptions ({ commit }) {
    commit('setEventsLoading', true)
    try {
      const subscriptions = (await axios.get('/api/events/subscriptions')).data
        .data
      commit('setSubscriptions', subscriptions)
    } catch (err) {
      Message.error(
        'An error occurred while fetching subscriptions. Please refresh the page and try again.'
      )
    }
    commit('setEventsLoading', false)
  },
  async getSubscriptionMessages ({ commit }, subscriptionID) {
    commit('setSubscriptionMessages', {
      subscriptionID,
      value: { loading: true },
    })
    try {
      const data = (
        await axios.get(`/api/events/subscriptions/${subscriptionID}/messages`)
      ).data.data
      commit('setSubscriptionMessages', {
        subscriptionID,
        value: { loading: false, data },
      })
    } catch (err) {
      commit('setSubscriptionMessages', {
        subscriptionID,
        value: { loading: false },
      })
      Message.error(
        'An error occurred while fetching messages. Please try again in some time.'
      )
    }
  },
  async createSubscription ({ commit }, { events, url }) {
    commit('setEventsLoading', true)
    const data = { attributes: { events, url }, type: 'subscription' }
    try {
      await axios.post(
        '/api/events/subscriptions',
        { data },
        { headers: { 'Content-Type': 'application/vnd.api+json' } }
      )
      Message.success({
        message:
          'Subscription creation started. It might take a few minutes for it to appear in the subscriptions list.',
        duration: 15 * 1000,
        showClose: true,
      })
    } catch (err) {
      Message.error(
        get(
          err,
          'response.data.errors[0].detail',
          'An error occurred while creating subscription. Please try again.'
        )
      )
    }
    commit('setEventsLoading', false)
  },
  async deleteSubscription ({ commit }, subscriptionID) {
    commit('setEventsLoading', true)
    try {
      await axios.delete(`/api/events/subscriptions/${subscriptionID}`)
      commit('removeSubscription', subscriptionID)
      Message.success('Subscription deleted.')
    } catch (err) {
      Message.error(
        get(
          err,
          'response.data.errors[0].detail',
          'An error occurred while deleting the subscription. Please try again.'
        )
      )
    }
    commit('setEventsLoading', false)
  },
  async getPlugins ({ commit }) {
    commit('setPluginsLoading', true)
    try {
      const plugins = (await axios.get(`${integrationsUrl}/plugins/`)).data.data
      commit('setPlugins', plugins)
    } catch (err) {
      Message.error(
        'An error occurred while fetching the plugins. Please refresh the page and try again.'
      )
    }
    commit('setPluginsLoading', false)
  },
  async createPlugin ({ commit }, form) {
    commit('setPluginsLoading', true)
    const {
      pluginName,
      slotName,
      baseUrl,
      pluginPath,
      configuration,
      content,
    } = form
    let _config = configuration || '{}'

    const data = getBlankPlugin(
      pluginName,
      slotName,
      baseUrl,
      pluginPath,
      content,
      JSON.parse(_config)
    )
    if (content) {
      delete data['attributes']['base_url']
      delete data['attributes']['plugin_slots'][0].plugin_file_path
      data['attributes']['content'] = content
    } else {
      delete data['attributes']['content']
    }
    try {
      var plugin = await axios.post(
        `${integrationsUrl}/plugins/`,
        { data },
        { headers: { 'Content-Type': 'application/vnd.api+json' } }
      )
      Message.success({
        message: 'Plugin registered',
        duration: 5 * 1000,
        showClose: true,
      })
      commit('addPlugin', plugin.data.data)
    } catch (err) {
      Message.error(
        get(
          err,
          'response.data.errors[0].detail',
          'An error occurred while registering the plugin. Please try again.'
        )
      )
    }
    commit('setPluginsLoading', false)
  },
  async deletePlugin ({ commit }, pluginId) {
    commit('setPluginsLoading', true)
    try {
      await axios.delete(`${integrationsUrl}/plugins/${pluginId}`)
      commit('removePlugin', pluginId)
      Message.success('Plugin removed.')
    } catch (err) {
      Message.error(
        get(
          err,
          'response.data.errors[0].detail',
          'An error occurred while removing the plugin. Please try again.'
        )
      )
    }
    commit('setPluginsLoading', false)
  },
  async updatePlugin ({ commit }, { pluginId, attributes }) {
    commit('setPluginsLoading', true)
    const {
      pluginName,
      slotName,
      baseUrl,
      pluginPath,
      configuration,
      content,
    } = attributes
    const data = getBlankPlugin(
      pluginName,
      slotName,
      baseUrl,
      pluginPath,
      content,
      JSON.parse(configuration)
    )
    if (content) {
      delete data['attributes']['base_url']
      delete data['attributes']['plugin_slots'][0].plugin_file_path
      data['attributes']['content'] = content
    } else {
      delete data['attributes']['content']
    }
    try {
      var _plugin = await axios.patch(
        `${integrationsUrl}/plugins/${pluginId}`,
        { data },
        { headers: { 'Content-Type': 'application/vnd.api+json' } }
      )
      Message.success({
        message: 'Plugin updated',
        duration: 5 * 1000,
        showClose: true,
      })
      commit('updatePlugin', _plugin.data.data)
    } catch (err) {
      Message.error(
        get(
          err,
          'response.data.errors[0].detail',
          'An error occurred while registering the plugin. Please try again.'
        )
      )
    }
    commit('setPluginsLoading', false)
  },
  async getMappings ({ commit }) {
    commit('setMappingsLoading', true)
    try {
      const mappings = (await axios.get(`/api/datamapping/mappings/`)).data.data
      commit('setMappings', mappings)
    } catch (err) {
      Message.error(
        'An error occurred while fetching the Data Mappings. Please try again.'
      )
    }
    commit('setMappingsLoading', false)
  },
  async getMapping ({ commit }, mappingId) {
    commit('setMappingsLoading', true)
    try {
      const mapping = (
        await axios.get(`/api/datamapping/mappings/${mappingId}`)
      ).data.data
      commit('setEditedMapping', mapping)
    } catch (err) {
      Message.error(
        `An error occurred while fetching the Data Mapping - ${mappingId}. Please refresh the page and try again.`
      )
    }
    commit('setMappingsLoading', false)
  },
  async createMapping ({ commit }, form) {
    commit('setMappingsLoading', true)
    const data = {
      type: 'mapping',
      attributes: {
        from: form.mapFrom,
        to: form.mapTo,
        version: form.version,
        mapping: JSON.parse(form.mapping),
      },
    }
    try {
      let mapping = await axios.post(
        `/api/datamapping/mappings/`,
        { data },
        { headers: { 'Content-Type': 'application/vnd.api+json' } }
      )
      Message.success({
        message: 'Mapping created',
        duration: 5 * 1000,
        showClose: true,
      })
      commit('addMapping', mapping.data.data)
    } catch (err) {
      Message.error(
        get(
          err,
          'response.data.errors[0].detail',
          'An error occurred while registering the Data Mapping. Please try again.'
        )
      )
    }
    commit('setMappingsLoading', false)
  },
  async updateMapping ({ commit }, form) {
    commit('setMappingsLoading', true)
    const data = {
      type: 'mapping',
      attributes: {
        from: form.mapFrom,
        to: form.mapTo,
        version: form.version,
        mapping: JSON.parse(form.mapping),
      },
    }
    try {
      let mapping = await axios.patch(
        `/api/datamapping/mappings/${form.id}`,
        { data },
        { headers: { 'Content-Type': 'application/vnd.api+json' } }
      )
      Message.success({
        message: 'Data Mapping updated',
        duration: 5 * 1000,
        showClose: true,
      })
      commit('updateMapping', mapping.data.data)
    } catch (err) {
      Message.error(
        get(
          err,
          'response.data.errors[0].detail',
          'An error occurred while updating the Data Mapping. Please try again.'
        )
      )
    }
    commit('setMappingsLoading', false)
  },
  async deleteMapping ({ commit }, mappingId) {
    commit('setMappingsLoading', true)
    try {
      await axios.delete(`/api/datamapping/mappings/${mappingId}`)
      commit('removeMapping', mappingId)
      Message.success('Data Mapping removed.')
    } catch (err) {
      Message.error(
        get(
          err,
          'response.data.errors[0].detail',
          'An error occurred while removing the Data Mapping. Please try again.'
        )
      )
    }
    commit('setMappingsLoading', false)
  },
  async getIntegrations ({ commit }) {
    commit('setIntegrationsLoading', true)
    try {
      const integrations = await services.integrations.findAll('integration')
      commit('setIntegrations', integrations.data)
    } catch (err) {
      Message.error(
        'An error occurred while fetching integrations. Please refresh the page and try again.'
      )
    }
    commit('setIntegrationsLoading', false)
  },
  async getIntegration ({ commit }, integrationId) {
    commit('setIntegrationsLoading', true)
    try {
      const integration = await services.integrations.find(
        'integration',
        integrationId
      )
      commit('setCurrentIntegration', integration.data)
    } catch (err) {
      Message.error(
        'An error occurred while fetching integration. Please refresh the page and try again.'
      )
    }
    commit('setIntegrationsLoading', false)
  },
  async deleteIntegration ({ commit }, integrationId) {
    commit('setIntegrationsLoading', true)
    try {
      await services.integrations.destroy('integration', integrationId)
      commit('removeIntegration', integrationId)
    } catch (err) {
      throw err
    } finally {
      commit('setIntegrationsLoading', false)
    }
  },
  async createIntegration ({ commit }, form) {
    commit('setIntegrationsLoading', true)
    try {
      await services.integrations.create('integration', form)
    } catch (err) {
      throw err
    } finally {
      commit('setIntegrationsLoading', false)
    }
  },
  async getQuickBooksUrl ({ commit }) {
    commit('setVendorsLoading', true)
    try {
      let QuickBooksUrl = (await axios.get(
        `${integrationsUrl}/accounting/quickbooks/oauth/auth`,
        {headers: {'Content-Type': 'application/vnd.api+json'}}
      )).data.links.authorization_url
      commit('setVendorAuthUrl', QuickBooksUrl)
    } catch (err) {
      Message.error(
        get(
          err,
          'response.data.errors[0].detail',
          'An error occurred while connecting to vendor. Please try again.'
        )
      )
    } finally {
      commit('setVendorsLoading', false)
    }
  },
  async deleteQuickBooksAuth ({ commit }) {
    commit('setVendorsLoading', true)
    try {
      await axios.delete(
        `${integrationsUrl}/accounting/quickbooks/oauth/auth`,
        {headers: {'Content-Type': 'application/vnd.api+json'}}
      )
    } catch (err) {
      Message.error(
        get(
          err,
          'response.data.errors[0].detail',
          'An error occurred while disconnecting from vendor. Please try again.'
        )
      )
    } finally {
      commit('setVendorsLoading', false)
    }
  },
  async getEsignatureConfig ({ commit }) {
    try {
      const response = await axios.get(
        `${integrationsUrl}/esignature/config/`,
      )
      commit('setEsignatureConfig', response.data.data.attributes)
    } catch (err) {
      Message.error('Failed to retrieve E-Signature config, please try again later.')
    }
  },
  async storeEsignatureConfig ({ commit }, config) {
    try {
      const response = await axios.patch(
        `${integrationsUrl}/esignature/config/`,
        {
          data: {
            type: 'config',
            attributes: config
          }
        },
        {headers: {'Content-Type': 'application/vnd.api+json'}}
      )
      commit('setEsignatureConfig', response.data.data.attributes)
      Message.success('Saved electronic signature settings successfully.')
    } catch (err) {
      Message.error('Failed to store E-Signature config, please try again later.')
    }
  },
  async getDocuSignUrl ({ commit }) {
    commit('setVendorsLoading', true)
    try {
      let docusignUrl = (await axios.get(
        `${integrationsUrl}/esignature/oauth/url`,
        {headers: {'Content-Type': 'application/vnd.api+json'}}
      )).data.links.authorization_url
      commit('setVendorAuthUrl', docusignUrl)
    } catch (err) {
      Message.error(
        get(
          err,
          'response.data.errors[0].detail',
          'An error occurred while setting up DocuSign. Please try again.'
        )
      )
    } finally {
      commit('setVendorsLoading', false)
    }
  },
  async deleteDocuSignAuth ({ commit }) {
    commit('setVendorsLoading', true)
    try {
      await axios.delete(
        `${integrationsUrl}/esignature/oauth`,
        {headers: {'Content-Type': 'application/vnd.api+json'}}
      )
    } catch (err) {
      Message.error(
        get(
          err,
          'response.data.errors[0].detail',
          'An error occurred while disconnecting from DocuSign. Please try again.'
        )
      )
    } finally {
      commit('setVendorsLoading', false)
    }
  },
}
