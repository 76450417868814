import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL

const linesService = {
  /**
   * Returns the Risk Type rating context.
   * @param productName
   * @param versionId
   * @param riskTypeName
   * @param riskItemId
   * @returns {Promise<Array>} Promise object that receives an array of references
   */
  getRiskTypeRatingContext: (productName, versionId, riskTypeName, riskItemId, riskFieldId) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/rating-context/`
    const params = {
      version_id: versionId,
      risk_item_id: riskItemId,
      risk_field_id: riskFieldId
    }
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params }).then(response => {
        resolve(response.data.references)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Returns the Risk Types for a given product
   * @param productName
   * @param versionId
   * @returns {Promise<Object>} Promise object that receives a list of Risk Types for a product
   */
  getRiskTypesForProduct: (productName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Returns the id of copied Risk Type
   * @param productName
   * @param versionId
   * @param riskTypeName
   * @param label
   * @param name
   * @returns {Promise<Object>} Promise object that receives the id of copied Risk Type
   */
  copyRiskType: (productName, versionId, riskTypeName, label, name) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/copy/`
    const data = {
      label: label,
      name: name
    }
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, data, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Returns the risk field serialized data
   * @param productName
   * @param riskTypeName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Promise object that receives the form data to create new risk field
   */
  createRiskField: (productName, riskTypeName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-fields/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, formData, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Returns all risk fields serialized data
   * @param productName
   * @param riskTypeName
   * @param versionId
   * @param options
   * @returns {Promise<Object>} Promise object to get risk field
   */
  getAllRiskFields: (productName, riskTypeName, versionId, options) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-fields/`
    const params = { version_id: versionId, options: options }
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params }).then(response => {
        resolve(response.data.results)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Returns the risk field serialized data
   * @param productName
   * @param riskTypeName
   * @param riskFieldName
   * @param versionId
   * @returns {Promise<Object>} Promise object to get risk field
   */
  getRiskField: (productName, riskTypeName, riskFieldName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-fields/${riskFieldName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Returns the risk field serialized data
   * @param riskFieldName
   * @param productName
   * @param riskTypeName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Promise object that receives the risk field ID and updated data for risk field
   */
  updateRiskField: (riskFieldName, productName, riskTypeName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-fields/${riskFieldName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.put(url, formData, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Reorders a risk field
   * @param riskFieldName
   * @param productName
   * @param riskTypeName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Promise object that receives the risk field ID and updated data for risk field
   */
  reorderRiskField: (riskFieldName, productName, riskTypeName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-fields/${riskFieldName}/reorder/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.patch(url, formData, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Restores a risk field
   * @param productName
   * @param riskTypeName
   * @param riskFieldName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Promise object that receives the risk field ID and restored data for risk field
   */
  restoreRiskField: (productName, riskTypeName, riskFieldName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-fields/${riskFieldName}/restore/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, formData, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Deletes RiskField.
   * @param riskFieldName
   * @param productName
   * @param riskTypeName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Delete request promise.
   */
  deleteRiskField: (riskFieldName, productName, riskTypeName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-fields/${riskFieldName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.delete(url, { data: formData, params: params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Creates Risk Type.
   * @param productName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Create request promise.
   */
  createRiskType: (productName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, formData, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Updates Risk Type.
   * @param productName
   * @param riskTypeName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Update request promise.
   */
  updateRiskType: (productName, riskTypeName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.patch(url, formData, { params: params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Deletes Risk Type.
   * @param productName
   * @param riskTypeName
   * @param versionId
   * @returns {Promise<Object>} Delete request promise.
   */
  deleteRiskType: (productName, riskTypeName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.delete(url, { params: params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Returns the risk name template references for autocomplete.
   * @param riskTypeName
   * @param productName
   * @param versionId
   * @returns {Promise<Array>} Promise object that receives an array of references
   */
  getRiskNameTemplateReferences: (riskTypeName, productName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-name-template-references/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params }).then(response => {
        resolve(response.data.references)
      })
    })
  },

  /**
   * Returns all risk items serialized data
   * @param productName
   * @param riskTypeName
   * @param versionId
   * @returns {Promise<Object>} Promise object to get risk item
   */
  getAllRiskItems: (productName, riskTypeName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-items/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params }).then(response => {
        resolve(response.data.results)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Returns the risk item serialized data
   * @param productName
   * @param riskTypeName
   * @param riskItemName
   * @param versionId
   * @returns {Promise<Object>} Promise object to get risk item
   */
  getRiskItem: (productName, riskTypeName, riskItemName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-items/${riskItemName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Reorders Risk Item.
   * @param riskItemName
   * @param productName
   * @param riskTypeName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Re-order request promise.
   */
  reorderRiskItem: (riskItemName, productName, riskTypeName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-items/${riskItemName}/reorder/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.patch(url, formData, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Restores Risk Item.
   * @param productName
   * @param riskTypeName
   * @param riskItemName
   * @param versionId
   * @returns {Promise<Object>} Restore request promise.
   */
  restoreRiskItem: (productName, riskTypeName, riskItemName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-items/${riskItemName}/restore/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, {}, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Deletes a RiskItem.
   * @param riskItemName
   * @param productName
   * @param riskTypeName
   * @param versionId
   * @returns {Promise<Object>} Delete request promise.
   */
  deleteRiskItem: (riskItemName, productName, riskTypeName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-items/${riskItemName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.delete(url, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Creates a Risk Item.
   * @param productName
   * @param riskTypeName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Create request promise.
   */
  createRiskItem: (productName, riskTypeName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-items/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, formData, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Updates a Risk Item.
   * @param riskItemName
   * @param productName
   * @param riskTypeName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Update request promise.
   */
  updateRiskItem: (riskItemName, productName, riskTypeName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-items/${riskItemName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.put(url, formData, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Creates Rate Table.
   * @param productName
   * @param riskTypeName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Create request promise.
   */
  createRateTable: (productName, riskTypeName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/rate-tables/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, formData, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Returns the rate table serialized data
   * @param productName
   * @param riskTypeName
   * @param rateTableName
   * @param versionId
   * @returns {Promise<Object>} Promise object to get rate table
   */
  getRateTable: (productName, riskTypeName, rateTableName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/rate-tables/${rateTableName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Updates Rate Table.
   * @param rateTableName
   * @param productName
   * @param riskTypeName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Update request promise.
   */
  updateRateTable: (rateTableName, productName, riskTypeName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/rate-tables/${rateTableName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.put(url, formData, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Reorders a rate table
   * @param rateTableName
   * @param productName
   * @param riskTypeName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Promise object that receives the rate table ID and updated data for rate table
   */
  reorderRateTable: (rateTableName, productName, riskTypeName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/rate-tables/${rateTableName}/reorder/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.patch(url, formData, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Restore a rate table
   * @param productName
   * @param riskTypeName
   * @param rateTableName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Promise object that receives the rate table ID and restored data for rate table
   */
  restoreRateTable: (productName, riskTypeName, rateTableName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/rate-tables/${rateTableName}/restore/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, formData, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Deletes Rate Table.
   * @param rateTableName
   * @param productName
   * @param riskTypeName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Delete request promise.
   */
  deleteRateTable: (rateTableName, productName, riskTypeName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/rate-tables/${rateTableName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.delete(url, { data: formData, params: params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Creates a Product
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Promise object that receives the newly created product
   */
  createProduct: (versionId, formData) => {
    const url = `${API_URL}/lines/products/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, formData, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Updates a Product
   * @param productName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Update request promise.
   */
  updateProduct: (productName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.patch(url, formData, { params: params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Deletes a Product
   * @param productName
   * @param versionId
   * @returns {Promise<Object>} Delete request promise.
   */
  deleteProduct: (productName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.delete(url, { params: params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Creates Limit.
   * @param productName
   * @param riskTypeName
   * @param riskItemName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Create request promise.
   */
  createLimit: (productName, riskTypeName, riskItemName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-items/${riskItemName}/limits/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, formData, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Get Limit details.
   * @param productName
   * @param riskTypeName
   * @param riskItemName
   * @param limitName
   * @param versionId
   * @returns {Promise<Object>} Details request promise.
   */
  getLimit: (productName, riskTypeName, riskItemName, limitName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-items/${riskItemName}/limits/${limitName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Updates Limit.
   * @param productName
   * @param riskTypeName
   * @param riskItemName
   * @param limitName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Update request promise.
   */
  updateLimit: (productName, riskTypeName, riskItemName, limitName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-items/${riskItemName}/limits/${limitName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.put(url, formData, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Deletes Limit.
   * @param productName
   * @param riskTypeName
   * @param riskItemName
   * @param limitName
   * @param versionId
   * @returns {Promise<Object>} Delete request promise.
   */
  deleteLimit: (productName, riskTypeName, riskItemName, limitName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-items/${riskItemName}/limits/${limitName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.delete(url, { params: params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Returns the calculation serialized data
   * @param productName
   * @param riskTypeName
   * @param calculationName
   * @param versionId
   * @returns {Promise<Object>} Promise object to get calculation
   */
  getCalculation: (productName, riskTypeName, calculationName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/calculations/${calculationName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Re-orders a Calculation.
   * @param productName
   * @param riskTypeName
   * @param calculationName
   * @param versionId
   * @param order
   * @returns {Promise<Object>} Re-order request promise.
   */
  reorderCalculation: (productName, riskTypeName, calculationName, versionId, order) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/calculations/${calculationName}/reorder/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.patch(url, { order }, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Restore a Calculation.
   * @param productName
   * @param riskTypeName
   * @param calculationName
   * @param versionId
   * @returns {Promise<Object>} Restore request promise.
   */
  restoreCalculation: (productName, riskTypeName, calculationName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/calculations/${calculationName}/restore/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, {}, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Returns the item calculation serialized data
   * @param productName
   * @param riskTypeName
   * @param riskItemName
   * @param calculationName
   * @param versionId
   * @returns {Promise<Object>} Promise object to get calculation
   */
  getItemCalculation: (productName, riskTypeName, riskItemName, calculationName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-items/${riskItemName}/calculations/${calculationName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Re-orders an Item Calculation.
   * @param productName
   * @param riskTypeName
   * @param riskItemName
   * @param calculationName
   * @param versionId
   * @param order
   * @returns {Promise<Object>} Re-order request promise.
   */
  reorderItemCalculation: (productName, riskTypeName, riskItemName, calculationName, versionId, order) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-items/${riskItemName}/calculations/${calculationName}/reorder/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.patch(url, { order }, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Restores an Item Calculation.
   * @param productName
   * @param riskTypeName
   * @param riskItemName
   * @param calculationName
   * @param versionId
   * @returns {Promise<Object>} Restore request promise.
   */
  restoreItemCalculation: (productName, riskTypeName, riskItemName, calculationName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-items/${riskItemName}/calculations/${calculationName}/restore/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, {}, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Deletes Calculation.
   * @param calculationName
   * @param productName
   * @param riskTypeName
   * @param versionId
   * @returns {Promise<Object>} Delete request promise.
   */
  deleteCalculation: (calculationName, productName, riskTypeName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/calculations/${calculationName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.delete(url, { params: params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Deletes Item Calculation.
   * @param calculationName
   * @param itemName
   * @param productName
   * @param riskTypeName
   * @param versionId
   * @returns {Promise<Object>} Delete request promise.
   */
  deleteItemCalculation: (calculationName, itemName, productName, riskTypeName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/risk-items/${itemName}/calculations/${calculationName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.delete(url, { params: params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Returns the line serialized data
   * @param formData
   * @returns {Promise<Object>} Promise object that receives the form data to create new line
   */
  createLine: (formData) => {
    const url = `${API_URL}/lines/lines/`
    const data = formData
    return new Promise((resolve, reject) => {
      axios.post(url, data).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Updates a line
   * @param lineName
   * @param formData
   * @returns {Promise<Object>} update request promise
   */
  updateLine: (lineName, formData) => {
    const url = `${API_URL}/lines/lines/${lineName}/`
    return new Promise((resolve, reject) => {
      axios.patch(url, formData).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Get product versions.
   * @param productName
   * @returns {Promise<Object>} Product versions request promise.
   */
  getProductVersions: (productName) => {
    const url = `${API_URL}/lines/products/${productName}/versions/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data.results)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Create product version.
   * @param productName
   * @param formData
   * @returns {Promise<Object>} Create product version request promise.
   */
  createProductVersion: (productName, formData) => {
    const url = `${API_URL}/lines/products/${productName}/versions/`
    return new Promise((resolve, reject) => {
      axios.post(url, formData).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Update product version.
   * @param productName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Update product version request promise.
   */
  updateProductVersion: (productName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/versions/${versionId}/`
    return new Promise((resolve, reject) => {
      axios.put(url, formData).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Update product version status.
   * @param productName
   * @param versionId
   * @param status
   * @returns {Promise<Object>} Update product version status request promise.
   */
  updateProductVersionStatus: (productName, versionId, status) => {
    const url = `${API_URL}/lines/products/${productName}/versions/${versionId}/status/`
    return new Promise((resolve, reject) => {
      axios.patch(url, { status }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Reinstate product version.
   * @param productName
   * @param versionId
   * @returns {Promise<Object>} Reinstate product version status request promise.
   */
  reinstateProductVersion: (productName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/versions/${versionId}/reinstate/`
    return new Promise((resolve, reject) => {
      axios.post(url).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  deleteProductVersion: (productName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/versions/${versionId}/`
    return new Promise((resolve, reject) => {
      axios.delete(url).then(() => {
        resolve()
      }).catch((error) => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Exports a Product Template.
   * @param productName
   * @param versionId
   * @returns {Promise<Object>} Generated Product Template promise object.
   */
  exportProductTemplate: (productName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/template/?version_id=${versionId}`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * creates a Product Template Import.
   * @param productTemplateFileName
   * @returns {Promise<Object>} Product Template Import promise object.
   */
  createProductImport: (productTemplateFileName) => {
    const url = `${API_URL}/lines/product-imports/`
    const formData = new FormData()
    formData.append('key', productTemplateFileName)
    return new Promise((resolve, reject) => {
      axios.post(url, formData).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Imports a Product Template.
   * @param productImportId
   * @returns {Promise<Object>} Imported Product Template promise object.
   */
  importProductTemplate: (productImportId, overwrite = false) => {
    const url = `${API_URL}/lines/product-imports/${productImportId}/import/`
    return new Promise((resolve, reject) => {
      axios.post(url, { 'overwrite': overwrite }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * retrieves a Product Template Import.
   * @param productImportId
   * @returns {Promise<Object>} Product Template Import promise object.
   */
  getProductImport: (productImportId) => {
    const url = `${API_URL}/lines/product-imports/${productImportId}/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Gets a list of data field formats.
   * @returns {Promise<Array>} Promise object that resolves to an array of data field formats.
   */
  getFieldFormats: () => {
    const url = `${API_URL}/lines/field-formats/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Get Sequences
   * @param productName
   * @param versionId
   * @returns {Promise<Object>} Promise object that receives the sequences data.
   */
  getSequences: (productName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/sequences/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.get(url, { params }).then(response => {
        resolve(response.data.results)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Get Sequence
   * @param productName
   * @param sequenceName
   * @param versionId
   * @returns {Promise<Object>} Promise object that receives the sequence data.
   */
  getSequence: (productName, sequenceName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/sequences/${sequenceName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.get(url, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Updates a Sequence
   * @param productName
   * @param sequenceName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Promise object that receives the updated sequence data
   */
  updateSequence: (productName, sequenceName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/sequences/${sequenceName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.put(url, formData, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Creates a Sequence
   * @param productName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Promise object that receives the created sequence data
   */
  createSequence: (productName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/sequences/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, formData, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Deletes a Sequence
   * @param productName
   * @param sequenceName
   * @param versionId
   * @param formData
   * @returns {Promise<Object>} Delete request promise.
   */
  deleteSequence: (productName, sequenceName, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/sequences/${sequenceName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.delete(url, { data: formData, params: params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Retrieves business locations from Gen2 BriteCore
   * @returns {Promise<Object>} Business locations promise.
   */
  getGen2BusinessLocations: () => {
    const url = `/gateway?file=settings.views&method=retrieveBusinessLocations`
    return new Promise((resolve, reject) => {
      axios.post(url).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Retrieves lines products
   * @returns {Promise<Object>} Products promise.
   */
  getProducts: () => {
    const url = `${API_URL}/lines/products/`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  }
}

export default linesService
