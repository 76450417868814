import { _mutations } from './mutation-types'
import Vue from 'vue'
import assign from 'lodash/assign'
import reduce from 'lodash/reduce'

export const mutations = {
  [_mutations.SET_SCOPE] (state, scope) {
    Vue.set(state, 'scope', scope)
  },
  [_mutations.SET_QUOTE_FLOW_STEPS] (state, quoteFlowSteps) {
    Vue.set(state, 'quoteFlowSteps', quoteFlowSteps)
  },
  [_mutations.SET_TRANSACTION_TYPES] (state, transactionTypes) {
    Vue.set(state, 'transactionTypes', transactionTypes)
  },
  [_mutations.SET_VARIABLE] (state, { name, label, type, subtype, creator, position, output }) {
    Vue.set(state.variables, name, {
      name,
      label,
      type,
      subtype,
      creator,
      position,
      output
    })
  },
  [_mutations.SET_VARIABLE_POSITION] (state, { variableName, position }) {
    let variable = state.variables[variableName]
    if (variable) {
      Vue.set(variable, 'position', position)
    }
  },
  [_mutations.REMOVE_VARIABLE] (state, { variableName, type, creator, position }) {
    let varToRemove = state.variables[variableName]
    if (varToRemove) {
      if ((!type || type === varToRemove.type) &&
          (!creator || creator === varToRemove.creator) &&
          (!position || position === varToRemove.position)) {
        Vue.delete(state.variables, variableName)
      }
    }
  },
  [_mutations.REMOVE_VARIABLES_CREATED_BY] (state, creator) {
    let newVariables = reduce(state.variables, (acc, v, name) => {
      return assign(acc, v.creator === creator ? null : { [name]: v })
    }, {})
    Vue.set(state, 'variables', newVariables)
  },
  [_mutations.CLEAR_CONTEXT] (state) {
    Vue.set(state, 'scope', '')
    Vue.set(state, 'quoteFlowSteps', [])
    Vue.set(state, 'variables', {})
  }
}
