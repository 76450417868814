import {_actions} from './action-types'
import {_mutations} from './mutation-types'
import { _mutations as ruleEditorContextMutations } from './ruleEditorContextStore/mutation-types'
import { RULE_EDITOR_CONTEXT_STORE_PATH, ACTIVE_PROJECT_STORE_PATH } from '@/store/rules/constants'

import RulesService from '@/api/rules'
import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import pullAt from 'lodash/pullAt'

export const utils = {
  getParentNamespaces (compiledNamespaces = [], componentNamespaces) {
    forEach(componentNamespaces, (componentNamespace) => {
      let foundParentNamespaceIndex = findIndex(compiledNamespaces, {name: componentNamespace.name})
      let foundParentNamespace = compiledNamespaces[foundParentNamespaceIndex]
      if (foundParentNamespace) {
        forEach(componentNamespace.value, (componentNamespaceValue) => {
          let foundValueIndex = foundParentNamespace.value.indexOf(componentNamespaceValue)
          pullAt(foundParentNamespace.value, foundValueIndex)
        })
        if (foundParentNamespace.value.length === 0) {
          pullAt(compiledNamespaces, foundParentNamespaceIndex)
        }
      }
    })
    return compiledNamespaces
  }
}

export const actions = {
  async [_actions.GET_COMPONENT] ({commit, dispatch, rootGetters}, { componentId, effectiveDateId }) {
    commit(_mutations.START_LOADING_COMPONENT)
    try {
      let component = await RulesService.getComponent(componentId, effectiveDateId)
      commit(_mutations.SET_COMPONENT, component)
      commit(`${RULE_EDITOR_CONTEXT_STORE_PATH}${ruleEditorContextMutations.CLEAR_CONTEXT}`, null, { root: true })
      await dispatch(_actions.GET_COMPILED_COMPONENT, {component, effectiveDateId})
    } catch (error) {
      const errors = [error]
      try {
        let versions = await RulesService.getEffectiveDateByComponent(rootGetters[`${ACTIVE_PROJECT_STORE_PATH}activeProjectId`], componentId)
        versions = versions.filter(v => !!v.componentId)
        commit(_mutations.SET_COMPONENT_AVAILABLE_VERSIONS, versions)
      } catch (error) {
        errors.push(error)
      }
      commit(_mutations.SET_ERROR_LOADING_COMPONENT, errors)
    }
    commit(_mutations.STOP_LOADING_COMPONENT)
  },
  async [_actions.GET_COMPILED_COMPONENT] ({ commit }, { component, effectiveDateId, executeInBackground }) {
    if (!executeInBackground) {
      commit(_mutations.START_LOADING_COMPILED_COMPONENT)
    }
    commit(_mutations.CLEAR_COMPILED_COMPONENT_ERRORS)
    let compiledComponent
    try {
      compiledComponent = await RulesService.getComponentCompiled(component.id, effectiveDateId)
      commit(_mutations.SET_COMPILED_COMPONENT, compiledComponent)
      commit(_mutations.SET_PARENT_NAMESPACES, utils.getParentNamespaces(compiledComponent.namespaces, component.body.namespaces))
    } catch ({apiError: response}) {
      commit(_mutations.SET_ERROR_LOADING_COMPILED_COMPONENT, response.errors)
    }
    commit(_mutations.STOP_LOADING_COMPILED_COMPONENT)
    return compiledComponent
  },
  [_actions.RESET_COMPONENT] ({commit, state}) {
    commit(_mutations.RESET)
    commit(`${RULE_EDITOR_CONTEXT_STORE_PATH}${ruleEditorContextMutations.CLEAR_CONTEXT}`, null, { root: true })
  }
}
