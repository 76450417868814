export const state = {
  component: null,
  templateArguments: {},
  componentStatus: {
    started: false,
    loading: false,
    loaded: false,
    errors: []
  },
  activeBRComponentInput: {
    reference: null
  },
  compiledComponentStatus: {
    started: false,
    loading: false,
    loaded: false,
    errors: []
  },
  compiledComponent: null,
  parentNamespaces: [],
  formulaEditorsProcessing: 0,
  isComponentUnsaved: false,
  availableVersions: []
}
