<template>
  <div
    v-test-id="'activity-sidebar'"
    v-if="isSidebarActive"
    class="c-activity-nav-sidebar__wrapper"
    :class="{
      'c-activity-nav-sidebar__wrapper--expanded-lg': showExpandedSidebar,
      'c-activity-nav-sidebar__wrapper--expanded-md': showExpandedByDefaultSidebar
    }"
  >
    <div
      v-test-id="'activity-view'"
      class="c-activity-sidebar-view__wrapper"
      v-if="isActivitySidebarOpen"
    >
      <template
        v-for="(module, name) in enabledSidebarModules"
      >
        <portal-target
          v-test-id="`activity-portal-${module.portalName}`"
          :key="name"
          :name="`activity-sidebar-${module.portalName}`"
        />
      </template>
    </div>
    <div class="c-activity-sidebar-links__wrapper u-p3">
      <nav
        class="c-activity-sidebar-link"
        v-for="(module, name) in enabledSidebarModules"
        :key="name"
      >
        <fa-icon
          v-test-id="`activity-link-${module.portalName}`"
          v-tooltip:left="{ content: module.label, visibleArrow: false }"
          @click="onIconClick(name)"
          :icon="module.icon"
          size="lg"
          :data-element="`activity-sidebar-link-${module.portalName}`"
        />
      </nav>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations, mapGetters} from 'vuex'

export default {
  name: 'ActivityNavSidebar',
  watch: {
    enabledSidebarModules: function () {
      // if an active module is disabled, and activity sidebar is opened,
      // we need to close the activity sidebar as no component should be displayed.
      if (this.isActivitySidebarOpen && !this.selectedModule) {
        this.toggleActivitySidebar(false)
        this.unsetActivitySidebarActiveModule()
      }
    }
  },
  computed: {
    ...mapState('activity', [
      'isActivitySidebarOpen',
      'activitySidebarActiveModule',
      'activitySidebarExpanded'
    ]),
    ...mapState('notes', [
      'noteIsDirty'
    ]),
    ...mapGetters('activity', [
      'isSidebarActive',
      'enabledSidebarModules',
      'activitySidebarModulesNames'
    ]),
    selectedModule () {
      return this.enabledSidebarModules[this.activitySidebarActiveModule]
    },
    showExpandedSidebar () {
      return this.isActivitySidebarOpen && this.activitySidebarExpanded
    },
    showExpandedByDefaultSidebar () {
      return this.isActivitySidebarOpen && this.selectedModule && this.selectedModule.expandByDefault
    }
  },
  created () {
    // Always stay collapsed when vue instance is created.
    this.toggleActivitySidebar(false)
    // Reset notes to initial state when we refresh the page
    this.resetNotesInitialState()
    // Due to vue-extend-layout implementation we need to disable the ActivitySidebar
    // when switching between layout to avoid the re-rendering of children components
    this.disableAllModules()
  },
  methods: {
    ...mapMutations('notes', {'resetNotesInitialState': 'reset'}),
    ...mapMutations('activity', [
      'unsetActivitySidebarActiveModule',
      'setActivitySidebarActiveModule',
      'toggleActivitySidebar',
      'enableModule',
      'disableModule',
      'disableAllModules'
    ]),
    onIconClick (name) {
      if (this.noteIsDirty && this.isActivitySidebarOpen) {
        this.openDialog(name)
      } else {
        this.sideNavToggle(name)
      }
    },
    sideNavToggle (name) {
      // Switch view
      if (this.isActivitySidebarOpen && this.activitySidebarActiveModule !== name) {
        this.setActivitySidebarActiveModule(name)
        return
      }

      // Toggle activity sidebar
      this.setActivitySidebarActiveModule(this.isActivitySidebarOpen ? null : name)
      this.toggleActivitySidebar()
    },
    openDialog (name) {
      this.$confirm('You have unsaved changes in Notes. Please save or cancel your changes.', 'Warning', {
        confirmButtonText: 'OK',
        closeOnClickModal: false,
        showCancelButton: false,
        type: 'warning'
      }).then(() => {
      })
    }
  }
}
</script>
