
export const state = {
  templateIsValid: false,
  searchType: '',
  previewClaimData: Object,
  previewQuoteData: Object,
  previewPolicyData: Object,
  template: {
    id: null,
    name: null,
    description: null,
    versions: []
  },
  importedDocument: null,
  asset: null,
  openTemplates: [],
  activeVersion: {
    id: null,
    name: null,
    description: null,
    fields: null,
    effective_date: null,
    html: null
  },
  folders: {},
  folderIds: [],
  assets: {},
  assetIds: [],
  documents: {},
  documentIds: [],
  currentDocumentId: '',
  settings: []
}
