import forIn from 'lodash/forIn'
import forEach from 'lodash/forEach'
import find from 'lodash/find'
import { PURPOSE_TAG_CATEGORY, LABEL_TAG_CATEGORY } from '@/pages/rules/constants'

const FILTER_NAMES = {
  NAMESPACES: 'namespaces',
  PURPOSE: 'Component Purpose',
  STATE: 'State',
  NEW_COMPONENTS_ONLY: 'New components only',
  LABEL: 'Label'
}

const FILTERS = {
  [FILTER_NAMES.NAMESPACES]: {
    filterFactory: (filterCriteria) => {
      let filters = []
      forIn(filterCriteria, (value, category) => {
        if (value) {
          filters.push((data) => !!find(data.tags, { category, value }))
        }
      })
      return filters
    }
  },
  [FILTER_NAMES.LABEL]: {
    filterFactory: (filterCriteria) => {
      let filters = []
      forEach(filterCriteria, (label) => {
        if (label) {
          filters.push((data) => !!find(data.tags, { category: LABEL_TAG_CATEGORY, value: label }))
        }
      })
      return filters
    }
  },
  'purpose-state-version': {
    filterFactory: (filterCriteria) => {
      let filters = []
      if (filterCriteria[FILTER_NAMES.STATE]) {
        filters.push((data) => data.item.state.toLowerCase() === filterCriteria[FILTER_NAMES.STATE])
      }
      if (filterCriteria[FILTER_NAMES.PURPOSE]) {
        filters.push((data) => !!find(data.tags, { category: PURPOSE_TAG_CATEGORY, value: filterCriteria[FILTER_NAMES.PURPOSE] }))
      }
      if (filterCriteria[FILTER_NAMES.NEW_COMPONENTS_ONLY]) {
        filters.push((data) => data.item.effective_date === filterCriteria[FILTER_NAMES.NEW_COMPONENTS_ONLY])
      }
      return filters
    }
  }
}

const getNamespacesAvailableFilterCriteria = (namespaces) => {
  let namespacesAvailableFilterCriteria = []
  let filterCriteria
  forIn(namespaces, (value, namespaceName) => {
    if (!filterCriteria) {
      filterCriteria = {
        type: 'form',
        name: FILTER_NAMES.NAMESPACES,
        label: 'Namespaces',
        fields: []
      }
    }
    filterCriteria.fields.push({
      name: namespaceName,
      label: namespaceName,
      type: 'dropdown',
      closeOnChange: true,
      options: value.map(data => {
        return { name: data, label: data }
      })
    })
  })
  if (filterCriteria) {
    namespacesAvailableFilterCriteria.push(filterCriteria)
  }
  return namespacesAvailableFilterCriteria
}

const getLabelFilterCriteria = (labels) => {
  if (!labels.length) {
    return []
  } else {
    return [{
      name: FILTER_NAMES.LABEL,
      label: FILTER_NAMES.LABEL,
      type: 'select',
      closeOnChange: true,
      options: labels.map(label => {
        return { name: label, label }
      })
    }]
  }
}

const getPurposeStateAndVersionFilterCriteria = (purposes, currentVersion) => {
  let filterCriteria = {
    type: 'form',
    name: 'purpose-state-version',
    fields: []
  }
  filterCriteria.fields.push({
    name: FILTER_NAMES.PURPOSE,
    label: FILTER_NAMES.PURPOSE,
    type: 'dropdown',
    closeOnChange: true,
    options: purposes.map(purpose => {
      return { name: purpose, label: purpose }
    })
  })
  filterCriteria.fields.push({
    name: FILTER_NAMES.STATE,
    label: FILTER_NAMES.STATE,
    type: 'dropdown',
    closeOnChange: true,
    options: [
      { name: 'disabled', label: 'Only disabled' },
      { name: 'enabled', label: 'Only enabled' }
    ]
  })
  if (currentVersion) {
    filterCriteria.fields.push({
      name: FILTER_NAMES.NEW_COMPONENTS_ONLY,
      label: 'Version',
      type: 'dropdown',
      closeOnChange: true,
      options: [{ name: currentVersion.effective_date, label: 'New components only' }]
    })
  }
  return [filterCriteria]
}

const componentsFilterFactory = (selectedFilterCriteria) => {
  let filters = []
  forIn(selectedFilterCriteria, (value, key) => {
    filters = [...filters, ...FILTERS[key].filterFactory(value)]
  })
  return filters
}

export {
  FILTER_NAMES,
  getNamespacesAvailableFilterCriteria,
  getLabelFilterCriteria,
  getPurposeStateAndVersionFilterCriteria,
  componentsFilterFactory
}
