import Vue from 'vue'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'
import store from './store'

const authLink = setContext((_, { headers }) => {
  // Pass `Authorization` header with AWS Cognito's `accessToken` value
  let accessToken = store.getters['authentication/accessToken'] || ''

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: accessToken
    }
  }
})

export const linesClient = new ApolloClient({
  link: authLink.concat(
    new HttpLink({
      uri: process.env.VUE_APP_API_URL + '/graphql/lines',
      opts: {
        credentials: 'same-origin'
      },
      transportBatching: true
    })
  ),
  cache: new InMemoryCache({dataIdFromObject: object => object.id}),
  addTypename: true,
  connectToDevTools: true
})

export const quoteClient = new ApolloClient({
  link: authLink.concat(
    new HttpLink({
      uri: process.env.VUE_APP_API_URL + '/quote/graphql/',
      opts: {
        credentials: 'same-origin'
      },
      transportBatching: true
    })
  ),
  cache: new InMemoryCache({dataIdFromObject: object => object.id}),
  addTypename: true,
  connectToDevTools: true
})

const rulesClient = new ApolloClient({
  link: authLink.concat(
    new HttpLink({
      uri: process.env.VUE_APP_API_URL + '/graphql/rules',
      opts: {
        credentials: 'same-origin'
      },
      transportBatching: true
    })
  ),
  cache: new InMemoryCache({dataIdFromObject: object => object.id}),
  addTypename: true,
  connectToDevTools: true
})

const apolloProvider = new VueApollo({
  clients: {
    lines: linesClient,
    quote: quoteClient,
    rules: rulesClient
  },
  defaultClient: linesClient
})

// Install the vue plugin
Vue.use(VueApollo)

export default apolloProvider
