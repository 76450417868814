import inflection from 'inflection'
import {clone, groupBy, isEmpty, isObject, sortBy} from 'lodash'

export default {
  exposuresByClaimant (state) {
    const reducer = (exposuresByClaimant, exposure) => {
      const claimant = isObject(exposure.party) ? exposure.party.id : exposure.party
      if (claimant in exposuresByClaimant) {
        exposuresByClaimant[claimant].push(exposure)
      } else {
        exposuresByClaimant[claimant] = [exposure]
      }
      return exposuresByClaimant
    }
    return state.claim.exposures ? state.claim.exposures.reduce(reducer, {}) : {}
  },
  claimants (state, getters) {
    if (isEmpty(getters.exposuresByClaimant)) {
      return []
    }

    const claimants = Object.entries(getters.exposuresByClaimant).map(([, exposures]) => exposures[0].party)
    return sortBy(claimants, ['number'])
  },
  claimantsTreeData (state) {
    const geExposureSubHeader = (exposure) => {
      if (exposure.risks && exposure.risks.length > 0) {
        return exposure.risks.map(risk => {
          const riskSubName = risk.sub_name.substring(11)
          return riskSubName ? `${risk.name} (${riskSubName})` : risk.name
        }).join(', ')
      } else if (exposure.is_injury && exposure.party.injury) {
        return exposure.party.injury.injury_nature.label
      } else {
        return '--'
      }
    }
    const groupByClaimant = () => {
      let data = []
      if (state.claim.exposures.length === 0) {
        return []
      }
      // There are cases when claim.exposures are arrays of IDs
      if (!isObject(state.claim.exposures[0])) {
        return []
      }
      const exposuresByClaimant = Object.values(groupBy(state.claim.exposures, 'party.display_number'))
      exposuresByClaimant.forEach((exposures, index) => {
        data.push({
          id: exposures[0].party.display_number,
          header: exposures[0].party.display_number + ' - ' + exposures[0].party.contact.name,
          children: []
        })
        for (let i = 0; i < exposures.length; i++) {
          data[index].children.push({
            id: exposures[i].display_number,
            header: exposures[i].exposure_name,
            sub_header: geExposureSubHeader(exposures[i]),
            route: {
              name: 'claims:claimants:exposures:details',
              params: {
                claimant_number: exposures[i].party.display_number,
                exposure_number: exposures[i].display_number
              }
            }
          })
        }
      })
      return data
    }
    const groupByExposure = () => {
      let data = []
      if (state.claim.exposures.length === 0) {
        return []
      }
      // There are cases when claim.exposures are arrays of IDs
      if (!isObject(state.claim.exposures[0])) {
        return []
      }
      state.claim.exposures.forEach((exposure) => {
        data.push({
          id: exposure.display_number,
          header: exposure.exposure_name,
          sub_header: geExposureSubHeader(exposure),
          route: {
            name: 'claims:claimants:exposures:details',
            params: {
              claimant_number: exposure.party.display_number,
              exposure_number: exposure.display_number
            }
          },
          children: []
        })
      })
      return data
    }
    if (!state.claim) {
      return []
    }
    if (state.exposureGroupBy === 'claimant') {
      return groupByClaimant()
    } else if (state.exposureGroupBy === 'exposure') {
      return groupByExposure()
    }
    return []
  },
  getClaimTabItems (state) {
    let items = clone(state.claimTabItems)
    if (state.product.injury_type && state.injuryPermission) {
      items.push({
        label: 'Injuries',
        name: 'Injuries',
        urlName: 'claims:injury-summary'
      })
    }
    if (state.product.risk_type_groups) {
      const groups = state.product.risk_type_groups.sort(a => a.order).map(item => ({
        label: inflection.pluralize(item.label),
        name: item.name,
        urlName: 'claims:risks:types',
        params: {risk_type_group_name: item.name}
      }))
      items.splice(items.length, 0, ...groups)
    }
    return items
  },
  getProduct (state) {
    return state.product
  },
  getCurrentFnolStep (state) {
    return state.claim ? state.claim.current_fnol_step : null
  }
}
