import axios from 'axios'
import moment from 'moment'

const API_URL = process.env.VUE_APP_API_URL

let policyService = {
  /**
   * Get a Policy by id.
   * @param {String} policyId
   * @returns {Promise<Object>} Promise object that receives the Policy.
   */
  getPolicy: (policyId) => {
    var url = `${API_URL}/policies/policies/${policyId}/`
    return new Promise((resolve, reject) =>
      axios.get(url).then(response => {
        resolve(response.data)
      }, error => {
        reject(error.response.data)
      })
    )
  },
  /**
   * Get all Revisions by policy id.
   * @param {String} policyId
   * @returns {Promise<Object>} Promise object that receives all Revisions.
   */
  getRevisions: (policyId, contactRole, contactId) => {
    const noRoleUrl = `${API_URL}/policies/revisions/?filter[policy_id]=${policyId}`
    const roleUrlMap = {
      'agency': (policyId, agencyAgentId) => `${API_URL}/policies/revisions/?filter[policy_id]=${policyId}&agencies=${agencyAgentId}`,
      'agent': (policyId, agencyAgentId) => `${API_URL}/policies/revisions/?filter[policy_id]=${policyId}&agents=${agencyAgentId}`
    }

    if (contactRole in roleUrlMap) {
      return axios.get(roleUrlMap[contactRole.toLowerCase()](policyId, contactId))
    } else {
      return axios.get(noRoleUrl)
    }
  },
  /**
   * Get PolicyInfoScreenFlow by policy id.
   * @param {String} policyId
   * @returns {Promise<Object>} Promise object that receives the PolicyInfoScreenFlow.
   */
  getPolicyInfoScreenFlow: (policyId) => {
    const url = `${API_URL}/policies/policy-info-screen-flows/?filter[policy_id]=${policyId}`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },
  /**
   * Get prepared risk data by revision id.
   * @param {String} revisionId
   * @returns {Promise<Object>} Promise object that receives the risks.
   */
  getRisks: (revisionId, tabIndex) => {
    return axios.get(`${API_URL}/policies/formatted-risk/?filter[revision_id]=${revisionId}&filter[tab_index]=${tabIndex}`)
  },
  /**
   * Get the raw RiskData by revision id.
   * @param {String} revisionId
   * @returns {Promise<Object>} Promise object that receives the RiskData.
   */
  getRiskData: (revisionId) => {
    const url = `${API_URL}/policies/risk-data/?filter[revision_id]=${revisionId}`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },
  /**
   * Create a quote
   * @param {Date} effectiveDate
   * @param {String} quoteNumber
   * @param {String} transactionType
   * @param {Object} additionalData
   * @returns {Promise<Object>} Promise object that retrieves any new quote information.
   */
  createQuote: (effectiveDate, quoteNumber, transactionType, additionalData = {}) => {
    let payload = {
      effective_date: moment(effectiveDate).format('YYYY-MM-DD'),
      transaction_type: transactionType,
      source_quote_number: quoteNumber,
      ...additionalData
    }
    let headers = {
      'X-Request': 'JSON',
      'Content-Type': 'application/json'
    }
    return axios.post(`${API_URL}/quote/`, payload, {headers})
  },
  /**
   * Start an endorsement
   * @param {Date} effectiveDate
   * @param {String} quoteNumber
   * @param {String} productName
   * @returns {Promise<Object>} Promise object that retrieves any endorsement information.
   */
  endorsePolicy: (effectiveDate, quoteNumber, productName) => {
    return policyService.createQuote(effectiveDate, quoteNumber, 'endorsement', {productName})
  },
  /**
   * Reinstate a Cancelled Policy
   * @param {String} policyId
   * @param {Date} reinstateDate
   * @param {String} quoteNumber
   * @param {String} reason
   * @param {String} assessFee
   * @returns {Promise<Object>} Promise object that starts the process to reinstate a cancelled policy
   */
  reinstatePolicy: (policyId, reinstateDate, quoteNumber, reason, assessFee) => {
    let postback = {
      reinstate_reason: reason,
      assess_fee: assessFee
    }
    return policyService.createTransaction(policyId, 'reinstatement', reinstateDate, true, postback)
  },

  /**
   * Reinstate a Cancellation Pending Policy
   * @param {String} revisionId
   * @param {String} policyId
   * @returns {Promise<Object>} Promise object that reinstates a cancellation pending policy
   */
  reinstateCancellationPendingPolicy: (revisionId, policyId) => axios.post(
    `/gateway?file=policies.views&method=reinstateCancellationPendingPolicy`,
    {
      revisionId,
      policyId
    },
    {
      headers: {
        'X-Request': 'JSON',
        'Content-Type': 'application/json'
      }
    }
  ),
  /**
   * Rewrite a policy
   * @param {Date} effectiveDate
   * @param {String} quoteNumber
   * @param {Object} term
   * @param {Object} postback
   * @returns {Promise<Object>} Promise object that retrieves any rewrite information.
   */
  rewritePolicy: (effectiveDate, quoteNumber, term, postback) => {
    return policyService.createQuote(effectiveDate, quoteNumber, 'rewrite', {term, postback})
  },
  /**
   * Get the billing information of a policy
   * @param {String} policyId
   * @param {String} policyTermId
   * @returns {Promise<Object>} Promise object that retrieves any policy billing information
   */
  getBillingInfo: (policyId, policyTermId) => axios.post(
    `${API_URL}/v2/payments/retrieve_policy_billing_information`,
    {
      policy_id: policyId,
      policy_term_id: policyTermId,
      billing_only: false
    },
    {
      headers: {
        'X-Request': 'JSON',
        'Content-Type': 'application/json'
      }
    }
  ),
  /**
   * Get claims after a given date for a policy
   * @param {String} policyNumber
   * @param {String} dateString
   * @returns {Promise<Object>} Promise object that retrieves claims after a given date
   */
  // ToDo : filter out the claims with a tag of Not Paid (No Loss) when tag from Claims is ready
  getLossAfterADate: (policyNumber, dateString) => axios.get(
    `${API_URL}/claims/`, {
      params: {
        'filter[policy]': policyNumber,
        'filter[loss_date_from]': dateString,
        'page[number]': 1,
        'page[size]': 1
      }
    }
  ),
  /**
   * Get the number of claims a policy has.
   * @param {String} policyNumber
   * @returns {Promise<Object>} Promise object returns the number of claims a policy has.
   */
  getClaims: (policyNumber) => axios.get(
    `${API_URL}/claims/`, {
      params: {
        'page[number]': 1,
        'page[size]': 1,
        'filter[policy]': policyNumber
      }
    }
  ),
  /**
   * Get Open Endorsement from BriteQuote
   * @param {string} policyId
   * returns {Promise<Object>} Promise object return the open endorsements of a policy
  */
  async getOpenQuotes (policyId) {
    return axios.get(`${API_URL}/quote/`, {
      params: {
        'filter[source_policy_id]': policyId,
        'filter[is_bound]': false,
        'filter[status.not]': 'Abandoned',
        'filter[is_deleted]': false,
        sort: 'date_added'
      }
    })
  },
  /**
   * Discard an open endorsement
   * @param {String} quoteNumber
   */
  discardOpenQuote: (quoteNumber) => axios.delete(`${API_URL}/quote/${quoteNumber}/`),
  getCancellationInfo: (revisionId) => axios.post(
    `/gateway?file=policies.views&method=retrieveCancellationInfo`,
    { revisionId },
    {
      headers: {
        'X-Request': 'JSON',
        'Content-Type': 'application/json'
      }
    }
  ),
  /**
   * Cancels a Policy
   * @param {String} policyId
   * @param {Date} effectiveDate
   * @param {String} reason
   * @param {String} printDescription
   * @param {String} additionalDescription
   * @returns {Promise<Object>} Promise object that starts the process to cancel a policy
   */
  cancelPolicy: ({
    policyId,
    effectiveDate,
    reason,
    printDescription,
    additionalDescription
  }) => {
    let postback = {
      cancel_reason_id: reason,
      print_description: printDescription,
      additional_description: additionalDescription,
      policy_status: 'Canceled'
    }
    return policyService.createTransaction(policyId, 'cancellation', effectiveDate, true, postback)
  },
  placePolicyInCancellationPending: (payload) => axios.post(
    `/gateway?file=policies.views&method=cancelPolicy`,
    payload,
    {
      headers: {
        'X-Request': 'JSON',
        'Content-Type': 'application/json'
      }
    }
  ),
  getPolicyTransactions: (policyId, {type, status, reasonDismissed}) => axios.get(
    `${API_URL}/policies/policy-transactions/`,
    {
      params: {
        'filter[policy_id]': policyId,
        'filter[status]': status,
        'filter[type]': type,
        'filter[reason_dismissed]': reasonDismissed,
      }
    }
  ),
  getOOSPolicyTransactions: (oosTransactionId) => axios.get(
    `${API_URL}/policies/policy-transactions/?filter[triggering_oos_transaction_id]=${oosTransactionId}&filter[status]=completed`
  ),
  async getTransactionChanges (transactionId) {
    const headers = {
      headers: {
        'X-Request': 'JSON',
        'Content-Type': 'application/vnd.api+json'
      }
    }
    const payload = {
      'data': {
        'type': 'compare-transactions',
        'attributes': {
          'after': transactionId
        }
      }
    }
    const url = `${API_URL}/policies/compare-transactions/`
    return axios.post(url, payload, headers)
  },
  getRiskType: (productName, versionId, riskTypeName) => axios.get(
    `${API_URL}/lines/products/${productName}/risk-types/${riskTypeName}/`, { params: { version_id: versionId } }
  ),
  /**
   * Kick off the transaction creation process asynchronously
   * @param {String} policyId
   * @param {String} transactionType
   * @param {Date} transactionDate
   * @param {Boolean} autoBind
   * @param {String} postback
   * @returns {Promise<Object>} Promise object with results of the new in progress transaction
   */
  createTransaction: (policyId, transactionType, transactionDate, autoBind, postback = {}) => {
    const headers = {
      headers: {
        'X-Request': 'JSON',
        'Content-Type': 'application/vnd.api+json'
      }
    }
    const payload = {
      'data': {
        'type': 'policy-transactions',
        'attributes': {
          'policy': policyId,
          'transaction_date_time': transactionDate,
          'type': transactionType,
          'status': autoBind ? 'in_progress' : 'initiated',
          'auto_bind': autoBind,
          'process_async': autoBind,
          'postback': postback
        }
      }
    }
    const url = `${API_URL}/policies/policy-transactions/`
    return axios.post(url, payload, headers)
  },
  updateTransaction: (transactionId, reasonDismissed) => {
    const headers = {
      headers: {
        'X-Request': 'JSON',
        'Content-Type': 'application/vnd.api+json'
      }
    }
    const payload = {
      'data': {
        'type': 'policy-transactions',
        'id': transactionId,
        'attributes': {
          'reason_dismissed': reasonDismissed
        }
      }
    }
    const url = `${API_URL}/policies/policy-transactions/${transactionId}/`
    return axios.patch(url, payload, headers)
  },

  /**
   * Renew a policy
   * @param {String} policyId
   * @param {Date} renewalEffectiveDate
   * @param {Boolean} autoBind
   * @returns {Promise<Object>} Promise object with results of the renewal transaction
   */
  renewPolicy: (policyId, renewalEffectiveDate, autoBind) => {
    return policyService.createTransaction(policyId, 'renewal', renewalEffectiveDate, autoBind)
  },
  /**
   * Get the policy term renewal status
   * @param {string} revisionId
   * @returns {Promise<Object>} Promise object with results of the renewal status.
   */
  getRenewalStatus: (revisionId) => axios.post(
    `/gateway?file=policies.views&method=retrieveRevisionStatus`,
    { revisionId },
    {
      headers: {
        'X-Request': 'JSON',
        'Content-Type': 'application/json'
      }
    }
  ),
  /**
   * Set the renewal status of a policy term
   * @param {string} renewalStatus
   * @param {string} renewalStatusReasonId
   * @param {string} renewalStatusDescription
   * @param {string} revisionId
   * @returns {Promise<Object>} Promise object with results of the set operation.
   */
  setRenewalStatus: (renewalStatus, renewalStatusReasonId, renewalStatusDescription, revisionId) => axios.post(
    `/gateway?file=policies.views&method=storeRenewalStatus`,
    {
      renewalStatus,
      renewalStatusReason: renewalStatusReasonId,
      renewalStatusDescription,
      revisionId
    },
    {
      headers: {
        'X-Request': 'JSON',
        'Content-Type': 'application/json'
      }
    }
  ),
  /**
   * Get Reinstatement Info.
   * @param {String} policyId
   * @param {String} revisionId
   * @returns {Promise<Object>} Promise object with reinstatement info.
   */
  getReinstateInfo: (policyId, revisionId) => axios.post(
    `/gateway?file=policies.views&method=retrieveReinstateInfo`,
    { policyId, revisionId },
    {
      headers: {
        'X-Request': 'JSON',
        'Content-Type': 'application/json'
      }
    }
  ),
  getLinkedPolicies: (policyId, policyNumber) => axios.post(
    `/gateway?file=policies.views&method=retrieveLinkedPolicies`,
    { policyId, policyIdName: policyNumber },
    {
      headers: {
        'X-Request': 'JSON',
        'Content-Type': 'application/json'
      }
    }
  ),
  getSearchedLinkablePolicies: (policyId, currentPage, searchString) => axios.post(
    `/gateway?file=policies.views&method=retrievePolicies`,
    { id: policyId, currentPage, searchString, pageSize: 6 },
    {
      headers: {
        'X-Request': 'JSON',
        'Content-Type': 'application/json'
      }
    }
  ),
  addLinkedPolicy: ({groupId, policyId, parentPolicyId}) => axios.post(
  `/gateway?file=policies.views&method=addLinkedPolicy`,
    { groupId, policyId, parentPolicyId },
    {
      headers: {
        'X-Request': 'JSON',
        'Content-Type': 'application/json'
      }
    }
  ),
  removeLinkedPolicy: (groupId, policyId, parentPolicyId) => axios.post(
    `/gateway?file=policies.views&method=removeLinkedPolicy`,
    { groupId, policyId, parentPolicyId },
    {
      headers: {
        'X-Request': 'JSON',
        'Content-Type': 'application/json'
      }
    }
  ),
  getDecFileId: (revisionId) => axios.get(`${API_URL}/policies/get-dec-file-id/?revision_id=${revisionId}`),
  getFailedRenewals: (pageNumber, pageSize, sort, search) => {
    let params = {}
    if (pageNumber) {
      params['page[number]'] = pageNumber
    }
    if (pageSize) {
      params['page[size]'] = pageSize
    }
    if (sort) {
      params['sort'] = sort
    }
    if (search) {
      params['filter[search]'] = search
    }
    return axios.get(`${API_URL}/policies/failed-renewals/`, { params })
  },
  /**
   * Get the invoices of a policy
   * @param {String} policyId
   * @param {String} sortingOrder
   * @returns {Promise<Object>} Promise object that retrieves invoices
   */
  getInvoices: (policyId, sortingOrder) => axios.post(
    `${API_URL}/v2/accounting/get_invoices`,
    {
      policy_id: policyId,
      sorting_order: sortingOrder
    },
    {
      headers: {
        'X-Request': 'JSON',
        'Content-Type': 'application/json'
      }
    }
  )
}

export default policyService
