import {JsonApiService} from '@/modules/claims/services/api'

class ClaimApi extends JsonApiService {
  constructor () {
    super('claim')
  }
}

class ClaimEventApi extends JsonApiService {
  constructor () {
    super('claim-event')
  }
}

class ClaimEventTypeApi extends JsonApiService {
  constructor () {
    super('claim-event-type')
  }
}

class ClaimStatusApi extends JsonApiService {
  constructor () {
    super('claim-status')
  }
}

class ClaimStatusChangeReasonApi extends JsonApiService {
  constructor () {
    super('claim-status-change-reason')
  }
}

class LossDateTimezoneApi extends JsonApiService {
  constructor () {
    super('loss-date-timezone')
  }
}

class TimezoneApi extends JsonApiService {
  constructor () {
    super('timezone')
  }
}

class OnSiteServiceApi extends JsonApiService {
  constructor () {
    super('on-site-service')
  }
}

class OnSiteServiceTypeApi extends JsonApiService {
  constructor () {
    super('on-site-service-type')
  }
}

class PerilApi extends JsonApiService {
  constructor () {
    super('peril')
  }
}

class ReportedMethodApi extends JsonApiService {
  constructor () {
    super('reported-method')
  }
}

export {
  ClaimApi,
  ClaimEventApi,
  ClaimEventTypeApi,
  ClaimStatusApi,
  ClaimStatusChangeReasonApi,
  LossDateTimezoneApi,
  TimezoneApi,
  OnSiteServiceApi,
  OnSiteServiceTypeApi,
  PerilApi,
  ReportedMethodApi
}
