import {_mutations} from './mutation-types'
import Vue from 'vue'

export const mutations = {
  [_mutations.RESET] (state) {
    Vue.set(state.componentStatus, 'errors', [])
    state.componentStatus.loaded = false
    state.componentStatus.started = false
    state.componentStatus.loading = false
    Vue.set(state, 'component', null)
    Vue.set(state.compiledComponentStatus, 'errors', [])
    state.compiledComponentStatus.loaded = false
    state.compiledComponentStatus.started = false
    state.compiledComponentStatus.loading = false
    Vue.set(state, 'compiledComponent', null)
    Vue.set(state, 'availableVersions', [])
  },
  [_mutations.SET_COMPONENT] (state, component) {
    Vue.set(state, 'component', component)
  },
  [_mutations.START_LOADING_COMPONENT] (state) {
    state.componentStatus.started = true
    Vue.set(state.componentStatus, 'errors', [])
    state.componentStatus.loaded = false
    state.componentStatus.loading = true
  },
  [_mutations.STOP_LOADING_COMPONENT] (state) {
    state.componentStatus.loaded = true
    state.componentStatus.loading = false
  },
  [_mutations.SET_ERROR_LOADING_COMPONENT] (state, errors) {
    Vue.set(state.componentStatus, 'errors', [...errors])
  },
  [_mutations.SET_TEMPLATE_ARGUMENTS] (state, value) {
    value.forEach((arg) => {
      Vue.set(state.templateArguments, arg.name, arg)
    })
  },
  [_mutations.SET_ACTIVE_BR_COMPONENT_INPUT] (state, component) {
    Vue.set(state.activeBRComponentInput, 'reference', component)
  },
  [_mutations.START_LOADING_COMPILED_COMPONENT] (state) {
    state.compiledComponentStatus.started = true
    state.compiledComponentStatus.loaded = false
    state.compiledComponentStatus.loading = true
  },
  [_mutations.CLEAR_COMPILED_COMPONENT_ERRORS] (state) {
    Vue.set(state.compiledComponentStatus, 'errors', [])
  },
  [_mutations.STOP_LOADING_COMPILED_COMPONENT] (state) {
    state.compiledComponentStatus.loaded = true
    state.compiledComponentStatus.loading = false
  },
  [_mutations.SET_ERROR_LOADING_COMPILED_COMPONENT] (state, errors) {
    Vue.set(state.compiledComponentStatus, 'errors', Array.isArray(errors) ? errors : [errors])
  },
  [_mutations.SET_COMPILED_COMPONENT] (state, compiledComponent) {
    Vue.set(state, 'compiledComponent', compiledComponent)
  },
  [_mutations.SET_PARENT_NAMESPACES] (state, namespaces) {
    Vue.set(state, 'parentNamespaces', namespaces)
  },
  [_mutations.INCREMENT_FORMULA_EDITOR_PROCESSING] (state) {
    state.formulaEditorsProcessing++
  },
  [_mutations.DECREMENT_FORMULA_EDITOR_PROCESSING] (state) {
    state.formulaEditorsProcessing--
  },
  [_mutations.SET_COMPONENT_UNSAVED] (state, value) {
    Vue.set(state, 'isComponentUnsaved', value)
  },
  [_mutations.SET_COMPONENT_AVAILABLE_VERSIONS] (state, value) {
    Vue.set(state, 'availableVersions', value)
  }
}
