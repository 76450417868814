// includes

export const PRODUCT_INCLUDE = [
  'product.configurations',
  'product.claim_party_roles_all.party_types',
  'product.claim_type',
  'product.claim_tags_all',
  'product.exposure_tags_all',
  'product.injury_type.body_parts',
  'product.injury_type.injury_natures',
  'product.loss_categories_all',
  'product.risk_types.attributes',
  'product.risk_types.damage_type',
  'product.risk_type_groups',
  'product.risk_party_roles_all.party_types',
  'product.risk_party_roles_all.settings',
  'product.perils_all',
  'product.party_relation_roles_all.party_types'
].join(',')

export const CLAIM_INCLUDE = [
  'loss_category',
  'loss_location',
  'perils',
  'primary_adjuster.user',
  'primary_insured.contact',
  'reported_by',
  'reported_method',
  'status_change_reason',
  'tags'
].join(',')

export const CLAIMANTS_INCLUDE = [
  'contact',
  'injury.injury_nature',
  'injury.settlement',
  'injury.treatment',
  'related_parties.related_party',
  'related_parties.role'
].join(',')

export const EXPOSURE_INCLUDE = [
  'adjuster',
  'coverage',
  'risks.risk_type.risk_type_group',
  // exposures page
  'party.injury.injury_nature',
  'party.injury.settlement',
  'party.injury.treatment',
  // TODO if exposure has related parties with injuries there seems to be an issue with
  //  api call if we dont specify injury here (the injury object is not returned)
  'party.related_parties.related_party.injury',
  'party.related_parties.role',
  // exposure page
  'coverage.endorsements.limits',
  'coverage.item',
  'coverage.limits',
  'party.contact',
  'status_change_reason',
  'tags'
].join(',')

export const EXPOSURES_INCLUDE = EXPOSURE_INCLUDE

export const FNOL_INCLUDE = [
  'loss_location',
  'perils',
  'reported_method'
].join(',')

// Reserve Categories

export const RESERVE_CATEGORY_INCLUDE = [
  'pay_codes',
  'reserve_category_type'
].join(',')

export const RESERVE_CATEGORIES_INCLUDE = RESERVE_CATEGORY_INCLUDE

// Summaries

export const SUMMARY_CLAIMANT_INCLUDE = [
  'contact',
  'exposures.coverage.limits',
  'exposures.reserve_category_types',
  'exposures.status_change_reason',
  'reserve_category_types'
].join(',')

export const SUMMARY_CLAIMANTS_INCLUDE = SUMMARY_CLAIMANT_INCLUDE

export const SUMMARY_RECOVERY_INCLUDE = [
  'reserve_categories.reserve_category_type'
].join(',')

export const SUMMARY_RECOVERIES_INCLUDE = SUMMARY_RECOVERY_INCLUDE

export const SUMMARY_RESERVE_INCLUDE = [
  'reserve_categories.reserve_category_type'
].join(',')

export const SUMMARY_RESERVES_INCLUDE = SUMMARY_RESERVE_INCLUDE

// Transactions

export const CLAIMANT_TRANSACTION_INCLUDE = [
  'exposure.party',
  // payee names
  'payment.payees',
  // payor name
  'recovery.payor.party.contact',
  'reserve_category.reserve_category_type',
  'transaction_sub_type',
  'transaction_type'
].join(',')

export const CLAIMANT_TRANSACTIONS_INCLUDE = CLAIMANT_TRANSACTION_INCLUDE

export const EXPOSURE_TRANSACTION_INCLUDE = [
  // payee names
  'payment.payees',
  // payor name
  'recovery.payor.party.contact',
  'reserve_category.reserve_category_type',
  'transaction_sub_type',
  'transaction_type'
].join(',')

export const EXPOSURE_TRANSACTIONS_INCLUDE = EXPOSURE_TRANSACTION_INCLUDE

export const RECOVERY_TRANSACTION_INCLUDE = [
  'entry_by',
  'recovery.pay_code',
  'recovery.payment_method',
  'recovery.payor.location',
  'recovery.payor.party.contact',
  'reserve_category.reserve_category_type',
  'reserve_category.statutory_code',
  'transaction_type',
  'transaction_sub_type'
].join(',')

export const RECOVERY_TRANSACTIONS_INCLUDE = RECOVERY_TRANSACTION_INCLUDE

export const RESERVE_TRANSACTION_INCLUDE = [
  'entry_by',
  'reserve_category.statutory_code',
  'transaction_type',
  'transaction_sub_type'
].join(',')

export const RESERVE_TRANSACTIONS_INCLUDE = RESERVE_TRANSACTION_INCLUDE
