import axios from 'axios'
import store from '@/store'
import { MUTATIONS } from '@/modules/claims/store/modules/mutation-types'

export default {
  /**
   * Wraps an Axios client and handles displaying all non 400 relatad errors from one place by commiting a vuex mutation
   * @param {String} method           the request method
   * @param {String} endpoint         the endpoint
   * @param {Object} data             the request data.
   * @param {Object} config           the Axios configuration object.
   * @returns {Promise<any>}          the Axios promise.
   */
  async execute (method, endpoint, data, config) {
    try {
      const response = await axios({
        method,
        url: endpoint,
        data,
        ...config
      })
      return response.data
    } catch (error) {
      let errorMessage
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2x
        if (error.response.status === 400) {
          throw error.response.data
        } else if (error.response.status === 401) {
          errorMessage = 'Authorization Required'
        } else if (error.response.status === 403) {
          errorMessage = 'Forbidden resource can\'t be accessed.'
        } else if (error.response.status === 404) {
          errorMessage = 'The requested resource not found.'
        } else if (error.response.status === 500) {
          errorMessage = 'Internal Server Error.'
        } else {
          errorMessage = 'Service unavailable'
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser
        // "Network Error" will fall here
        errorMessage = error.message
      } else {
        // Something happened in setting up the request that triggered an Error
        throw error.message
      }
      store.commit(MUTATIONS.SET_ERROR, errorMessage)
      return null
    }
  }
}
