import {delay} from '@/modules/claims/utils/promise-delay'
import {_mockPoliciesMaintenanceMutations} from './mutation-types'
import {_mockPoliciesMaintenanceActions} from './action-types'
import {policiesApi} from '@/modules/claims/utils/api'
import {contacts} from './__fixtures__/contacts'

export default {
  [_mockPoliciesMaintenanceActions.GET_POLICIES] ({commit, state}) {
    const searchConfig = {
      page: state.searchPolicy.page,
      page_size: state.searchPolicy.pageSize,
      orderBy: state.searchPolicy.orderBy
    }
    if (state.searchPolicy.queryType && state.searchPolicy.query) {
      searchConfig[state.searchPolicy.queryType] = state.searchPolicy.query
    }
    commit(_mockPoliciesMaintenanceMutations.CLEAR_POLICIES)
    return policiesApi.get('search/', {params: searchConfig}).then(response => {
      commit(_mockPoliciesMaintenanceMutations.SET_POLICIES, response['results'])
    })
  },
  [_mockPoliciesMaintenanceActions.GET_POLICY_BY_ID] ({commit}, id) {
    return policiesApi.get(`revisions/${id}/`).then(response => {
      commit(_mockPoliciesMaintenanceMutations.SET_POLICY, response)
      return response
    })
  },
  [_mockPoliciesMaintenanceActions.SAVE_POLICY] ({commit, state, dispatch}, {selectedPolicy, policy}) {
    let policyTerm = {
      effective_date: policy.effectiveDate,
      expiration_date: policy.expirationDate
    }
    let additionalInsuredsList = []
    let namedInsuredsList = []
    if (policy.additionalInsureds.length > 0) {
      for (let additionalInsured of policy.additionalInsureds) {
        additionalInsuredsList.push({
          contact: additionalInsured.contact,
          driver: additionalInsured.driver
        })
      }
    }
    if (policy.nameInsureds.length > 0) {
      for (let nameInsured of policy.nameInsureds) {
        namedInsuredsList.push({
          contact: nameInsured.contact,
          driver: nameInsured.driver
        })
      }
    }
    let fileList = []
    if (policy.files.length > 0) {
      let defaultLabel = 'Declaration'
      for (let file of policy.files) {
        fileList.push({
          declaration: file.declaration,
          title: file.title,
          label: file.label ? file.label : defaultLabel,
          mime_type: file.mime_type,
          url: file.url
        })
      }
    }
    const policyData = {
      number: policy.number,
      description: policy.description,
      policy_type_id: policy.policyType,
      policy_term: policyTerm,
      policy_status_id: policy.policyStatus,
      primary_insured_contact: {
        contact: policy.primaryInsured,
        driver: policy.primaryInsuredDriver
      },
      named_insured_contacts: namedInsuredsList,
      additional_insured_contacts: additionalInsuredsList,
      agency_contact: {contact: policy.agency},
      agent_contact: {contact: policy.agent},
      files: fileList
    }
    if (selectedPolicy) {
      return policiesApi.patch(`revisions/${selectedPolicy.id}/`, policyData).then(response => {
        dispatch(_mockPoliciesMaintenanceActions.GET_POLICIES)
        return response['results']
      })
    } else {
      return policiesApi.post('/', policyData).then(response => {
        dispatch(_mockPoliciesMaintenanceActions.GET_POLICIES)
        return response['results']
      })
    }
  },
  [_mockPoliciesMaintenanceActions.GET_POLICY_TYPES] ({commit}) {
    policiesApi.get('policy-types/').then(response => {
      commit(_mockPoliciesMaintenanceMutations.SET_POLICY_TYPES, response['results'])
      return response['results']
    })
  },
  [_mockPoliciesMaintenanceActions.GET_INDIVIDUAL_CONTACTS] ({commit}) {
    return Promise.resolve(contacts)
      .then(delay(100))
      .then(modules => {
        commit(_mockPoliciesMaintenanceMutations.SET_INDIVIDUAL_CONTACTS, modules)
      })
  },
  [_mockPoliciesMaintenanceActions.GET_AGENCIES] ({commit}) {
    return Promise.resolve(contacts)
      .then(delay(100))
      .then(modules => {
        commit(_mockPoliciesMaintenanceMutations.SET_AGENCIES, modules)
      })
  },
  [_mockPoliciesMaintenanceActions.GET_AGENTS] ({commit}) {
    return Promise.resolve(contacts)
      .then(delay(100))
      .then(modules => {
        commit(_mockPoliciesMaintenanceMutations.SET_AGENTS, modules)
      })
  },
  [_mockPoliciesMaintenanceActions.GET_POLICY_STATUSES] ({commit}) {
    policiesApi.get('policy-statuses/')
      .then(response => {
        commit(_mockPoliciesMaintenanceMutations.SET_POLICY_STATUSES, response['results'])
        return response['results']
      })
  },
  [_mockPoliciesMaintenanceActions.GET_DOCUMENT_TYPES] ({commit}) {
    return Promise.resolve([{
      id: 1,
      label: 'Pdf',
      type: 'application/pdf'
    }, {
      id: 2,
      label: 'Image',
      type: 'application/img'
    }])
      .then(delay(100))
      .then(modules => {
        commit(_mockPoliciesMaintenanceMutations.SET_DOCUMENT_TYPES, modules)
      })
  },
  [_mockPoliciesMaintenanceActions.SAVE_POLICY_TYPE] ({commit, state, dispatch}, policyType) {
    if (policyType.id) {
      // update policyType
      return policiesApi.patch(`policy-types/${policyType.id}/`, policyType).then(response => {
        commit(_mockPoliciesMaintenanceMutations.SET_POLICY_TYPES, null)
        dispatch(_mockPoliciesMaintenanceActions.GET_POLICY_TYPES)
        return response['results']
      })
    } else {
      // create policyType
      return policiesApi.post('policy-types/', policyType).then(response => {
        commit(_mockPoliciesMaintenanceMutations.SET_POLICY_TYPES, null)
        dispatch(_mockPoliciesMaintenanceActions.GET_POLICY_TYPES)
        return response['results']
      })
    }
  },
  [_mockPoliciesMaintenanceActions.DELETE_POLICY_TYPE] ({commit, state, dispatch}, id) {
    return policiesApi.delete(`policy-types/${id}/`).then(response => {
      commit(_mockPoliciesMaintenanceMutations.SET_POLICY_TYPES, null)
      dispatch(_mockPoliciesMaintenanceActions.GET_POLICY_TYPES)
      return response['results']
    })
  },
  [_mockPoliciesMaintenanceActions.GET_POLICY_TYPE_BY_ID] ({commit}, id) {
    return policiesApi.get(`policy-types/${id}/`).then(response => {
      return response
    })
  },
  [_mockPoliciesMaintenanceActions.SAVE_POLICY_STATUS] ({commit, state, dispatch}, policyStatus) {
    if (policyStatus.id) {
      // update policy status
      return policiesApi.patch(`policy-statuses/${policyStatus.id}/`, policyStatus).then(response => {
        commit(_mockPoliciesMaintenanceMutations.SET_POLICY_STATUSES, null)
        dispatch(_mockPoliciesMaintenanceActions.GET_POLICY_STATUSES)
        return response['results']
      })
    } else {
      // create policy status
      return policiesApi.post('policy-statuses/', policyStatus).then(response => {
        commit(_mockPoliciesMaintenanceMutations.SET_POLICY_STATUSES, null)
        dispatch(_mockPoliciesMaintenanceActions.GET_POLICY_STATUSES)
        return response['results']
      })
    }
  },
  [_mockPoliciesMaintenanceActions.DELETE_POLICY_STATUS] ({commit, state, dispatch}, id) {
    return policiesApi.delete(`policy-statuses/${id}/`).then(response => {
      commit(_mockPoliciesMaintenanceMutations.SET_POLICY_STATUSES, null)
      dispatch(_mockPoliciesMaintenanceActions.GET_POLICY_STATUSES)
      return response['results']
    })
  },
  [_mockPoliciesMaintenanceActions.GET_POLICY_STATUS_BY_ID] ({commit}, id) {
    return policiesApi.get(`policy-statuses/${id}/`).then(response => {
      return response
    })
  }
}
