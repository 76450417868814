import {ADMIN_SETUP_PATH} from '../constants'
import {addNamespace} from '@/store/helpers'

export const _adminSetupMutations = {
  SET_BREADCRUMB: 'setBreadcrumb',
  SET_ADMIN_SETUP_MODULES: 'setAdminSetupModules',
  UPDATE_MODULES_SEARCH_QUERY: 'updateModulesSearchQuery'
}

export const ADMIN_SETUP_MUTATIONS = addNamespace(
  ADMIN_SETUP_PATH,
  _adminSetupMutations
)
