export function roles (state, newRoles) {
  state.roles = newRoles
}

export function permissions (state, permissions) {
  state.permissions = permissions
}

export function version (state, version) {
  state.version = version
}

export function carrier (state, { name, website }) {
  state.carrier = { name, website }
}

export function metaData (state, {
  live_status: status,
  live_status_description: description,
  claim_prefix: claimPrefix,
  policy_prefix: policyPrefix,
  enable_analytics: analyticsEnabled,
  track_agents_in_pendo: trackAgentsInPendo,
  whitelisted_for_pendo: whitelistedForPendo
}) {
  state.metaData = {
    liveStatus: { status, description },
    claimPrefix,
    policyPrefix,
    analyticsEnabled,
    trackAgentsInPendo,
    whitelistedForPendo
  }
}

export function setContactInfo (state, contactInfo) {
  state.contact = { ...contactInfo }
}

export function setRecentPolicySearches (state, recentPolicies) {
  state.recentPolicies = recentPolicies
}

export function setRecentClaimSearches (state, recentClaims) {
  state.recentClaims = recentClaims
}

export function setRecentContactSearches (state, recentContacts) {
  state.recentContacts = recentContacts
}

export function setRecentSearches (state, searchResults) {
  state.searchResults = searchResults
}

export function setEffectiveDates (state, effectiveDates) {
  state.effectiveDates = effectiveDates
}
export function setLines (state, lines) {
  state.lines = lines
}
export function setBusinessLocations (state, businessLocations) {
  state.businessLocations = businessLocations
}

export function setMassDeliverablesTable (state, massDeliverablesTable) {
  state.massDeliverablesTable = massDeliverablesTable
}
