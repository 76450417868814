import { state } from './state'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import linePreviewModule from './preview'
import productMappingModule from './product-mapping'
import productExportsModule from './product-exports'

const linesModule = {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
  modules: {
    preview: linePreviewModule,
    productmapping: productMappingModule,
    productexports: productExportsModule
  }
}

export default linesModule
