import contactsService from '@/api/contacts'

export const actions = {
  async getContactsInfo ({ commit }, { contactId }) {
    await contactsService.getContact(contactId).then(data => {
      commit('setContactsInfo', data)
    }, error => {
      console.log(error)
    })
  },
  async fetchRevisionContactsInfo ({ commit }, { revisionId }) {
    await contactsService.getRevisionContacts(revisionId).then(r => {
      commit('setRevisionContactsInfo', { revisionId, contactsInfo: r.data })
    }, error => {
      console.log(error.data)
    })
  }
}
