import { state } from './state'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { ruleEditorContextStore } from './ruleEditorContextStore'

export const componentEditorStore = {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
  modules: {
    ruleEditorContext: ruleEditorContextStore
  }
}
