const AllModulesLandingPage = () => import('@/pages/all-modules/AllModulesLandingPage')

export default [
  {
    path: '',
    name: 'all-modules',
    component: AllModulesLandingPage,
    meta: {
      layout: 'full-page'
    }
  }
]
