import _ from 'lodash'

import {
  CHANGE_TYPE_ADDED,
  CHANGE_TYPE_REMOVED,
  ENTITY_GROUP_FIELDS,
  ENTITY_GROUP_ITEMS,
  FIELD_OPTIONS_KEY,
  OLD_VALUE_KEY,
  NEW_VALUE_KEY,
  TOTAL_REMOVED_CHANGES
} from '@/pages/lines/product-mapping/constants'

export const getters = {
  getFieldsAddedFlat: (state, getters) => (riskTypeName) => {
    const fieldsAdded = getters.getFieldsAdded(riskTypeName)
    return _.map(fieldsAdded, fieldAdded => {
      return fieldAdded[NEW_VALUE_KEY]
    })
  },
  getFieldsRemovedFlat: (state, getters) => (riskTypeName) => {
    const fieldsRemoved = getters.getFieldsRemoved(riskTypeName)
    return _.map(fieldsRemoved, fieldRemoved => {
      return fieldRemoved[OLD_VALUE_KEY]
    })
  },
  getFieldsAdded: (state, getters) => (riskTypeName) => {
    const addedChanges = getters.getAddedChangesForEntityGroup(riskTypeName, ENTITY_GROUP_FIELDS)
    return _.pickBy(addedChanges, (fieldDifference, _) => fieldDifference.hasOwnProperty(NEW_VALUE_KEY))
  },
  getFieldsRemoved: (state, getters) => (riskTypeName) => {
    const removedChanges = getters.getRemovedChangesForEntityGroup(riskTypeName, ENTITY_GROUP_FIELDS)
    return _.pickBy(removedChanges, (fieldDifference, _) => fieldDifference.hasOwnProperty(OLD_VALUE_KEY))
  },
  getFieldOptionsAdded: (state, getters) => (riskTypeName, fieldName) => {
    const options = _.get(getters.getFieldsWhereOptionsAdded(riskTypeName)[fieldName], FIELD_OPTIONS_KEY, {})
    return _.map(options, option => {
      return option[NEW_VALUE_KEY]
    })
  },
  getFieldOptionsRemoved: (state, getters) => (riskTypeName, fieldName) => {
    const options = _.get(getters.getFieldsWhereOptionsRemoved(riskTypeName)[fieldName], FIELD_OPTIONS_KEY, {})
    return _.map(options, option => {
      return option[OLD_VALUE_KEY]
    })
  },
  getFieldsUpdated: (state, getters) => (riskTypeName) => {
    return getters.getFieldsWhereOptionsRemoved(riskTypeName)
  },
  getFieldsWhereOptionsAdded: (state, getters) => (riskTypeName) => {
    const addedChanges = getters.getAddedChangesForEntityGroup(riskTypeName, ENTITY_GROUP_FIELDS)
    return _.pickBy(addedChanges, (fieldDifference, _) => fieldDifference.hasOwnProperty(FIELD_OPTIONS_KEY))
  },
  getFieldsWhereOptionsRemoved: (state, getters) => (riskTypeName) => {
    const removedChanges = getters.getRemovedChangesForEntityGroup(riskTypeName, ENTITY_GROUP_FIELDS)
    return _.pickBy(removedChanges, (fieldDifference, _) => fieldDifference.hasOwnProperty(FIELD_OPTIONS_KEY))
  },
  getAddedChangesForEntityGroup: (state, getters) => (riskTypeName, entityGroupName) => {
    const addedChanges = getters.getAddedChanges(riskTypeName)
    return _.get(addedChanges, entityGroupName, {})
  },
  getRemovedChangesForEntityGroup: (state, getters) => (riskTypeName, entityGroupName, productName = null) => {
    const removedChanges = getters.getRemovedChanges(riskTypeName, productName)
    return _.get(removedChanges, entityGroupName, {})
  },
  getAddedChanges: (state, getters) => (riskTypeName) => {
    const riskTypeDifference = getters.getRiskTypeDifference(riskTypeName)
    return _.get(riskTypeDifference, CHANGE_TYPE_ADDED, {})
  },
  getRemovedChanges: (state, getters) => (riskTypeName, productName = null) => {
    const riskTypeDifference = getters.getRiskTypeDifference(riskTypeName, productName)
    return _.get(riskTypeDifference, CHANGE_TYPE_REMOVED, {})
  },
  getRiskTypeDifference: (state) => (riskTypeName, productName = null) => {
    let difference = state.activeProductDifference
    if (productName) {
      difference = state.productDifference[productName]
    }
    return _.get(difference, riskTypeName, {})
  },
  namesOfRiskTypesHavingDifference (state) {
    return _.keys(state.activeProductDifference)
  },
  getVisibleRiskTypesTreeNode: (state, getters) => (nodeId) => {
    const node = getters.getRiskTypesTreeNode(nodeId)
    return node && node.visible ? node : null
  },
  getRiskTypesTreeNode: (state) => (nodeId) => {
    return state.riskTypesTree.getNode(nodeId)
  },
  countRemovedChangesForRiskType: (state, getters) => (riskTypeName, productName = null) => {
    const changes = {
      [ENTITY_GROUP_FIELDS]: 0,
      [ENTITY_GROUP_ITEMS]: 0,
      [TOTAL_REMOVED_CHANGES]: 0
    }
    const removedFieldChanges = getters.getRemovedChangesForEntityGroup(riskTypeName, ENTITY_GROUP_FIELDS, productName)
    changes[ENTITY_GROUP_FIELDS] = Object.keys(removedFieldChanges).length

    const removedItemChanges = getters.getRemovedChangesForEntityGroup(riskTypeName, ENTITY_GROUP_ITEMS, productName)
    changes[ENTITY_GROUP_ITEMS] = Object.keys(removedItemChanges).length

    changes[TOTAL_REMOVED_CHANGES] = changes[ENTITY_GROUP_FIELDS] + changes[ENTITY_GROUP_ITEMS]
    return changes
  },
  countRemovedChangesForProduct: (state, getters) => (productName) => {
    let changes = 0
    const difference = state.productDifference[productName]
    for (const riskTypeName in difference) {
      changes += getters.countRemovedChangesForRiskType(riskTypeName, productName)[TOTAL_REMOVED_CHANGES]
    }
    return changes
  }
}
