import _ from 'lodash'

const NAVIGATION_KEY = 'quote_flow_navigation'

/**
 * Return the global navigation state, which is represented like this:
 *
 *   {
 *     'quote-1': {
 *       'requiredSteps': ['init', 'step2', 'step3', 'finish'],
 *       'completedSteps': ['init', 'step2']
 *     },
 *     'quote-2': {
 *       'requiredSteps': ['init', 'step2', 'step3', 'finish'],
 *       'completedSteps': []
 *     },
 *     ...
 *   }
 *
 * @returns {Object} The navigation state.
 */
export function getNavigationState () {
  return JSON.parse(localStorage.getItem(NAVIGATION_KEY)) || {}
}

/**
 * Return the navigation state for a given quote, which is represented like this:
 *
 *   {
 *     'requiredSteps': ['init', 'step2', 'step3', 'finish'],
 *     'completedSteps': ['init', 'step2']
 *   }
 *
 * @param {string} quoteNumber
 * @returns {Object} The navigation state for the quote.
 */
export function getNavigationStateForQuote (quoteNumber) {
  return _.get(getNavigationState(), quoteNumber)
}

/**
 * Overwrite the navigation state for a given quote in local storage.
 * @param {string} quoteNumber
 * @param {object} quoteNavigationState
 */
export function setNavigationStateForQuote (quoteNumber, quoteNavigationState) {
  const navigationState = getNavigationState()
  navigationState[quoteNumber] = quoteNavigationState
  localStorage.setItem(NAVIGATION_KEY, JSON.stringify(navigationState))
}

/**
 * Generate the list of required steps from the `quoteFlowData`.
 *
 * @param {object} quoteFlowData
 */
export function getRequiredSteps (quoteFlowData) {
  return _.flatten(
    quoteFlowData.pageGroups.map(pageGroup => {
      return pageGroup.pages.map(page => {
        return makeStepName(pageGroup.name, page.name)
      })
    })
  )
}

/**
 * Compute the navigation state based on the steps defined on `quoteFlowData`
 * and initialize the local storage with that state.
 *
 * @param {string} quoteNumber
 * @param {object} quoteFlowData
 */
export function initializeNavigationState (quoteNumber, quoteFlowData, isQuoteBound) {
  // Skip checking for empty navigation state when quote is bound as all steps will be marked as completed
  // for the bound quote.
  if (!isQuoteBound && !navigationStateForQuoteIsEmpty(quoteNumber)) {
    return
  }

  const requiredSteps = getRequiredSteps(quoteFlowData)
  setNavigationStateForQuote(quoteNumber, {
    requiredSteps: requiredSteps,
    completedSteps: isQuoteBound ? requiredSteps : []
  })
}

/**
 * Return whether the global navigation state is initialized or not.
 * @returns {boolean}
 */
export function navigationStateIsEmpty () {
  return _.isNull(localStorage.getItem(NAVIGATION_KEY))
}

/**
 * Return whether the navigation state for a given quote is initialized or not.
 * @param {string} quoteNumber
 * @returns {boolean}
 */
export function navigationStateForQuoteIsEmpty (quoteNumber) {
  return navigationStateIsEmpty() || _.isUndefined(getNavigationStateForQuote(quoteNumber))
}

/**
 * Build a step name based on `pageGroupName` and `pageName`.
 * @param {string} pageGroupName
 * @param {string} pageName
 * @returns {string} The step name.
 */
export function makeStepName (pageGroupName, pageName) {
  return `${pageGroupName}/${pageName}`
}

/**
 * Mark a step as completed in the state for a given quote.
 * @param {string} quoteNumber
 * @param {string} targetStepName
 */
export function completeStep (quoteNumber, targetPageGroupName, targetPageName) {
  const targetStepName = makeStepName(targetPageGroupName, targetPageName)
  const quoteNavigationState = getNavigationStateForQuote(quoteNumber)
  const completedAlready = quoteNavigationState.completedSteps.find(step => step === targetStepName)
  if (!completedAlready) {
    quoteNavigationState.completedSteps.push(targetStepName)
    setNavigationStateForQuote(quoteNumber, quoteNavigationState)
  }
}

/**
 * Return whether an user is authorized to navigate to a given step. The
 * condition for navigation to a step N is that the user should have gone
 * through all the N-1 steps preceding that one.
 * @param {string} quoteNumber
 * @param {string} targetStepName
 */
export function canNavigateTo (quoteNumber, targetPageGroupName, targetPageName) {
  const navigationState = getNavigationStateForQuote(quoteNumber)
  if (_.isUndefined(navigationState)) {
    return false
  }

  const targetStepName = makeStepName(targetPageGroupName, targetPageName)
  const targetStepIndex = navigationState.requiredSteps.findIndex(step => step === targetStepName)
  const requiredPriorSteps = _.slice(navigationState.requiredSteps, 0, targetStepIndex)

  const canNavigate = requiredPriorSteps.every(step => navigationState.completedSteps.includes(step))
  return canNavigate
}

export function isStepCompleted (quoteNumber, targetPageGroupName, targetPageName) {
  const navigationState = getNavigationStateForQuote(quoteNumber)
  if (_.isUndefined(navigationState)) {
    return false
  }
  const targetStepName = makeStepName(targetPageGroupName, targetPageName)
  return _.includes(navigationState.completedSteps, targetStepName)
}

/**
 * Clear completed steps, reset navigation.
 * @param {string} quoteNumber
 * @param {object} quoteFlow
 */
export function resetNavigationSteps (quoteNumber, quoteFlow) {
  setNavigationStateForQuote(quoteNumber, {
    requiredSteps: getRequiredSteps(quoteFlow),
    completedSteps: []
  })
}
