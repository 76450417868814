import { addNamespace } from '@/store/helpers'
import { LINES_STORE_PATH } from './constants'

// To be used only inside vuex modules
export const _mutationTypes = {
  SET_LINE: 'line',
  SET_PRODUCT: 'product',
  RESET_PRODUCT: 'resetProduct',
  SET_RISK_TYPE: 'riskType',
  SET_VERSIONS: 'versions',
  SET_VERSION: 'version',
  UPDATE_VERSION: 'updateVersion',
  SET_ACTIVE_VERSION: 'activeVersion',
  SET_LINE_OVERVIEW_ACTIVE_PRODUCT: 'addLineOverviewActiveProduct',
  SET_LINE_OVERVIEW_ACTIVE_PRODUCTS: 'updateLineOverviewActiveProducts',
  SET_FIELD_FORMATS: 'fieldFormats',
  SET_BREADCRUMBS: 'breadcrumbs',
  SET_PRODUCT_IMPORTS: 'productImports',
  SET_PRODUCT_IMPORT: 'productImport',
  REMOVE_PRODUCT_IMPORT: 'removeProductImport',
  REMOVE_ENTITY_FROM_RISK_TYPE: 'removeEntityFromRiskType',
  SET_GEN2_BUSINESS_LOCATIONS: 'setGen2BusinessLocations'
}

// To be used publicly outside vuex modules
export const mutationTypes = addNamespace(LINES_STORE_PATH, _mutationTypes)
