/**
 * BriteCore's custom extension of the Error class
 * There is one caveat: we have to redefine the class prototype
 * this is due to babel not being able to extend any built-in JS classes
 * see https://babeljs.io/docs/en/caveats/#classes
 * and https://stackoverflow.com/questions/1382107/whats-a-good-way-to-extend-error-in-javascript
 */

export class BriteCoreError extends Error {
  constructor (...params) {
    super(...params)
    this.name = 'BriteCoreError'

    // caveat
    Object.setPrototypeOf(this, BriteCoreError.prototype)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BriteCoreError)
    }
  }

  toJSON () {
    return {
      error: {
        name: this.name,
        message: this.message,
        stack: this.stack
      }
    }
  }
}

export class EntityNotFoundError extends BriteCoreError {
  constructor (...params) {
    super(...params)
    Object.setPrototypeOf(this, EntityNotFoundError.prototype)
    this.name = 'EntityNotFoundError'

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, EntityNotFoundError)
    }
  }
}

export class CorruptedDataError extends BriteCoreError {
  constructor (...params) {
    super(...params)
    Object.setPrototypeOf(this, CorruptedDataError.prototype)
    this.name = 'CorruptedDataError'

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CorruptedDataError)
    }
  }
}

export class PermissionDenied extends BriteCoreError {
  constructor (axiosError) {
    super('This operation is not allowed. (Error code: 403)')
    Object.setPrototypeOf(this, PermissionDenied.prototype)
    this.name = 'PermissionDenied'
    this.axiosError = axiosError
    this.redirectToForbidden = true

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PermissionDenied)
    }
  }

  preventRedirect () {
    this.redirectToForbidden = false
  }
}
