import Promise from 'bluebird'
import Vue from 'vue'
import moment from 'moment'
import router from '@/router'
import * as Sentry from '@sentry/browser'
import { state as myAccountState } from '@/store/my-account/state'
import { captureEffectiveRoleRecord } from '@/store/access'
import { find, isEmpty, get } from 'lodash'
import AWS from 'aws-sdk/global'
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js'
import { EXCEPTIONS } from './constants'
import Cookies from 'js-cookie'
import accessActions from '@/store/access/actionTypes'
import accessMutations from '@/store/access/mutationTypes'
import { ACCESS_MODULES_ACCESS_ATTEMPTS } from '@/pages/access/constants'
import { isGen2Route } from '@/router/utils'

const IDENTITY_POOL_ID = process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID
const LOGIN_KEY = `cognito-idp.${process.env.VUE_APP_COGNITO_REGION}.amazonaws.com/${process.env.VUE_APP_COGNITO_USER_POOL_ID}`
const LOGIN_STATES = {
  AUTHENTICATED: 'AUTH',
  NEW_PASS_REQ: 'NEW_PASSWORD_REQUIRED',
  MFA_REQ: 'MFA_REQUIRED',
  TOTP_REQ: 'SOFTWARE_TOKEN_MFA'
}
export const OAUTH_CLIENT_STATE_LOCAL_STORAGE_KEY = `BriteAuth.${process.env.VUE_APP_COGNITO_APP_CLIENT_ID}.state`

export const actions = {
  async refreshSession ({ commit, dispatch, rootGetters }, skipGen2Interaction = false) {
    const currentUser = await dispatch('getCurrentUser')

    return new Promise((resolve, reject) => {
      if (currentUser != null) {
        currentUser.getSession(async (error, session) => {
          if (error) {
            commit('currentUser', null)
            commit('session', null)
            reject(error)
          } else {
            commit('currentUser', Promise.promisifyAll(currentUser))
            commit('session', session)

            if (!skipGen2Interaction && rootGetters['bcClassic/shouldRetrieveNavLinksAndVersionFromGen2']) {
              await dispatch('retrieveSessionTimeoutSetting')
              commit('loading', true, { root: true })

              // This is the first API call being made after retrieving session timeout from Gen2
              // Await for this API call first, as it can trigger sign out flow in case session is expired
              await dispatch('bcClassic/retrieveNavigationLinks', null, { root: true })

              await Promise.all([
                dispatch('bcClassic/retrieveBriteCoreGen2Version', null, { root: true }),
                dispatch('bcClassic/retrieveGen2Contact', currentUser.username, { root: true }),
                dispatch('bcClassic/retrieveBriteCoreGen2MetaData', null, { root: true })
              ])

              commit('loading', false, { root: true })
            }
            resolve(session)
          }
        })
      } else {
        commit('currentUser', null)
        commit('session', null)
        resolve()
      }
    })
  },
  async authorizationCodeSuccess ({ dispatch }, redirectPath) {
    await dispatch('signInSuccessful', redirectPath)
  },

  authorizationCodeFailure ({ state, commit }, error) {
    router.replace({ name: 'login' }, async () => {
      console.error(error)

      Sentry.captureException(error)

      await Vue.nextTick()

      commit('lastSignInError', { message: 'We\'re sorry, but something went wrong while signing in.' })
      commit('isAuthenticating', false)
    })
  },

  storeClientState (_, { oauthClient, clientState }) {
    const nonce = oauthClient.generateRandomString(
      oauthClient.getCognitoConstants().STATELENGTH,
      oauthClient.getCognitoConstants().STATEORIGINSTRING
    )

    clientState.nonce = nonce
    const encodedOAuthClientState = window.btoa(JSON.stringify(clientState))

    localStorage.setItem(OAUTH_CLIENT_STATE_LOCAL_STORAGE_KEY, encodedOAuthClientState)
    oauthClient.setState(window.btoa(nonce))
  },

  validateAndRetrieveClientState (_, session) {
    const clientStateLocalStorageData = localStorage.getItem(OAUTH_CLIENT_STATE_LOCAL_STORAGE_KEY)
    localStorage.removeItem(OAUTH_CLIENT_STATE_LOCAL_STORAGE_KEY)

    const sessionState = window.atob(decodeURIComponent(session.state))
    const localState = JSON.parse(window.atob(clientStateLocalStorageData))

    if (localState.nonce !== sessionState) {
      throw new Error('Local state\'s nonce does not match received value')
    }

    return localState
  },

  async parseAndUseOAuthAuthorizationCode ({ state, dispatch, commit }) {
    const oauthClient = state.oauthClient
    commit('isAuthenticating', true)

    try {
      const session = await new Promise((resolve, reject) => {
        oauthClient.userhandler = {
          onSuccess: resolve,
          onFailure: message => reject(new Error(decodeURIComponent(message.replace(/\+/g, ' '))))
        }

        oauthClient.parseCognitoWebResponse(window.location.href)
      })

      const localClientState = await dispatch('validateAndRetrieveClientState', session)
      await dispatch('authorizationCodeSuccess', localClientState.redirectPath)
    } catch (e) {
      commit('isAuthenticating', false)
      dispatch('authorizationCodeFailure', e)
    }
  },

  async signInWith ({ commit, state, dispatch }, payload) {
    const { providerName, redirectPath } = payload
    const provider = find(state.oauthProviders, ['name', providerName])

    commit('isAuthenticating', true)
    commit('isBeingLoggedOut', false)

    const clientState = {
      redirectPath: redirectPath
    }

    const oauthClient = state.oauthClient
    await dispatch('storeClientState', { oauthClient, clientState })

    oauthClient.IdentityProvider = provider ? provider.id : providerName
    oauthClient.userhandler = {
      onSuccess: () => dispatch('authorizationCodeSuccess'),
      onFailure: (error) => dispatch('authorizationCodeFailure', error)
    }

    // This results in a redirect to the chosen provider for authentication.
    // Once authenticated, the user is redirected whence they came with extra
    // information contained in the URL fragments which will be read by
    // `amazon-cognito-auth-js` to provide authentication for the user.
    oauthClient.getSession()
  },
  signIn: async function ({ dispatch, state, commit }, payload) {
    commit('isAuthenticating', true)
    commit('isBeingLoggedOut', false)
    const { credentials } = payload
    const redirectPath = payload.redirectPath || '/'

    const cognitoUser = await dispatch('createNewCognitoUserObject', credentials.username)
    const authenticationDetails = new AuthenticationDetails({
      Username: credentials.username,
      Password: credentials.password
    })

    return new Promise((resolve, reject) => {
      return cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session) => {
          resolve({
            cognitoUser: cognitoUser,
            state: LOGIN_STATES.AUTHENTICATED,
            data: {
              session: session
            }
          })
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          resolve({
            cognitoUser: cognitoUser,
            state: LOGIN_STATES.NEW_PASS_REQ,
            data: {
              userAttributes: userAttributes,
              requiredAttribures: requiredAttributes
            }
          })
        },
        mfaRequired: (challengeName, challengeParam) => {
          resolve({
            cognitoUser: cognitoUser,
            state: LOGIN_STATES.MFA_REQ,
            data: challengeParam
          })
        },
        totpRequired: (challengeName, challengeParam) => {
          resolve({
            cognitoUser: cognitoUser,
            state: LOGIN_STATES.TOTP_REQ,
            data: challengeParam
          })
        },
        onFailure: reject
      })
    }).then(({cognitoUser, state, data}) => {
      if (state === LOGIN_STATES.AUTHENTICATED) {
        return dispatch('refreshAWSCredentials')
          .then(() => {
            return redirectPath
          }).catch((error) => {
            console.error(error)
          })
      } else if (state === LOGIN_STATES.NEW_PASS_REQ) {
        return {
          name: 'reset-password',
          params: {
            cognitoUser: cognitoUser,
            userAttributes: data.userAttributes,
            requiredAttribures: data.requiredAttribures
          },
          query: {
            redirectPath: redirectPath
          }

        }
      } else if (state === LOGIN_STATES.MFA_REQ || state === LOGIN_STATES.TOTP_REQ) {
        let deliveryMedium
        if (state === LOGIN_STATES.MFA_REQ) {
          deliveryMedium = myAccountState.mfaTypes.SMS
        } else {
          deliveryMedium = myAccountState.mfaTypes.SOFTWARE_TOKEN
        }

        return {
          name: 'mfa',
          query: {
            userSession: cognitoUser.Session,
            username: cognitoUser.username,
            redirectPath: redirectPath,
            medium: deliveryMedium,
            destination: data.CODE_DELIVERY_DESTINATION || data.FRIENDLY_DEVICE_NAME
          }
        }
      } else {
        return Error(`Unknown status: ${state}`)
      }
    }).then(async (goTo) => {
      if (typeof goTo === typeof '') {
        await dispatch('signInSuccessful', goTo)
      } else {
        router.push(goTo)
      }
    }).then(() => {
      commit('isAuthenticating', false)
    }).catch(async (error) => {
      if (error.code === EXCEPTIONS.PasswordResetRequired) {
        commit('isAuthenticating', false)
        if (payload.isPostMigrationSignIn) {
          // Password reset was prompted right after migration, this only occurs when user password is weak
          // and a verification code was sent to their email address to reset their password. Hence directing
          // user to Reset password with code form instead of Forgot password
          router.push({
            name: 'reset-password-with-code',
            params: {
              username: credentials.username,
              redirectPath,
              deliveryDestination: payload.userEmail,
              deliveryMedium: 'EMAIL',
              resetRequiredReason: 'For security reasons, please reset your password.'
            }
          })
        } else {
          router.push({name: 'force-reset-password'})
        }
      } else if (error.code === EXCEPTIONS.UserNotFound) {
        try {
          const response = await dispatch('userManagement/migrateBritecoreContact', credentials, {root: true})
          await dispatch('signIn', {...payload, isPostMigrationSignIn: true, userEmail: response.data.attributes.email})
        } catch (err) {
          err.message = get(
            err, 'response.data.errors[0].detail', 'We\'re sorry, but this username could not be found.'
          )
          commit('lastSignInError', err)
          commit('isAuthenticating', false)
        }
      } else {
        if (error.code === EXCEPTIONS.UserNotFound) {
          error.message = 'We\'re sorry, but this username could not be found.'
        }
        commit('lastSignInError', error)
        commit('isAuthenticating', false)
      }
    })
  },

  async signOut ({ commit, dispatch, state }) {
    commit('loading', true, { root: true })
    commit('isBeingLoggedOut', true)
    commit('access/clearEvaluationsResult', null, { root: true })

    // Refreshing the session can fail for multiple reasons (eg., the user's password was reset),
    // but none of these should ever prevent logging out from working
    try {
      await dispatch('refreshSession', true)
    } catch (error) {}

    if (state.currentUser) {
      let userData

      // This will also fail if the user's entire session is no longer valid
      try {
        userData = await state.currentUser.getUserDataAsync()
      } catch (error) {}

      await Promise.all([
        dispatch('clearAWSCredentials'),
        dispatch('bcClassic/signOut', null, { root: true })
      ]).reflect()
      localStorage.removeItem('EffectiveRole')
      Cookies.remove('EffectiveRole')
      state.oauthClient.signOut()

      if (userData) {
        const username = userData.Username
        const mfaEnabled = Boolean(userData.PreferredMfaSetting)

        dispatch('clearUserSessionCookies', { mfaEnabled, username })
      }
    } else {
      // This is safe operation even when user is not logged in. Takes care of redirection to login page
      state.oauthClient.signOut()
    }
  },
  getCurrentUser ({ state }) {
    return state.userPool.getCurrentUser()
  },

  createNewCognitoUserObject ({ state }, username) {
    return new CognitoUser({
      Username: username,
      Pool: state.userPool,
      Storage: state.userPool.storage
    })
  },

  async refreshAccessToken ({ dispatch }) {
    // Extend the validity of access token whenever the AWS session is still valid
    await dispatch('invalidateCachedCredentials')
    await dispatch('refreshSession', true)
  },

  async refreshAWSCredentials ({ dispatch }, skipRefreshForUserAttributeUpdates) {
    await dispatch('forceRefreshSessionIfNeeded', skipRefreshForUserAttributeUpdates)
    const currentUser = await dispatch('getCurrentUser')

    let credentials = AWS.config.credentials
    const hasGuestCredentials = credentials && isEmpty(credentials.params.Logins)

    if (!credentials || (currentUser && hasGuestCredentials)) {
      let logins = {}
      if (currentUser) {
        const session = await dispatch('refreshSession')
        logins[LOGIN_KEY] = session.getIdToken().getJwtToken()
      }

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IDENTITY_POOL_ID,
        Logins: logins
      })
    }

    return AWS.config.credentials.getPromise()
  },

  async retrieveSessionTimeoutSetting ({ commit, dispatch }) {
    let response = await dispatch('bcClassic/retrieveSettingsBySectionAndOption', { section: 'login', option: 'session-timeout' }, { root: true })
    const { data, success, message } = response.data
    if (success) {
      commit('setSessionTimeout', moment().add(data, 'minutes'))
    } else {
      if (message === 'Unauthorized request') {
        commit('setSessionTimeout', 0)
      }
    }
  },

  /**
   * Method to clear user cookies when the user signs out.
   */
  clearUserSessionCookies ({ state }, { mfaEnabled, username }) {
    const cognitoCookiesPrefix = 'CognitoIdentityServiceProvider'
    const deviceRemembranceCookieSuffixes = ['deviceKey', 'randomPasswordKey', 'deviceGroupKey']

    Object.keys(Cookies.get()).forEach(cookieName => {
      const isCognitoRelatedCookie = cookieName.startsWith(cognitoCookiesPrefix)
      const isCookieRelatedToCorrectAppClient = cookieName.startsWith(`${cognitoCookiesPrefix}.${state.oauthClient.clientId}`)
      const isDeviceRemembranceCookie = Boolean(deviceRemembranceCookieSuffixes.find(
        suffix => cookieName.endsWith(suffix)))
      const isLoggedInUsersDeviceCookie = Boolean(deviceRemembranceCookieSuffixes.find(
        suffix => cookieName.endsWith(`${username}.${suffix}`)))

      const cookieShouldBeDeleted =
        isCognitoRelatedCookie &&
        (!isDeviceRemembranceCookie ||
          !isCookieRelatedToCorrectAppClient ||
          (isLoggedInUsersDeviceCookie && !mfaEnabled))

      if (cookieShouldBeDeleted) {
        Cookies.remove(cookieName)
      }
    })
  },

  clearAWSCredentials () {
    if (!AWS.config.credentials) {
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IDENTITY_POOL_ID
      })
    }

    AWS.config.credentials.clearCachedId()
    delete AWS.config.credentials
  },
  async invalidateCachedCredentials ({ commit, dispatch }) {
    const currentUser = await dispatch('getCurrentUser')

    commit('clearAccessToken', currentUser)
    dispatch('clearAWSCredentials')
  },
  async forceRefreshSessionIfNeeded ({ state, dispatch, getters }, skipRefreshForUserAttributeUpdates) {
    const invalidateAndRefreshSession = async () => {
      dispatch('invalidateCachedCredentials')
      try {
        await dispatch('refreshSession')
      } catch (error) {
        dispatch('signOut')
      }
    }

    const isAuthenticated = getters['isAuthenticated']
    if (isAuthenticated) {
      const currentTime = moment()
      let expiryTime = getters['accessTokenExpiry']

      if (state.sessionTimeout && state.sessionTimeout < expiryTime) {
        expiryTime = state.sessionTimeout
      }

      if (currentTime > expiryTime) {
        return invalidateAndRefreshSession()
      }

      if (!skipRefreshForUserAttributeUpdates) {
        const attributes = await state.currentUser.getUserAttributesAsync()
        const updatedAt = attributes.find(attribute => attribute.Name === 'updated_at')
        if (updatedAt && updatedAt.Value > state.session.getIdToken().decodePayload().iat) {
          return invalidateAndRefreshSession()
        }
      }
    }
  },
  /**
   * Method called on successful sign in to determine where the user should be redirected next.
   *
   * @param {string} redirectPath - string containing the destination path
   */
  async signInSuccessful ({state, dispatch, commit}, redirectPath = '/?pageReload=true') {
    await dispatch('refreshSession')
    await dispatch('cacheProfilePicture', state.currentUser)
    commit('loading', true, { root: true })

    if (process.env.VUE_APP_ENABLED_MODULE_ACCESS) { // let BriteAccess handle redirection
      await dispatch('captureEffectiveRole', redirectPath)
    } else {
      // Redirect user to proceed with navigation normally.
      dispatch('setWindowLocation', redirectPath, { root: true })
    }
  },
  /**
   * Redirect the user to the select role page.
   *
   * @param {string} redirectPath - string containing the destination path
   */
  async proceedToSelectRole ({ dispatch }, redirectPath) {
    dispatch('setWindowLocation', await dispatch('buildSelectRolePageUrl', redirectPath), { root: true })
  },
  /**
   * Generate a URL string so that users are redirected to the correct destination after picking
   * a role in the select role page.
   *
   * @param {object|string} route - string containing the destination path.
   */
  async buildSelectRolePageUrl (_, redirectPath = '') {
    const encodedRedirectPath = encodeURIComponent(redirectPath)
    let redirectQueryString = ''

    if (process.env.VUE_APP_ENABLED_MODULE_ACCESS) {
      if (redirectPath && !redirectPath.startsWith('/access/select-role')) {
        redirectQueryString = `?redirectTo=${encodedRedirectPath}`
      }
      return `/access/select-role${redirectQueryString}`
    } else {
      if (redirectPath && !redirectPath.startsWith('/login/ko_roleSelect')) {
        redirectQueryString = `?redirect=${encodedRedirectPath}`
      }
      return `/login/ko_roleSelect${redirectQueryString}`
    }
  },
  /**
   * If state.gen2EffectiveRole is not set, try to set it using the chosen_role cookie.
   * If the chosen_role cookie is not set either, the user is redirected to the role selection page.
   *
   * @param {object} toRoute - vue router object; fullPath attribute has the user's destination.
   */
  updateGen2EffectiveRoleThenProceedToRoute ({ dispatch, state, commit }, toRoute) {
    const gen2SelectRolePath = '/login/ko_roleSelect'
    const gen3SelectRolePath = '/access/select-role'

    const redirectPath = toRoute.fullPath

    if (redirectPath.startsWith(gen2SelectRolePath) ||
        redirectPath.startsWith(gen3SelectRolePath)) {
      return
    }

    if (state.gen2EffectiveRole) return

    const chosenRole = Cookies.get('chosen_role')

    if (chosenRole) {
      commit('setGen2EffectiveRole', chosenRole)
    } else if (process.env.NODE_ENV === 'production' && !process.env.VUE_APP_IS_QA) {
      dispatch('proceedToSelectRole', redirectPath)
    } else {
      commit('setGen2EffectiveRole', process.env.VUE_APP_GEN2_EFFECTIVE_ROLE)
    }
  },

  /**
   * Fetches and stores the profile picture URL of the user in LocalStorage. This allows Gen2 to use the URL
   * to show the profile picture as right now we don't have a way to get it in Gen2.
   */
  async cacheProfilePicture (context, currentUser) {
    if (!currentUser) return
    try {
      const attributes = await currentUser.getUserAttributesAsync()
      const pictureUrl = attributes.find(attribute => attribute.Name === 'picture').Value
      localStorage.setItem('briteauth:profile-picture-url', pictureUrl)
    } catch (err) {
      console.warn('Unable to cache profile picture', get(err, 'message', 'No error message'))
    }
  },
  /**
   * Method to determine the user Gen 3 role and update Access state.
   */
  async captureEffectiveRole ({dispatch, commit, state}, redirectPath = '/?pageReload=true') {
    let effectiveRoleRecord
    try {
      effectiveRoleRecord = await captureEffectiveRoleRecord(state.currentUser, state.currentUserGroups)
      if (effectiveRoleRecord) {
        console.info(`automatically captured Effective Role ${effectiveRoleRecord.name} for user ${state.currentUser.username}`)
        commit(accessMutations.SET_EFFECTIVE_ROLE, effectiveRoleRecord.name, {root: true})
        await dispatch(accessActions.SELECT_USER_ROLE, effectiveRoleRecord.name, { root: true })
        await dispatch('bcClassic/setRole', effectiveRoleRecord.gen2_role, { root: true })
      } else {
        console.warn(`unable to automatically capture Effective Role for user ${state.currentUser.username}: redirecting to prompt`)
        await dispatch('proceedToSelectRole', redirectPath)
        return
      }
    } catch (error) {
      console.warn(`ignoring failure while attempting to capture effective role (this will soon prevent login): ${error.message}`)
      // TODO: in the future, prevent navigation from proceeding if effective role capturing fails. Assuming relaxed constraints for now.
      dispatch('proceedToSelectRole', redirectPath)
      return
    }

    // Effective role could be determined automatically: carry on without interfering with the flow.

    if (isGen2Route(redirectPath)) {
      await dispatch(accessActions.EVALUATE_ACCESS_ATTEMPTS, ACCESS_MODULES_ACCESS_ATTEMPTS, { root: true })
    }
    dispatch('setWindowLocation', redirectPath, { root: true })
  }
}
