
export const state = {
  integrations: {
    loading: false,
    integrations: [],
    currentIntegration: {}
  },
  events: {
    loading: false,
    subscriptions: [],
    subscriptionMessages: {} // { [subscription_id]: [{ data: [...messages], loading: false }] }
  },
  plugins: {
    loading: false,
    registered: [],
  },
  mappings: {
    loading: false,
    registered: [],
    edited: {}
  },
  vendors: {
    loading: false,
    redirectUrl: '',
  },
  esignatureConfig: {}
}
