// Others

export const DEFAULT_FNOL_STEPS = [
  {
    'name': 'policy_search',
    'title': 'Policy Search',
    'page': 'policy-search',
    'summary': {
      'component': 'policy-search-summary'
    }
  },
  {
    'name': 'reported_by',
    'title': 'Reported By',
    'page': 'reported-by',
    'summary': {
      'component': 'reported-by-summary'
    }
  }
]
export const DEFAULT_LAST_STEP = [
  {
    'name': 'manual_claim_number',
    'title': 'Submit Claim',
    'page': 'manual-claim-number'
  }
]

export const CLAIM_LIST_BY = [
  {
    value: 'my_assigned_claims',
    label: 'My assigned claims'
  },
  {
    value: 'all_claims',
    label: 'All claims'
  },
  {
    value: 'recently_viewed_claims',
    label: 'Recently viewed claims'
  }
]
