import axios from 'axios'
import moment from 'moment'

const API_URL = process.env.VUE_APP_API_URL
const API_DATE_FORMAT = 'YYYY-MM-DD'

const linePreviewService = {
  /**
   * Creates a new scenario
   * @param productName
   * @param versionId
   * @param scenarioName
   * @returns {Promise<Array>} Promise object that receives the scenario
   */
  newScenario: (productName, versionId, scenarioName) => {
    const url = `${API_URL}/lines/products/${productName}/scenarios/`
    const formData = {
      name: scenarioName
    }
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, formData, { params: params }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Copy a scenario.
   * @param productName
   * @param scenarioId
   * @param versionId
   * @returns {Promise<Array>} Promise object that receives copied scenario result
   */
  copyScenario: (productName, scenarioId, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/scenarios/${scenarioId}/copy/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, {}, { params }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Removes a scenario.
   * @param productName
   * @param scenarioId
   * @param versionId
   * @returns {Promise<Array>} Promise object that receives remove scenario result
   */
  removeScenario: (productName, scenarioId, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/scenarios/${scenarioId}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.delete(url, { params: params }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Updates a Scenario.
   * @param productName
   * @param scenarioId
   * @param versionId
   * @param formData
   * @returns {Promise<Array>} Promise object that receives updated Scenario.
   */
  updateScenario: (productName, scenarioId, versionId, formData) => {
    const url = `${API_URL}/lines/products/${productName}/scenarios/${scenarioId}/`
    const params = { version_id: versionId }
    if (formData['effective_date']) {
      formData['effective_date'] = moment(formData['effective_date']).format(API_DATE_FORMAT)
    }
    return new Promise((resolve, reject) => {
      axios.put(url, formData, { params }).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  },

  /**
   * Get scenarios for a given productName and versionId
   * @param productName
   * @param versionId
   * @returns {Promise<Array>} Promise object that receives the list of scenarios
   */
  getScenarios: (productName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/scenarios/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Get scenario for a given scenarioId, productName and versionId
   * @param productName
   * @param scenarioId
   * @param versionId
   * @returns {Promise<Array>} Promise object that receives the scenario
   */
  getScenario: (productName, scenarioId, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/scenarios/${scenarioId}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Reset scenario and return the new unified_risk_state
   * @param productName
   * @param scenarioId
   * @param versionId
   * @returns {Promise<Array>} Promise object that receives the scenario state
   */
  resetScenario: (productName, scenarioId, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/scenarios/${scenarioId}/reset/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, {}, { params }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Creates a risk and returns its risk state
   * @param productName
   * @param scnearioId
   * @param parentRiskId
   * @param riskTypeId
   * @param versionId
   * @returns {Promise<Array>} Promise object that receives the risk state
   */
  createRisk: (productName, scenarioId, parentRiskId, riskTypeId, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/scenarios/${scenarioId}/risks/`
    const formData = {
      parent_risk: parentRiskId,
      risk_type: riskTypeId
    }
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, formData, { params: params }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Retrieves a risk state
   * @param productName
   * @param scenarioId
   * @param riskId
   * @param versionId
   * @returns {Promise<Array>} Promise object that receives the risk state
   */
  getRiskState: (productName, scenarioId, riskId, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/scenarios/${scenarioId}/risks/${riskId}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Saves the risk state.
   * @param productName
   * @param scenarioId
   * @param riskId
   * @param riskState
   * @param versionId
   * @returns {Promise<Array>} Promise object that receives the risk state
   */
  saveRiskState: (productName, scenarioId, riskId, riskState, versionId, config) => {
    const url = `${API_URL}/lines/products/${productName}/scenarios/${scenarioId}/risks/${riskId}/`
    const formData = {
      risk_state: riskState
    }
    config.params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.put(url, formData, config).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        if (!axios.isCancel(error)) {
          reject(error.response.data)
        }
      })
    })
  },

  /**
   * Removes a risk from a scenario.
   * @param productName
   * @param scenarioId
   * @param riskId
   * @param versionId
   * @returns {Promise<Array>} Promise object that receives the updated quote
   */
  removeRisk: (productName, scenarioId, riskId, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/scenarios/${scenarioId}/risks/${riskId}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.delete(url, { params: params }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Adds an item to a Risk.
   * @param productName
   * @param scenarioId
   * @param riskId
   * @param itemName
   * @param versionId
   * @returns {Promise<Object>} Promise object that receives the updated risk state
   */
  addItemToRiskState: (productName, scenarioId, riskId, itemName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/scenarios/${scenarioId}/risks/${riskId}/items/`
    const formData = {
      item_name: itemName
    }
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, formData, { params: params }).then(response => {
        resolve(response.data.risk_state)
      }).catch((error) => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Removes an item from a Risk.
   * @param productName
   * @param scenarioId
   * @param riskId
   * @param itemName
   * @param versionId
   * @returns {Promise<Object>} Promise object that receives the updated risk state
   */
  removeItemFromRiskState: (productName, scenarioId, riskId, itemName, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/scenarios/${scenarioId}/risks/${riskId}/items/${itemName}/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.delete(url, { params: params }).then(response => {
        resolve(response.data.risk_state)
      }).catch((error) => {
        reject(error.response.data)
      })
    })
  },

  /**
   * Rated a risk state.
   * @param productName
   * @param scenarioId
   * @param riskId
   * @param config
   * @param versionId
   * @returns {Promise<Object>} Promise object that receives the rated risk state
   */
  rateRiskState: (productName, scenarioId, riskId, versionId, config) => {
    const url = `${API_URL}/lines/products/${productName}/scenarios/${scenarioId}/risks/${riskId}/rate/`
    config.params = { version_id: versionId }
    const data = {}
    return new Promise((resolve, reject) => {
      axios.post(url, data, config).then(response => {
        resolve(response.data)
      }).catch((error) => {
        if (!axios.isCancel(error)) {
          reject(error.response.data)
        }
      })
    })
  },

  /**
   * Rate a scenario.
   * @param productName
   * @param scenarioId
   * @param versionId
   * @returns {Promise<Object>} Promise object that receives the rated scenario
   */
  rateScenario: (productName, scenarioId, versionId) => {
    const url = `${API_URL}/lines/products/${productName}/scenarios/${scenarioId}/rate/`
    const params = { version_id: versionId }
    const data = {}
    return new Promise((resolve, reject) => {
      axios.post(url, data, { params: params }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        if (!axios.isCancel(error)) {
          reject(error.response.data)
        }
      })
    })
  },

  /**
   * Validate a risk field answer.
   * @param productName
   * @param scenarioId
   * @param riskId
   * @param fieldName
   * @param formData
   * @param versionId
   * @param config
   * @returns {Promise<Object>} Validation request promise.
   */
  validateRiskFieldAnswer: (productName, scenarioId, riskId, fieldName, formData, versionId, config) => {
    const riskEndpoint = `${API_URL}/lines/products/${productName}/scenarios/${scenarioId}/risks/${riskId}`
    const url = `${riskEndpoint}/fields/${fieldName}/validate-answer/`
    const params = { version_id: versionId }
    return new Promise((resolve, reject) => {
      axios.post(url, formData, { ...config, params }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        if (!axios.isCancel(error)) {
          reject(error.response.data)
        }
      })
    })
  }

}

export default linePreviewService
