import { addNamespace } from '@/store/helpers'

import { PRODUCT_EXPORTS_STORE_PATH } from './constants'

// To be used only inside vuex modules.
export const _mutationTypes = {
  SET_EXPORT: 'setExport',
  SET_EXPORTS: 'setExports'
}

// To be used publicly outside vuex modules.
export const mutationTypes = addNamespace(PRODUCT_EXPORTS_STORE_PATH, _mutationTypes)
