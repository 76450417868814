import JobRunner from '@/pages/rules/utils/JobRunner'

export const state = {
  activeProject: {
    loading: false,
    loaded: false,
    value: undefined,
    error: null
  },
  components: {
    loading: false,
    loaded: false,
    value: undefined,
    error: null
  },
  componentsFilter: {
    selectedCriteria: null,
    availableFilterCriteria: {
      loading: false,
      loaded: false,
      value: null,
      error: null
    }
  },
  uncommittedComponents: {
    loading: false,
    loaded: false,
    value: null,
    error: null
  },
  validateProject: {
    running: false,
    dialogVisible: false,
    validationErrors: [],
    serviceError: false
  },
  testProject: {
    jobRunner: JobRunner(),
    dialogVisible: false
  }
}
