import LoginRoutes from '@/pages/login/LoginRoutes'
import AllModulesRoutes from '@/pages/all-modules/AllModulesRoutes'
import MyAccountRoutes from '@/pages/my-account/MyAccountRoutes'
import UserManagementRoutes from '@/pages/users/UserManagementRoutes'
import store from '@/store'
import metaPresets from './metaPresets'
import { formatRoutePath } from './utils'

const Login = () => import('@/pages/login/Login')
const AllModules = () => import('@/pages/all-modules/AllModules')
const MyAccount = () => import('@/pages/my-account/MyAccount')
const UserManagement = () => import('@/pages/users/UserManagement')
const PageNotFound = () => import('@/pages/PageNotFound')
const Forbidden = () => import('@/pages/Forbidden')

// Please ensure that the path property is the same as the module name
// This is how we determine if a route should exist for an enabled module
const baseRoutes = [
  {
    path: 'login',
    component: Login,
    meta: {
      layout: 'component-only',
      ...metaPresets.onlyAllowUnauthenticatedAccess
    },
    children: LoginRoutes
  },
  {
    path: 'all-modules',
    alias: '/',
    component: AllModules,
    children: AllModulesRoutes
  },
  {
    path: 'my-account',
    name: 'my-account',
    component: MyAccount,
    children: MyAccountRoutes
  },
  {
    path: 'oauth2callback',
    name: 'oauth2callback',
    beforeEnter () {
      window.opener.popupCompleted()
      window.close()
    }
  },
  {
    path: 'users',
    component: UserManagement,
    children: UserManagementRoutes,
    beforeEnter (to, from, next) {
      if (store.getters['authentication/isAgentOrAgency']) next({ name: 'forbidden', replace: true })
      else next()
    }
  },
  {
    path: 'forbidden',
    name: 'forbidden',
    component: Forbidden,
    meta: {
      layout: 'full-page',
      ...metaPresets.ignoreAuthorization
    }
  }
]

// eslint-disable-next-line complexity
const conditionalRoutes = (() => {
  const routes = []

  /**
   * DO NOT CHANGE THE CONDITIONS FOR THESE BLOCKS.
   * For Dead Code Elimination to work, it is required that the conditions only use simple assertions against process.env variables
   * with no dynamic elements (like a variable) whatsoever
   */
  if (process.env.VUE_APP_ENABLED_MODULE_POLICIES) {
    const Policies = () => import('@/pages/policies/Policies')
    const { default: PoliciesRoutes } = require('@/pages/policies/PoliciesRoutes')

    routes.push({
      path: 'policies',
      component: Policies,
      children: PoliciesRoutes
    })
  }

  if (process.env.VUE_APP_ENABLED_MODULE_CLAIMS) {
    const Claims = () => import('@/modules/claims/App')
    const { default: ClaimsRoutes } = require('@/modules/claims/views/routes')

    routes.push({
      path: 'claims',
      component: Claims,
      children: ClaimsRoutes
    })
  }

  if (process.env.VUE_APP_ENABLED_MODULE_LINES) {
    const Lines = () => import('@/pages/lines/Lines')
    const { default: LinesRoutes } = require('@/pages/lines/LinesRoutes')

    routes.push({
      path: 'lines',
      component: Lines,
      children: LinesRoutes
    })
  }

  if (process.env.VUE_APP_ENABLED_MODULE_QUOTE) {
    const Quote = () => import('@/pages/quote/Quote')
    const { default: QuoteRoutes } = require('@/pages/quote/QuoteRoutes')
    routes.push({
      path: 'quote',
      component: Quote,
      children: QuoteRoutes
    })
  }

  if (process.env.VUE_APP_ENABLED_MODULE_DOCUMENTS) {
    const Documents = () => import('@/pages/documents/Documents')
    const { default: DocumentsRoutes } = require('@/pages/documents/DocumentsRoutes')
    routes.push({
      path: 'documents',
      component: Documents,
      children: DocumentsRoutes
    })
  }

  if (process.env.VUE_APP_ENABLED_MODULE_RULES) {
    const Rules = () => import('@/pages/rules/Rules')
    const { default: RulesRoutes } = require('@/pages/rules/RulesRoutes')

    routes.push({
      path: 'rules',
      component: Rules,
      children: RulesRoutes
    })
  }

  if (process.env.VUE_APP_ENABLED_MODULE_ACCESS) {
    const Access = () => import('@/pages/access/Access')
    const { default: AccessRoutes } = require('@/pages/access/AccessRoutes')

    routes.push({
      path: 'access',
      component: Access,
      children: AccessRoutes,
      meta: {
        layout: 'default--no-container-padding'
      }
    })
  }

  if (process.env.VUE_APP_ENABLED_MODULE_NOTES) {
    const Notes = () => import('@/pages/notes/NotesModule')
    const { default: NotesRoutes } = require('@/pages/notes/NotesRoutes')

    routes.push({
      path: 'notes',
      component: Notes,
      children: NotesRoutes,
      meta: {
        layout: 'default--no-container-padding'
      }
    })
  }

  if (process.env.VUE_APP_ENABLED_MODULE_INTEGRATIONS) {
    const Integrations = () => import('@/pages/integrations/Integrations')
    const { default: IntegrationsRoutes } = require('@/pages/integrations/IntegrationsRoutes')

    routes.push({
      path: 'integrations',
      component: Integrations,
      children: IntegrationsRoutes
    })
  }

  return routes
})()

const specialRoutes = [
  {
    path: 'logout',
    beforeEnter: (to, from, next) => {
      store.dispatch('authentication/signOut')
      next()
    },
    meta: {
      layout: 'component-only'
    }
  },
  // This catch-all PageNotFound needs to be the last route
  // Otherwise, any routes added after this will be redirected to the PageNotFound
  {
    path: '*',
    name: 'not-found',
    component: PageNotFound,
    meta: {
      layout: 'full-page'
    }
  }
]

let routes = [
  ...baseRoutes,
  ...conditionalRoutes,
  ...specialRoutes
]

export default {
  mode: 'history',
  routes: routes.map(formatRoutePath)
}
