import utils from '@/store/policies/utils'

export const getters = {
  policyId (state) {
    return state.policyId
  },
  policyNumber (state) {
    return state.policyNumber
  },
  fullPolicyNumber (state) {
    if (state.revisionId) {
      const revision = getters.currentRevision(state)
      return revision.fullPolicyNumber
    }
    return ''
  },
  active (state) {
    return state.active
  },
  riskTabs (state) {
    return state.riskTabs
  },
  riskData (state) {
    return state.riskData
  },
  riskDataLookup: (state) => ({revisionId, tabTitle}) => {
    return state.riskData[`${revisionId},${tabTitle}`] || null
  },
  cancellationPendingNoticePeriod (state) {
    return state.cancellationInfo.cancellationPendingNotice
  },
  cancellationReasons (state) {
    if (!state.cancellationInfo) {
      return []
    }
    return state.cancellationInfo.cancellationReasons.map(reason => ({
      id: reason.reasonId,
      title: reason.reason
    }))
  },
  billingInfoSummary (state) {
    let data = state.billingInfo
    if (!data) {
      return {}
    }
    let selectedValues = data.billingSetup.recurringSelectedValues
    let billWhom = data.billingSetup.billWhoms.find(whom => whom.id === selectedValues.billWhoms)
    let billingSchedule = null
    if (billWhom) {
      billingSchedule = billWhom.billingSchedules.find(schedule => schedule.id === selectedValues.billingSchedules)
    }
    return {
      currentlyDue: data.currentDue,
      billingSchedule: billingSchedule ? billingSchedule.billingSchedule : '',
      premiumDueDate: data.dueDate,
      nonPayCancelDate: data.cancelDate,
      termNonPay: data.policyDetails.nonPays,
      totalNonPay: data.policyDetails.totalNonPays
    }
  },
  isPolicyCanceled (state) {
    const revisions = getters.committedRevisions(state)
    const found = revisions.find(revision => revision.policyStatus.startsWith('Canceled'))
    return found !== undefined
  },
  committedRevisions (state) {
    return state.revisions.filter(r => r.revisionState === 'committed')
  },
  getPolicyTerm: (state) => (policyTermId) => {
    const term = state.policyTerms.find(pt => pt.id === policyTermId)
    return { ...term.attributes, id: term.id }
  },
  revisionWithTerm: (state) => (revision) => {
    const term = revision ? getters.getPolicyTerm(state)(revision.policyTermId) : {}
    return {
      ...revision,
      policyTerm: term
    }
  },
  revisionWithTermById: (state) => (revisionId) => {
    let revision = state.revisions.find(r => r.revisionId === revisionId)
    revision = getters.revisionWithTerm(state)(revision)
    return revision
  },
  mostRecentRevisionWithTerm (state) {
    const committedRevisions = getters.committedRevisions(state)
    const revision = committedRevisions.find(rev => rev.policyStatus !== 'Expired')
    return getters.revisionWithTerm(state)(revision)
  },
  mostRecentRevisionToDateWithTerm: (state) => (date) => {
    const committedRevisions = getters.committedRevisions(state)
    const revision = committedRevisions.find(rev => new Date(rev.revisionDate) <= new Date(date))
    return getters.revisionWithTerm(state)(revision)
  },
  revisionForReinstatement (state) {
    const committedRevisions = getters.committedRevisions(state)
    const canceledOrPendingCancellationRevision = committedRevisions.reverse().find(rev => rev.policyStatus.startsWith('Cancel'))
    return getters.revisionWithTerm(state)(canceledOrPendingCancellationRevision)
  },
  isDateOutOfAllPolicyTerms: (state) => (date) => {
    const earliestEffectiveDate = utils.parseDate(state.policyTerms[state.policyTerms.length - 1].attributes.effective_date)
    const latestExpirationDate = utils.parseDate(state.policyTerms[0].attributes.expiration_date)
    const inputDate = utils.parseDate(date)
    return !(earliestEffectiveDate <= inputDate && inputDate <= latestExpirationDate)
  },
  currentRevision (state) {
    const committedRevisions = getters.committedRevisions(state)
    const revision = committedRevisions.find(rev => rev.revisionId === state.revisionId)
    return getters.revisionWithTerm(state)(revision)
  }
}

export default getters
