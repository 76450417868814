export default {
  policies: null,
  policy: null,
  policyTypes: null,
  individualContacts: null,
  agencies: null,
  agents: null,
  policyStatuses: null,
  documentTypes: null,
  searchPolicy: {
    defaultSearchType: null,
    queryType: 'policyNumber',
    query: '',
    pageSize: 10,
    total: 0,
    page: 1,
    hasNextPage: false,
    orderBy: '-date_added',
    searchTypeOptions: [
      {
        label: 'Number',
        value: 'policyNumber'
      },
      {
        label: 'Primary Insured',
        value: 'name'
      }
    ]
  }
}
