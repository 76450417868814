/* istanbul ignore file */

/**
 * Britecore-UI's analytics platform using Pendo.
 * https://support.pendo.io/hc/en-us/articles/360031862272-Installation-for-Single-Page-Frameworks
 */
export default {
  initialize: (userAttributesArr, carrier, role, contactId) => {
    const userInfo = {}

    for (let attribute of userAttributesArr) {
      userInfo[attribute.Name] = attribute.Value
    }

    const carrierId = (carrier || 'no-carrier-specified').replace(/\s/g, '-').toUpperCase()
    const email = userInfo.email || ''
    const name = userInfo.name || ''
    const phone = userInfo.phone_number || ''

    const initObject = {
      visitor: {
        id: contactId,
        email: email,
        full_name: name,
        role: role,
        phone: phone
      },
      account: {
        id: carrierId
      }
    };

    /* eslint-disable */
    (function(apiKey) {
      (function(p, e, n, d, o) { var v, w, x, y, z;o = p[d] = p[d] || {};o._q = [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];for (w = 0, x = v.length;w < x;++w) { (function(m) {
            o[m] = o[m] || function() { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); }; })(v[w]); }
        y = e.createElement(n);y.async = !0;y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y, z); })(window, document, 'script', 'pendo');

        pendo.initialize(initObject);
    })('5e81bc1a-2602-4287-5341-51fc109f36f4')
    /* eslint-enable */
  }
}
