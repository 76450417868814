import Vue from 'vue'

export const mutations = {
  setContactsInfo (state, contactsInfo) {
    let address = contactsInfo.included ? contactsInfo.included[0].attributes : {}
    let email = (contactsInfo.included && contactsInfo.included.length > 1) ? contactsInfo.included[1].attributes : {}
    let contact = contactsInfo.data.attributes

    Vue.set(state.contacts, contactsInfo.data.id, {
      id: contactsInfo.data.id,
      name: contact.name,
      type: contact.type,
      email: email.email || '',
      city: address.addresscity || '',
      state: address.addressstate || '',
      zip: address.addresszip || '',
      addressline1: address.addressline1 || ''
    })
  },
  setRevisionContactsInfo (state, { revisionId, contactsInfo }) {
    Vue.set(state.revisionContacts, revisionId, contactsInfo.data.filter(
      c => c.relationships.contact.data
    ).map(contact => ({
      ...contact.attributes,
      contactId: contact.relationships.contact.data.id
    })))
  }
}
