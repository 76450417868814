import linePreviewService from '@/api/lines/linepreview'
import { linesClient } from '@/apollo'
import { LINES_GQL_CLIENT } from '@/pages/lines/constants'
import GetRiskTypeState from '@/pages/lines/queries/GetRiskTypeState.gql'
import GetRiskTypesByProduct from '@/pages/lines/queries/GetRiskTypesByProduct.gql'
import { _actionTypes } from './actionTypes'
import { _mutationTypes } from './mutationTypes'

export const actions = {
  [_actionTypes.NEW_SCENARIO] ({ commit }, { productName, scenarioName, versionId }) {
    return new Promise((resolve, reject) => {
      linePreviewService.newScenario(
        productName,
        versionId,
        scenarioName
      ).then((response) => {
        commit(_mutationTypes.SET_SCENARIO, response)
        commit(_mutationTypes.ADD_SCENARIO_TO_SCENARIOS, response)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [_actionTypes.LOAD_SCENARIO] ({ commit }, { productName, scenarioId, versionId }) {
    return new Promise((resolve, reject) => {
      linePreviewService.getScenario(
        productName,
        scenarioId,
        versionId,
      ).then((response) => {
        commit(_mutationTypes.SET_SCENARIO, response)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [_actionTypes.REMOVE_SCENARIO] ({ commit, state }, { productName, scenarioId, versionId }) {
    return new Promise((resolve, reject) => {
      linePreviewService.removeScenario(
        productName,
        scenarioId,
        versionId,
      ).then((response) => {
        commit(_mutationTypes.REMOVE_SCENARIO_FROM_SCENARIOS, scenarioId)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [_actionTypes.UPDATE_SCENARIO] ({ commit }, { productName, scenarioId, versionId, formData }) {
    return new Promise((resolve, reject) => {
      linePreviewService.updateScenario(
        productName,
        scenarioId,
        versionId,
        formData
      ).then((response) => {
        commit(_mutationTypes.SET_SCENARIO, response)
        commit(_mutationTypes.UPDATE_SCENARIO_IN_SCENARIOS, response)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [_actionTypes.LOAD_SCENARIOS] ({ commit }, { productName, versionId }) {
    return new Promise((resolve, reject) => {
      linePreviewService.getScenarios(
        productName,
        versionId,
      ).then((response) => {
        commit(_mutationTypes.SET_SCENARIOS, response.results)
        resolve(response.results)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [_actionTypes.RESET_SCENARIO] ({ commit }, { productName, scenarioId, versionId }) {
    return new Promise((resolve, reject) => {
      linePreviewService.resetScenario(
        productName,
        scenarioId,
        versionId
      ).then((scenario) => {
        commit(_mutationTypes.SET_SCENARIO, scenario)
        commit(_mutationTypes.UPDATE_SCENARIO_IN_SCENARIOS, scenario)
        resolve(scenario)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [_actionTypes.REFRESH_SCENARIO] ({ commit }, { productName, scenarioId, versionId }) {
    return new Promise((resolve, reject) => {
      linePreviewService.getScenario(
        productName,
        scenarioId,
        versionId
      ).then((scenario) => {
        commit(_mutationTypes.SET_SCENARIO, scenario)
        commit(_mutationTypes.UPDATE_SCENARIO_IN_SCENARIOS, scenario)
        resolve(scenario)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [_actionTypes.CREATE_RISK] ({ commit }, { productName, scenarioId, parentRiskId, riskTypeId, versionId }) {
    return new Promise((resolve) => {
      return linePreviewService.createRisk(
        productName,
        scenarioId,
        parentRiskId,
        riskTypeId,
        versionId
      ).then((response) => {
        commit(_mutationTypes.SET_RISK_STATE, response.risk_state)
        resolve(response)
      })
    })
  },
  [_actionTypes.LOAD_RISK_STATE] ({ commit, state }, { productName, scenarioId, riskId, versionId }) {
    return new Promise((resolve) => {
      if (state.riskState && state.riskState.id === riskId) {
        resolve({ 'risk_state': state.riskState })
      } else {
        return linePreviewService.getRiskState(
          productName,
          scenarioId,
          riskId,
          versionId
        ).then((response) => {
          commit(_mutationTypes.SET_RISK_STATE, response.risk_state)
          resolve(response)
        })
      }
    })
  },
  [_actionTypes.LOAD_RISK_TYPE_STATE] ({ commit }, { productId, riskTypeName }) {
    return new Promise((resolve) => {
      linesClient.query({
        client: LINES_GQL_CLIENT,
        query: GetRiskTypeState,
        fetchPolicy: 'network-only',
        variables: {
          productId: productId,
          riskTypeName: riskTypeName
        }
      }).then(({ data: { riskType } }) => {
        commit(_mutationTypes.SET_RISK_TYPE_STATE, riskType.state)
        resolve(riskType.state)
      })
    })
  },
  [_actionTypes.RATE_RISK_STATE] ({ dispatch, commit, state }, { productName, scenarioId, riskId, versionId, config }) {
    state.storeRiskStateRequestsCount++
    const counter = state.storeRiskStateRequestsCount
    return new Promise((resolve, reject) => {
      return linePreviewService.rateRiskState(
        productName,
        scenarioId,
        riskId,
        versionId,
        config
      ).then((response) => {
        if (counter === state.storeRiskStateRequestsCount) {
          commit(_mutationTypes.SET_RISK_STATE, response.risk_state)
          commit(_mutationTypes.SET_RISK_META, response.risk_meta)
          // whenever risk is rated update that in active scenario as well
          dispatch(_actionTypes.UPDATE_RISK_IN_SCENARIO, response.risk_state)
          resolve(response)
        } else {
          resolve(null)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [_actionTypes.SAVE_RISK_STATE] (context, { productName, scenarioId, riskId, riskState, versionId, config }) {
    return new Promise((resolve, reject) => {
      return linePreviewService.saveRiskState(
        productName,
        scenarioId,
        riskId,
        riskState,
        versionId,
        config
      ).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [_actionTypes.RATE_SCENARIO] ({ dispatch, commit, state }, { productName, scenarioId, versionId, config }) {
    return new Promise((resolve, reject) => {
      return linePreviewService.rateScenario(
        productName,
        scenarioId,
        versionId,
        config
      ).then((scenario) => {
        commit(_mutationTypes.UPDATE_SCENARIO_IN_SCENARIOS, scenario)
        commit(_mutationTypes.SET_SCENARIO, scenario)
        resolve(scenario)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [_actionTypes.REMOVE_RISK] ({ commit }, { productName, scenarioId, riskId, versionId }) {
    return new Promise((resolve, reject) => {
      return linePreviewService.removeRisk(
        productName,
        scenarioId,
        riskId,
        versionId
      ).then((response) => {
        commit(_mutationTypes.SET_SCENARIO_UNIFIED_RISK_STATE, response.unified_risk_state)
        resolve(response)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },
  [_actionTypes.ADD_ITEM_TO_RISK_STATE] (context, { productName, scenarioId, riskId, itemName, versionId }) {
    return new Promise((resolve, reject) => {
      return linePreviewService.addItemToRiskState(
        productName,
        scenarioId,
        riskId,
        itemName,
        versionId
      ).then((response) => {
        resolve(response)
      }).catch(response => {
        reject(response)
      })
    })
  },
  [_actionTypes.REMOVE_ITEM_FROM_RISK_STATE] (context, { productName, scenarioId, riskId, itemName, versionId }) {
    return new Promise((resolve) => {
      return linePreviewService.removeItemFromRiskState(
        productName,
        scenarioId,
        riskId,
        itemName,
        versionId
      ).then((response) => {
        resolve(response)
      })
    })
  },
  [_actionTypes.UPDATE_RISK_IN_SCENARIO] ({ state }, riskState) {
    const replaceRiskState = function (unifiedRiskState, riskStateToReplace) {
      if (unifiedRiskState.id === riskStateToReplace.id) {
        unifiedRiskState = riskStateToReplace
      } else {
        const updatedChildren = []
        for (const childRiskState of unifiedRiskState.children) {
          updatedChildren.push(replaceRiskState(childRiskState, riskStateToReplace))
        }
        unifiedRiskState.children = updatedChildren
      }
      return unifiedRiskState
    }
    state.scenario.unified_risk_state = replaceRiskState(state.scenario.unified_risk_state, riskState)
  },
  [_actionTypes.GET_RISK_TYPES_BY_PRODUCT] (context, productId) {
    return new Promise((resolve) => {
      linesClient.query({
        client: LINES_GQL_CLIENT,
        query: GetRiskTypesByProduct,
        fetchPolicy: 'network-only',
        variables: {
          productId: productId
        }
      }).then(({ data: { riskTypes } }) => {
        resolve(riskTypes)
      })
    })
  },
  [_actionTypes.CLEAR_SCENARIO_RISK] ({ commit }) {
    commit(_mutationTypes.SET_RISK_STATE, null)
    commit(_mutationTypes.SET_RISK_TYPE_STATE, null)
  },
  [_actionTypes.COPY_SCENARIO] ({ commit, state }, { productName, scenarioId, versionId }) {
    return new Promise((resolve, reject) => {
      linePreviewService.copyScenario(
        productName,
        scenarioId,
        versionId,
      ).then((response) => {
        commit(_mutationTypes.SET_SCENARIO, response)
        commit(_mutationTypes.ADD_SCENARIO_TO_SCENARIOS, response)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}
