<template>
  <el-dialog
    title="Keyboard Shortcuts"
    :visible="value"
    @update:visible="$emit('input', $event)"
    append-to-body>
    <div>
      <table>
        <tr>
          <th class="u-pb2"></th>
          <th class="u-px3 u-pb3 u-text--left">
            <h5 class="u-m0">Quick Search</h5>
          </th>
        </tr>
        <tr>
          <td class="u-text--right u-pb2">
            <kbd class="u-mb1">g</kbd> <small class="u-px1 u-mb1 u-text--light">then</small> <kbd class="u-mb1">p</kbd>
          </td>
          <td class="u-px3 u-pb3">
            Go to a Policy
          </td>
        </tr>
        <tr>
          <td class="u-text--right u-pb2">
            <kbd class="u-mb1">g</kbd> <small class="u-px1 u-mb1 u-text--light">then</small> <kbd class="u-mb1">c</kbd>
          </td>
          <td class="u-px3 u-b3">
            Go to a Claim
          </td>
        </tr>
        <tr>
          <td class="u-text--right u-pb2">
            <kbd class="u-mb1">g</kbd> <small class="u-px1 u-mb1 u-text--light">then</small> <kbd class="u-mb1">n</kbd>
          </td>
          <td class="u-px3 u-pb3">
            Go to a Contact (Name)
          </td>
        </tr>
        <tr>
          <th class="u-pb2"></th>
          <th class="u-px3 u-pb3 u-text--left">
            <h5 class="u-mt3 u-mb0">Help</h5>
          </th>
        </tr>
        <tr>
          <td class="u-text--right u-pb2">
            <kbd class="u-mb1">?</kbd>
          </td>
          <td class="u-px3 u-pb3">
            Open Keyboard Shortcuts
          </td>
        </tr>
      </table>
    </div>

  </el-dialog>
</template>

<script>
export default {
  name: 'KeyboardShortcutsModal',
  props: {
    value: { type: Boolean, required: true }
  }
}
</script>
