import Vue from 'vue'

import { _mutationTypes } from './mutationTypes'

export const mutations = {
  [_mutationTypes.SET_RISK_TYPE_DETAILS_ID] (state, riskTypeDetailsId) {
    state.riskTypeDetailsId = riskTypeDetailsId
  },
  [_mutationTypes.SET_RISK_TYPE_DETAILS_ACTIVE_TAB] (state, riskTypeDetailsActiveTab) {
    state.riskTypeDetailsActiveTab = riskTypeDetailsActiveTab
  },
  [_mutationTypes.SET_SCENARIO] (state, scenario) {
    state.scenario = scenario
  },
  [_mutationTypes.SET_SCENARIOS] (state, scenarios) {
    state.scenarios = scenarios
  },
  [_mutationTypes.ADD_SCENARIO_TO_SCENARIOS] (state, scenario) {
    state.scenarios.push(scenario)
  },
  [_mutationTypes.UPDATE_SCENARIO_IN_SCENARIOS] (state, scenario) {
    const index = state.scenarios.findIndex(s => s.id === scenario.id)
    Vue.set(state.scenarios, index, scenario)
  },
  [_mutationTypes.REMOVE_SCENARIO_FROM_SCENARIOS] (state, scenarioId) {
    const index = state.scenarios.findIndex(s => s.id === scenarioId)
    Vue.delete(state.scenarios, index)
  },
  [_mutationTypes.SET_SCENARIO_UNIFIED_RISK_STATE] (state, unifiedRiskState) {
    state.scenario.unified_risk_state = unifiedRiskState
  },
  [_mutationTypes.SET_RISK_STATE] (state, riskState) {
    state.riskState = riskState
  },
  [_mutationTypes.SET_RISK_TYPE_STATE] (state, riskTypeState) {
    state.riskTypeState = riskTypeState
  },
  [_mutationTypes.SET_RISK_META] (state, riskMeta) {
    state.riskMeta = riskMeta
  }
}
