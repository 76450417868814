import map from 'lodash/map'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import { buildAutocompleteOption } from '@/pages/rules/component-editor/utils/utils'

export const getters = {
  isLoadingProjects (state) {
    return !state.projects
  },
  isLoadingPackages (state) {
    return !state.packages
  },
  getProjects (state) {
    return state.projects
  },
  getPackages (state) {
    return orderBy(map(state.packages, (p) => {
      return {
        ...p,
        lowercaseName: p.name.toLowerCase()
      }
    }), ['lowercaseName'], ['asc'])
  },
  projectsByCategory (state) {
    if (state.projects) {
      let categories = map(groupBy(state.projects, 'category'), (projects, category) => ({
        name: category,
        projects: orderBy(map(projects, (p) => {
          return {
            ...p,
            lowercaseName: p.name.toLowerCase()
          }
        }), ['lowercaseName'], ['asc'])
      }))
      return orderBy(categories, ['name'], ['asc'])
    } else {
      return []
    }
  },
  packageCatalog (state) {
    return get(state, 'packageCatalog.value', [])
  },
  packageCatalogMap (state) {
    return keyBy(state.packageCatalog.value, 'name')
  },
  packageCatalogStatus (state) {
    return {
      loaded: get(state, 'packageCatalog.loaded', false),
      loading: get(state, 'packageCatalog.loading', false),
      error: get(state, 'packageCatalog.error', null)
    }
  },
  activePackage (state) {
    return get(state, 'activePackage.value', {})
  },
  activePackageUuid (state) {
    return get(state, 'activePackage.value.id', null)
  },
  activePackageId (state) {
    return get(state, 'activePackage.value.packageId', null)
  },
  activePackageStatus (state) {
    return {
      loaded: get(state, 'activePackage.loaded', false),
      loading: get(state, 'activePackage.loading', false),
      error: get(state, 'activePackage.error', null)
    }
  },
  suggestions (state) {
    return state.suggestions || []
  },
  componentsSuggestions (state) {
    return getters.suggestions(state).filter((suggestion) => suggestion.suggestionType === 'component')
  },
  valuesSetsSuggestions (state) {
    return getters.suggestions(state).filter((suggestion) => suggestion.suggestionType === 'values-set')
  },
  functionSuggestions (state) {
    return orderBy(getters.componentsSuggestions(state).map((suggestion) => buildAutocompleteOption(suggestion)), ['caption'], ['asc'])
  }
}
