import { _mutationTypes } from './mutationTypes'
import { ENTITY_GROUP_FIELDS, OPERATION_MAP } from '@/pages/lines/product-mapping/constants'

export const mutations = {
  [_mutationTypes.SET_PRODUCT] (state, product) {
    state.product = product
  },
  [_mutationTypes.SET_PRODUCT_DIFFERENCE] (state, productDifference) {
    state.productDifference = productDifference
  },
  [_mutationTypes.SET_ACTIVE_PRODUCT_DIFFERENCE] (state, productDifference) {
    state.activeProductDifference = productDifference
  },
  [_mutationTypes.RESET_ACTIVE_PRODUCT_DIFFERENCE] (state) {
    state.activeProductDifference = {}
  },
  [_mutationTypes.SET_RISK_TYPE_DIFFERENCE] (state, riskTypeDifference) {
    state.riskTypeDifference = riskTypeDifference
  },
  [_mutationTypes.SET_RISK_TYPES_TREE] (state, riskTypesTree) {
    state.riskTypesTree = riskTypesTree
  },
  [_mutationTypes.SET_ACTIVE_RISK_TYPES_TREE_NODE_ID] (state, nodeId) {
    state.activeRiskTypesTreeNodeId = nodeId
  },
  [_mutationTypes.SET_PRODUCT_MAPPING] (state, productMapping) {
    state.productMapping = productMapping
  },
  [_mutationTypes.MAP_ENTITY] (state, { riskTypeName, parentEntityName, sourceEntityName, operation, targetEntityName }) {
    let entityToFind = sourceEntityName
    if (parentEntityName) {
      entityToFind = `${parentEntityName}.${sourceEntityName}`
      if (operation === OPERATION_MAP) {
        targetEntityName = `${parentEntityName}.${targetEntityName}`
      }
    }
    let targets = []
    if (targetEntityName) {
      targets = [targetEntityName]
    }

    for (const field of state.productMapping[riskTypeName][ENTITY_GROUP_FIELDS]) {
      if (field.sources.toString() === entityToFind) {
        field.operation = operation
        field.targets = targets
        return
      }
    }
    state.productMapping[riskTypeName][ENTITY_GROUP_FIELDS].push({
      sources: [entityToFind],
      targets: targets,
      operation: operation
    })
  },
  [_mutationTypes.CLEAR_ENTITY_MAPPING] (state, { riskTypeName, parentEntityName, sourceEntityName }) {
    let entityToFind = sourceEntityName
    if (parentEntityName) {
      entityToFind = `${parentEntityName}.${sourceEntityName}`
    }
    for (const field of state.productMapping[riskTypeName][ENTITY_GROUP_FIELDS]) {
      if (field.sources.toString() === entityToFind) {
        field.operation = ''
        field.targets = []
      }
    }
  }
}
