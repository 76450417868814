import store from '@/store'
/**
 * Captures an Effective Role persistently for the given user
 *
 * @param {object} userData - cognito user data
 * @param {object} userGroups - cognito user groups
 * @return {object} - the captured Effective Role record, undefined in case none is captured
 * @throws {Error} - in case fetching or analyzing the set of Effective Role records fails
 */
export async function captureEffectiveRoleRecord (userData, userGroups) {
  const possibleRoleRecords = await getPossibleEffectiveRoles(userData, userGroups)
  return pickEffectiveRoleRecord(possibleRoleRecords)
}

/**
 * Determines whether Effective Role capturing should occur for the given user
 *
 * Conditions for Effective Role capturing are driven by safety rollout reasons and may be temporary.
 *
 * @param {object} userData - cognito user data
 * @return {boolean} - whether the capture of effective role should happen
 */
export function shouldEffectiveRoleBeCaptured (userData) {
  if (!userData || !userData.username) {
    throw new Error('unable to determine whether to capture effective role for user: invalid argument')
  }

  // NOTE: this is a likely temporary condition.
  const isBriteCoreStaff = !!((userData.username || '').match(/^britecore_.+$/))

  // NOTE: this is a likely temporary condition.
  const hasBriteCoreEmail = !!((userData.username || '').match(/^.+@britecore.com$/))

  return hasBriteCoreEmail && isBriteCoreStaff
}

/**
 * Retrieves the set of possible roles the given user is eligible to assume
 *
 * @param {object} userData - cognito user data
 * @param {object} userGroups - cognito user groups
 * @return {List of object} - the set of possible effective roles for the user
 * @throws {Error} - in case the fetching the data fails
 */
async function getPossibleEffectiveRoles (userData, userGroups) {
    // TODO: handle results pagination - pagination is unlikely to be triggered initially.
  try {
    await store.dispatch('access/fetchUserRoles', {username: userData.username, usergroups: userGroups})
  } catch (err) {
    throw new Error('unable to fetch user roles')
  }
  return store.state.access.userRoles
}

/**
 * Determines a single Effective Role Record out of a set of possible records
 *
 * @param {List of object} possibleRoleRecords - a set of possible effective role records
 * @return {object} - a single record extracted from the given set of records, potentially undefined
 * @throws {Error} - in case the provided set is invalid or empty
 */
function pickEffectiveRoleRecord (possibleRoleRecords) {
  // Application Error: users should be eligible to assume at least one role.
  if (possibleRoleRecords.length === 0) {
    throw new Error('unable to determine effective role: user is not eligible to any role')
  }

  // Multiple eligible roles detected: user must be prompted about which one to assume.
  if (possibleRoleRecords.length > 1) {
    return undefined
  }

  // Only one eligible role was detected: return it as the selected effective role.
  return possibleRoleRecords[0]
}
