<template>
  <a
    :href="policyURL"
    class="search-results__search-item">
    <div class="u-mb1">
      {{ policyId }}
    </div>

    <div class="search-item__details">
      <div class="l-flex-grid l-flex-grid--v-center">
        <fa-icon
          v-if="policyTypeIcon"
          class="u-mr2"
          size="sm"
          ref="policyIcon"
          :icon="policyTypeIcon"></fa-icon>
        <span>{{ data.policyType || 'N/A' }}</span>
      </div>

      <div class="l-flex-grid l-flex-grid--v-center">
        <fa-icon
          class="u-mr2"
          size="sm"
          icon="user"></fa-icon>
        <span>{{ namedInsured }}</span>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'QuickSearchPolicyItem',
  props: {
    data: { type: Object, required: true }
  },
  computed: {
    policyId () {
      return this.data.name || this.data.policyId
    },
    namedInsured () {
      return this.data.contact || this.data.namedInsured || 'N/A'
    },
    policyURL () {
      return this.data.dbPolicyId ? `/britecore/policies/information?policyId=${this.data.dbPolicyId}` : `/britecore/policies/information?policyId=${this.data.id}`
    },
    // eslint-disable-next-line complexity
    policyTypeIcon () {
      let policyTypeIcon

      if (/(ho)|(home)/gi.test(this.data.policyType)) policyTypeIcon = 'home'
      if (/(mobile home)|(mobilehomeowners)|(mh)/gi.test(this.data.policyType)) policyTypeIcon = 'bc-mobile-home'
      if (/(dwelling)|(dp )|(dp\d)|(condo)|(fire)|(rental)|(renters)|(landlord)|(tenant)|(residence)/gi.test(this.data.policyType)) policyTypeIcon = 'bc-rental'
      if (/contents/gi.test(this.data.policyType)) policyTypeIcon = 'archive'
      if (/inland marine/gi.test(this.data.policyType)) policyTypeIcon = 'truck'
      if (/(agri)|(farm)|(fo )|(fo\d)|(hog)|(poultry)/gi.test(this.data.policyType)) policyTypeIcon = 'farm'
      if (/(bop)|(business)/gi.test(this.data.policyType)) policyTypeIcon = 'bc-business'
      if (/(commercial)/gi.test(this.data.policyType)) policyTypeIcon = 'bc-commercial'
      if (/church/gi.test(this.data.policyType)) policyTypeIcon = 'bc-church'
      if (/personal/gi.test(this.data.policyType)) policyTypeIcon = 'user'
      if (/(umbrella)|(blanket)/gi.test(this.data.policyType)) policyTypeIcon = 'umbrella'

      return policyTypeIcon
    }
  }
}
</script>
