import _ from 'lodash'
import { COMPONENT_TYPES } from '@/pages/rules/constants'

const folderPathSeparator = '>'
const homeFolderName = 'Home'

var path2Array = (path) => {
  return path ? path.split(folderPathSeparator) : []
}

var path2String = (path) => {
  return path ? path.join(folderPathSeparator) : ''
}

var buildBreadcrumb = (path, rootName, parentRouteName, paramId, componentName, componentType) => {
  var breadcrumb = [{
    label: rootName,
    folderPath: [homeFolderName],
    route: {
      name: parentRouteName,
      params: paramId
    }
  }]

  componentType = [COMPONENT_TYPES.RULE, COMPONENT_TYPES.MODEL, COMPONENT_TYPES.TEST].includes(componentType) ? componentType : 'other'

  for (var i = 0; i < path.length; i++) {
    var item = {}
    item.folderPath = [...breadcrumb[i].folderPath, path[i]]
    item.route = {
      name: parentRouteName,
      params: paramId,
      query: {
        path: path2String(removeHomeFromPath(item.folderPath)),
        componentType
      }
    }
    item.label = path[i]
    breadcrumb.push(item)
  }
  breadcrumb.push({
    label: componentName
  })
  return breadcrumb
}

var removeHomeFromPath = (path) => {
  if (!path) {
    return []
  }
  if (path[0] === homeFolderName) {
    return _.drop(path)
  } else {
    return path
  }
}

export default {
  folderPathSeparator,
  homeFolderName,
  path2Array,
  path2String,
  buildBreadcrumb
}
