import ApiService from './api'

export default class UsersIntegrationApi extends ApiService {
  constructor () {
    super('claims/integrations/users')
  }

  async getAllUsersByRole (role) {
    return this.get('', {params: {role}})
  }
  getUserRoles (levels = null, filters = null) {
    const params = {
      levels,
      ...filters
    }
    return this.get('roles/', {params})
  }

  getClaimAssignments (claimId, page, pageSize, orderBy, filters, queryType = null, query = null) {
    const params = {
      claim_id: claimId,
      page: page,
      page_size: pageSize,
      orderBy: orderBy,
      ...filters
    }
    if (queryType && query) {
      params[queryType] = query
    }
    return this.get('claim-assignments/', {params})
  }
  addClaimAssignment (username, roleId, claimId) {
    return this.post('claim-assignments/', {
      username,
      role_id: roleId,
      claim_id: claimId
    })
  }
  addClaimAssignmentCollection (claimAssignments) {
    return this.post('claim-assignments/', claimAssignments)
  }
  removeClaimAssignment (assigmentId) {
    return this.delete(`claim-assignments/${assigmentId}`)
  }
  getExposureAssignments (exposureId) {
    const params = {
      exposure_id: exposureId
    }
    return this.get('exposure-assignments/', {params})
  }
  addExposureAssignment (username, roleId, exposureId) {
    return this.post(`exposure-assignments/`, {
      username,
      role_id: roleId,
      exposure_id: exposureId
    })
  }
  removeExposureAssignment (assigmentId) {
    return this.delete(`exposure-assignments/${assigmentId}`)
  }

  getUser (username) {
    return this.get(`${username}/`)
  }

  updateRecentClaims (settingsId, claimId) {
    return this.patch(`settings/${settingsId}/`, {claim_id: claimId})
  }
}
