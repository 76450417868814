import moment from 'moment-timezone'
import {clone, get as lodashGet} from 'lodash'
import {parsePhoneNumber} from 'libphonenumber-js'

/**
 * Converts an index number to its alphabetical equivalent. 0 = A, 1 = B,
 * 26 = AA, 27 = AB, and so on. Supports index numbers up to 701 (ZZ).
 */
export function alphabetIndex (index) {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  if (index < alphabet.length) {
    return alphabet[index]
  } else {
    let firstLetter = Math.floor(index / alphabet.length) - 1
    let secondLetter = index % alphabet.length
    return alphabet[firstLetter] + alphabet[secondLetter]
  }
}

export function ordinalNumber (digit) {
  const special = ['Zeroth', 'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh',
    'Eighth', 'Ninth', 'Tenth', 'Eleventh', 'Twelfth', 'Thirteenth', 'Fourteenth', 'Fifteenth',
    'Sixteenth', 'Seventeenth', 'Eighteenth', 'Nineteenth']
  const deca = ['Twent', 'Thirt', 'Fort', 'Fift', 'Sixt', 'Sevent', 'Eight', 'Ninet']

  if (digit < 20) {
    return special[digit]
  }

  if (digit % 10 === 0) {
    return deca[Math.floor(digit / 10) - 2] + 'ieth'
  }

  return deca[Math.floor(digit / 10) - 2] + 'y-' + special[digit % 10]
}

const DEFAULT_VALUE_STRING = '--'

/**
 * Find value returns the value based on the path from an object.
 *  E.x
 *  let obj = {
 *       inner: {
 *          name: "My Name"
 *      }
 *  }
 *  console.log(get(obj, "inner.name")) # this would print "My Name"
 *
 *  E.x
 *  let obj = { name: "My Name" }
 *  console.log(get(obj, "inner.name")) # this would print "--"
 *
 *  E.x
 *  let obj = { name: "My Name" }
 *  console.log(get(obj)) # this would print "{ name: "My Name" }"
 *
 *  E.x
 *  let obj = "My Name"
 *  console.log(get(obj)) # this would print "My Name"

 *  If the path is not found this would return "--".
 *
 * @param value - The value, can be null, number, string, boolean, or object.
 * @param path - The path to the value, this can also be null and this function
 *  will return the value itself if it is not null, if its null it will return "--".
 * @param defaultValue - Default value to return if the value is not found, by default it is "--".
 * @returns {*} Either "--" or the value if found.
 */
export function get (value, path, defaultValue = DEFAULT_VALUE_STRING) {
  if (!path || path === '') {
    return value || defaultValue
  }
  const v = clone(lodashGet(value, path, defaultValue))
  if (v === '' || v === null) {
    return defaultValue
  }
  return v
}

/**
 * Join list returns by default return a comma separated list from a list of Strings, Numbers, Object
 * but you can also specify the joinStr
 *  if the path value is specified the objects in the list will be searched for that value or
 *  it will return "--" for the object not found.
 *  E.x
 *  let list = ["abc","xyz"]
 *  console.log(joinList(list)) # this will print: abc, xyz
 *
 *  E.x
 *  let list = ["abc","xyz"]
 *  console.log(joinList(list, " & ")) # this will print: abc & xyz
 *
 *  E.x
 *  let list = [ { inner: { name: "abc" } }, { inner: { name: "xyz" } }]
 *  console.log(joinList(list, "inner.name")) # this will print: abc, xyz
 *
 * @param list - The list that will be joined
 * @param joinStr - The string with which we want to join the list
 * @param path - The path to the object.
 * @param defaultValue - Default value to return if the value is not found, by default it is "--".
 * @returns {*} - A string, could be "--" if list is null, or it will return a string with comma separated items.
 */
export function joinList (list, path, joinStr, defaultValue = DEFAULT_VALUE_STRING) {
  if (!list || !list.length || !Array.isArray(list)) return defaultValue
  if (!joinStr) {
    joinStr = ', '
  }
  const preparedList = list.map(item => get(item, path, null)).filter(item => item != null)
  if (preparedList.length === 0) {
    return defaultValue
  }
  return preparedList.join(joinStr)
}

/**
 * DateString returns a formatted date or "--" if the provided date is not a valid date.
 *  E.x
 *  let dt = dateString("2018-01-01T01:01:01Z")
 *  console.log(dt) # 01/01/2018
 *  E.x
 *  let dt = dateString("2018-01-01T01:01:01Z", "MM/DD/YYYY hh:mm A")
 *  console.log(dt) # 01/01/2018 01:01 PM
 *  E.x
 *  console.log(dateString("Something stupid")) # --
 *  E.x
 *  console.log(dateString(null)) # --
 * @param date - The date string, date object.
 * @param format - format string.
 * @param defaultValue - Default value to return if the date is malformed or none, by default it is "--".
 * @returns {string} - The date string.
 */
export function dateString (date, format, defaultValue) {
  if (!moment(date, ['YYYY-MM-DD', moment.ISO_8601], true).isValid()) {
    return defaultValue === undefined ? DEFAULT_VALUE_STRING : defaultValue
  }
  if (!format) {
    format = 'MM/DD/YYYY'
  }
  const timezone = moment.tz.guess()
  return moment.tz(date, timezone).format(format)
}

/**
 * TimeString returns a formatted time or "--" if the provided time is not a valid time
 * E.x
 * ley t = timeString("20:40:44")
 * console.log(t) # 08:40 PM
 * E.x
 * let t = timeString("20:40:44", 'h:mm:ss')
 * console.log(t) # 8:40:44
 * E.x
 * console.log(timeString("Something stupid")) # --
 * E.x
 * console.log(timeString(null)) # --
 * @param time - The time string or time object
 * @param format - format string
 * @param defaultValue - Default value to return if the time is malformed or none, by default it is "--".
 * @returns {string} - The time string
 */
export function timeString (time, format, defaultValue) {
  if (!moment(time, 'HH:mm:ss').isValid()) {
    return defaultValue || DEFAULT_VALUE_STRING
  }
  if (!format) {
    format = 'hh:mm A'
  }
  const timezone = moment.tz.guess()
  return moment.tz(time, timezone).format(format)
}

/**
 * DateString returns a formatted date or "--" if the provided date is not a valid date.
 *  E.x
 *  let dt = dateString("2018-01-01T01:01:01Z")
 *  console.log(dt) # 01/01/2018
 *  E.x
 *  let dt = dateString("2018-01-01T01:01:01Z", "MM/DD/YYYY hh:mm A")
 *  console.log(dt) # 01/01/2018 01:01 PM
 *  E.x
 *  console.log(dateString("Something stupid")) # --
 *  E.x
 *  console.log(dateString(null)) # --
 * @param date - The date string, date object.
 * @param tz - specific timezone to use.
 * @param format - format string.
 * @param defaultValue - Default value to return if the date is malformed or none, by default it is "--".
 * @returns {string} - The date string.
 */
export function tzDateString (date, tz, format, defaultValue) {
  if (!moment(date, ['YYYY-MM-DD', moment.ISO_8601], true).isValid()) {
    return defaultValue === undefined ? DEFAULT_VALUE_STRING : defaultValue
  }
  if (!format) {
    format = 'MM/DD/YYYY'
  }
  return moment(date).tz(tz).format(format)
}

/**
 * TimeString returns a formatted time or "--" if the provided time is not a valid time
 * E.x
 * ley t = timeString("20:40:44")
 * console.log(t) # 08:40 PM
 * E.x
 * let t = timeString("20:40:44", 'h:mm:ss')
 * console.log(t) # 8:40:44
 * E.x
 * console.log(timeString("Something stupid")) # --
 * E.x
 * console.log(timeString(null)) # --
 * @param time - The time string or time object
 * @param tz - specific timezone to use.
 * @param format - format string
 * @param defaultValue - Default value to return if the time is malformed or none, by default it is "--".
 * @returns {string} - The time string
 */
export function tzTimeString (time, tz, format, defaultValue) {
  if (!moment(time, 'HH:mm:ss').isValid()) {
    return defaultValue || DEFAULT_VALUE_STRING
  }
  if (!format) {
    format = 'hh:mm A z'
  }
  return moment(time).tz(tz).format(format)
}

/**
 * getYearsDifferenceFromToday returns a number of year or "--" if the provided date is not a valid date.
 *  E.x
 *  let yourDateOfBirth = "1990-01-01T12:00:00"
 *  let dt = getYearsDifferenceFromToday(yourDateOfBirth)
 *  console.log(dt) # 29
 *  E.x
 *  let yourDateOfBirth = "1990-01-01"
 *  let dt = getYearsDifferenceFromToday(yourDateOfBirth)
 *  console.log(dt) # 29
 *  E.x
 *  console.log(dateString("Something stupid")) # --
 *  E.x
 *  console.log(dateString(null)) # --
 * @param date - The date string, date object.
 * @param defaultValue - Default value to return if the date is malformed or none, by default it is "--".
 * @returns {number} - .
 */
export function getYearsDifferenceFromToday (date, defaultValue) {
  if (!moment(date, ['YYYY-MM-DD', moment.ISO_8601], true).isValid()) {
    return defaultValue || DEFAULT_VALUE_STRING
  }
  return moment().diff(moment(date, 'YYYY-MM-DD'), 'years')
}

/**
 *
 * 12345 => $12,345.00
 *
 * @param {number|String} value to display as currency
 * @param {String} currency sign
 * @param {Number} decimals Decimal places
 * @param defaultValue - Default value to return if the date is malformed or none, by default it is "--".
 */
// eslint-disable-next-line complexity
export function currency (value, currency, decimals, defaultValue) {
  const digitsRE = /(\d{3})(?=\d)/g
  const parsedValue = parseFloat(value)
  if (!isFinite(parsedValue) || (!parsedValue && parsedValue !== 0)) return defaultValue || DEFAULT_VALUE_STRING
  currency = currency != null ? currency : '$'
  decimals = decimals != null ? decimals : 2
  let stringValue = Math.abs(parsedValue).toFixed(decimals)
  let _int = decimals
    ? stringValue.slice(0, -1 - decimals)
    : stringValue
  const i = _int.length % 3
  const head = i > 0
    ? (_int.slice(0, i) + (_int.length > 3 ? ',' : ''))
    : ''
  const _float = decimals
    ? stringValue.slice(-1 - decimals)
    : ''
  const sign = parsedValue < 0 ? '-' : ''
  return sign + currency + head +
    _int.slice(i).replace(digitsRE, '$1,') +
    _float
}
/**
 * formatPhoneNumber returns a formatted version of a U.S phone number.
 *  E.x
 *  Input phone number "(prefix)123456789" returns "(123) 456-789"
 * @param {String} phoneNumber - The phone string.
 * @returns {string} - The formatted phone number
 */
export function formatPhoneNumber (phoneNumber) {
  if (!phoneNumber || !/\d/.test(phoneNumber) || typeof phoneNumber !== 'string') return phoneNumber
  try {
    let phone = parsePhoneNumber(phoneNumber)
    if (phone.country === 'US') {
      return phone.formatNational()
    }
    return phone.formatInternational()
  } catch (_) {
    return phoneNumber
  }
}
