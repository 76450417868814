const LINES_GQL_CLIENT = 'lines'

const VALID_REFERENCE_NAME = /^[a-zA-Z_0-9.\-\u00A2-\uFFFF]*$/

const FIELD_TYPE_ICONS = new Map([
  ['date', 'calendar-alt'],
  ['boolean', 'check-square'],
  ['string', 'file-alt'],
  ['number', 'hashtag'],
  ['enum', 'list-ul'],
  ['computed', 'calculator']
])

const TRANSACTION_TYPE_NEW_BUSINESS = 'new_business'
const TRANSACTION_TYPE_RENEWAL = 'renewal'
const TRANSACTION_TYPE_ENDORSEMENT = 'endorsement'
const TRANSACTION_TYPE_CANCELLATION = 'cancellation'
const TRANSACTION_TYPE_REINSTATEMENT = 'reinstatement'
const TRANSACTION_TYPE_REWRITE = 'rewrite'

const TRANSACTION_TYPES = {
  [TRANSACTION_TYPE_NEW_BUSINESS]: 'New Business',
  [TRANSACTION_TYPE_RENEWAL]: 'Renewal',
  [TRANSACTION_TYPE_ENDORSEMENT]: 'Endorsement',
  [TRANSACTION_TYPE_CANCELLATION]: 'Cancellation',
  [TRANSACTION_TYPE_REINSTATEMENT]: 'Reinstatement',
  [TRANSACTION_TYPE_REWRITE]: 'Rewrite'
}

const RATE_TABLE_MISSING_DATA = 'missing-data'
const RATE_TABLE_SOURCES_CHANGED = 'sources-have-changed'

export {
  LINES_GQL_CLIENT,
  VALID_REFERENCE_NAME,
  FIELD_TYPE_ICONS,
  RATE_TABLE_MISSING_DATA,
  RATE_TABLE_SOURCES_CHANGED,
  TRANSACTION_TYPES,
  TRANSACTION_TYPE_NEW_BUSINESS,
  TRANSACTION_TYPE_RENEWAL,
  TRANSACTION_TYPE_ENDORSEMENT,
  TRANSACTION_TYPE_CANCELLATION,
  TRANSACTION_TYPE_REWRITE
}
