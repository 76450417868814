import { addNamespace } from '@/store/helpers'

import { PRODUCT_EXPORTS_STORE_PATH } from './constants'

// To be used only inside vuex modules.
export const _actionTypes = {
  INITIALIZE_EXPORT: 'initializeExport',
  LOAD_EXPORT: 'loadExport',
  LOAD_EXPORTS: 'loadExports',
  SET_EXPORT: 'setExport',
  SET_EXPORTS: 'setExports'
}

// To be used publicly outside vuex modules.
export const actionTypes = addNamespace(PRODUCT_EXPORTS_STORE_PATH, _actionTypes)
