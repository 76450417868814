import AWS from 'aws-sdk/global'
import { CognitoUserPool, CookieStorage } from 'amazon-cognito-identity-js'
import { CognitoAuth } from 'amazon-cognito-auth-js'

const REGION = process.env.VUE_APP_COGNITO_REGION
const USER_POOL_ID = process.env.VUE_APP_COGNITO_USER_POOL_ID
const APP_CLIENT_ID = process.env.VUE_APP_COGNITO_APP_CLIENT_ID
const ENABLED_OAUTH_PROVIDERS = process.env.VUE_APP_COGNITO_ENABLED_OAUTH_PROVIDERS

const OAUTH_TOKEN_SCOPES = ['openid', 'email', 'profile', 'phone', 'aws.cognito.signin.user.admin']
const STORAGE = new CookieStorage({})

AWS.config.region = REGION

let oauthProviders = []

if (ENABLED_OAUTH_PROVIDERS) {
  for (let providerName of ENABLED_OAUTH_PROVIDERS.split(' ')) {
    const emailDomain = process.env[`VUE_APP_COGNITO_PROVIDER_${providerName}_EMAIL_DOMAIN`]
    const signInLabel = process.env[`VUE_APP_COGNITO_PROVIDER_${providerName}_SIGN_IN_LABEL`]
    const signInWithButtonVisible = process.env[`VUE_APP_COGNITO_PROVIDER_${providerName}_SHOW_SIGN_IN_BUTTON`]
    const providerId = process.env[`VUE_APP_COGNITO_PROVIDER_${providerName}_ID`]

    oauthProviders.push({
      id: providerId,
      name: providerName,
      domain: emailDomain,
      label: signInLabel,
      showSignInWithButton: !!signInWithButtonVisible
    })
  }
}

const poolData = {
  UserPoolId: USER_POOL_ID,
  ClientId: APP_CLIENT_ID,
  Storage: STORAGE
}

const oauthClientData = {
  TokenScopesArray: OAUTH_TOKEN_SCOPES,
  RedirectUriSignIn: process.env.VUE_APP_COGNITO_OAUTH_REDIRECT_SIGNIN,
  RedirectUriSignOut: process.env.VUE_APP_COGNITO_OAUTH_REDIRECT_SIGNOUT,
  AppWebDomain: process.env.VUE_APP_COGNITO_APP_DOMAIN,
  ClientId: APP_CLIENT_ID,
  UserPoolId: USER_POOL_ID,
  Storage: STORAGE
}

const oauthClient = new CognitoAuth(oauthClientData)
oauthClient.useCodeGrantFlow()

export const state = {
  oauthClient,
  oauthProviders,
  userPool: new CognitoUserPool(poolData),
  currentUser: null,
  isAdmin: null,
  isEmployee: null,
  session: null,
  sessionTimeout: null,
  isAuthenticating: false,
  isBeingLoggedOut: false,
  lastSignInError: null,
  gen2EffectiveRole: null
}
