
export const state = {
  quote: {},
  quotes: [],
  quotesSearchMeta: {},
  contacts: [],
  contactMap: {},
  rootRiskQuote: {},
  quoteFlow: null,
  activePageGroup: {},
  activePage: {},
  riskTypes: [],
  riskTypeStates: {},
  rulesVersion: null,
  underwriterContact: {},
  quoteState: {
    isProcessingRequest: false,
    lastRequestId: 0
  },
  quoteIsBinding: false,
  quoteLastBindRequest: 0
}
