<template>
  <ul class="c-nav-sidebar__list u-list-unstyled u-mb0">
    <li
      v-for="item in items"
      :key="item.label">
      <a
        v-if="!item.children"
        :href="getHref(item)"
        @click="handleNavLinkClick($event, item)"
        class="c-nav-sidebar__link"
        :class="{ 'c-nav-sidebar__link--active': item.location && currentRouteName === item.location.name }">
        <fa-icon
          v-if="item.icon"
          :icon="item.icon"
          class="c-nav-sidebar__link-icon"
          fixed-width />
          <span>{{ item.label }}</span>
      </a>
      <div
        class="c-nav-sidebar__item"
        :class="{'u-pb0': !childrenCollapsed}"
        v-else>
        <div
          @click="toggleCollapse"
          class="l-flex-grid l-flex-grid--v-center">
          <fa-icon
            icon="caret-down"
            class="c-nav-sidebar__collapse-icon"
            :class="{
              'c-nav-sidebar__collapse-icon--rotated': childrenCollapsed
            }"/>
          <span>{{ item.label }}</span>
        </div>
        <div
          class="c-nav-sidebar__break"
          v-if="item.children"></div>
        <el-collapse-transition>
          <ul
            v-show="!childrenCollapsed"
            class="c-nav-sidebar__list u-list-unstyled u-mt2">
            <li
              v-for="child in item.children"
              :key="child.label">
              <a
                :href="getHref(child)"
                @click="handleNavLinkClick($event, child)"
                class="c-nav-sidebar__link"
                :class="{ 'c-nav-sidebar__link--active': child.location && currentRouteName === child.location.name }">
                {{ child.label }}
              </a>
            </li>
          </ul>
        </el-collapse-transition>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'NavigationList',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      currentRouteName: this.$router.currentRoute.name,
      childrenCollapsed: true
    }
  },
  methods: {
    handleNavLinkClick (event, item) {
      if (item.method !== undefined) {
        item.method(event, item.label)
      } else if (item.location !== undefined) {
        this.$router.push(item.location)
      }
    },
    getHref (item) {
      let params = item.params ? item.params(this.$route.params) : ''
      let href = item.href
      return href ? href + params : null
    },
    toggleCollapse () {
      this.childrenCollapsed = !this.childrenCollapsed
    }
  },
  watch: {
    $route (to, from) {
      this.currentRouteName = to.name
    }
  }
}
</script>
