
import Cookies from 'js-cookie'
import {mutationTypes} from '@/store/access/mutationTypes'
import {makeEvaluationKey} from '@/store/access/utils'
import {ASSIGNMENTS_PAGINATION_INITIAL_STATE} from './constants'
import Vue from 'vue'

const mutations = {
  [mutationTypes.SET_ACCESS_POLICIES] (state, {accessPolicyList, type}) {
    switch (type) {
      case 'custom':
        state.accessPolicyListCustom = accessPolicyList
        break
      case 'system':
        state.accessPolicyListSystem = accessPolicyList
        break
      default:
        state.accessPolicyList = accessPolicyList
    }
  },
  [mutationTypes.SET_CURRENT_ACCESS_POLICY] (state, accessPolicy) {
    state.accessPolicy = accessPolicy
  },
  [mutationTypes.SET_ROLES] (state, {roles}) {
    state.roles = roles
  },
  [mutationTypes.SET_ROLE] (state, role) {
    state.role = role
  },
  [mutationTypes.SET_USERS] (state, users) {
    state.users = [...state.users, ...users]
  },
  [mutationTypes.SET_GROUPS] (state, groups) {
    state.groups = groups
  },
  [mutationTypes.SET_CURRENT_PAGE] (state, currentPage) {
    state.policyPagination.currentPage = currentPage
  },
  [mutationTypes.RESET_ACCESS_POLICIES] (state) {
    state.accessPolicyListCustom = []
    state.accessPolicyListSystem = []
  },
  [mutationTypes.RESET_ACCESS_POLICY] (state) {
    state.accessPolicy = {}
    state.assignments = []
    state.localAssignments = []
  },
  [mutationTypes.RESET_ASSIGNMENTS_PAGINATION] (state) {
    state.accessPolicyAssignmentsPagination = {...ASSIGNMENTS_PAGINATION_INITIAL_STATE}
    state.roleAccessPolicyAssignmentsPagination = {...ASSIGNMENTS_PAGINATION_INITIAL_STATE}
    state.roleGroupAssignmentsPagination = {...ASSIGNMENTS_PAGINATION_INITIAL_STATE}
    state.roleUserAssignmentsPagination = {...ASSIGNMENTS_PAGINATION_INITIAL_STATE}
  },
  [mutationTypes.SET_PAGINATION_TOTAL] (state, total) {
    state.policyPagination.total = total
  },
  [mutationTypes.SET_ACCESS_POLICY_STATEMENTS] (state, statements) {
    state.accessPolicy.statements = statements
  },
  [mutationTypes.SET_ACCESS_POLICY_ASSIGNMENTS] (state, assignments) {
    state.assignments = assignments
  },
  [mutationTypes.SET_LOCAL_ACCESS_POLICY_ASSIGNMENTS] (state, assignments) {
    state.localAssignments = assignments
  },
  [mutationTypes.SET_ASSIGNMENTS_WERE_MODIFIED] (state, value) {
    state.assignmentsWereModified = value
  },
  [mutationTypes.SET_EFFECTIVE_ROLE] (state, role) {
    state.effectiveRole = role
    // TODO: Refactor to make createPersistedState handle the local storage persistence
    localStorage.setItem('EffectiveRole', role)
    Cookies.set('EffectiveRole', role)
    // Clear the evaluations state when a new role is selected to force the re-evaluation of access rules
    state.evaluations = {}
  },
  [mutationTypes.SET_LOCAL_ROLE] (state, role) {
    state.localRole = role
  },
  [mutationTypes.SET_LOCAL_ROLES_ASSIGNMENTS] (state, assignments) {
    state.localRoleUserGroupAssignments = assignments
  },
  [mutationTypes.SET_ROLE_ASSIGNMENTS] (state, assignments) {
    state.roleAssignments = assignments
  },
  [mutationTypes.RESET_ROLE] (state) {
    state.role = {
      name: '',
      id: ''
    }
    state.localRole = {
      name: '',
      id: ''
    }
    state.roleAssignments = []
    state.localRoleUserGroupAssignments = []
    state.localAssignments = []
    state.assignments = []
  },
  [mutationTypes.RESET_ROLES_LIST] (state) {
    state.roles = []
  },
  [mutationTypes.RESET_USERS_LIST] (state) {
    state.users = []
  },
  [mutationTypes.RESET_GROUPS_LIST] (state) {
    state.groups = []
  },
  [mutationTypes.SET_CURRENT_ROLES_PAGE] (state, currentPage) {
    state.rolePagination.currentPage = currentPage
  },
  [mutationTypes.SET_CURRENT_USERS_PAGE] (state, currentPage) {
    state.userPagination.currentPage = currentPage
  },
  [mutationTypes.SET_CURRENT_GROUPS_PAGE] (state, currentPage) {
    state.groupPagination.currentPage = currentPage
  },
  [mutationTypes.SET_USERS_PAGINATION_TOTAL] (state, total) {
    state.userPagination.total = total
  },
  [mutationTypes.SET_GROUPS_PAGINATION_TOTAL] (state, total) {
    state.groupPagination.total = total
  },
  [mutationTypes.SET_ROLES_PAGINATION_TOTAL] (state, total) {
    state.rolePagination.total = total
  },
  [mutationTypes.SET_USER_ROLES] (state, userRoles) {
    state.userRoles = userRoles
  },
  [mutationTypes.SET_ASSIGNED_USERS_GROUPS_RECORDS] (state, assignedUsersGroupsRecords) {
    state.assignedUsersGroupsRecords = assignedUsersGroupsRecords
  },
  [mutationTypes.SET_EVALUATE_RESULT] (state, {resource, action, record, context, evaluation}) {
    const key = makeEvaluationKey(resource, action, record, context)
    Vue.set(state.evaluations, key, evaluation)
  },
  [mutationTypes.CLEAR_EVALUATE_RESULT] (state) {
    state.evaluations = {}
  }
}
export default mutations
