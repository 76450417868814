import {cloneDeep, flatten, isNil, isObject, range, size} from 'lodash'
import request from './request'
import api from './jsonapi'

const DEFAULT_BULK_SIZE = 250

/**
 * Utilities
 */

const toList = (items) => items.filter(item => !isNil(item)).map(item => ({id: item}))
const toObject = (item) => ({id: item})

/**
 * apiService is used as a base API class for other API services.
 */
class ApiService {
  /**
   * Constructor.
   * @param {String} url the url that gets appended to the API url.
   *  e.x `claims`, if no url is provided the service will use only process.env.API_URL
   */
  constructor (url) {
    if (url !== '') {
      url = url.replace(/\/$/, '') // Remove if there is a trailing slash at the end of the string
      this.baseUrl = `${process.env.VUE_APP_API_URL}/${url}`
    } else {
      this.baseUrl = process.env.VUE_APP_API_URL
    }
  }
  /**
   * Wraps an Axios get request and returns the promise.
   * @param {String} url             the url without the base route, the base will be appended afterwards
   *  e.x if you have created the service with `/claims` and you want to get `/claims/something` you only sent
   *  `something` as the url parameter not `/claims/something`.
   * @param {Object} config           the Axios configuration object.
   * @returns {Promise<any>}          the get promise.
   */
  get (url, config = null) {
    return request.execute('get', `${this.baseUrl}/${url}`, null, config)
  }

  /**
   * Wraps an Axios post request and returns the promise.
   * @param {String} url              the url without the base route.
   * @param {Object} data             the data that will be sent to the endpoint.
   * @param {Object} config           the Axios configuration object.
   * @returns {Promise<any>}          the post promise.
   */
  post (url, data, config = null) {
    return request.execute('post', `${this.baseUrl}/${url}`, data, config)
  }

  /**
   * Wraps an Axios delete request and returns the promise.
   * @param {String} url              the url without the base route.
   * @param {Object} config           the Axios configuration object.
   * @returns {Promise<any>}          the delete promise.
   */
  delete (url, data = null, config = null) {
    return request.execute('delete', `${this.baseUrl}/${url}/`, data, config)
  }

  /**
   * Wraps an Axios patch request and returns the promise.
   * @param {String} url              the url without the base route.
   * @param {Object} data             the data that will be sent to the endpoint.
   * @param {Object} config           the Axios configuration object.
   * @returns {Promise<any>}          the patch promise.
   */
  patch (url, data, config = null) {
    return request.execute('patch', `${this.baseUrl}/${url}`, data, config)
  }

  /**
   * Wraps an Axios put request and returns the promise.
   * @param {String} url              the url without the base route.
   * @param {Object} data             the data that will be sent to the endpoint.
   * @param {Object} config           the Axios configuration object.
   * @returns {Promise<any>}          the put promise.
   */
  put (url, data, config = null) {
    return request.execute('put', `${this.baseUrl}/${url}`, data, config)
  }
}

/**
 * JsonApiService is used as a base API class for JSON:API services.
 */
class JsonApiService {
  constructor (modelName) {
    this.api = api
    this.model = api.models[modelName]
    this.modelName = modelName
  }

  serializeByModel (data) {
    // Automated way to serialize, by model definition
    Object.entries(this.model.attributes).map(map => {
      let [prop, value] = map

      // exit early if prop does not exist in data
      if (!data[prop]) {
        return
      }

      // exit early if value is not an object or
      // it does not contain valid jsonApi key-value pair
      if (!isObject(value) || !value.jsonApi) {
        return
      }

      switch (value.jsonApi) {
        case 'hasOne':
          data[prop] = toObject(data[prop])
          break
        case 'hasMany':
          data[prop] = toList(data[prop])
      }
    })
  }

  serializeByMapping (data, mapping) {
    // Manual way to serialize, if mapping defined
    Object.entries(mapping).map(map => {
      let [prop, convert] = map
      if (data[prop]) {
        data[prop] = convert(data[prop])
      }
    })
  }

  serialize (data, mapping = {}) {
    // The data needs to be cloned, otherwise a repeated call will double serialize the data due to its
    // by reference design.
    const clonedData = cloneDeep(data)
    size(mapping) === 0
      ? this.serializeByModel(clonedData)
      : this.serializeByMapping(clonedData, mapping)

    // Remove id key, if exists
    delete clonedData.id
    // Individual api service may implement its own serializer
    return clonedData
  }

  create ({data}, params = {}) {
    return this.api.create(this.modelName, this.serialize(data), params)
  }

  update ({id, data}, params = {}) {
    return this.api.update(this.modelName, {...this.serialize(data), id}, params)
  }

  delete ({id}) {
    return this.api.destroy(this.modelName, id)
  }

  get ({id}, params = {}) {
    return this.api.find(this.modelName, id, params)
  }

  list (params = {}) {
    return this.api.findAll(this.modelName, {
      page: {
        number: 1,
        size: DEFAULT_BULK_SIZE
      },
      ...params
    })
  }

  async listAll (params = {}) {
    // 1. Fetch first page
    const {data, meta, links} = await this.list({
      ...params,
      page: {
        number: 1,
        size: DEFAULT_BULK_SIZE
      }
    })

    // 2. Populate remaining requests
    // - Short-circuit exit if no further fetching is needed.
    if (meta.pagination.pages === 1) {
      return {data, meta, links}
    }
    // - Populate the remaining requests
    const requests = range(2, meta.pagination.pages + 1).map(page => {
      return this.list({
        ...params,
        page: {
          number: page,
          size: DEFAULT_BULK_SIZE
        }
      })
    })
    const responses = await Promise.all(requests)

    // 3. Return aggregated data
    return {
      data: [
        ...data,
        ...flatten(responses.map(response => response.data))
      ]
    }
  }
}

export { ApiService as default, ApiService, JsonApiService, toList, toObject }
