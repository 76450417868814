import { library } from '@fortawesome/fontawesome-svg-core'
import customIcons from './fontawesomeCustomIcons'

// @fortawesome/free-regular-svg-icons
import { faEdit as farEdit } from '@fortawesome/free-regular-svg-icons/faEdit'
import { faCheckCircle as farCheckCircle } from '@fortawesome/free-regular-svg-icons/faCheckCircle'
import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons/faCircle'
import { faClone as farClone } from '@fortawesome/free-regular-svg-icons/faClone'
import { faEnvelope as farEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope'
import { faFile as farFile } from '@fortawesome/free-regular-svg-icons/faFile'
import { faFileAlt as farFileAlt } from '@fortawesome/free-regular-svg-icons/faFileAlt'
import { faFileCode as farFileCode } from '@fortawesome/free-regular-svg-icons/faFileCode'
import { faFlag as farFlag } from '@fortawesome/free-regular-svg-icons/faFlag'
import { faTimesCircle as farTimesCircle } from '@fortawesome/free-regular-svg-icons/faTimesCircle'
import { faWindowClose as farWindowClose } from '@fortawesome/free-regular-svg-icons/faWindowClose'

// @fortawesome/pro-light-svg-icons
import { faAngleDown as faAngleDownLight } from '@fortawesome/pro-light-svg-icons/faAngleDown'
import { faBan as faBanLight } from '@fortawesome/pro-light-svg-icons/faBan'
import { faFile as faFileLight } from '@fortawesome/pro-light-svg-icons/faFile'
import { faShield as faShieldLight } from '@fortawesome/pro-light-svg-icons/faShield'
import { faUser as faUserLight } from '@fortawesome/pro-light-svg-icons/faUser'
import { faCube as faCubeLight } from '@fortawesome/pro-light-svg-icons/faCube'

// @fortawesome/pro-regular-svg-icons
import { faAlignLeft as faAlignLeftRegular } from '@fortawesome/pro-regular-svg-icons/faAlignLeft'
import { faAlignRight as faAlignRightRegular } from '@fortawesome/pro-regular-svg-icons/faAlignRight'
import { faAlignCenter as faAlignCenterRegular } from '@fortawesome/pro-regular-svg-icons/faAlignCenter'
import { faAlignJustify as faAlignJustifyRegular } from '@fortawesome/pro-regular-svg-icons/faAlignJustify'
import { faBan as faBanRegular } from '@fortawesome/pro-regular-svg-icons/faBan'
import { faBars as faBarsRegular } from '@fortawesome/pro-regular-svg-icons/faBars'
import { faClipboardCheck as faClipboardCheckRegular } from '@fortawesome/pro-regular-svg-icons/faClipboardCheck'
import { faClipboardList as faClipboardListRegular } from '@fortawesome/pro-regular-svg-icons/faClipboardList'
import { faCloudDownload } from '@fortawesome/pro-regular-svg-icons/faCloudDownload'
import { faCopy as faCopyRegular } from '@fortawesome/pro-regular-svg-icons/faCopy'
import { faDownload as daDownloadRegular } from '@fortawesome/pro-regular-svg-icons/faDownload'
import { faEllipsisH as faEllipsisHRegular } from '@fortawesome/pro-regular-svg-icons/faEllipsisH'
import { faFile as faFileRegular } from '@fortawesome/pro-regular-svg-icons/faFile'
import { faFileTimes as faFileTimesRegular } from '@fortawesome/pro-regular-svg-icons/faFileTimes'
import { faFilePdf as faFilePdfRegular } from '@fortawesome/pro-regular-svg-icons/faFilePdf'
import { faFolder as faFolderRegular } from '@fortawesome/pro-regular-svg-icons/faFolder'
import { faFolderTree as faFolderTreeRegular } from '@fortawesome/pro-regular-svg-icons/faFolderTree'
import { faFunction as faFunctionRegular } from '@fortawesome/pro-regular-svg-icons/faFunction'
import { faHorizontalRule as faHorizontalRuleRegular } from '@fortawesome/pro-regular-svg-icons/faHorizontalRule'
import { faIndent as faIndentRegular } from '@fortawesome/pro-regular-svg-icons/faIndent'
import { faInfoCircle as faInfoCircleRegular } from '@fortawesome/pro-regular-svg-icons/faInfoCircle'
import { faLink as faLinkRegular } from '@fortawesome/pro-regular-svg-icons/faLink'
import { faList as faListRegular } from '@fortawesome/pro-regular-svg-icons/faList'
import { faListOl as faListOlRegular } from '@fortawesome/pro-regular-svg-icons/faListOl'
import { faMap as faMapRegular } from '@fortawesome/pro-regular-svg-icons/faMap'
import { faMobile as faMobileRegular } from '@fortawesome/pro-regular-svg-icons/faMobile'
import { faOutdent as faOutdentRegular } from '@fortawesome/pro-regular-svg-icons/faOutdent'
import { faPencil as faPencilRegular } from '@fortawesome/pro-regular-svg-icons/faPencil'
import { faRedoAlt as faRedoAltRegular } from '@fortawesome/pro-regular-svg-icons/faRedoAlt'
import { faSearch as faSearchRegular } from '@fortawesome/pro-regular-svg-icons/faSearch'
import { faTablet as faTabletRegular } from '@fortawesome/pro-regular-svg-icons/faTablet'
import { faTasks as faTasksRegular } from '@fortawesome/pro-regular-svg-icons/faTasks'
import { faThList as faThListRegular } from '@fortawesome/pro-regular-svg-icons/faThList'
import { faUndoAlt as faUndoAltRegular } from '@fortawesome/pro-regular-svg-icons/faUndoAlt'

// @fortawesome/free-brands-svg-icons
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'

// @fortawesome/pro-solid-svg-icons
import { faFolder as faFolderSolid } from '@fortawesome/pro-solid-svg-icons/faFolder'
import { faListUl as faListUlfrom } from '@fortawesome/pro-solid-svg-icons/faListUl'
import { faAddressCard } from '@fortawesome/pro-solid-svg-icons/faAddressCard'
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown'
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons/faAngleUp'
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight'
import { faArrowsAlt } from '@fortawesome/pro-solid-svg-icons/faArrowsAlt'
import { faArrowCircleRight } from '@fortawesome/pro-solid-svg-icons/faArrowCircleRight'
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons/faArrowDown'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons/faArrowToBottom'
import { faArrowsH } from '@fortawesome/pro-solid-svg-icons/faArrowsH'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft'
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons/faAsterisk'
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars'
import { faBold } from '@fortawesome/pro-solid-svg-icons/faBold'
import { faBolt } from '@fortawesome/pro-solid-svg-icons/faBolt'
import { faBook } from '@fortawesome/pro-solid-svg-icons/faBook'
import { faBug } from '@fortawesome/pro-solid-svg-icons/faBug'
import { faCalculator } from '@fortawesome/pro-solid-svg-icons/faCalculator'
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt'
import { faCar } from '@fortawesome/pro-solid-svg-icons/faCar'
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp'
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'
import { faChartBar } from '@fortawesome/pro-solid-svg-icons/faChartBar'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp'
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle'
import { faComment } from '@fortawesome/pro-solid-svg-icons/faComment'
import { faComments } from '@fortawesome/pro-solid-svg-icons/faComments'
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock'
import { faCloudUploadAlt } from '@fortawesome/pro-solid-svg-icons/faCloudUploadAlt'
import { faCodeBranch } from '@fortawesome/pro-solid-svg-icons/faCodeBranch'
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog'
import { faCogs } from '@fortawesome/pro-solid-svg-icons/faCogs'
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy'
import { faCompressWide } from '@fortawesome/pro-solid-svg-icons/faCompressWide'
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons/faCreditCard'
import { faDatabase } from '@fortawesome/pro-solid-svg-icons/faDatabase'
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload'
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons/faDollarSign'
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons/faEllipsisH'
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faEquals } from '@fortawesome/pro-solid-svg-icons/faEquals'
import { faExchange } from '@fortawesome/pro-solid-svg-icons/faExchange'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import { faExpandWide } from '@fortawesome/pro-solid-svg-icons/faExpandWide'
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons/faExternalLinkAlt'
import { faExternalLinkSquareAlt } from '@fortawesome/pro-solid-svg-icons/faExternalLinkSquareAlt'
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons/faFileAlt'
import { faFileCertificate } from '@fortawesome/pro-solid-svg-icons/faFileCertificate'
import { faFileImage } from '@fortawesome/pro-solid-svg-icons/faFileImage'
import { faFilePdf } from '@fortawesome/pro-solid-svg-icons/faFilePdf'
import { faFilePlus } from '@fortawesome/pro-solid-svg-icons/faFilePlus'
import { faFlag } from '@fortawesome/pro-solid-svg-icons/faFlag'
import { faFlask } from '@fortawesome/pro-solid-svg-icons/faFlask'
import { faFolderOpen } from '@fortawesome/pro-solid-svg-icons/faFolderOpen'
import { faFolderPlus } from '@fortawesome/pro-solid-svg-icons/faFolderPlus'
import { faGlobe } from '@fortawesome/pro-solid-svg-icons/faGlobe'
import { faHashtag } from '@fortawesome/pro-solid-svg-icons/faHashtag'
import { faHandshake } from '@fortawesome/pro-solid-svg-icons/faHandshake'
import { faHistory } from '@fortawesome/pro-solid-svg-icons/faHistory'
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage'
import { faItalic } from '@fortawesome/pro-solid-svg-icons/faItalic'
import { faKey } from '@fortawesome/pro-solid-svg-icons/faKey'
import { faLaptop } from '@fortawesome/pro-solid-svg-icons/faLaptop'
import { faLevelUp } from '@fortawesome/pro-solid-svg-icons/faLevelUp'
import { faLevelDown } from '@fortawesome/pro-solid-svg-icons/faLevelDown'
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink'
import { faLocationArrow } from '@fortawesome/pro-solid-svg-icons/faLocationArrow'
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock'
import { faLockOpen } from '@fortawesome/pro-solid-svg-icons/faLockOpen'
import { faMapMarker } from '@fortawesome/pro-solid-svg-icons/faMapMarker'
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt'
import { faMobileAlt } from '@fortawesome/pro-solid-svg-icons/faMobileAlt'
import { faMoon } from '@fortawesome/pro-solid-svg-icons/faMoon'
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus'
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons/faPaperclip'
import { faPercent } from '@fortawesome/pro-solid-svg-icons/faPercent'
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons/faPencilAlt'
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle'
import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle'
import { faQuestionSquare } from '@fortawesome/pro-solid-svg-icons/faQuestionSquare'
import { faQuoteRight } from '@fortawesome/pro-solid-svg-icons/faQuoteRight'
import { faReply } from '@fortawesome/pro-solid-svg-icons/faReply'
import { faSave } from '@fortawesome/pro-solid-svg-icons/faSave'
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { faShareAlt } from '@fortawesome/pro-solid-svg-icons/faShareAlt'
import { faShieldAlt } from '@fortawesome/pro-solid-svg-icons/faShieldAlt'
import { faSignOut } from '@fortawesome/pro-solid-svg-icons/faSignOut'
import { faSlash } from '@fortawesome/pro-solid-svg-icons/faSlash'
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons/faSlidersH'
import { faSort } from '@fortawesome/pro-solid-svg-icons/faSort'
import { faSortDown } from '@fortawesome/pro-solid-svg-icons/faSortDown'
import { faSortUp } from '@fortawesome/pro-solid-svg-icons/faSortUp'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons/faSpinnerThird'
import { faStrikethrough } from '@fortawesome/pro-solid-svg-icons/faStrikethrough'
import { faStickyNote } from '@fortawesome/pro-solid-svg-icons/faStickyNote'
import { faStamp } from '@fortawesome/pro-solid-svg-icons/faStamp'
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons/faSyncAlt'
import { faTag } from '@fortawesome/pro-solid-svg-icons/faTag'
import { faTasks } from '@fortawesome/pro-solid-svg-icons/faTasks'
import { faTh } from '@fortawesome/pro-solid-svg-icons/faTh'
import { faThLarge } from '@fortawesome/pro-solid-svg-icons/faThLarge'
import { faThList } from '@fortawesome/pro-solid-svg-icons/faThList'
import { faThumbtack } from '@fortawesome/pro-solid-svg-icons/faThumbtack'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { faTools } from '@fortawesome/pro-solid-svg-icons/faTools'
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash'
import { faUmbrella } from '@fortawesome/pro-solid-svg-icons/faUmbrella'
import { faUnderline } from '@fortawesome/pro-solid-svg-icons/faUnderline'
import { faUnlink } from '@fortawesome/pro-solid-svg-icons/faUnlink'
import { faUpload } from '@fortawesome/pro-solid-svg-icons/faUpload'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faUserInjured } from '@fortawesome/pro-solid-svg-icons/faUserInjured'
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers'
import { faVideo } from '@fortawesome/pro-solid-svg-icons/faVideo'
import { faWrench } from '@fortawesome/pro-solid-svg-icons/faWrench'
import { faCalendar } from '@fortawesome/pro-solid-svg-icons/faCalendar'
import { faExclamationSquare } from '@fortawesome/pro-solid-svg-icons/faExclamationSquare'
import { faUserClock } from '@fortawesome/pro-solid-svg-icons/faUserClock'
import { faUserTie } from '@fortawesome/pro-solid-svg-icons/faUserTie'
import { faArchive } from '@fortawesome/pro-solid-svg-icons/faArchive'
import { faPalette } from '@fortawesome/pro-solid-svg-icons/faPalette'
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons/faUserFriends'
import { faUserTag } from '@fortawesome/pro-solid-svg-icons/faUserTag'
import { faFolderTree } from '@fortawesome/pro-solid-svg-icons/faFolderTree'
import { faTruck } from '@fortawesome/pro-solid-svg-icons/faTruck'
import { faFarm } from '@fortawesome/pro-solid-svg-icons/faFarm'
import { faStoreAlt } from '@fortawesome/pro-solid-svg-icons/faStoreAlt'
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons/faPaperPlane'
import { faFileUpload } from '@fortawesome/pro-solid-svg-icons/faFileUpload'
import { faPlug } from '@fortawesome/pro-solid-svg-icons/faPlug'
import { faChartNetwork } from '@fortawesome/pro-solid-svg-icons/faChartNetwork'

library.add(
  ...customIcons,
  faAddressCard,
  faAlignCenterRegular,
  faAlignJustifyRegular,
  faAlignLeftRegular,
  faAlignRightRegular,
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faArchive,
  faArrowCircleRight,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faArrowToBottom,
  faArrowsAlt,
  faArrowsH,
  faAsterisk,
  faBanLight,
  faBanRegular,
  faBars,
  faBarsRegular,
  faBold,
  faBolt,
  faBook,
  faBug,
  faCalculator,
  faCalendar,
  faCalendarAlt,
  faCar,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faChartBar,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClipboardCheckRegular,
  faClipboardListRegular,
  faClock,
  faCloudDownload,
  faCloudUploadAlt,
  faCodeBranch,
  faCog,
  faCogs,
  faComment,
  faComments,
  faCompressWide,
  faCopy,
  faCopyRegular,
  faCreditCard,
  faDatabase,
  faDollarSign,
  faDownload,
  daDownloadRegular,
  faEdit,
  faEllipsisH,
  faEllipsisHRegular,
  faEllipsisV,
  faEnvelope,
  faEquals,
  faExchange,
  faExclamationCircle,
  faExclamationSquare,
  faExclamationTriangle,
  faExpandWide,
  faExternalLinkAlt,
  faExternalLinkSquareAlt,
  faFileAlt,
  faFileCertificate,
  faFileImage,
  faFileLight,
  faFilePdf,
  faFilePdfRegular,
  faFilePlus,
  faFileRegular,
  faFileTimesRegular,
  faFlag,
  faFlask,
  faFolderOpen,
  faFolderPlus,
  faFolderRegular,
  faFolderSolid,
  faFolderTreeRegular,
  faFunctionRegular,
  faGlobe,
  faGoogle,
  faHandshake,
  faHashtag,
  faHistory,
  faHome,
  faHorizontalRuleRegular,
  faImage,
  faIndentRegular,
  faInfoCircle,
  faInfoCircleRegular,
  faItalic,
  faKey,
  faLaptop,
  faLevelUp,
  faLevelDown,
  faLink,
  faLinkRegular,
  faListOlRegular,
  faListRegular,
  faListUlfrom,
  faLocationArrow,
  faLock,
  faLockOpen,
  faMapMarker,
  faMapMarkerAlt,
  faMapRegular,
  faMobileAlt,
  faMobileRegular,
  faMoon,
  faMinus,
  faOutdentRegular,
  faPaperclip,
  faPencil,
  faPencilAlt,
  faPencilRegular,
  faPercent,
  faPhone,
  faPlay,
  faPlus,
  faPlusCircle,
  faQuestion,
  faQuestionCircle,
  faQuestionSquare,
  faQuoteRight,
  faRedoAltRegular,
  faReply,
  faSave,
  faSearch,
  faSearchRegular,
  faShareAlt,
  faShieldAlt,
  faShieldLight,
  faSignOut,
  faSlash,
  faSlidersH,
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
  faSpinnerThird,
  faStamp,
  faStickyNote,
  faStrikethrough,
  faSyncAlt,
  faTabletRegular,
  faTasksRegular,
  faTag,
  faTasks,
  faTh,
  faThLarge,
  faThList,
  faThListRegular,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faTools,
  faTrash,
  faUnlink,
  faUmbrella,
  faUnderline,
  faUndoAltRegular,
  faUpload,
  faUser,
  faUserClock,
  faUserInjured,
  faUserLight,
  faUserTie,
  faUsers,
  faUserTag,
  faVideo,
  faWrench,
  faAngleDownLight,
  farFlag,
  farCircle,
  farClone,
  farEnvelope,
  farFile,
  farFileCode,
  farCheckCircle,
  farTimesCircle,
  farEdit,
  farWindowClose,
  farFileAlt,
  faPalette,
  faUserFriends,
  faFolderTree,
  faTruck,
  faFarm,
  faStoreAlt,
  faPaperPlane,
  faCubeLight,
  faPlug,
  faChartNetwork,
  faFileUpload
)
