import { ROOT_STORE_PATH } from './constants'
import { addNamespace } from '@/store/helpers'

// To be used only inside vuex modules
export const _mutations = {
  SET_ERROR: 'setError'
}

// To be used publicly outside vuex modules
export const MUTATIONS = addNamespace(ROOT_STORE_PATH, _mutations)
