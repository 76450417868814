import {_assignClaimsActions} from './action-types'
import {_assignClaimsMutations} from './mutation-types'
import getters from './getters'
import api from '@/modules/claims/services'
import {claimsApi} from '@/modules/claims/utils/api'
import {delay} from '@/modules/claims/utils/promise-delay'
import services from '@/api/services'

export default {
  // Get Pending Claims
  async [_assignClaimsActions.GET_PENDING_CLAIMS] ({state, commit}) {
    const filters = {...state.filters}
    if (filters.addressType && filters.addressType === 'loss') {
      filters['loss_city'] = filters['city']
      filters['loss_state'] = filters['state']
      filters['loss_zip'] = filters['zip']
      delete filters['addressType']
      delete filters['city']
      delete filters['state']
      delete filters['zip']
    }
    const filter = {
      has_adjuster: false,
      ...filters
    }
    const page = {
      number: state.pendingClaimsPagination.page,
      size: state.pendingClaimsPagination.pageSize
    }
    const include = 'primary_insured,loss_category,loss_location'
    const {data, meta} = await api.claim.list({filter, page, include})
    // Set state pending claims
    commit(_assignClaimsMutations.SET_PENDING_CLAIMS, data)
    // Set Pagination
    commit(_assignClaimsMutations.SET_PENDING_CLAIMS_TOTAL, meta.pagination.count)
    return data
  },

  // Submit All Pending Claims
  [_assignClaimsActions.SUBMIT_ALL_PENDING_CLAIMS] ({state, commit, dispatch}, adjusterId) {
    // Assign Pending Claims to Adjuster
    let pendingClaimsIds = []
    getters.selectedClaims(state).forEach(claim => {
      pendingClaimsIds.push(claim.id)
    })

    const config = {
      claim_ids: pendingClaimsIds
    }

    return claimsApi
      .post(`users/${adjusterId}/assign/claims/`, config)
      .then(response => {
        // Reload Adjusters and Pending Claims
        commit(_assignClaimsMutations.CLEAR_ADJUSTERS)
        commit(_assignClaimsMutations.CLEAR_CLAIMS)
        dispatch(_assignClaimsActions.GET_ADJUSTERS, state.activeTeam)
        dispatch(_assignClaimsActions.GET_PENDING_CLAIMS)

        // Return response
        return response['results']
      })
  },

  // Get Pending Claims Next Page
  [_assignClaimsActions.GET_PENDING_CLAIMS_NEXT_PAGE] ({commit, dispatch}, page) {
    commit(_assignClaimsMutations.SET_PENDING_CLAIMS_PAGE, page)
    return dispatch(_assignClaimsActions.GET_PENDING_CLAIMS)
  },

  // Filters
  [_assignClaimsActions.GET_CLAIM_STATUSES] ({commit}) {
    return claimsApi.get(`statuses/`).then(response => {
      commit(
        _assignClaimsMutations.SET_CLAIM_STATUSES,
        response['results']
      )

      return response['results']
    })
  },
  async [_assignClaimsActions.GET_LOBS] ({commit}) {
    const {data: products} = await services.claims.findAll('product', {
      page: {size: 100}
    })
    commit(
      _assignClaimsMutations.SET_LOBS,
      products
    )
    return products
  },
  [_assignClaimsActions.GET_COVERAGE_TYPES] ({commit}) {
    return Promise.resolve({
      results: [
        {id: '58730d85-4bc8-426e-95d2-30282b78f01d', name: 'Liability (mock)'},
        {id: '1c893fc6-26ea-4f6c-a7b5-929fd9be10e4', name: 'Collision (mock)'},
        {id: '8c65454d-597d-41cf-a4cc-0c7f0e6f6adf', name: 'Comprehensive (mock)'},
        {id: 'c3aa566a-60c4-4ed5-a037-24d1423db1cb', name: 'Medical (mock)'}
      ]
    })
      .then(delay(100))
      .then(response => {
        commit(
          _assignClaimsMutations.SET_COVERAGE_TYPES,
          response['results']
        )
        return response['results']
      })
  },

  [_assignClaimsActions.GET_LOSS_TYPES] ({commit}) {
    return Promise.resolve({
      results: [
        {
          id: '58730d85-4bc8-426e-95d2-30282b78f01d',
          name: 'Automobile Bodily Injury (mock)'
        },
        {id: '1c893fc6-26ea-4f6c-a7b5-929fd9be10e4', name: 'Collision (mock)'},
        {id: '8c65454d-597d-41cf-a4cc-0c7f0e6f6adf', name: 'Bodily Injury (mock)'},
        {
          id: 'c3aa566a-60c4-4ed5-a037-24d1423db1cb',
          name: 'Automobile Property Damage (mock)'
        }
      ]
    })
      .then(delay(100))
      .then(response => {
        commit(_assignClaimsMutations.SET_LOSS_TYPES, response['results'])
        return response['results']
      })
  },
  [_assignClaimsActions.UPDATE_FILTER] ({commit, dispatch}, {filter, value, removeFilter}) {
    if (removeFilter) {
      commit(_assignClaimsMutations.REMOVE_FILTER, removeFilter)
    } else {
      commit(_assignClaimsMutations.ADD_FILTER, {filter, value})
    }
    commit(_assignClaimsMutations.CLEAR_CLAIMS)
    return dispatch(_assignClaimsActions.GET_PENDING_CLAIMS)
  }
}
