const metaPresets = {
  default: {
    requiresAuth: true,
    layout: 'default'
  },
  onlyAllowUnauthenticatedAccess: {
    requiresAuth: false,
    onlyAllowUnauthenticatedAccess: true
  },
  ignoreAuthorization: {
    requiresAuth: true,
    ignoreAuthorization: true
  }
}

export default metaPresets
