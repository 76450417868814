<template>
  <layout-common
    v-if="!inLimbo"
    v-bind="props">
  </layout-common>
</template>

<script>
import LayoutCommon from '@/layouts/common.vue'

const validLayoutNames = [
  'default',
  'default--no-container-padding',
  'full-page',
  'full-page--no-container-padding',
  'component-only'
]

const defaultLayoutProps = () => ({
  noContainerPadding: false,
  isFullPage: false,
  isComponentOnly: false
})

export default {
  name: 'vue-extend-layout',

  data () {
    return {
      inLimbo: false,
      props: defaultLayoutProps()
    }
  },
  methods: {
    useDefaultLayout () {
      this.props = defaultLayoutProps()
    },
    useDefaultNoContainerPaddingLayout () {
      this.props = { ...defaultLayoutProps(), noContainerPadding: true }
    },
    useFullPageLayout () {
      this.props = { ...defaultLayoutProps(), isFullPage: true }
    },
    useFullPageNoContainerPaddingLayout () {
      this.props = { ...defaultLayoutProps(), isFullPage: true, noContainerPadding: true }
    },
    useComponentOnlyLayout () {
      this.props = { ...defaultLayoutProps(), isComponentOnly: true }
    }
  },
  watch: {
    '$route': {
      immediate: true,
      // eslint-disable-next-line complexity
      handler (route) {
        const newLayout = route.meta.layout

        if (newLayout && !validLayoutNames.includes(newLayout)) {
          throw Error(`"${newLayout}" is not a valid layout. Valid layouts are: ${validLayoutNames.join(', ')}`)
        }

        if (!newLayout && !this.$route.name) { // route is loading, don't render anything
          this.inLimbo = true
          return
        }

        this.inLimbo = false
        if (!newLayout || newLayout === 'default') this.useDefaultLayout()
        else if (newLayout === 'default--no-container-padding') this.useDefaultNoContainerPaddingLayout()
        else if (newLayout === 'full-page') this.useFullPageLayout()
        else if (newLayout === 'full-page--no-container-padding') this.useFullPageNoContainerPaddingLayout()
        else if (newLayout === 'component-only') this.useComponentOnlyLayout()
      }
    }
  },
  components: {
    [LayoutCommon.name]: LayoutCommon
  }
}
</script>
