import { RULES_ROOT_STORE_PATH } from './constants'
import { addNamespace } from '@/store/helpers'
import { ACTIONS as ComponentEditorActions } from '@/store/rules/componentEditorStore/action-types'
import { ACTIONS as ActiveProjectActions } from '@/store/rules/activeProjectStore/action-types'

// To be used only inside vuex modules
export const _actions = {
  INIT_RULES_MODULE: 'initRulesModule',
  LOAD_PROJECTS: 'loadProjects',
  LOAD_PACKAGES: 'loadPackages',
  RELOAD_PROJECTS: 'reloadProjects',
  RELOAD_PACKAGES: 'reloadPackages',
  LOAD_PACKAGE_CATALOG: 'loadPackageCatalog',
  RELOAD_PACKAGE_CATALOG: 'reloadPackageCatalog',
  SET_ACTIVE_PACKAGE: 'setActiveProject',
  LOAD_SUGGESTIONS: 'loadSuggestions'
}

// To be used publicly outside vuex modules
export const ACTIONS = {
  ...addNamespace(
    RULES_ROOT_STORE_PATH,
    _actions
  ),
  COMPONENT_EDITOR: ComponentEditorActions,
  ACTIVE_PROJECT: ActiveProjectActions
}
