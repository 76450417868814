import {Loading} from '@britecore/bc-design-system'
import _ from 'lodash'
import moment from 'moment'

/*
 Loader mixin which shows a full page loading spinner by default.
 To turn it off by default set data property `showLoader = false` in the component where this mixin will be used.
 Usage in other components:

 import {LoaderMixin} from '@/components/ui/mixins'

 export default {
  name: 'MyComponent',
  mixins: [LoaderMixin],
  methods: {
    doSomething () {
      this.showLoader = false
    }
  }
 }
*/

export let LoaderMixin = {
  data () {
    return {
      loader: null,
      showLoader: true
    }
  },
  created () {
    if (this.showLoader) {
      this.startLoader()
    }
  },
  beforeDestroy () {
    this.stopLoader()
  },
  methods: {
    startLoader () {
      if (this.showLoader && !this.loader) {
        this.loader = Loading.service({ lock: true })
      }
    },
    stopLoader () {
      if (this.loader) {
        this.loader.close()
        this.loader = null
      }
    }
  },
  watch: {
    showLoader: function (val, oldVal) {
      if (val) {
        this.startLoader()
      } else {
        this.stopLoader()
      }
    }
  }
}

/*
 ErrorsMixin which uses vee-validate ErrorsBag to populate errors.

 Usage in other components:

 import {ErrorsMixin} from '@/components/ui/mixins'

 export default {
  name: 'MyComponent',
  mixins: [ErrorsMixin],
  methods: {
    postToServer () {
      performMutation().then(response => {
        this.$addGraphQLErrors(response.errors)
      })
    }
  }
 }
*/

export let ErrorsMixin = {
  $_veeValidate: {
    validator: 'new'
  },
  props: {
    formWideErrorName: {
      type: String,
      required: false,
      default: '__all__'
    },
    notificationMessage: {
      type: String,
      required: false,
      default: 'We’re sorry, but it looks like there is a problem with the information you entered.'
    }
  },
  methods: {
    /**
     * Adds GraphQL errors into ErrorsBag.
     * @param errors - Array of objects in the following format:
     * [
     *   {
     *     'field': 'fieldName',
     *     'messages': [
     *       {
     *         'message': 'This field is required.',
     *         'code': 'required'
     *       }
     *     ]
     *   }
     * ]
     * @param {boolean} resetErrors - Whether to reset existing errors or not.
     * @returns {boolean} - Whether there were any errors or not
     */
    $addGraphQLErrors (errors, resetErrors = true) {
      let hasErrors = false
      if (errors && errors.length) {
        hasErrors = true
        let hasFormWideErrors = false
        if (resetErrors) {
          this.$resetErrors()
        }
        for (let error of errors) {
          for (let fieldError of error.messages) {
            fieldError.toString = () => {
              return fieldError.message
            }
            this.$errors.add({
              field: error.field,
              msg: fieldError.message
            })
          }
          if (error.field === this.formWideErrorName) {
            hasFormWideErrors = true
          }
        }
        if (!hasFormWideErrors) {
          this.$displayErrorNotification()
        }
      }
      return hasErrors
    },
    /**
     * Adds REST API errors into ErrorsBag.
     * @param errors - Object of following format:
     * {
     *    'name': [
     *      {
     *        'message': 'This field is required.',
     *        'code': 'required'
     *      }
     *    ],
     *    'password': [
     *      {
     *        'message': 'This field is required.',
     *        'code': 'required'
     *      }
     *    ]
     *  }
     * @param {boolean} resetErrors - Whether to reset existing errors or not.
     * @returns {boolean} - Whether there were any errors or not
     */
    $addAPIErrors (errors, resetErrors = true) {
      let vm = this
      let hasErrors = false
      if (errors) {
        hasErrors = true
        let hasFormWideErrors = false
        if (resetErrors) {
          this.$resetErrors()
        }
        // Display the errors as validation messages
        _.forOwn(errors, function (fieldErrors, fieldName) {
          if (fieldErrors.constructor === Array) {
            // Check if any of the `fieldErrors` objects have `message`
            // property inside them. If not then we have got nested array errors
            let _fieldErrors = _.filter(fieldErrors, function (o) {
              return _.has(o, 'message')
            })
            if (_fieldErrors.length) {
              for (let fieldError of fieldErrors) {
                fieldError.toString = () => {
                  return fieldError.message
                }
                vm.$errors.add({
                  field: fieldName,
                  msg: fieldError.message
                })
              }
            } else {
              vm.$errors.add({
                field: fieldName,
                msg: fieldErrors.message
              })
            }
          } else {
            _.forOwn(fieldErrors, function (fieldNestedErrors, fieldNestedName) {
              for (let fieldNestedError of fieldNestedErrors) {
                fieldNestedError.toString = () => {
                  return fieldNestedError.message
                }
                vm.$errors.add({
                  field: fieldName,
                  msg: fieldNestedError.message
                })
              }
            })
          }
          if (fieldName === vm.formWideErrorName) {
            hasFormWideErrors = true
          }
        })
        if (!hasFormWideErrors) {
          vm.$displayErrorNotification()
        }
      }
      return hasErrors
    },
    /**
     * Adds JSON API errors into ErrorsBag.
     * @param errors - Array of following format:
     * [
     *     {
     *        code: "unique",
     *        detail: "user with this username already exists."
     *        source: {pointer: "/data/attributes/username"}
     *        status: "400"
     *     },
     *     {
     *        code: "invalid",
     *        detail: "Enter a valid email address."
     *        source: {pointer: "/data/attributes/email"}
     *        status: "400"
     *     },
     *     {
     *        code: "blank",
     *        detail: "This field may not be blank."
     *        source: {pointer: "/data/attributes/name"}
     *        status: "400"
     *     }
     * ]
     * @param {boolean} resetErrors - Whether to reset existing errors or not.
     * @returns {boolean} - Whether there were any errors or not
     */
    $addJsonAPIErrors (errors, resetErrors = true) {
      let hasErrors = false
      if (errors && errors.length) {
        hasErrors = true
        let hasFormWideErrors = false
        if (resetErrors) {
          this.$resetErrors()
        }
        for (let error of errors) {
          error.toString = () => {
            return error.detail
          }
          if (error.source) {
            if (error.source.pointer) {
              this.$errors.add({
                field: error.source.pointer.replace('/data/attributes/', ''),
                msg: _.upperFirst(error.detail)
              })
              if (error.source.pointer === '/data') {
                hasFormWideErrors = true
              }
            } else {
              // In else case, as per JSON:API spec, source can contain `parameter`.
              // `parameter` is string indicating which URI query parameter caused the error.
              // https://jsonapi.org/format/#errors
              // TODO: Add errors to error bag when parameter is being returned. Currently not in use.
            }
          } else {
            hasFormWideErrors = true
          }
        }
        if (hasFormWideErrors) {
          this.$displayErrorNotification()
        }
      }
      return hasErrors
    },
    /**
     * Empties the ErrorsBag
     */
    $resetErrors () {
      this.$errors.clear()
    },
    /**
     * Display general error notification.
     */
    $displayErrorNotification () {
      this.$message.error(this.notificationMessage)
    }
  },
  watch: {
    '$errors.items' () {
      let error = this.$errors.first(this.formWideErrorName)
      if (error) {
        this.$message.error(error)
      }
    }
  }
}

export let FilterComponentMixin = {
  props: {
    selectedCriteria: Object,
    filter: Object
  },
  methods: {
    $getCriteria (name) {
      return _.get(this.selectedCriteria, name, undefined)
    },
    $inputChanged (value) {
      this.$emit('input', { value: value, closeOnChange: this.$closeOnChange() })
    },
    $closeOnChange () {
      return _.get(this.filter, 'closeOnChange', true)
    }
  }
}

export let FilterableMixin = {
  data: function () {
    return {
      selectedFilterCriteria: {}
    }
  },
  computed: {
    filterable: function () {
      return {
        bindings: {
          badges: this.$_filterable_badges,
          availableFilterCriteria: this.availableFilterCriteria,
          selectedFilterCriteria: this.selectedFilterCriteria
        },
        closeOnChange: true,
        listeners: {
          'selected-filters-updated': this.$_filterable_updateSelectedFilterCriteria,
          'filter-badge-closed': (badge) => this.$_filterable_removeFilter(badge.metaData)
        }
      }
    },
    hasEnabledFilters: function () {
      return this.$_filterable_activeFilterNames.length >= 1
    },
    $_filterable_updaters: function () {
      return {
        select: this.$_filterable_updateSelectFilter,
        radio: this.$_filterable_updateRadioFilter,
        form: this.$_filterable_updateFormFilter
      }
    },
    $_filterable_badgeGetters: function () {
      return {
        select: this.$_filterable_getSelectionBadges,
        radio: this.$_filterable_getRadioBadge,
        form: this.$_filterable_getFormBadges
      }
    },
    $_filterable_activeFilterNames: function () {
      let activeFilters = _.filter(
        Object.entries(this.selectedFilterCriteria), ([filterName, filterValue]) => {
          return !_.isEmpty(filterValue)
        }
      )
      return _.map(activeFilters, _.head)
    },
    $_filterable_filtersByName: function () {
      let filtersByName = {}
      for (let availableFilter of this.availableFilterCriteria) {
        filtersByName[availableFilter.name] = availableFilter
      }
      return filtersByName
    },
    $_filterable_badges: function () {
      let badges = []
      for (let filterName of this.$_filterable_activeFilterNames) {
        let filter = this.$_filterable_filtersByName[filterName]
        let selection = this.selectedFilterCriteria[filterName]
        let badgeGetter = this.$_filterable_badgeGetters[filter.type]
        badges = badges.concat(badgeGetter(filter, selection))
      }
      return badges
    },
    $_filterable_getFormFilterBadge: function () {
      return {
        input: this.$_filterable_getFormInputFilterBadge,
        dropdown: this.$_filterable_getFormDropdownFilterBadge,
        datepicker: this.$_filterable_getFormDatePickerFilterBadge
      }
    }
  },
  methods: {
    $_filterable_getFilterOptionByName: function (filter, optionName) {
      let filterOptions = filter.options
      return _.find(filterOptions, ['name', optionName])
    },
    $_filterable_getSelectFilterBadge: function (filter, selection) {
      let option = this.$_filterable_getFilterOptionByName(filter, selection)
      return {
        type: 'filter',
        label: `${filter.label}: ${option.label}`,
        metaData: { filter: filter, value: { value: option }, selection: selection }
      }
    },
    $_filterable_getRadioFilterBadge: function (filter, selection) {
      let option = this.$_filterable_getFilterOptionByName(filter, selection)
      return {
        type: 'filter',
        label: `${filter.label}: ${option.label}`,
        metaData: { filter: filter, value: option, selection: selection }
      }
    },
    $_filterable_getFormInputFilterBadge: function (filter, field, value) {
      return {
        type: 'filter',
        label: `${field.label}: ${value}`,
        metaData: { filter: filter, value: { field, value } }
      }
    },
    $_filterable_getFormDropdownFilterBadge: function (filter, field, value) {
      let option = this.$_filterable_getFilterOptionByName(field, value)
      return {
        type: 'filter',
        label: `${field.label}: ${option.label}`,
        metaData: { filter: filter, value: { field, value } }
      }
    },
    $_filterable_getFormDatePickerFilterBadge: function (filter, field, value) {
      let formatters = {
        daterange: value => `${moment(value.minDate).format('MM/DD/YYYY')}-${moment(value.maxDate).format('MM/DD/YYYY')}`
      }
      let badgeValue = ''
      if (field.valueFormatter) {
        badgeValue = field.valueFormatter(value)
      } else if (formatters[field.pickerType]) {
        badgeValue = formatters[field.pickerType](value)
      } else {
        badgeValue = JSON.stringify(value)
      }
      return {
        type: 'filter',
        label: `${field.label}: ${badgeValue}`,
        metaData: { filter: filter, value: { field, value } }
      }
    },
    $_filterable_getSelectionBadges: function (filter, selections) {
      let badges = []
      for (let selection of selections) {
        badges.push(this.$_filterable_getSelectFilterBadge(filter, selection))
      }
      return badges
    },
    $_filterable_getFormBadges: function (filter) {
      let badges = []
      for (let field of filter.fields) {
        if (field.type === 'group') {
          for (let inner of field.fields) {
            if (this.selectedFilterCriteria[filter.name][inner.name] && !field.hideBadge) {
              badges.push(
                this.$_filterable_getFormFilterBadge[inner.type](
                  filter, inner, this.selectedFilterCriteria[filter.name][inner.name]
                )
              )
            }
          }
        }
        if (this.selectedFilterCriteria[filter.name][field.name] && !field.hideBadge) {
          badges.push(this.$_filterable_getFormFilterBadge[field.type](
            filter, field, this.selectedFilterCriteria[filter.name][field.name])
          )
        }
      }
      return badges
    },
    $_filterable_getRadioBadge: function (filter, selection) {
      return this.$_filterable_getRadioFilterBadge(filter, selection)
    },
    $_filterable_updateSelectFilter: function (filter, { value, selected }, isRemoved) {
      // Set an empty list if this key is not set and return the selection or default.
      if (_.isUndefined(this.selectedFilterCriteria[filter.name])) {
        this.$set(this.selectedFilterCriteria, filter.name, [])
      }
      let currentSelections = this.selectedFilterCriteria[filter.name]
      if (isRemoved || !selected) {
        // Remove the option -- splice is used for Vue reactivity
        currentSelections.splice(currentSelections.indexOf(value.name), 1)
      } else {
        currentSelections.push(value.name)
      }
    },
    $_filterable_updateRadioFilter: function (filter, value, isRemoved) {
      if (_.isUndefined(this.selectedFilterCriteria[filter.name])) {
        this.$set(this.selectedFilterCriteria, filter.name, '')
      }
      if (isRemoved) {
        this.selectedFilterCriteria[filter.name] = null
      } else {
        this.selectedFilterCriteria[filter.name] = value.name
      }
    },
    $_filterable_updateFormFilter: function (filter, { field, value }, isRemoved) {
      if (_.isUndefined(this.selectedFilterCriteria[filter.name])) {
        this.$set(this.selectedFilterCriteria, filter.name, {})
      }
      if (_.isUndefined(this.selectedFilterCriteria[filter.name][field.name])) {
        this.$set(this.selectedFilterCriteria[filter.name], field.name, {})
      }
      if (isRemoved) {
        this.selectedFilterCriteria[filter.name][field.name] = null
      } else {
        this.selectedFilterCriteria[filter.name][field.name] = value
      }
    },
    $_filterable_updateSelectedFilterCriteria: function ({ filter, value }) {
      this.$_filterable_updaters[filter.type](filter, value, false)
    },
    $_filterable_removeFilter: function ({ filter, value }) {
      this.$_filterable_updaters[filter.type](filter, value, true)
    },
    $_filterable_getValidators: function () {
      const hasNotBeenSeen = function (attributePath) {
        let knownValues = []
        return (filter) => {
          let value = _.get(filter, attributePath)
          let hasNotBeenSeen = false
          if (!_.includes(knownValues, value)) {
            hasNotBeenSeen = true
          }
          knownValues.push(value)
          return hasNotBeenSeen
        }
      }

      return {
        filterHasStringName: (filter) => _.isString(filter.name),
        filterNameNotYetSeen: hasNotBeenSeen('name'),
        filterHasStringLabel: (filter) => _.isString(filter.label),
        filterLabelNotYetSeen: hasNotBeenSeen('label'),
        filterHasValidType: (filter) => _.includes(Object.keys(this.$_filterable_updaters), filter.type),
        filterHasOptionsArray: (filter) => _.isArray(filter.options),
        filterOptionsHaveStringNames: (filter) => _.every(_.map(_.map(filter.options, 'name'), _.isString)),
        filterOptionsHaveStringLabels: (filter) => _.every(_.map(_.map(filter.options, 'label'), _.isString)),
        filterOptionsHaveUniqueNames: (filter) => _.every(_.countBy(filter.options, 'name'), count => count === 1),
        filterOptionsHaveUniqeLabels: (filter) => _.every(_.countBy(filter.options, 'label'), count => count === 1)
      }
    },
    $_filterable_validateAvailableFilterCriteria: function () {
      let validityChecks = []
      for (let [index, filter] of Object.entries(this.availableFilterCriteria)) {
        for (let [check, condition] of Object.entries(this.$_filterable_getValidators())) {
          let isValid = false

          try {
            isValid = condition(filter)
          } catch (error) {
            console.error('Error while validating available filter criteria', error)
          }

          validityChecks.push({
            filterIndex: index,
            check: check,
            isValid: isValid
          })
        }
      }

      const isValid = _.every(validityChecks, 'isValid')
      if (!isValid) {
        const failedChecks = _.filter(validityChecks, ['isValid', false])
        console.error('Filter criteria definition is invalid', failedChecks)
      }

      return validityChecks
    }
  },
  watch: {
    availableFilterCriteria: function () {
      this.$_filterable_validateAvailableFilterCriteria()
    }
  }
}
