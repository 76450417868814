const ACCESS_MODULES = [
  {name: 'briterules', path: '/rules'},
  {name: 'britelines', path: '/lines'},
  {name: 'britedocs', path: '/documents'},
  {name: 'britenotes', path: '/notes'},
  {name: 'britepolicies', path: '/policies'},
  {name: 'briteauth', path: '/users'},
  {name: 'briteclaims', path: '/claims'},
  {name: 'briteaccess', path: '/access'},
  {name: 'britequote', path: '/quote/create'}
]

const ACCESS_MODULES_NAMES = ACCESS_MODULES.map(module => module.name)

const ACCESS_MODULES_ACCESS_ATTEMPTS = ACCESS_MODULES_NAMES.map(
  name => ({resource: `bc.${name}.britecoreui`, action: 'ViewModule'})
)

export {
  ACCESS_MODULES,
  ACCESS_MODULES_NAMES,
  ACCESS_MODULES_ACCESS_ATTEMPTS
}
