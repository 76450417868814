export const state = {
  policyPageReadyToShow: false,
  revisionId: '',
  revisions: [],
  policyTransactions: [],
  policyTerms: [],
  policyNumber: '',
  policyType: '',
  policyGroupNumber: '',
  linkedPolicies: {
    loading: false,
    policies: [],
    groupId: null,
    groupBillingNumber: null,
    searchResults: {
      loading: false,
      activePage: 1,
      records: []
    }
  },
  riskTabs: [],
  riskData: {},
  billingInfo: null,
  openEndorsement: null,
  openRenewal: null,
  screenFlowInfoError: null,
  cancellationInfo: null,
  inProgressOOSTransaction: null,
  inProgressTransactions: null,
  failedRenewalTransactionsCount: null,
  failedNonDismissedTransactions: []
}
