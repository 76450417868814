import {JsonApiService} from './api'

class PaymentApi extends JsonApiService {
  constructor () {
    super('payment')
  }
}

class PaymentPreProcessStatusApi extends JsonApiService {
  constructor () {
    super('payment-pre-process-status')
  }
}

class PaymentTypeApi extends JsonApiService {
  constructor () {
    super('payment-type')
  }
}

class PayCodeApi extends JsonApiService {
  constructor () {
    super('pay-code')
  }
}

class PaymentMethodApi extends JsonApiService {
  constructor () {
    super('payment-method')
  }
}

class PaymentBatchApi extends JsonApiService {
  constructor () {
    super('payment-batch')
  }
}

export {
  PaymentApi,
  PaymentPreProcessStatusApi,
  PaymentTypeApi,
  PayCodeApi,
  PaymentMethodApi,
  PaymentBatchApi
}
