import _ from 'lodash'
import {
  APPROVED,
  QUOTE_TRANSACTION_TYPE_ENDORSEMENT,
  QUOTE_TRANSACTION_TYPE_RENEWAL,
  REVIEW_PANEL_QUOTE_FLOW_COMPONENT_TYPE,
  RISK_EDIT_QUOTE_FLOW_COMPONENT_TYPE
} from '@/pages/quote/constants'
import { makeStepName, canNavigateTo } from '@/api/quote/navigation.js'

export const getters = {
  quotes (state) {
    return state.quotes
  },
  activePageGroup (state) {
    return state.activePageGroup
  },
  activePage (state) {
    return state.activePage
  },
  activePageGroupIndex (state, getters) {
    const pageGroups = _.get(state.quoteFlow, 'pageGroups', [])
    const index = pageGroups.findIndex(pg => pg.name === getters.activePageGroup.name)
    return index < 0 ? 0 : index
  },
  activePageIndex (state, getters) {
    const pages = _.get(getters.activePageGroup, 'pages', [])
    const index = pages.findIndex(pg => pg.name === getters.activePage.name)
    return index < 0 ? 0 : index
  },
  paginationButtonsDirection (state) {
    return _.get(state.quoteFlow, 'pagination.buttonsDirection')
  },
  firstPageGroup (state) {
    return _.get(state.quoteFlow, 'pageGroups[0]', {})
  },
  firstPage (state, getters) {
    return _.get(getters.activePageGroup, 'pages[0]', {})
  },
  isFirstOverallPage (state, getters) {
    return getters.activePage === _.get(getters.firstPageGroup, 'pages[0]', {})
  },
  nextPageGroup (state, getters) {
    const pageGroups = _.get(state.quoteFlow, 'pageGroups')
    if (_.isUndefined(pageGroups)) {
      return {}
    }
    return pageGroups[getters.activePageGroupIndex + 1]
  },
  nextPage (state, getters) {
    const pages = _.get(getters.activePageGroup, 'pages')
    if (_.isUndefined(pages)) {
      return {}
    }
    return pages[getters.activePageIndex + 1]
  },
  hasNextStep (state, getters) {
    return getters.hasNextPageGroup || getters.hasMorePagesInActivePageGroup
  },
  hasNextPageGroup (state, getters) {
    const pageGroups = _.get(state.quoteFlow, 'pageGroups', [])
    return getters.activePageGroupIndex + 1 < pageGroups.length
  },
  hasMorePagesInActivePageGroup (state, getters) {
    const pages = _.get(getters.activePageGroup, 'pages', [])
    return getters.activePageIndex + 1 < pages.length
  },
  getPageGroups (state) {
    return _.get(state.quoteFlow, 'pageGroups', [])
  },
  getPageGroupByName: (state, { getPageGroups }) => (name) => {
    return getPageGroups.find(pg => pg.name === name)
  },
  getPageByName: (state) => (name) => {
    return _.get(state.activePageGroup, 'pages', []).find(page => page.name === name)
  },
  /**
   * Get the first page group in the Quote Flow whose page contains component with type that
   * matches with the given component type.
   * @param {String} componentType
   * @returns {Object} Page group of the Quote Flow.
   */
  getPageGroupByComponentType: (state, { getPageGroups }) => (componentType) => {
    return _.find(getPageGroups, { pages: [{ components: [{ type: componentType }] }] })
  },
  /**
   * Get the first page in the Quote Flow which contains component with type that
   * matches with the given component type.
   * @param {String} componentType
   * @returns {Object} Page of the Quote Flow.
   */
  getPageByComponentType: (state, { getPageGroupByComponentType }) => (componentType) => {
    const pages = _.get(getPageGroupByComponentType(componentType), 'pages', [])
    return _.find(pages, { components: [{ type: componentType }] })
  },
  /**
   * Get the first component in the Quote Flow which contains component with type that
   * matches with the given component type.
   * @param {String} componentType
   * @returns {Object} Component of the Quote Flow.
   */
  getComponentByType: (state, { getPageByComponentType }) => (componentType) => {
    const page = getPageByComponentType(componentType)
    if (!page) {
      return
    }
    return _.find(page.components, { type: componentType })
  },
  /**
   * Get the list of steps on a Quote Flow.
   * @returns {Array<Object>} All pairs of PageGroupName/PageName on a Quote Flow.
   * Example: [
   *   {pageGroupName: 'setup', pageName: 'underWritingQuestions'},
   *   {pageGroupName: 'setup', pageName: 'policySetup'},
   *   {pageGroupName: 'drivers', pageName: 'setup'}
   * ]
   */
  stepsList (state, { getPageGroups }) {
    return _.flattenDeep(
      getPageGroups.map(pageGroup => {
        return pageGroup.pages.map(page => {
          return {
            pageGroupName: pageGroup.name,
            pageName: page.name
          }
        })
      })
    )
  },
  /**
   * Get the previous step in the Quote Flow.
   * @returns {Object} A pair of pageGroupName/PageName.
   * Example: {pageGroupName: 'drivers', pageName: 'setup'}
   */
  previousStep (state, getters) {
    const stepIndex = getters.stepsList.findIndex(step => {
      return (
        step.pageGroupName === getters.activePageGroup.name &&
        step.pageName === getters.activePage.name
      )
    })
    if (stepIndex < 1) {
      // Return the currently active step if previous one is not found
      return {
        pageGroupName: getters.activePageGroup.name,
        pageName: getters.activePage.name
      }
    }
    return getters.stepsList[stepIndex - 1]
  },
  nextStep (state, getters) {
    const currentStepIndex = getters.stepsList.findIndex(step => {
      return (
        step.pageGroupName === getters.activePageGroup.name &&
        step.pageName === getters.activePage.name
      )
    })
    if (currentStepIndex === getters.stepsList.length - 1) {
      // Return the currently active step if next one is not found
      return {
        pageGroupName: getters.activePageGroup.name,
        pageName: getters.activePage.name
      }
    }
    return getters.stepsList[currentStepIndex + 1]
  },
  riskQuotes (state) {
    return _.get(state.rootRiskQuote, 'risk_quotes', [])
  },
  findRiskQuoteInStateById: (state, getters) => (riskQuote, id) => {
    if (riskQuote.id === id) {
      return riskQuote
    }
    if (riskQuote.risk_quotes === []) {
      return
    }
    for (const rq of riskQuote.risk_quotes) {
      const parentRiskQuote = getters.findRiskQuoteInStateById(rq, id)
      if (!_.isUndefined(parentRiskQuote)) {
        return parentRiskQuote
      }
    }
  },
  findRiskQuoteParentInState: (state, getters) => (parentRiskQuote, id) => {
    const riskQuote = parentRiskQuote.risk_quotes.find(rq => rq.id === id)
    if (riskQuote) {
      return parentRiskQuote
    }
    if (parentRiskQuote.risk_quotes === []) {
      return
    }
    for (const rq of parentRiskQuote.risk_quotes) {
      const parent = getters.findRiskQuoteParentInState(rq, id)
      if (parent) {
        return parent
      }
    }
  },
  isRiskQuoteLocked: (state) => (riskQuote) => {
    return _.get(riskQuote, 'meta.locked', false)
  },
  getRiskTypeState: (state, { getRiskTypeStateCacheKey }) => (riskTypeName) => {
    const quote = state.quote
    const productName = quote.product_name
    const productVersion = quote.product_version
    const riskTypeStateCacheKey = getRiskTypeStateCacheKey(productName, productVersion)

    return _.get(state.riskTypeStates, `${riskTypeStateCacheKey}.${riskTypeName}`)
  },
  getRiskTypeStateCacheKey: () => (productName, productVersion) => {
    return `${productName}_${productVersion.replace(/-/g, '')}`
  },
  quoteNumber (state) {
    return state.quote.quote_number
  },
  quoteTotalPremium (state) {
    return state.quote.premium.term
  },
  quoteTotalProRataPremium (state) {
    return state.quote.premium.pro_rata
  },
  firstPageOf: (state, getters) => (pageGroupName) => {
    const pageGroup = getters.getPageGroupByName(pageGroupName)
    return _.get(pageGroup, 'pages[0]', {})
  },
  getQuoteFlowResourceByName: (state, getters) => (quoteFlowNode, name) => {
    if (quoteFlowNode.name === name) {
      return quoteFlowNode
    }
    for (const node of Object.values(quoteFlowNode)) {
      if (node instanceof Object) {
        const found = getters.getQuoteFlowResourceByName(node, name)
        if (found) {
          return found
        }
      } else if (node instanceof Array) {
        for (const elem of node) {
          const found = getters.getQuoteFlowResourceByName(elem, name)
          if (found) {
            return found
          }
        }
      }
    }
  },
  /**
   * Get an object containing query parameters that should be passed on while navigating.
   */
  sharedQueryParams (state) {
    const query = {}
    if (state.rulesVersion !== null) {
      query.rules_version = state.rulesVersion
    }
    return query
  },
  policyCoverages (state, getters) {
    const riskTypeName = _.get(state.rootRiskQuote, 'risk_state.type.name')
    const riskTypeState = getters.getRiskTypeState(riskTypeName)
    const items = _.get(state.rootRiskQuote, 'risk_state.items', {})
    const itemDefinitions = _.keyBy(_.get(riskTypeState, 'items', []), 'name')

    return _.map(items, (item, name) => {
      const limit = _.sum(_.values(_.get(item, 'limits', {})))
      const deductible = _.get(item, 'deductible', 0)
      const label = _.get(itemDefinitions, [name, 'label'], name)
      return { label, limit, deductible }
    })
  },
  isEndorsement (state) {
    return state.quote.transaction_type === QUOTE_TRANSACTION_TYPE_ENDORSEMENT
  },
  isRenewal (state) {
    return state.quote.transaction_type === QUOTE_TRANSACTION_TYPE_RENEWAL
  },
  /**
   * Get a list of global and page-specific headerToolbar components.
   * @returns Array
   */
  headerToolbarComponents (state, { activePage }) {
    const globalHeaderToolbar = _.get(state.quoteFlow, 'headerToolbar.components', [])
    const pageHeaderToolbar = _.get(activePage, 'headerToolbar.components', [])
    return _.concat(globalHeaderToolbar, pageHeaderToolbar)
  },
  /**
   * Get footerToolbar QuoteFlow configuration.
   * Injects `continueButton` and `backButton` components if they are not present in the footer components.
   * @return {Object} The QuoteFlow configuration for the footer toolbar.
   */
  footerToolbar (state) {
    const toolbar = _.get(state.quoteFlow, 'footerToolbar')
    const firstRow = toolbar.rows[0]
    const columns = _.flatMap(toolbar.rows, 'columns')

    const hasContinueButton = _.find(columns, ['component.type', 'continueButton'])
    if (!hasContinueButton) {
      const defaultContinueButton = {
        align: 'left',
        name: 'defaultContinueButton',
        component: { type: 'continueButton' }
      }
      // Insert continue button as first column
      firstRow.columns.splice(0, 0, defaultContinueButton)
    }

    const hasBackButton = _.find(columns, ['component.type', 'backButton'])
    if (!hasBackButton) {
      const defaultBackButton = {
        align: 'left',
        name: 'defaultBackButton',
        component: { type: 'backButton' }
      }
      // Insert back button as second column
      firstRow.columns.splice(1, 0, defaultBackButton)
    }

    return toolbar
  },
  /**
   * Get sidebar QuoteFlow configuration.
   * @return {Object} The QuoteFlow configuration for the sidebar.
   */
  sidebar (state) {
    return _.get(state.quoteFlow, 'sidebar')
  },
  reviewPageName (state, getters) {
    const reviewPageGroup = getters.getPageGroupByComponentType(REVIEW_PANEL_QUOTE_FLOW_COMPONENT_TYPE)
    const reviewPage = getters.getPageByComponentType(REVIEW_PANEL_QUOTE_FLOW_COMPONENT_TYPE)
    if (!reviewPageGroup || !reviewPage) {
      return
    }

    return makeStepName(reviewPageGroup.name, reviewPage.name)
  },
  currentPageIsReviewPage (state, getters) {
    const currentPageName = makeStepName(getters.activePageGroup.name, getters.activePage.name)

    return currentPageName === getters.reviewPageName
  },
  nextPageIsReviewPage (state, getters) {
    const nextStep = getters.nextStep
    const nextPageName = makeStepName(nextStep.pageGroupName, nextStep.pageName)

    return nextPageName === getters.reviewPageName
  },
  getReviewStep (state, getters) {
    let reviewStep
    const reviewPageGroup = getters.getPageGroupByComponentType(REVIEW_PANEL_QUOTE_FLOW_COMPONENT_TYPE)
    const reviewPage = getters.getPageByComponentType(REVIEW_PANEL_QUOTE_FLOW_COMPONENT_TYPE)

    if (reviewPageGroup && reviewPage) {
      reviewStep = {
        pageGroupName: reviewPageGroup.name,
        pageName: reviewPage.name
      }
    }

    return reviewStep
  },
  isStepAfterReviewStep: (state, { getReviewStep, stepsList }) => (pageGroupName, pageName) => {
    if (!getReviewStep) {
      return false
    }

    const currentStepIndex = stepsList.findIndex(step => {
      return (
        step.pageGroupName === pageGroupName &&
        step.pageName === pageName
      )
    })
    const reviewStepIndex = stepsList.findIndex(step => {
      return (
        step.pageGroupName === getReviewStep.pageGroupName &&
        step.pageName === getReviewStep.pageName
      )
    })

    return reviewStepIndex < currentStepIndex
  },
  /**
   * Return whether an user is allowed to access a given step.
   *
   * Returns `false` when quote status is not approved and the user is trying to access a step that
   * is after the STP review step. This is to prevent users accessing steps by typing step URL directly.
   *
   * @param {string} pageGroupName
   * @param {string} pageName
   * @returns {boolean}
   */
  canAccessStep: (state, { quoteNumber, isStepAfterReviewStep }) => (pageGroupName, pageName) => {
    if (!canNavigateTo(quoteNumber, pageGroupName, pageName)) {
      return false
    }

    if (state.quote.status !== APPROVED && isStepAfterReviewStep(pageGroupName, pageName)) {
      return false
    }

    return true
  },
  isQuoteLocked (state) {
    return _.get(state.quote, 'meta.locked', false)
  },
  isQuoteRated (state) {
    return !!state.quote.last_rated_at
  },
  isQuoteBound (state) {
    return state.quote.is_bound
  },
  /**
   * Convenience getter to know if a request is being processed.
   * @return {boolean}
   */
  isProcessingRequest (state) {
    return state.quoteState.isProcessingRequest
  },
  unifiedRiskState (state) {
    function getUnifiedRiskStateNode (riskQuote) {
      return { ...riskQuote.risk_state, children: _.map(riskQuote.risk_quotes, getUnifiedRiskStateNode) }
    }
    return getUnifiedRiskStateNode(state.rootRiskQuote)
  },
  getRootRiskFieldAnswer: (state) => (fieldName) => {
    return _.get(state.rootRiskQuote.risk_state.field_answers, fieldName, undefined)
  },
  quoteEffectiveDate (state) {
    return state.quote.policy_state.term.effective_date
  },
  getPolicyRiskEditConfig: (state, { getPageGroups }) => {
    const componentConfig = { type: RISK_EDIT_QUOTE_FLOW_COMPONENT_TYPE, riskType: 'policy' }
    const pageGroup = _.find(getPageGroups, { pages: [{ components: [componentConfig] }] })
    const pages = pageGroup ? pageGroup.pages : []
    const page = _.find(pages, { components: [componentConfig] })
    if (!page) {
      return
    }
    return _.find(page.components, componentConfig)
  },
  quoteIsBinding (state) {
    return state.quoteIsBinding
  },
  isQuoteBindingDebounced: (state) => (requestTime) => {
    const DELAY_PERIOD = 3000
    return (state.quoteIsBinding && (state.quoteLastBindRequest + DELAY_PERIOD < requestTime))
  },
  hasPendingContactsSync (state) {
    return state.quote.has_pending_contacts_sync
  }
}
