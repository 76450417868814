import {JsonApiService} from '@/modules/claims/services/api'

class DeductibleOverrideReasonApi extends JsonApiService {
  constructor () {
    super('deductible-override-reason')
  }
}

class ExposureApi extends JsonApiService {
  constructor () {
    super('exposure')
  }
}

class ExposureStatusApi extends JsonApiService {
  constructor () {
    super('exposure-status')
  }
}

class ExposureStatusChangeReasonApi extends JsonApiService {
  constructor () {
    super('exposure-status-change-reason')
  }
}

export {
  DeductibleOverrideReasonApi,
  ExposureApi,
  ExposureStatusApi,
  ExposureStatusChangeReasonApi
}
