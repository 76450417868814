import _ from 'lodash'
import moment from 'moment'
import CognitoIdentityServiceProvider from 'aws-sdk/clients/cognitoidentityserviceprovider'
import AuthService from '@/api/auth'
import UserManagementService from '@/api/userManagement'

export const actions = {
  async getPasswordPolicy ({ state, commit }) {
    if (state.passwordPolicy && state.passwordPolicyExpiration && moment() < state.passwordPolicyExpiration) {
      // Read from cache if it's still valid
      return state.passwordPolicy
    } else {
      const policy = await AuthService.getPasswordPolicy()
      commit('updatePasswordPolicy', policy)
      commit('updatePasswordPolicyExpiration', moment().add(1, 'minutes'))
      return policy
    }
  },
  async refreshUserPoolSettings ({ dispatch, commit }) {
    await dispatch('authentication/refreshAWSCredentials', null, { root: true })

    let cisp = new CognitoIdentityServiceProvider()
    let currentUserPoolData = await cisp.describeUserPool({ UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID }).promise()
    let userPoolSettings = currentUserPoolData.UserPool

    if (_.has(userPoolSettings, 'Policies.PasswordPolicy.TemporaryPasswordValidityDays')) {
      userPoolSettings = _.omit(userPoolSettings, 'AdminCreateUserConfig.UnusedAccountValidityDays')
    }

    commit('userPoolSettings', userPoolSettings)
  },

  async updateUserPoolSettings ({ dispatch, commit, state }, newSettings) {
    await dispatch('authentication/refreshAWSCredentials', null, { root: true })

    if (_.isEmpty(state.userPoolSettings)) {
      await dispatch('refreshUserPoolSettings')
    }

    const cisp = new CognitoIdentityServiceProvider()
    const currentState = state.userPoolSettings

    const settingsToSend = _.pick(currentState, [
      'LambdaConfig',
      'AutoVerifiedAttributes',
      'SmsVerificationMessage',
      'EmailVerificationMessage',
      'EmailVerificationSubject',
      'VerificationMessageTemplate',
      'SmsAuthenticationMessage',
      'MfaConfiguration',
      'DeviceConfiguration',
      'EmailConfiguration',
      'SmsConfiguration',
      'UserPoolTags',
      'Policies',
      'AdminCreateUserConfig'
    ])

    const mergedSettings = _.merge(settingsToSend, newSettings)
    return cisp.updateUserPool({
      ...mergedSettings,
      UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID
    }).promise()
  },
  async getCognitoUser ({ dispatch, state }, username) {
    state.users = state.users || {}

    if (!state.users[username]) {
      await dispatch('authentication/refreshAWSCredentials', true, {root: true})
      const cisp = new CognitoIdentityServiceProvider()
      let user = await cisp.adminGetUser({
        UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
        Username: username
      }).promise()
      user.attributes = {}
      user.UserAttributes.forEach(attr => {
        user.attributes[attr.Name] = attr.Value
      })
      state.users[username] = user
    }

    return state.users[username]
  },
  async getUsers ({commit, state}, {resetPagination = false, fetchAll = false}) {
    let response
    if (resetPagination) {
      commit('updateUsersPagination', null)
    }
    const pagination = state.usersPagination
    const params = `limit=${pagination.limit}`

    if (fetchAll) {
      response = await UserManagementService.getAllUsers()
    } else {
      if (pagination.next) {
        response = await UserManagementService.get(pagination.next)
      } else {
        response = await UserManagementService.getUsers(params)
      }
    }

    if (resetPagination) {
      commit('setUsers', response.users)
    } else {
      commit('appendUsers', response.users)
    }
    commit('updateUsersPagination', response.next)
    return response
  },
  async getGroups ({commit, state}, {resetPagination = false, fetchAll = false}) {
    let response
    if (resetPagination) {
      commit('updateGroupsPagination', null)
    }
    const pagination = state.groupsPagination
    const params = `limit=${pagination.limit}`

    if (fetchAll) {
      response = await UserManagementService.getAllGroups()
    } else {
      if (pagination.next) {
        response = await UserManagementService.get(pagination.next)
      } else {
        response = await UserManagementService.getGroups(params)
      }
    }

    // Transform data structure into old Cognito style
    const fetchedGroups = response.groups.map((group) => ({name: group.attributes.name, description: group.attributes.description}))

    if (resetPagination) {
      commit('setGroups', fetchedGroups)
    } else {
      commit('appendGroups', fetchedGroups)
    }
    commit('updateGroupsPagination', response.next)
    return response
  },

  async migrateBritecoreContact ({state}, credentials) {
    return await UserManagementService.migrateBritecoreContact(credentials)
  }
}
