import _ from 'lodash'
import { linesClient } from '@/apollo'
import { LINES_GQL_CLIENT } from '@/pages/lines/constants'
import { CHANGE_TYPE_REMOVED } from '@/pages/lines/product-mapping/constants'
import GetProductRiskTypes from '@/pages/lines/queries/GetProductRiskTypes.gql'

import productMappingService from '@/api/lines/productmapping'
import { _actionTypes } from './actionTypes'
import { _mutationTypes } from './mutationTypes'

function cleanProductDifference (difference) {
  const cleanedDifference = {}

  for (const productName in difference) {
    const productDifference = difference[productName]
    if (!_.isEmpty(productDifference)) {
      const cleanedProductDifference = {}
      // clean up risk types
      for (const riskTypeName in productDifference) {
        const riskTypeDifference = productDifference[riskTypeName]
        if (!_.isEmpty(riskTypeDifference) && riskTypeDifference[CHANGE_TYPE_REMOVED]) {
          cleanedProductDifference[riskTypeName] = riskTypeDifference
        }
      }
      if (!_.isEmpty(cleanedProductDifference)) {
        cleanedDifference[productName] = cleanedProductDifference
      }
    }
  }

  return cleanedDifference
}

export const actions = {
  [_actionTypes.LOAD_PRODUCT_RISK_TYPES] ({ commit }, { productId, versionId }) {
    return new Promise((resolve) => {
      linesClient.query({
        client: LINES_GQL_CLIENT,
        query: GetProductRiskTypes,
        fetchPolicy: 'no-cache',
        variables: {
          productId: productId,
          versionId: versionId
        }
      }).then(({ data: { product } }) => {
        commit(_mutationTypes.SET_PRODUCT, product)
        resolve(product)
      })
    })
  },
  [_actionTypes.LOAD_PRODUCT_DIFFERENCE] ({ dispatch }, { productName, versionId, includeDescendants }) {
    return new Promise((resolve, reject) => {
      productMappingService.getProductDifference(
        productName,
        versionId,
        includeDescendants
      ).then((response) => {
        dispatch(_actionTypes.SET_PRODUCT_DIFFERENCE, cleanProductDifference(response))
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [_actionTypes.LOAD_PRODUCT_MAPPING] ({ dispatch }, { productName, versionId }) {
    return new Promise((resolve, reject) => {
      productMappingService.getProductMapping(
        productName,
        versionId
      ).then((response) => {
        dispatch(_actionTypes.SET_PRODUCT_MAPPING, response.mapping)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [_actionTypes.UPDATE_PRODUCT_MAPPING] ({ dispatch, state }, { productName, versionId }) {
    return new Promise((resolve, reject) => {
      productMappingService.updateProductMapping(
        productName,
        versionId,
        { 'mapping': state.productMapping }
      ).then((response) => {
        dispatch(_actionTypes.SET_PRODUCT_MAPPING, response.mapping)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  [_actionTypes.SET_PRODUCT_DIFFERENCE] ({ commit }, productDifference) {
    commit(_mutationTypes.SET_PRODUCT_DIFFERENCE, productDifference)
  },
  [_actionTypes.SET_ACTIVE_PRODUCT_DIFFERENCE] ({ commit }, productDifference) {
    commit(_mutationTypes.SET_ACTIVE_PRODUCT_DIFFERENCE, productDifference)
  },
  [_actionTypes.SET_RISK_TYPE_DIFFERENCE] ({ commit }, riskTypeDifference) {
    commit(_mutationTypes.SET_RISK_TYPE_DIFFERENCE, riskTypeDifference)
  },
  [_actionTypes.SET_RISK_TYPES_TREE] ({ commit }, riskTypesTree) {
    commit(_mutationTypes.SET_RISK_TYPES_TREE, riskTypesTree)
  },
  [_actionTypes.SET_ACTIVE_RISK_TYPES_TREE_NODE_ID] ({ commit }, nodeId) {
    commit(_mutationTypes.SET_ACTIVE_RISK_TYPES_TREE_NODE_ID, nodeId)
  },
  [_actionTypes.SET_PRODUCT_MAPPING] ({ commit }, productMapping) {
    commit(_mutationTypes.SET_PRODUCT_MAPPING, productMapping)
  }
}
