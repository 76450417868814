import axios from 'axios'

export async function apiRequest ({ commit }, { endpoint, mutationToCommit, payload, method = 'get' }) {
  try {
    let response = await axios[method](endpoint, payload)
    const { data, success, message, messages } = response.data
    if (success) {
      if (mutationToCommit) {
        commit(mutationToCommit, data)
      } else {
        return data
      }
    } else {
      console.error(message || messages)
    }
  } catch (e) {
    console.error(e)
  }
  return null
}

export function gatewayRequest (_, { filename, method, data }) {
  return axios.post('/gateway', data, {
    params: {
      file: filename,
      method
    },
    headers: {
      'X-Request': 'JSON',
      'Content-Type': 'application/json'
    }
  })
}

export async function refreshRoles ({ dispatch }) {
  await dispatch('apiRequest', {
    endpoint: '/api/v2/contacts/retrieve_all_roles',
    mutationToCommit: 'roles',
    payload: {
      login_only: true
    },
    method: 'post'
  })
}

export async function setRole ({dispatch}, role) {
  // Don't do anything if a role isn't passed - fallback since BriteAccess isn't on prod yet.
  if (!role) {
    return
  }
  return await dispatch('gatewayRequest', {
    filename: 'core.login',
    method: 'loadUserProfile',
    data: JSON.stringify({ role })
  })
}

export async function retrieveNavigationLinks ({ dispatch, commit }) {
  if (process.env.VUE_APP_GEN2_DISABLED === 'true') {
    commit('permissions', {})
    return
  }

  const response = await dispatch('apiRequest', {
    endpoint: '/api/v2/utils/get_navigation_links'
  })
  if (response) {
    const modules = process.env.VUE_APP_DISABLE_GEN2_MODULES ? process.env.VUE_APP_DISABLE_GEN2_MODULES.split(' ') : []
    if (modules.length > 0) {
      modules.forEach(moduleName => {
        if (response[moduleName]) {
          delete response[moduleName]
        }
      })
    }
    commit('permissions', response)
  }
}

export async function retrieveBriteCoreGen2Version ({ dispatch }) {
  await dispatch('apiRequest', {
    endpoint: '/api/v2/utils/get_release_info',
    mutationToCommit: 'version'
  })
}

export async function retrieveBriteCoreGen2MetaData ({ dispatch }) {
  await dispatch('apiRequest', {
    endpoint: '/api/v2/utils/meta',
    mutationToCommit: 'metaData'
  })
}

export async function retrieveGen2Contact ({ dispatch, commit }, cognitoUsername) {
  const response = await dispatch('apiRequest', {
    endpoint: '/api/v2/contacts/get_contact_by_cognito_username',
    payload: {
      cognito_username: cognitoUsername
    },
    method: 'post'
  })
  if (response) {
    commit('setContactInfo', response.contact)
    return response.contact
  }
}

export async function retrieveGen2ContactForMigration ({ dispatch }, query) {
  const response = await dispatch('apiRequest', {
    endpoint: '/api/v2/contacts/get_contact_for_migration',
    payload: {
      search_str: query
    },
    method: 'post'
  })
  if (response) {
    return response.contact
  }
}

export function signOut ({ dispatch }) {
  return dispatch('gatewayRequest', { filename: 'core.login', method: 'logOut' })
}

export async function submitSupportTicket ({ dispatch }, ticket) {
  const formData = new FormData()
  formData.append('oops_input_subject', ticket.subject)
  formData.append('oops_input_description', ticket.description)
  formData.append('oops_input_ccs', ticket.includedContactEmails.join(','))
  formData.append('oops_input_url', ticket.url)

  if (ticket.critical) {
    formData.append('oops_input_stop', 'on')
  }

  for (const file of ticket.fileAttachments) {
    formData.append('oops_input_attachments', file)
  }

  const result = await dispatch('gatewayRequest', {
    filename: 'more.oops_issue_tracker',
    method: 'submit_issue',
    data: formData
  })

  return result.data
}

export function retrieveSettingsBySectionAndOption (context, {section, option}) {
  return axios.post('/api/v2/settings/get_setting_value', {section, option}, {
    headers: {
      'X-Request': 'JSON',
      'Content-Type': 'application/json'
    }
  })
}

export async function retrieveCarrierInfo ({ dispatch }) {
  await dispatch('apiRequest', {
    endpoint: '/api/v2/insured/get_primary_carrier',
    mutationToCommit: 'carrier'
  })
}

export async function retrieveGen2ListEmailsForContact ({ dispatch }, contactId) {
  const response = await dispatch('apiRequest', {
    endpoint: '/api/v2/contacts/list_emails_for_contact',
    payload: {
      'contact_id': contactId
    },
    method: 'post'
  })
  if (response) {
    return response
  }
}

export async function retrieveGen2PolicyAttachments ({ dispatch }, payload) {
  const response = await dispatch('gatewayRequest', {
    filename: 'core.uploads',
    method: 'retrieveAttachments',
    data: payload
  })
  if (response) {
    return response.data
  }
}

export async function emailPolicyInformation ({ dispatch }, payload) {
  const response = await dispatch('gatewayRequest', {
    filename: 'core.uploads',
    method: 'email_files',
    data: payload
  })
  if (response) {
    return response.data
  }
}

export function getRecentSearches ({ commit }, searchType) {
  const previousSearches = localStorage.getItem('bclocalstorage')
  if (!previousSearches) return

  if (searchType === 'policies') commit('setRecentPolicySearches', JSON.parse(previousSearches)[`recent-${searchType}`])
  if (searchType === 'claims') commit('setRecentClaimSearches', JSON.parse(previousSearches)[`recent-${searchType}`])
  if (searchType === 'contacts') commit('setRecentContactSearches', JSON.parse(previousSearches)[`recent-${searchType}`])
}

export async function getQuickSearchResults ({ dispatch, commit }, data) {
  let postDetails
  switch (data.searchType) {
    case 'Contact':
      postDetails = {
        filename: 'contacts.views',
        method: 'retrieveContactList'
      }
      break
    case 'Claim':
      postDetails = {
        filename: 'claims.views',
        method: 'retrieveClaimsList'
      }
      break
    case 'Policy':
      postDetails = {
        filename: 'policies.views',
        method: 'retrievePolicies'
      }
      break
  }

  const response = await dispatch('gatewayRequest', {
    ...postDetails,
    data: {
      filter: '',
      searchString: data.searchInput,
      currentPage: 1,
      pageSize: 5
    }
  })

  if (response.data) commit('setRecentSearches', response.data.records)
}

export async function fetchEffectiveDates ({ commit, dispatch }) {
  try {
    const res = await dispatch('apiRequest', {
      endpoint: '/api/v2/lines/get_all_effective_dates',
      method: 'post',
    })
    commit('setEffectiveDates', res)
  } catch (e) {
    console.error(
      'failed to get the list of effective dates, please try again later.'
    )
  }
}

export async function retrieveEffectiveDate ({ commit, dispatch }, effectiveDateId) {
  try {
    const res = await dispatch('apiRequest', {
      endpoint: '/api/v2/lines/retrieve_effective_date',
      method: 'post',
      payload: {
        effective_date_id: effectiveDateId,
      }
    })
    commit('setLines', res?.lines)
    commit('setBusinessLocations', res?.states)
  } catch (e) {
    console.error(
      'failed to get the effective date details, please try again later.'
    )
  }
}

export async function fetchMassDeliverablesTable ({ commit, dispatch }, stateId) {
  const response = await dispatch('gatewayRequest', {
    filename: 'settings.views',
    method: 'retrieveMassDeliverablesTable',
    data: {
      stateId,
    },
  })
  commit('setMassDeliverablesTable', response.data)
}
