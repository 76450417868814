import {JsonApiService} from '@/modules/claims/services/api'

class LimitConsumptionApi extends JsonApiService {
  constructor () {
    super('limit-consumption')
  }
}

class RecoveryApi extends JsonApiService {
  constructor () {
    super('recovery')
  }
}

class RecoveryReserveApi extends JsonApiService {
  constructor () {
    super('recovery-reserve')
  }
}

class ReserveApi extends JsonApiService {
  constructor () {
    super('reserve')
  }
}

class ReserveCategoryApi extends JsonApiService {
  constructor () {
    super('reserve-category')
  }
}

class ReserveCategoryTypeApi extends JsonApiService {
  constructor () {
    super('reserve-category-type')
  }
}

class TransactionApi extends JsonApiService {
  constructor () {
    super('transaction')
  }
}

class TransactionTypeApi extends JsonApiService {
  constructor () {
    super('transaction-type')
  }
}

class SummaryClaimantApi extends JsonApiService {
  constructor () {
    super('summary-claimant')
  }
}

class SummaryRecoveryApi extends JsonApiService {
  constructor () {
    super('summary-recovery')
  }
}

class SummaryReserveApi extends JsonApiService {
  constructor () {
    super('summary-reserve')
  }
}

export {
  LimitConsumptionApi,
  RecoveryApi,
  RecoveryReserveApi,
  ReserveApi,
  ReserveCategoryApi,
  ReserveCategoryTypeApi,
  TransactionApi,
  TransactionTypeApi,
  SummaryClaimantApi,
  SummaryRecoveryApi,
  SummaryReserveApi
}
