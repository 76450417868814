import { addNamespace } from '@/store/helpers'
import { PRODUCT_MAPPING_STORE_PATH } from './constants'

// To be used only inside vuex modules
export const _mutationTypes = {
  SET_PRODUCT: 'setProduct',
  SET_PRODUCT_DIFFERENCE: 'setProductDifference',
  SET_ACTIVE_PRODUCT_DIFFERENCE: 'setActiveProductDifference',
  RESET_ACTIVE_PRODUCT_DIFFERENCE: 'resetActiveProductDifference',
  SET_RISK_TYPE_DIFFERENCE: 'setRiskTypeDifference',
  SET_RISK_TYPES_TREE: 'setRiskTypesTree',
  SET_ACTIVE_RISK_TYPES_TREE_NODE_ID: 'setActiveRiskTypesTreeNodeId',
  SET_PRODUCT_MAPPING: 'setProductMapping',
  MAP_ENTITY: 'mapEntity',
  CLEAR_ENTITY_MAPPING: 'clearEntityMapping'
}

// To be used publicly outside vuex modules
export const mutationTypes = addNamespace(PRODUCT_MAPPING_STORE_PATH, _mutationTypes)
