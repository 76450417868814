import RepositoryInterface from './RepositoryInterface'
import axios from 'axios'
import {omitBy, isNil, get, trim} from 'lodash'

const createSlug = Symbol('createSlug')
const mapPaginationToRemote = Symbol('mapPaginationToRemote')
const mapPaginationFromRemote = Symbol('mapPaginationFromRemote')
const createOrderingQuery = Symbol('createOrderingQuery')
const mapParamsToRemote = Symbol('mapParamsToRemote')

/**
 * Remote servers that use this abstraction should follow
 * the conventions listed here regarding:
 * pagination and ordering field names
 */
export default class RemoteResourceRepository extends RepositoryInterface {
  constructor (resource, {trailingSlash = true} = {}) {
    super()
    const API_URL = process.env.VUE_APP_API_URL || ''
    this.url = `${API_URL}/${resource}${trailingSlash ? '/' : ''}`
    this.trailingSlash = trailingSlash
    this.http = axios
  }

  [createSlug] (slug) {
    const leadingSlash = `${this.trailingSlash ? '' : '/'}`
    const trailingSlash = `${this.trailingSlash ? '/' : ''}`

    return leadingSlash + slug + trailingSlash
  }

  /**
   * Creates the ordering query string expected by the remote API
   * @param {Object} ordering object containing order and key
  */
  [createOrderingQuery] ({key = null, order = null} = {}) {
    if (!key) return null
    return `${order === 'desc' ? '-' : ''}${key}`
  }

  /**
   * Maps the names used on the frontend to those used on the remote API
   * Remote servers are expected to follow the same conventions for pagination fields listed here
   * @param {Object} pagination the pagination object as defined on pagination.js
   * @returns {Object} the new object with the names expected by the remote API
  */
  [mapPaginationToRemote] (pagination) {
    if (!pagination) return ({})
    return ({
      count: pagination.total,
      page: pagination.currentPage,
      page_size: pagination.pageSize
    })
  }

  /**
   * Maps the names used on the remote API to those used on the frontend
   * @param {Object} pagination the current pagination object that was sent to the endpoint
   * @param {Object} response the response object returned from the API call
   * @returns {Object} the new object with the names expected by the frontend
  */
  [mapPaginationFromRemote] (pagination, response) {
    if (!pagination) return ({})
    return ({
      total: response.count,
      currentPage: pagination.currentPage,
      pageSize: pagination.pageSize,
      nextPage: response.next ? pagination.currentPage + 1 : null,
      previousPage: response.previous
    })
  }

  [mapParamsToRemote] ({filters = {}, pagination = {}, ordering = {}, ...others} = {}) {
    return omitBy({
      ...others,
      ordering: this[createOrderingQuery](ordering),
      ...this[mapPaginationToRemote](pagination),
      ...filters
    }, isNil)
  }

  async createRecord ({data}) {
    const response = await this.http.post(this.url, data)
    return response.data
  }

  /**
   * @param {Object} params
   * @param {Object} params.queries
   * @param {Object} params.options
   * @param {String} params.options.url   overwrites the default url generated by this repository
   * @returns {Array<Object>}
   */
  async listRecords ({queries = null, options = {}} = {}) {
    let pagination
    let config = options
    if (queries) {
      const params = omitBy(queries, isNil)
      pagination = get(params, 'pagination')
      config.params = this[mapParamsToRemote](params)
    }
    let url = this.url
    if (options.hasOwnProperty('url')) {
      url = options.url
      delete options.url
    }
    const response = await this.http.get(url, config)
    return {
      data: response.data.results,
      pagination: this[mapPaginationFromRemote](pagination, response.data)
    }
  }

  async listAllRecords ({queries = null, options = {}} = {}) {
    let records = []
    let response

    while (true) {
      response = await this.listRecords({ queries, options })
      records = [...records, ...response.data]

      // No more pages to iterate, stop fetching.
      if (!response.pagination.nextPage) {
        break
      }

      // Backend pagination misbehaved, stop fetching.
      if (isNaN(parseInt(response.pagination.nextPage))) {
        break
      }

      // Backend pagination misbehaved, stop fetching.
      if (response.pagination.nextPage <= response.pagination.currentPage) {
        break
      }

      // Advance pagination.
      queries.pagination.currentPage = response.pagination.nextPage
    }
    return {
      data: records,
      pagination: response.pagination
    }
  }

  async readRecord ({id}) {
    const idUrl = this[createSlug](id)
    const response = await this.http.get(this.url + idUrl)
    return response.data
  }

  async updateRecord ({id, data, partial = false}) {
    const method = partial ? 'patch' : 'put'
    const idUrl = this[createSlug](id)
    const url = this.url + idUrl

    const response = await this.http({
      method,
      url,
      data
    })
    return response.data
  }

  async deleteRecord ({id}) {
    const idUrl = this[createSlug](id)
    const response = await this.http.delete(this.url + idUrl)
    return response.data
  }

  async downloadRecord ({queries = null, options = {}} = {}) {
    let config = options
    if (queries) {
      const params = omitBy(queries, isNil)
      config.params = this[mapParamsToRemote](params)
    }
    let url = this.url
    if (options.hasOwnProperty('url')) {
      url = options.url
      delete options.url
    }
    const response = await this.http.get(url, config)
    const filename = response.headers['content-disposition'].split('filename=')[1]
    return {
      data: response.data,
      filename: trim(filename, `'"`)
    }
  }

  async uploadRecord ({data}) {
    const response = await this.http.post(this.url, data)
    return response.data
  }
}
