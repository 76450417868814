import _ from 'lodash'
import LogRocket from 'logrocket'

export const mutations = {
  currentUser (state, cognitoUser) {
    state.currentUser = cognitoUser
  },
  setSessionTimeout (state, sessionTimeout) {
    state.sessionTimeout = sessionTimeout
  },
  session (state, session) {
    state.session = session
    if (session) {
      const accessTokenPayload = session.getAccessToken().decodePayload()
      const username = accessTokenPayload.username
      const groups = accessTokenPayload['cognito:groups']

      state.isEmployee = _.endsWith(username, '@britecore.com')
      state.isAdmin = _.includes(groups, 'Administrators')
      state.currentUserGroups = groups

      LogRocket.identify(username, {
        name: accessTokenPayload.name,
        email: accessTokenPayload.username
      })
    } else {
      state.isAdmin = null
      state.isEmployee = null
      state.currentUserGroups = []
    }
  },
  isAuthenticating (state, isAuthenticating) {
    state.isAuthenticating = isAuthenticating
  },
  isBeingLoggedOut (state, isBeingLoggedOut) {
    state.isBeingLoggedOut = isBeingLoggedOut
  },
  lastSignInError (state, error) {
    state.lastSignInError = error
  },
  clearAccessToken (state, currentUser) {
    if (currentUser) {
      const keyPrefix = `CognitoIdentityServiceProvider.${currentUser.pool.getClientId()}`
      const accessTokenKey = `${keyPrefix}.${currentUser.username}.accessToken`

      currentUser.storage.removeItem(accessTokenKey)
    }
  },
  setGen2EffectiveRole (state, role) {
    state.gen2EffectiveRole = role
  }
}
