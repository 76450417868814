import store from '@/store'
import Vue from 'vue'
import mutationTypes from '@/store/access/mutationTypes'

/**
 * BriteAccessMixin is a mixin provided to check permissions to perform an action on a given resource or record.
 * By importing the mixin in a component and calling $evaluate with a list of accessAttempts, an API request is sent to
 * /access/evaluations that will evaluate the user's permissions considering the role and access policies attached
 * to it.
 * The mixin provides a helper $access computed property to get the permissions configured in vuex and a loading
 * indicator for the API request.
 */

export let BriteAccessMixin = {
  data () {
    return {
      $isLoadingEvaluations: Vue.observable({ value: false })
    }
  },
  computed: {
    $access () {
      let accessHelper = ({resource, action, record = null, context = null}) => {
        /**
         * @param {string} resource - The resource that the user is trying to access.
         * @param {string} action - The action that the user is trying to perform.
         * @param {string} record - The specific record that the user is trying to access.
         * @param {array} context - The context of the action being performed.
         * This method reaches vuex to verify if the action is allowed to be performed on resource.
         */
        if (!resource || !action) {
          throw new Error(`BriteAccessMixin: Missing required resource or action parameters to execute $access computed property.
          Example: $access({resource: 'bc.briteaccess.RESOURCE', action: 'Create'})`)
        }

        return store.getters['access/accessAttemptIsAllowed'](resource, action, record, context)
      }

      const getReadyStatus = () => !this._data.$isLoadingEvaluations.value

      accessHelper.evaluate = async ({accessAttempts}, {redirect = false} = {}) => {
        /**
         * @param {Object<Array>} accessAttempts - The list of access attempts.
         * @param {Boolean} redirect - If true the page will redirect to forbidden when access is not allowed .
         * The accessAttempt model is in the format:
         * - action
         * - resource
         * - record (optional)
         * More information in https://bit.ly/30PTJfH (BriteAccess swagger file)
         * This method will either trigger an API call to /access/evaluations endpoint using the parameters passed to
         * the $access computed property or will retrieve it from the evaluations state (cache).
         */
        this._data.$isLoadingEvaluations.value = true
        if (process.env.VUE_APP_ENABLED_MODULE_ACCESS) {
          await store.dispatch('access/evaluateAccessAttempts', accessAttempts)
        } else {
          // allow all attempts if access is disabled
          accessAttempts.forEach(attempt => {
            const {resource, action, record, context} = attempt
            const evaluation = 'allowed'
            store.commit(mutationTypes.SET_EVALUATE_RESULT, {resource, action, record, context, evaluation})
          })
        }
        this._data.$isLoadingEvaluations.value = false

        accessAttempts.forEach(attempt => {
          const isAllowed = accessHelper(attempt)
          if (isAllowed === false && redirect) {
            this.$router.push('/forbidden')
          }
        })
      }
      accessHelper.ready = getReadyStatus()

      return accessHelper
    }
  }
}
