export default {
  // Pending Claims
  pendingClaims: [],

  defaultActiveTeamIndex: 0,

  // Pagination for Adjusters
  adjustersPagination: {
    page: 1,
    total: 0,
    pageSize: 10,
    hasNextPage: false
  },
  // Pagination for Pending Claims
  pendingClaimsPagination: {
    page: 1,
    total: 0,
    pageSize: 20
  },

  // Filters
  filters: {},
  statuses: null,
  lobs: null,
  coverageTypes: null,
  lossTypes: null
}
