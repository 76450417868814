import * as actions from './actions'
import * as mutations from './mutations'
import state from './state'

const bcClassicContacts = {
  namespaced: true,
  actions,
  mutations,
  state
}

export default bcClassicContacts
