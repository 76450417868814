
export const state = {
  riskTypeDetailsId: null,
  riskTypeDetailsActiveTab: 'fields',
  scenario: null,
  scenarios: [],
  riskState: null,
  riskTypeState: null,
  riskMeta: null,
  storeRiskStateRequestsCount: 0,
  productImports: []
}
