import AWSXRay from 'aws-sdk/clients/xray'
import store from '../store'
import uuid from 'uuid'
const xray = {}
const inProgressSegments = {}

xray.getSegment = (traceId) => {
  return inProgressSegments[traceId]
}

xray.getHexId = function (length) {
  return uuid.v4().replace(/-/g, '').substring(0, length)
}
xray.getHexTime = function () {
  return Math.round(new Date().getTime() / 1000).toString(16)
}
xray.getEpochTime = function () {
  return new Date().getTime() / 1000
}
xray.getTraceHeader = function (segment) {
  return 'Root=' + segment.trace_id + ';Parent=' + segment.id + ';Sampled=1'
}
xray.beginSegment = function (action, method) {
  const segment = {}
  const traceId = '1-' + xray.getHexTime() + '-' + xray.getHexId(24)

  const id = xray.getHexId(16)
  const startTime = xray.getEpochTime()

  segment.trace_id = traceId
  segment.id = id
  segment.start_time = startTime
  segment.name = action
  segment.in_progress = true
  const currentUser = store.state.authentication.currentUser
  segment.user = currentUser ? currentUser.username : 'guest_user'
  segment.http = {
    request: {
      url: window.location.href,
      method: method
    }
  }

  const documents = []
  documents[0] = JSON.stringify(segment)
  xray.putDocuments(documents)

  inProgressSegments['Root=' + segment.trace_id] = segment

  return segment
}

xray.endSegment = function (traceId) {
  let segment = this.getSegment(traceId)
  if (!segment) {
    return
  }
  const endTime = xray.getEpochTime()
  segment.end_time = endTime
  segment.in_progress = false
  const documents = []
  documents[0] = JSON.stringify(segment)
  xray.putDocuments(documents)
  delete inProgressSegments[traceId]
}

xray.putDocuments = function (documents) {
  const xraySdk = new AWSXRay()
  const params = {
    TraceSegmentDocuments: documents
  }
  xraySdk.putTraceSegments(params, function (err, data) {
    if (err) {
      console.log(err)
    }
  })
}

export default xray
