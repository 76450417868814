import {_adminSetupActions} from './action-types'
import {delay} from '@/modules/claims/utils/promise-delay'
import {_adminSetupMutations} from './mutation-types'
import {claimsApi} from '@/modules/claims/utils/api'

export default {
  [_adminSetupActions.GET_ADMIN_SETUP_MODULES] ({commit}) {
    return Promise.resolve([
      {
        id: 1,
        title: 'Mock Policies Maintenance',
        link: {name: 'claims:admin-setup:mock-policies-maintenance'}
      },
      {
        id: 2,
        title: 'Manual Items',
        link: {name: 'claims:admin-setup:manual-items'}
      },
      {
        id: 3,
        title: 'Perils',
        link: {name: 'claims:admin-setup:perils'}
      },
      {
        id: 4,
        title: 'Payment Processing',
        link: {name: 'claims:admin-setup:payment-processing'}
      },
      {
        id: 5,
        title: 'Tag Maintenance',
        link: {name: 'claims:admin-setup:tags'}
      },
      {
        id: 6,
        title: 'Products Maintenance',
        link: {name: 'claims:admin-setup:products-maintenance'}
      },
      {
        id: 7,
        title: 'Import Historical Claims',
        link: {name: 'claims:admin-setup:import-historical-claims'}
      }
    ])
      .then(delay(100))
      .then(modules => {
        commit(_adminSetupMutations.SET_ADMIN_SETUP_MODULES, modules)
      })
  },
  [_adminSetupActions.GET_CLAIM_PRODUCTS] () {
    const config = {
      is_line: true
    }
    return claimsApi.get('refdata/products/', {params: config}).then(response => {
      return response
    })
  },
  [_adminSetupActions.CREATE_CLAIM_PRODUCTS] ({commit}, product) {
    return claimsApi.post('refdata/products/', product).then(response => {
      return response
    })
  }
}
