import BaseService from '@/api/base'
import _ from 'lodash'

export const claimsApi = new BaseService('claims')
export const policiesApi = new BaseService('claims/integrations/policies')
export const contactsV1Api = new BaseService('v1/contacts')
export const contactsV2Api = new BaseService('v2/contacts')
export const settingsV2Api = new BaseService('v2/settings')
export const notesV2Api = new BaseService('v2/notes')

export const getErrorMessage = err => {
  let message
  if (err.hasOwnProperty('errors')) {
    const rowSep = '\n'
    const errors = err.errors
    message = Object.keys(errors).map(
      field => errors[field].map(
        e => _.get(e, 'message')
      ).join(rowSep)
    ).join(rowSep)
  } else {
    message = _.get(err, 'response.data.message')
  }
  return message || err.message
}
