import { cloneDeep } from 'lodash'
import { GEN2, GEN3 } from './constants'
import { enabledModules } from '@/utilities/utils.js'

// TODO: add JSDoc docstring for every property

/**
 * Do NOT change `moduleName` property for any of the modules here unless there's a good reason to.
 *
 * When adding, modifying, removing or changing the order for any module, make sure to make the appropriate changes
 * in Gen2's `BC_SITE_STRUCTURE` and `BQ_SITE_STRUCTURE`
 * https://github.com/IntuitiveWebSolutions/BriteCore/blob/master/lib/core/templates.py
 */
const adminModuleLinks = [
  {
    location: { name: 'contacts' },
    label: 'Contacts',
    moduleName: 'contacts',
    accessModuleName: 'britecontacts',
    icon: 'bc-contacts',
    topNav: true,
    description: 'Create and manage contacts',
    availableDestinations: [GEN2, GEN3]
  },
  {
    location: { name: 'lines:search-lines' },
    label: 'Lines',
    moduleName: 'lines',
    accessModuleName: 'britelines',
    icon: 'bc-lines',
    topNav: true,
    description: 'Create and manage lines of business',
    availableDestinations: [GEN2, GEN3]
  },
  {
    location: { name: '' },
    label: process.env.VUE_APP_ENABLED_MODULE_QUOTE ? 'Policies and Quotes' : 'Policies',
    moduleName: 'policies',
    accessModuleName: 'britepolicies',
    icon: 'bc-policies',
    topNav: true,
    description: 'Create and manage policies',
    availableDestinations: [GEN2]
  },
  {
    location: { name: 'claims:dashboard' },
    label: 'Claims',
    moduleName: 'claims',
    accessModuleName: 'briteclaims',
    icon: 'bc-claims',
    topNav: true,
    preferGen2: true,
    description: 'Manage claims from FNOL to settlement',
    availableDestinations: [GEN2, GEN3]
  },
  {
    location: { name: '' },
    label: 'Reports',
    moduleName: 'reports',
    accessModuleName: 'britereports',
    icon: 'bc-data',
    topNav: true,
    description: "Visualize your company's data",
    availableDestinations: [GEN2]
  },
  {
    location: { name: 'documents' },
    label: 'Documents',
    moduleName: 'documents',
    accessModuleName: 'britedocs',
    icon: 'bc-documents',
    topNav: true,
    description: 'Create and manage deliverables',
    availableDestinations: [GEN3]
  },
  {
    location: { name: 'rules' },
    label: 'Rules',
    moduleName: 'rules',
    accessModuleName: 'briterules',
    icon: 'bc-rules',
    topNav: true,
    description: 'Search and manage rules',
    availableDestinations: [GEN3]
  },
  {
    location: { name: 'access:roles' },
    label: 'Access',
    moduleName: 'access',
    accessModuleName: 'briteaccess',
    icon: 'bc-access',
    topNav: true,
    description: 'Manage access control rules',
    availableDestinations: [GEN3]
  },
  {
    location: { name: 'notes:global-list' },
    label: 'Notes',
    moduleName: 'notes',
    accessModuleName: 'britenotes',
    icon: 'bc-notes',
    topNav: true,
    description: 'Search and list notes',
    availableDestinations: [GEN3]
  },
  {
    location: { name: '' },
    label: 'Settings',
    moduleName: 'settings',
    accessModuleName: 'britesettings',
    icon: 'bc-settings',
    topNav: true,
    description: 'Settings',
    availableDestinations: [GEN2]
  },
  {
    location: { name: 'integrations:dashboard' },
    label: 'Integrations',
    moduleName: 'integrations',
    accessModuleName: 'briteintegrations',
    icon: 'bc-integrations',
    topNav: false,
    description: 'Manage integrations',
    availableDestinations: [GEN3]
  },
  {
    location: { name: 'users:manage' },
    label: 'Users',
    moduleName: 'users',
    accessModuleName: 'briteauth',
    icon: 'bc-users',
    topNav: true,
    description: 'Create and manage users',
    availableDestinations: [GEN3],
    excludeFromAllModules: true,
    isCoreModule: true
  },
  {
    location: { name: 'my-account' },
    label: 'My Account',
    moduleName: 'myaccount',
    accessModuleName: 'myaccount',
    icon: 'user',
    excludeFromAllModules: true,
    availableDestinations: [GEN3],
    isCoreModule: true
  }
]

const inheritModule = (moduleName, props) => {
  const moduleToInherit = cloneDeep(adminModuleLinks.find(module => module.moduleName === moduleName))
  return {
    ...moduleToInherit,
    ...props
  }
}

const agentModuleLinks = [
  {
    location: { name: 'quote:create-quote' },
    label: 'New Quote',
    moduleName: 'quote',
    accessModuleName: 'britequote',
    icon: 'comment',
    topNav: true,
    description: 'New Quote',
    availableDestinations: [GEN3]
  },
  inheritModule('policies', {
    label: 'Policies and Quotes',
    availableDestinations: [GEN2]
  }),
  inheritModule('claims', {
    availableDestinations: [GEN2, GEN3]
  }),
  {
    location: { name: '' },
    label: 'Payments',
    moduleName: 'payments',
    accessModuleName: 'britepayments',
    icon: 'credit-card',
    topNav: true,
    description: 'Payments',
    availableDestinations: [GEN2]
  },
  inheritModule('reports', {
    topNav: true,
    availableDestinations: [GEN2]
  }),
  {
    location: { name: '' },
    label: 'BriteApps',
    moduleName: 'briteapps',
    accessModuleName: 'briteapps',
    icon: 'mobile-alt',
    topNav: true,
    description: 'BriteApps',
    availableDestinations: [GEN2]
  }
]

const allModulesLink = {
  location: { name: 'all-modules' },
  label: 'All Products',
  moduleName: 'all-modules',
  accessModuleName: 'all-modules',
  icon: 'bc-all-modules',
  topNav: true,
  excludeFromAllModules: true,
  isCoreModule: true,
  availableDestinations: [GEN3]
}

if (enabledModules.length >= 1) {
  adminModuleLinks.push(allModulesLink)
  agentModuleLinks.push(allModulesLink)
}

export default {
  adminModuleLinks,
  agentModuleLinks,
  enabledModules
}
