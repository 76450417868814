const DEFAULT_INVITATION_MESSAGES = {
  EMAIL_SUBJECT: 'Your temporary password',
  EMAIL_BODY: 'Your username is {username} and temporary password is {####}.',
  SMS: 'Your username is {username} and temporary password is {####}.'
}

export const getters = {
  invitationMessages: (state) => {
    const adminSettings = state.userPoolSettings.AdminCreateUserConfig

    let messages = {
      sms: DEFAULT_INVITATION_MESSAGES.SMS,
      email: {
        subject: DEFAULT_INVITATION_MESSAGES.EMAIL_SUBJECT,
        message: DEFAULT_INVITATION_MESSAGES.EMAIL_BODY
      }
    }

    if (adminSettings && adminSettings.InviteMessageTemplate) {
      messages.sms = adminSettings.InviteMessageTemplate.SMSMessage
      messages.email.subject = adminSettings.InviteMessageTemplate.EmailSubject
      messages.email.message = adminSettings.InviteMessageTemplate.EmailMessage
    }

    return messages
  }
}
