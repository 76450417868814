import {DEFAULT_FNOL_STEPS} from '@/modules/claims/store/modules/claim/constants'

export default {
  claim: null,
  product: null,
  exposure: null,
  parties: null,
  attachments: null,
  claimantActiveTab: null,
  exposureGroupBy: 'claimant',
  // If you have child routes, make sure the name of the child route start with the name of the tab
  // that you want to be active when that page loads
  claimTabItems: [
    {
      label: 'Details',
      name: 'details',
      urlName: 'claims:loss-detail'
    },
    {
      label: 'Exposures',
      name: 'exposure',
      urlName: 'claims:exposures'
    },
    {
      label: 'Accounting',
      name: 'accounting',
      urlName: 'claims:accounting'
    },
    {
      label: 'Parties',
      name: 'parties',
      urlName: 'claims:parties'
    },
    {
      label: 'Attachments',
      name: 'attachments',
      urlName: 'claims:attachments'
    }
  ],
  injuryPermission: false,
  claimantTabItems: [],
  riskGroups: [],
  // accounting
  selectedExposure: null,
  accountingBreadcrumbs: [],
  risk: null,
  // FNOL
  fnolSaving: false,
  fnolSteps: [...DEFAULT_FNOL_STEPS],
  currentUser: null,
  // data from contacts api
  genders: null,
  addressTypes: null,
  allowDuplicateSSN: null
}
