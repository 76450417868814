<template>
  <div
    v-if="!isComponentOnly"
    v-loading.body.lock="loading"
    class="layout__container"
    :class="{
      'layout__container--full-page': isFullPage,
      'layout__container--has-live-site-warning': isLiveSite && isEmployee,
      ...agencyClasses
    }">

    <div
      class="layout__sidebar"
      :class="{
        'layout__sidebar--hidden-mobile': !sidebarVisible
      }">
      <main-nav-sidebar></main-nav-sidebar>
    </div>

    <template v-if="!isFullPage">
      <div class="layout__sub-nav-mobile">
        <sub-nav-mobile></sub-nav-mobile>
      </div>

      <div
        class="layout__sub-sidebar"
        :class="{ 'layout__sub-sidebar--hidden-mobile': !subSidebarVisibleMobile }">
        <sub-nav-sidebar></sub-nav-sidebar>
      </div>
    </template>

    <div class="layout__main-nav">
      <main-top-nav></main-top-nav>
    </div>

    <el-alert
      type="error"
      class="layout__live-site-warning"
      title=""
      :show-icon="false"
      v-if="isLiveSite && isEmployee">
      <div class="u-text--center">
        <fa-icon
          icon="times-circle"
          class="u-mr2 u-mt-auto u-mb-auto">
        </fa-icon>
        <span class="u-mt-auto u-mb-auto">
          Be careful, this is a <strong>Live</strong> site
        </span>
      </div>
    </el-alert>

    <main
      class="layout__page-content"
      :class="{
        'layout__page-content--no-padding': noContainerPadding,
        'layout__page-content--pushed-mobile': subSidebarVisibleMobile
      }">
      <portal-target name="module_header"></portal-target>
      <router-view></router-view>
    </main>

    <div
      v-show="isNotesSidebarActive"
      class="layout__activity-sidebar">
      <activity-nav-sidebar />
    </div>

    <keyboard-shortcuts-modal v-model="keyboardShortcutsModalVisible"></keyboard-shortcuts-modal>
    <quick-search-modal
      v-model="quickSearchModalVisible"
      :searchType="searchType">
    </quick-search-modal>

    <bc-plugin-slot
      plugin-slot-name="britecore:root:markup"
      handler-component="bc-plugin-markup"
      :plugins-url="pluginsUrl"
      :integrations-url="integrationsUrl"
      :access-token="accessToken"
      class="hide"
    />
  </div>
  <router-view v-else></router-view>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import mousetrap from 'mousetrap'
import {titleTemplate} from './mixins'
import KeyboardShortcutsModal from '@/components/ui/KeyboardShortcutsModal'
import QuickSearchModal from '@/components/ui/quick-search/QuickSearchModal'
import BcPluginSlot from '@britecore/bc-ui-plugin-slots'

export default {
  name: 'layout-common',
  mixins: [titleTemplate],
  props: {
    noContainerPadding: { type: Boolean, default: false },
    isFullPage: { type: Boolean, default: false },
    isComponentOnly: { type: Boolean, default: false }
  },
  data () {
    return {
      keyboardShortcutsModalVisible: false,
      quickSearchModalVisible: false,
      searchType: ''
    }
  },
  computed: {
    ...mapState(['loading', 'sidebarVisible', 'subSidebarVisibleMobile']),
    ...mapGetters('authentication', ['isLiveSite', 'accessToken', 'isAgentOrAgency']),
    ...mapState('authentication', ['isEmployee']),
    ...mapState('bcClassic', ['contact']),
    ...mapGetters('activity', { isNotesSidebarActive: 'isSidebarActive' }),
    pluginsUrl () {
      return `${process.env.VUE_APP_INTEGRATIONS_URL}/plugins`
    },
    integrationsUrl () {
      return process.env.VUE_APP_INTEGRATIONS_URL
    },
    /**
     * Returns classes to apply to the root layout div for targeting elements for agency-specific custom styling.
     * custom-style__agency-{agency.id} classes are applied for each agency that the user belongs to. Classes are
     * only applied for agent/agency users.
     * @returns {{ [className: string]: Boolean }}
     */
    agencyClasses () {
      if (!this.isAgentOrAgency || !this.contact.agencies) return {} // apply agency-namespaced custom styles for non-admins only
      return this.contact.agencies.reduce(
        (acc, agency) => ({ ...acc, [`custom-style__agency--${agency.id}`]: true }), {}
      )
    }
  },
  methods: {
    ...mapActions('bcClassic', ['getRecentSearches']),
    toggleKeyboardShortcuts () {
      this.keyboardShortcutsModalVisible = !this.keyboardShortcutsModalVisible
    },
    setQuickSearchTypeAndOpenModal (event) {
      switch (event.key) {
        case 'p':
          this.searchType = 'Policy'
          this.getRecentSearches('policies')
          break
        case 'c':
          this.searchType = 'Claim'
          this.getRecentSearches('claims')
          break
        case 'n':
          this.searchType = 'Contact'
          this.getRecentSearches('contacts')
          break
      }

      this.quickSearchModalVisible = true
    },
    toggleQuickSearch (event) {
      if (this.quickSearchModalVisible) {
        // if this.quickSearchModalVisible is true and the keys are trying to open an already open modal, do nothing
        if ((event.key === 'p' && this.searchType === 'Policy') ||
            (event.key === 'c' && this.searchType === 'Claim') ||
            (event.key === 'n' && this.searchType === 'Contact')) return

        // if this.quickSearchModalVisible is true, then close the modal and reopen with the new searchType
        this.quickSearchModalVisible = false
        this.setQuickSearchTypeAndOpenModal(event)
      } else {
        this.setQuickSearchTypeAndOpenModal(event)
      }
    }
  },
  mounted () {
    mousetrap.bind('?', this.toggleKeyboardShortcuts)
    mousetrap.bind(['g p', 'g c', 'g n'], this.toggleQuickSearch)
  },
  components: {
    KeyboardShortcutsModal,
    QuickSearchModal,
    'bc-plugin-slot': BcPluginSlot,
  }
}
</script>
