<template>
  <el-dialog
    title="BriteCore Support"
    top="3vh"
    :visible="value"
    @update:visible="$emit('input', $event)"
    append-to-body>
    <h2 class="u-text--bold">Submit a Support Ticket</h2>

    <el-form @submit.native.prevent>
      <el-form-item
        prop="subject"
        label="Subject">
        <el-input
          v-model="ticket.subject"
          v-validate="'required'"
          name="subject"
          v-test-id="'ticket-subject'">
        </el-input>
      </el-form-item>
      <el-form-item
        prop="description"
        label="Description">
        <el-input
          v-model="ticket.description"
          v-validate="'required'"
          name="description"
          type="textarea"
          v-test-id="'ticket-description'">
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-checkbox
          v-model="ticket.critical"
          label="This is preventing our entire business from functioning">
        </el-checkbox>
      </el-form-item>

      <el-form-item
        prop="includedContactEmails"
        label="Add staff members to ticket (optional)">
        <el-select
          v-model="ticket.includedContactEmails"
          multiple
          filterable
          remote
          :remote-method="loadContactsForStaffMembers"
          :loading="loadingContacts">
          <el-option
            v-for="contact in searchedContacts"
            :key="contact.email"
            :label="contact.email"
            :value="contact.email">
            {{ contact.name }} ({{ contact.email }})
          </el-option>
        </el-select>
      </el-form-item>

      <el-upload
        class="u-mb5"
        ref="uploader"
        v-bind="fileUploader.options"
        :on-change="evaluateHasFiles">

        <!-- uploader content start -->
        <div class="u-text--center u-text--muted u-mt3">
          <fa-icon
            icon="cloud-upload-alt"
            size="5x">
          </fa-icon>
          <div class="u-mt3 u-text--xl">Drag &amp; drop or click here to select files</div>
        </div>
        <!-- uploader content end -->

      </el-upload>

      <div class="u-text--right">
        <el-button @click="$emit('input', false)">
          Cancel
        </el-button>
        <el-button
          type="primary"
          @click="validateAndSendTicket"
          native-type="submit"
          v-test-id="'submit-ticket-btn'">
          Send Ticket
        </el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { LoaderMixin, ErrorsMixin } from '@/components/ui/mixins'

const MINIMUM_EMAIL_QUERY_LENGTH = 3

export default {
  name: 'SubmitSupportTicketModal',
  mixins: [LoaderMixin, ErrorsMixin],
  props: {
    value: { type: Boolean, required: true }
  },
  data: function () {
    return {
      hasFiles: false,
      showLoader: false,
      loadingContacts: false,
      searchedContacts: [],
      fileUploader: {
        id: 'support-ticket-file-uploader',
        options: {
          action: '-',
          multiple: true,
          expanded: true,
          drag: true,
          dropzoneClass: 'u-mb3',
          autoUpload: false,
          accept: 'image/*',
          listType: 'picture-card',
          listPlacement: 'below'
        }
      },
      ticket: {
        url: '',
        subject: '',
        description: '',
        critical: false,
        includedContactEmails: [],
        fileAttachments: []
      }
    }
  },
  methods: {
    evaluateHasFiles (file, fileList, action) {
      this.hasFiles = !!fileList.length
    },
    async validateAndSendTicket () {
      const isValid = await this.$validator.validateAll()
      if (isValid) {
        this.showLoader = true

        try {
          await this.sendTicket()
        } catch (e) {
          console.error(e)
        } finally {
          this.showLoader = false
        }
      } else {
        this.$message.error('We\'re sorry, but some information is missing.')
      }
    },

    async sendTicket () {
      this.ticket.url = document.location.toString()
      this.ticket.fileAttachments = this.$refs.uploader.getFiles().map(file => file.raw)

      const result = await this.$store.dispatch('bcClassic/submitSupportTicket', this.ticket)
      if (result.success) {
        this.$message.success('Success! Your ticket was created.')
        this.$nextTick(() => this.$emit('input', false))
      } else {
        this.$message.error('Failed to submit support ticket. Please try again later.')
      }
    },

    async loadContactsForStaffMembers (query = '') {
      if (query !== '' && query.length >= MINIMUM_EMAIL_QUERY_LENGTH) {
        this.loadingContacts = true

        try {
          const contacts = await this.$store.dispatch('bcClassic/contacts/getSuggestedEmails', query)
          this.searchedContacts = contacts.filter(contact => !!contact.name && !!contact.email)
        } catch (e) {
          console.error(e)
        } finally {
          this.loadingContacts = false
        }
      } else {
        this.searchedContacts = []
      }
    }
  },
  watch: {
    value: function (val) {
      if (!val) {
        this.$refs.uploader.clearFiles()
        Object.assign(this.$data, this.$options.data.apply(this))
        this.$nextTick(() => {
          this.$errors.clear()
          this.$validator.reset()
        })
      }
    }
  }
}
</script>
