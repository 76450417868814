import _ from 'lodash'
import documentsService from '@/api/documents'

import api from '@/modules/claims/services'
import quoteService from '@/api/quote/quote'
import policyService from '@/api/policy'

function addFileExtraProperties (file, parentId, type) {
  return Object.assign({}, file, { parentId: parentId, type: type })
}

const _getDocumentVersions = function (context, document) {
  if (document.type === 'component') {
    return
  }
  let apiCall = 'getImportedDocumentVersions'
  if (document.type === 'document_template' || document.type === 'html_template') {
    apiCall = 'getTemplateVersions'
  }
  return new Promise(resolve => {
    documentsService[apiCall](document.id).then(versions => {
      resolve(versions)
    })
  })
}

const _openDocument = function (context, document) {
  let stateMutation = 'importedDocument'
  if (document.type === 'component') {
    stateMutation = 'addOpenTemplate'
    context.commit(stateMutation, document)
    return
  }
  if (document.type === 'document_template' || document.type === 'html_template') {
    stateMutation = 'addOpenTemplate'
  }
  return new Promise(resolve => {
    _getDocumentVersions(context, document).then((versions) => {
      document.versions = versions
      context.commit(stateMutation, document)
      // open newly created version
      if (document.new_version) {
        let version
        if (document.new_version.version_name) {
          version = versions.find(v => { return v.name === document.new_version.version_name })
        } else if (document.new_version.id) {
          version = versions.find(v => { return v.id === document.new_version.id })
        }
        const versionData = {
          document: document,
          version: version
        }
        context.dispatch('openVersion', versionData)
      }
      resolve()
    })
  })
}

function orderFolders (folders) {
  folders.sort((a, b) => a.name.localeCompare(b.name))
  const sortObject = function (container, property) {
    if (container.hasOwnProperty(property)) {
      if (container[property].length > 1) {
        container[property].sort((a, b) => a.name.localeCompare(b.name))
      }
    }
  }
  for (let folder of folders) {
    sortObject(folder, 'documents')
    sortObject(folder, 'assets')
    sortObject(folder, 'folders')
    orderFolders(folder.folders)
  }
}

export const actions = {
  openComponent (context, template) {
    documentsService.getComponentData(template).then(response => {
      if (typeof response === 'string') {
        const html = decodeURIComponent(escape(atob(response)))
        const activeVersion = {
          html: html,
          id: template.id,
          name: template.name
        }
        template.versions = []
        context.commit('addOpenTemplate', template)
        context.commit('activeVersion', activeVersion)
      }
    })
  },
  openTemplate (context, template) {
    return new Promise(resolve => {
      _openDocument(context, template).then(() => {
        resolve()
      })
    })
  },
  openImportedDocument (context, importedDocument) {
    return new Promise(resolve => {
      _openDocument(context, importedDocument).then(() => {
        resolve()
      })
    })
  },
  openAsset (context, asset) {
    return new Promise((resolve, reject) => {
      documentsService.getAssetData(asset.id).then(data => {
        asset.data = data
        context.commit('asset', asset)
        resolve(asset)
      })
    })
  },
  openVersion (context, data) {
    let apiCall = 'getImportedDocumentVersionData'
    if (data.document.type === 'document_template' || data.document.type === 'html_template') {
      apiCall = 'getTemplateVersionData'
    }
    const versionData = {
      'documentId': data.document.id,
      'versionId': data.version.id,
      'versionName': data.version.name,
      'versionEffectiveDate': data.version.effective_date,
      'productionDocumentId': data.version.production_document_id
    }
    return new Promise((resolve, reject) => {
      documentsService[apiCall](versionData).then(response => {
        if (typeof response === 'string') {
          data.version.html = decodeURIComponent(escape(atob(response)))
        } else if (typeof response === 'object') {
          data.version.data = response.document_data
          data.version.fields = response.document_fields
        }
        context.commit('activeVersion', data.version)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  saveVersion (context, data) {
    documentsService.updateTemplate(data).then(updatedTemplate => {
      updatedTemplate.new_version = data
      context.dispatch('openTemplate', updatedTemplate)
    })
  },
  updateVersion (context, data) {
    documentsService.updateTemplate(data).then(updatedTemplate => {
      const versionData = {
        effective_date: data.effective_date,
        description: data.version_description,
        name: data.version_name,
        updated_content: updatedTemplate.html,
        type: data.type
      }

      context.commit('updateActiveVersion', versionData)
    })
  },
  updateImportedDocumentVersion (context, data) {
    documentsService.updateImportedDocument(data).then(updatedVersion => {
      context.state.activeVersion.effective_date = data.effective_date
      context.state.activeVersion.description = data.version_description
      context.state.activeVersion.name = data.version_name
      context.commit('activeVersion', context.state.activeVersion)
    })
  },
  updateTemplate (context, data) {
    return new Promise((resolve, reject) => {
      documentsService.updateTemplate(data).then(result => {
        context.state.template.name = result.name
        context.state.template.folder = result.folder
        context.state.template.description = result.description
        context.state.template.tags = result.tags
        context.commit('template', context.state.template)
        resolve(result)
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateImportedDocument (context, data) {
    return new Promise((resolve, reject) => {
      documentsService.updateImportedDocument(data).then(result => {
        context.state.importedDocument.name = result.name
        context.state.importedDocument.folder = result.folder
        context.state.importedDocument.description = result.description
        context.commit('importedDocument', context.state.importedDocument)
        resolve(result)
      })
    })
  },
  updateTemplateAndVersion (context, data) {
    return new Promise((resolve, reject) => {
      documentsService.updateTemplate(data).then(result => {
        context.state.template.name = data.name
        context.state.template.description = data.description
        context.commit('template', context.state.template)
        context.state.activeVersion.effective_date = data.effective_date
        context.state.activeVersion.description = data.version_description
        context.state.activeVersion.name = data.version_name
        context.commit('activeVersion', context.state.activeVersion)
        resolve(result)
      })
    })
  },
  createProductionVersion (context, data) {
    return new Promise((resolve, reject) => {
      documentsService.createProductionVersion(data).then(result => {
        let newVersion = {}
        newVersion.document = data.id
        newVersion.production_document_id = result.id
        newVersion.id = result.version
        newVersion.effective_date = result.effective_date
        context.commit('ADD_VERSION', newVersion)

        let activeVersion = {}
        activeVersion.effective_date = data.effective_date
        activeVersion.description = data.description
        activeVersion.name = data.version_name
        activeVersion.production_document_id = result.id
        activeVersion.id = result.version
        context.commit('activeVersion', activeVersion)

        resolve(result)
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateProductionVersion (context, data) {
    return new Promise((resolve, reject) => {
      documentsService.updateProductionVersion(data).then(result => {
        context.state.template.name = data.name
        context.state.template.description = data.description
        context.commit('template', context.state.template)
        context.state.activeVersion.effective_date = data.effective_date
        context.state.activeVersion.description = data.version_description
        context.state.activeVersion.name = data.version_name
        context.state.activeVersion.production_document_id = data.production_document_id
        context.commit('activeVersion', context.state.activeVersion)
        resolve(result)
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateImportedDocumentAndVersion (context, data) {
    return new Promise((resolve, reject) => {
      documentsService.updateImportedDocument(data).then(result => {
        context.state.importedDocument.name = data.name
        context.state.importedDocument.description = data.description
        context.commit('importedDocument', context.state.importedDocument)
        context.state.activeVersion.effective_date = data.effective_date
        context.state.activeVersion.description = data.version_description
        context.state.activeVersion.name = data.version_name
        context.commit('activeVersion', context.state.activeVersion)
        resolve(result)
      })
    })
  },
  rename (context, data) {
    let apiMethod

    if (data.type === 'document_template' || data.type === 'html_template') {
      apiMethod = 'renameDocument'
    } else {
      apiMethod = _.camelCase(`rename_${data.type}`)
    }

    return new Promise((resolve, reject) => {
      documentsService[apiMethod](data.objectId, data.newName).then(function (newData) {
        data.newName = newData.name
        context.commit('rename', data)
        resolve(newData.name)
      }).catch(function (error) {
        reject(error)
      })
    })
  },
  delete (context, data) {
    context.getters.folderAssets(data.objectId).forEach(function (asset, context) {
      this.dispatch('deleteAsset', asset)
    }, context)

    context.getters.folderDocuments(data.objectId).forEach(function (document, context) {
      this.dispatch('deleteDocument', document)
    }, context)

    context.getters.folderFolders(data.objectId).forEach(function (folder, context) {
      this.dispatch('delete', {objectId: folder.id, type: 'folder'})
    }, context)

    let apiMethod

    if (data.type === 'document_template' || data.type === 'html_template') {
      apiMethod = 'deleteDocument'
    } else {
      apiMethod = _.camelCase(`delete_${data.type}`)
    }

    documentsService[apiMethod](data.objectId).then(function () {
      context.commit('delete', data)
    })
  },
  mapTemplateTree (context, folders) {
    let mappedState = {
      assets: {},
      documents: {},
      folders: {}
    }

    const mapFile = function (memo, file, parentId, type) {
      const newFile = addFileExtraProperties(file, parentId, type)
      memo[newFile.id] = newFile

      return memo
    }

    const normalize = function (memo, folder, parentId) {
      mapFile(memo.folders, folder, parentId, 'folder')

      for (let asset of folder.assets) {
        mapFile(memo.assets, asset, folder.id, 'asset')
      }

      for (let document of folder.documents) {
        mapFile(memo.documents, document, folder.id, document.type)
      }

      for (let form of folder.forms) {
        mapFile(memo.documents, form, folder.id, 'imported_document')
      }

      for (let g of folder.folders) {
        normalize(memo, g, folder.id)
      }

      return memo
    }
    orderFolders(folders)
    for (let folder of folders) {
      normalize(mappedState, folder, null)
    }

    context.commit('assets', mappedState.assets)
    context.commit('documents', mappedState.documents)
    context.commit('folders', mappedState.folders)
  },
  getNavigationTree (context) {
    const createFileNode = function (file) {
      return {
        id: file.id,
        label: file.name,
        type: file.type
      }
    }
    const createFolderNode = function (folder, getChildFiles, getChildFolders) {
      let folderNav = {
        id: folder.id,
        label: folder.name,
        type: folder.type,
        children: []
      }

      const files = getChildFiles(folder.id)
      const folders = getChildFolders(folder.id)

      folderNav.children.push(...files.map(f => createFileNode(f)))
      folderNav.children.push(...folders.map(
          g => createFolderNode(g, getChildFiles, getChildFolders)
        )
      )

      if (folderNav.children.length === 0) {
        folderNav.disabled = true
        folderNav.children.push({visible: false})
      }
      return folderNav
    }
    return context.getters.rootFolders.map(folder => {
      return createFolderNode(
        folder,
        context.getters.folderFiles,
        context.getters.folderFolders
      )
    })
  },
  addAsset (context, data) {
    const asset = addFileExtraProperties(data, data.folder, 'asset')
    context.commit('assets', Object.assign({}, context.state.assets, { [asset.id]: asset }))
  },
  updateAsset (context, data) {
    const asset = addFileExtraProperties(data, data.folder, 'asset')
    if (asset.id === undefined) {
      asset.id = context.state.asset.id
    }
    if (asset.folder === undefined) {
      asset.folder = context.state.asset.folder
    }
    documentsService.updateAsset(asset).then(function () {
      context.commit('updateAsset', asset)
    })
  },
  deleteAsset (context, asset) {
    const assetId = asset.id

    documentsService.deleteAsset(assetId).then(function () {
      context.commit('delete', {objectId: assetId, type: 'asset'})
    })
  },
  deleteDocument (context, document) {
    const documentId = document.id

    documentsService.deleteDocument(documentId).then(function () {
      context.commit('delete', {objectId: documentId, type: 'document_template'})
      context.commit('deleteTemplate', null)
    })
  },
  deleteImportedDocument (context, document) {
    const documentId = document.id
    return new Promise((resolve, reject) => {
      documentsService.deleteImportedDocument(documentId).then(function (data) {
        resolve(data)
      })
    })
  },
  deleteImportedDocumentEdition (context, editionId) {
    return documentsService.deleteImportedDocumentEdition(editionId)
  },
  copyObject (context, data) {
    return new Promise((resolve, reject) => {
      documentsService.copyObject(data.object, data.newFolder).then(response => {
        resolve(response)
      })
    })
  },
  createTemplate (context, data) {
    return new Promise((resolve, reject) => {
      const html = '<html></html>'

      const template = Object.assign(
        {},
        addFileExtraProperties(data, data.folder, 'document_template'),
        { html: html }
      )
      documentsService.createTemplate(template).then(function (newTemplate) {
        template.id = newTemplate.id
        context.commit('documents', Object.assign({}, context.state.documents, { [template.id]: template }))
        resolve(template)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  createComponentTemplate (context, data) {
    return new Promise((resolve, reject) => {
      const html = '<html></html>'

      const component = Object.assign(
        {},
        addFileExtraProperties(data, data.folder, 'component'),
        { html: html }
      )
      documentsService.createComponentTemplate(component).then(function (newComponent) {
        component.id = newComponent.id
        context.commit('documents', Object.assign({}, context.state.components, { [component.id]: component }))
        resolve(component)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  createFolder (context, data) {
    return new Promise((resolve, reject) => {
      const folder = Object.assign(
        {},
        addFileExtraProperties(data, data.parent, 'folder'),
        {folders: []}
      )

      documentsService.createFolder(folder.name, folder.parentId).then(newFolder => {
        folder.id = newFolder.id
        context.commit('folders', Object.assign({}, context.state.folders, { [folder.id]: folder }))
        resolve(folder)
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateFormField (context, data) {
    return new Promise((resolve, reject) => {
      documentsService.updateFormField(data).then(response => {
        resolve(response)
      })
    })
  },
  addImportedDocument (context, importedDocument) {
    const newImportedDocument = Object.assign(
      {},
      addFileExtraProperties(importedDocument, importedDocument.folder, 'imported_document')
    )

    context.commit(
      'documents',
      Object.assign({}, context.state.documents, { [newImportedDocument.id]: newImportedDocument })
    )
  },
  fetchAppSyncData (context, _) {
    return new Promise((resolve, reject) => {
      documentsService.fetchAppSyncData().then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  fetchHelperFunctionsData (context, _) {
    return new Promise((resolve, reject) => {
      documentsService.fetchHelperFunctionsData().then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  fetchSettings (context, _) {
    return new Promise((resolve, reject) => {
      documentsService.fetchSettings().then(response => {
        let settings = {}
        response.results.forEach(setting => { settings[setting.id] = setting })
        context.commit('settings', settings)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateSetting (context, setting) {
    return new Promise((resolve, reject) => {
      documentsService.updateSetting(setting.id, {value: setting.value}).then(response => {
        context.commit('settings', Object.assign({}, context.state.settings, { [setting.id]: setting }))
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  toggleTemplateIsValid (context, valid) {
    context.commit('templateIsValid', valid)
  },
  changeSearchType (context, type) {
    context.commit('searchType', type)
  },
  changePreviewData (context, {previewType, data}) {
    let parameterNames = []
    switch (previewType) {
      case 'claim':
        parameterNames = ['claim_id']
        context.commit('previewClaimData', data)
        break
      case 'quote':
        parameterNames = ['quote_number']
        context.commit('previewQuoteData', data)
        break
      case 'policy':
        parameterNames = ['policy_id', 'revision_id']
        context.commit('previewPolicyData', data)
        break
    }

    if (data) {
      _.forEach(parameterNames, function (parameterName) {
        let identifier = data.previewId
        if (parameterName === 'revision_id') {
          identifier = data.revisionId
        }
        documentsService.storePreviewData(identifier, parameterName).catch(() => {})
      })
    }
  },
  async getClaimsList (context, data) {
    let filter = {}
    if (data.query) {
      filter = {
        q: data.query
      }
    }
    const page = {
      number: 1,
      size: 10
    }
    const include = 'primary_insured'
    const {data: _data} = await api.claim.list({filter, page, include})
    return _data.map(claim => ({
      idNumber: claim.number,
      policyType: claim.policy_type ? claim.policy_type.label : '',
      namedInsured: claim.primary_insured ? claim.primary_insured.contact.name : '',
      previewId: claim.id
    }))
  },
  getQuotesList (context, data) {
    function getNamedInsureds (namedInsuredsData, namedInsureds) {
      const namedInsuredsNames = namedInsuredsData.map((namedInsured) => namedInsureds[namedInsured.id].attributes.name)
      return namedInsuredsNames.join(', ')
    }
    return new Promise((resolve, reject) => {
      quoteService.getQuotes(null, 10, 0, { 'quote_number.icontains': data.query }).then(response => {
        const namedInsureds = response.data.included.reduce((memo, insured) => ({...memo, [insured.id]: insured}), {})
        const previewItems = response.data.data.map((quote) => ({
          idNumber: quote.attributes.quote_number,
          policyType: quote.attributes.product_label,
          namedInsured: getNamedInsureds(quote.relationships.named_insureds.data, namedInsureds),
          previewId: quote.attributes.quote_number
        }))
        resolve(previewItems)
      }).catch(error => {
        reject(error)
      })
    })
  },
  async getPoliciesList (context, data) {
    const policyId = null
    const page = 1
    const searchString = data.query
    const pageSize = 10
    const result = await policyService.getSearchedLinkablePolicies(policyId, page, searchString, pageSize)
    const previewItems = result.data.records.map((policy) => ({
      idNumber: policy.policyId,
      policyType: policy.policyType,
      namedInsured: policy.namedInsured,
      previewId: policy.dbPolicyId
    }))
    return previewItems
  },
  getVersionPreview (context, data) {
    return new Promise((resolve, reject) => {
      documentsService.documentPreview(data.templateId, data.name, data.versionId, data.templateType)
        .then(response => {
          if (response.status === 202) {
            resolve({async: true, contents: response.data.data.preview_id})
          } else {
            resolve({async: false, contents: response.data.data})
          }
        }).catch(error => {
          reject(error)
        })
    })
  },
  fetchPreview (context, id) {
    return new Promise((resolve, reject) => {
      documentsService.fetchPreview(id).then(response => {
        if (response.status === 200) {
          resolve({preview: response.data.data})
        } else {
          resolve({preview: null})
        }
      }).catch(function (error) {
        reject({preview: null, error: error.data.data})
      })
    })
  },
  updateDocumentVersions (context, document) {
    let stateMutation = 'importedDocument'
    if (document.type === 'document_template' || document.type === 'html_template') {
      stateMutation = 'template'
    }
    return new Promise(resolve => {
      _getDocumentVersions(context, document).then((versions) => {
        document.versions = versions
        context.commit(stateMutation, document)
        resolve()
      })
    })
  },
  async getRevisionsList (context, data) {
    const policyId = data.previewId
    return policyService.getRevisions(policyId, '', '')
  },
  async getInvoicesList (context, data) {
    const policyId = data.previewId
    return policyService.getInvoices(policyId, 'desc')
  }
}
