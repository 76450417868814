import RulesService from '@/api/rules'
import Poller from '@/utilities/Poller'
import noop from 'lodash/noop'

const STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  NEW: 'NEW',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED'
}

const RESULT_STATUS = {
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS'
}

const INITIAL_JOB_STATE = {
  id: null,
  status: null,
  resultStatus: null,
  result: null,
  progress: '0.00'
}

let JobRunner = () => {
  let instance = {
    isReady: true,
    isRunning: false,
    isFinished: false,
    isCancelled: false,
    poller: null,
    serviceError: false,
    job: {
      ...INITIAL_JOB_STATE
    },
    timeoutError: false,
    errorFn: noop,
    finishedFn: noop,
    async run (serviceName, serviceParams) {
      instance.timeoutError = false
      instance.serviceError = false
      instance.isRunning = true
      instance.isReady = false
      instance.isFinished = false
      instance.isCancelled = false
      instance.job = {
        ...INITIAL_JOB_STATE
      }
      try {
        let jobData
        if (serviceParams) {
          jobData = await RulesService[serviceName](...serviceParams)
        } else {
          jobData = await RulesService[serviceName]()
        }
        if (!instance.isCancelled && instance.isRunning) {
          instance.setJobData(jobData)
          instance.startPolling()
        }
      } catch (error) {
        instance.setServiceError(error)
      }
    },
    startPolling () {
      instance.stopPolling()
      instance.poller = new Poller()
        .onCheck(instance.onCheck)
        .isFinished(instance.isPollingFinished)
        .onEachUpdate(instance.setJobData)
        .onTimeout(instance.setTimeoutError)
        .onFinished(instance.setFinished)
        .onError(instance.setServiceError)
    },
    onCheck () {
      return RulesService.getJob(instance.job.id)
    },
    isPollingFinished (jobData) {
      return jobData.status !== STATUS.IN_PROGRESS && jobData.status !== STATUS.NEW
    },
    setFinished (data) {
      instance.isRunning = false
      instance.isReady = true
      instance.isFinished = true
      instance.finishedFn(data)
    },
    stopPolling () {
      if (instance.poller) {
        instance.poller.stop()
      }
    },
    setJobData (jobData) {
      instance.job = { ...jobData }
    },
    setTimeoutError () {
      instance.isReady = true
      instance.timeoutError = true
      instance.isRunning = false
    },
    setServiceError (error) {
      instance.isReady = true
      instance.isRunning = false
      instance.serviceError = true
      instance.errorFn(error)
    },
    tryAgain () {
      instance.isRunning = true
      instance.timeoutError = false
      if (instance.poller) {
        instance.poller.start()
      }
    },
    cancel () {
      if (instance.isRunning) {
        // TODO: await RulesService.cancelJob(instance.job.id)
        instance.isCancelled = true
        instance.stop()
      }
    },
    stop () {
      if (instance.isRunning) {
        instance.isRunning = false
        instance.timeoutError = false
        instance.serviceError = false
        instance.stopPolling()
      }
    },
    onFinished (fn) {
      instance.finishedFn = fn
    },
    onError (fn) {
      instance.errorFn = fn
    }
  }

  return instance
}

JobRunner.status = STATUS
JobRunner.resultStatus = RESULT_STATUS

export default JobRunner
