<template>
  <div>
    <div
      class="c-nav-sidebar__header-wrapper"
      @click="collapsed = !collapsed && displayIcon"
      :style="!displayIcon ? { 'cursor': 'default' } : {}">
      <h3 class="c-nav-sidebar__header">{{ title }}</h3>
      <fa-icon
        icon="caret-down"
        class="c-nav-sidebar__collapse-icon"
        :class="{
          'c-nav-sidebar__collapse-icon--rotated': collapsed
        }"
        v-if="displayIcon"
      />
    </div>
    <el-collapse-transition>
      <div v-show="!collapsed">
        <div class="u-mt3">
          <slot />
        </div>
      </div>
    </el-collapse-transition>

    <hr class="c-nav-sidebar__divider">
  </div>
</template>

<script>
export default {
  name: 'NavigationSection',
  props: {
    title: {
      type: String,
      required: true
    },
    displayIcon: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      collapsed: false
    }
  }
}
</script>
