export const getters = {
  revisionContacts: (state) => (revisionId) => {
    return state.revisionContacts[revisionId] || []
  },
  additionalInterests: (state, getters) => (revisionId) => {
    return getters.revisionContacts(revisionId)
      .filter(c => c.addtlInterest && state.contacts[c.contactId])
      .map(c => ({ interest: c.interest, contact: state.contacts[c.contactId] }))
  },
  namedInsureds: (state, getters) => (revisionId) => {
    return getters.revisionContacts(revisionId)
      .filter(c => c.namedinsured && state.contacts[c.contactId])
      .map(c => state.contacts[c.contactId])
  },
  agent: (state, getters) => (revisionId) => {
    return getters.revisionContacts(revisionId).filter(
      c => c.agent && state.contacts[c.contactId] && state.contacts[c.contactId].type === 'individual'
    ).map(c => state.contacts[c.contactId])[0]
  },
  agency: (state, getters) => (revisionId) => {
    return getters.revisionContacts(revisionId).filter(
      c => c.agent && state.contacts[c.contactId] && state.contacts[c.contactId].type === 'organization'
    ).map(c => state.contacts[c.contactId])[0]
  }
}
