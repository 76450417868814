
export const getters = {
  existingVersion: (state) => (version) => {
    if (version) {
      return state.template.versions.find(v => { return v.name === version.name })
    }
  },
  documentsSet (state) {
    return state.documentIds.map(documentId => state.documents[documentId])
  },
  assetsSet (state) {
    return state.assetIds.map(assetId => state.assets[assetId])
  },
  foldersSet (state) {
    return state.folderIds.map(folderId => state.folders[folderId])
  },
  rootFolders (state, getters) {
    return getters.foldersSet.filter(folder => folder.parentId === null)
  },
  settingsSet (state) {
    return state.settingIds.map(settingId => state.settings[settingId])
  },
  allSet (state, getters) {
    return [...getters.documentsSet, ...getters.assetsSet, ...getters.foldersSet]
  },
  folderAssets (state, getters) {
    return parentId => getters.assetsSet.filter(asset => {
      return asset.parentId === parentId
    })
  },
  folderDocuments (state, getters) {
    return parentId => getters.documentsSet.filter(document => {
      return document.parentId === parentId
    })
  },
  folderFiles (state, getters) {
    return parentId => [...getters.folderAssets(parentId), ...getters.folderDocuments(parentId)]
  },
  folderFolders (state, getters) {
    return parentId => getters.allSet.filter(o => {
      return o.parentId === parentId && o.hasOwnProperty('folders')
    })
  },
  folderByName (state, getters) {
    return folderName => getters.foldersSet.find(folder => {
      return folder.name === folderName
    })
  },
  documentById (state, getters) {
    return documentId => getters.documentsSet.find(document => {
      return document.id === documentId
    })
  },
  documentByName (state, getters) {
    return documentName => getters.documentsSet.find(document => {
      return document.name === documentName
    })
  },
  documentVersions: state => state.template.versions,
  documentVersionById (state, getters) {
    return versionId => getters.documentVersions.find(version => {
      return version.id === versionId
    })
  },
  documentFirstVersion (state, getters) {
    return getters.documentVersions[0]
  },
  assetByName (state, getters) {
    return assetName => getters.assetsSet.find(asset => {
      return asset.name === assetName
    })
  },
  settingByName (state, getters) {
    return settingName => getters.settingsSet.find(setting => {
      return setting.name === settingName
    })
  },
  fileById (state, getters) {
    return fileId => getters.allSet.find(file => {
      return file.id === fileId
    })
  }
}
