import moment from 'moment'
import policyService from '@/api/policy'

let utils = {
  parseDate (inputDate) {
    if (moment(inputDate, ['YYYY-MM-DD', moment.ISO_8601], true).isValid()) {
      return moment(inputDate, 'YYYY-MM-DD').toDate()
    } else {
      return moment(inputDate, 'MM/DD/YYYY').toDate()
    }
  },
  policyUrl (policyId, isBritePolicies) {
    if (isBritePolicies) {
      return `/policies/policy/${policyId}`
    } else {
      return `/britecore/policies/information?policyId=${policyId}`
    }
  },
  async declarationURL (policyId, revisionId, isAgent) {
    const basePath = isAgent ? 'agent' : 'britecore'
    const baseAttachmentsPage = `/${basePath}/policies/ko_attachments?policyId=${policyId}#policies/${policyId}`
    let decURL

    try {
      const res = await policyService.getDecFileId(revisionId)
      const { fileId, isDir } = res.data.data
      if (isDir) {
        decURL = `${baseAttachmentsPage}/${fileId}`
      } else {
        decURL = `/gateway?file=core.uploads&method=getAttachment&id=${fileId}`
      }
    } catch (ex) {
      decURL = baseAttachmentsPage
    }

    return decURL
  }
}

export default utils
