import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import mockPoliciesMaintenanceStore from '@/modules/claims/store/modules/admin-setup/mock-policies-maintenance'

const adminSetupStore = {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
  modules: {
    mockPoliciesMaintenance: mockPoliciesMaintenanceStore
  }
}

export default adminSetupStore
