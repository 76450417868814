<template>
  <div class="c-nav-top">
    <get-help-modal
      @submit-support-ticket-clicked="submitSupportTicket"
      v-model="getHelpModal.visible">
    </get-help-modal>

    <submit-support-ticket-modal v-model="submitSupportTicketModal.visible"></submit-support-ticket-modal>

    <!-- a custom event handler uses this button's class name (main-sidebar-toggler)  -->
    <el-button
      type="text"
      class="main-sidebar-toggler u-mr3 u-hidden-lg u-p0 u-mt1"
      @click="toggleSidebar">
      <div
        class="c-nav-sidebar-toggler"
        :class="{ 'c-nav-sidebar-toggler--opened': sidebarVisible }">
        <div class="c-nav-sidebar-toggler__bar"></div>
        <div class="c-nav-sidebar-toggler__bar"></div>
        <div class="c-nav-sidebar-toggler__bar"></div>
      </div>
    </el-button>

    <div class="l-flex-grid">
        <a
          v-if="carrier.website"
          class="c-nav-top__client-link"
          :href="carrier.website"
          target="_blank"
          rel="noopener noreferrer">
          <div class="c-nav-top__client-logo">
            <img
              src="../../../assets/icons/company-icon.svg"
              class="u-mx-auto u-mt-auto u-mb-auto"/>
          </div>
        </a>
        <div
          v-else
          class="c-nav-top__client-logo">
          <img
            src="../../../assets/icons/company-icon.svg"
            class="u-mx-auto u-mt-auto u-mb-auto"/>
        </div>
        <h2 class="c-nav-top__client-name">
          <a
            v-if="carrier.website"
            class="c-nav-top__client-link"
            :href="carrier.website"
            target="_blank"
            rel="noopener noreferrer">
            {{ carrier.name }}
          </a>
          <template v-else>{{ carrier.name }}</template>
        </h2>

      <div
        v-if="prNumber"
        class="l-flex-grid l-flex-grid--v-center u-ml2">
        <el-tag
          type="info"
          size="small"
          class="u-text--sm">
            PR {{ prNumber }}
        </el-tag>
      </div>
    </div>

    <div class="l-flex-grid u-ml-auto">
      <button
        type="button"
        class="btn c-nav-top__help-button"
        @click="getHelpModal.visible = true">
          <fa-icon
            class="c-nav-top__help-button-icon"
            size="lg"
            icon="question-circle"></fa-icon>
          <span class="c-nav-top__help-button-text">Help</span>
      </button>

      <el-dropdown
        class="u-ml3"
        :hide-on-click="false"
        trigger="click"
        @command="toggleNestedMenu"
        @visible-change="hideNestedMenu">

        <div class="l-flex-grid l-flex-grid--v-center u-height100 u-cursor--pointer">
          <div class="l-flex-grid l-flex-grid--v-center">
            <div
              class="c-nav-top__user-icon"
              :style="profilePictureUrl ? { backgroundImage: `url(${profilePictureUrl})` } : {}">
              <fa-icon
                v-if="!profilePictureUrl"
                icon="bc-contacts"></fa-icon>
            </div>
            <!-- Hidden by default -- this can be shown per client by setting the $topnav-show-name scss variable to true -->
            <span class="c-nav-top__user-name">{{ briteauthUserAttributes.name }}</span>
          </div>
          <fa-icon
            icon="caret-down"
            class="u-ml1 u-text--light-gray">
          </fa-icon>
        </div>

        <el-dropdown-menu
          ref="userDropdownMenu"
          class="c-nav-top__dropdown-menu"
          slot="dropdown">

          <!-- resources options start -->
          <div class="u-pr2"
            v-if="showResources">
            <el-dropdown-item>
              <div @click="toggleNestedMenu()">
                <fa-icon
                  class="u-mt-auto u-mb-auto u-mr2"
                  icon="chevron-left"></fa-icon>
                <span>Back</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item class="c-nav-top__dropdown-item"
              divided>
              <a href="https://help.britecore.com"
                target="_blank"
                rel="noopener noreferrer">
                <fa-icon
                  icon="question-circle"
                  size="xs"
                  class="u-mr2 u-text--lg"
                  fixed-width>
                </fa-icon>BriteCore Help Site
              </a>
            </el-dropdown-item>
            <el-dropdown-item class="c-nav-top__dropdown-item">
              <a href="http://community.britecore.com"
                target="_blank"
                rel="noopener noreferrer">
                <fa-icon
                  icon="comments"
                  size="xs"
                  class="u-mr2 u-text--lg"
                  fixed-width>
                </fa-icon>Community Forum
              </a>
            </el-dropdown-item>
            <el-dropdown-item
              v-if="shouldShowFeedbackLink"
              class="c-nav-top__dropdown-item">
              <a
                href="https://britecore.optimalworkshop.com/questions/britecorelabs"
                target="_blank"
                rel="noopener noreferrer">
                <fa-icon
                  icon="flask"
                  size="xs"
                  class="u-mr2 u-text--lg"
                  fixed-width>
                </fa-icon>Join BriteCore Labs
              </a>
            </el-dropdown-item>
            <el-dropdown-item
              class="c-nav-top__dropdown-item"
              v-if="!gen2Disabled">
              <a href="/britecore/misc/support_tools">
                <fa-icon
                  icon="wrench"
                  size="xs"
                  class="u-mr2 u-text--lg"
                  fixed-width>
                </fa-icon>Support Tools
              </a>
            </el-dropdown-item>
            <el-dropdown-item class="c-nav-top__dropdown-item">
              <a href="https://britecore.zendesk.com"
                target="_blank"
                rel="noopener noreferrer">
                <fa-icon
                  icon="bug"
                  size="xs"
                  class="u-mr2 u-text--lg"
                  fixed-width>
                </fa-icon>View Outstanding Tickets
              </a>
            </el-dropdown-item>
            <el-dropdown-item
              class="c-nav-top__dropdown-item"
              v-if="!gen2Disabled">
              <a href="/britecore/misc/smore_setup">
                <fa-icon
                  icon="tools"
                  size="xs"
                  class="u-mr2 u-text--lg"
                  fixed-width>
                </fa-icon>Setup for Demo
              </a>
            </el-dropdown-item>
          </div>
          <!-- resources options end -->

          <!-- select role options start -->
          <div class="u-pl2"
          v-else-if="showSelectRole">
            <el-dropdown-item>
              <div @click="toggleNestedMenu()">
                <fa-icon
                  class="u-mt-auto u-mb-auto u-mr2"
                  icon="chevron-left"></fa-icon>
                <span>Back</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item divided
            class="c-nav-top__dropdown-item">
              <access-role-select forceReload>
                <template slot-scope="{ userRoles, selectedRole, roleSelected, loading }">
                  <access-role-switcher :userRoles="userRoles"
                  :selectedRole="selectedRole"
                  @role-selected="roleSelected"
                  :loading="loading"/>
                </template>
              </access-role-select>
            </el-dropdown-item>
          </div>
          <!-- select role options end -->

          <!-- default/initial options start -->
          <div v-else>
            <el-dropdown-item disabled>
              <strong class="u-block u-mb1">{{ briteauthUserAttributes.name }}</strong>
              <small class="u-block">{{ briteauthUserAttributes.email }}</small>
            </el-dropdown-item>
            <el-dropdown-item
            v-if="shouldDisplayRoleSwitcher"
            :command="'selectRole'">
              <div class="l-flex-grid l-flex-grid--h-space-between u-width100">
                <span class="u-mt-auto u-mb-auto"
                v-if="selectedRole">
                  {{selectedRole}}
                </span>
                <span class="u-mt-auto u-mb-auto u-text--warning"
                v-else>
                  ⚠️ Please select a role
                </span>
                <fa-icon
                  class="u-mt-auto u-mb-auto u-mr1"
                  icon="chevron-right"></fa-icon>
              </div>
            </el-dropdown-item>
            <el-dropdown-item
              class="c-nav-top__dropdown-item"
              divided>
              <router-link :to="{ name: 'my-account' }">
                <fa-icon
                  icon="user"
                  size="xs"
                  class="u-mr2 u-text--lg"
                  fixed-width></fa-icon>My Account
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item class="c-nav-top__dropdown-item">
              <a href="#"
                 @click="signOut">
                <fa-icon
                  icon="sign-out"
                  class="u-mr2 u-text--lg"
                  size="xs"
                  fixed-width></fa-icon>Log out
              </a>
            </el-dropdown-item>
            <el-dropdown-item
              v-if="!isAgentOrAgency && !gen2Disabled"
              :command="'resources'"
              divided>
              <div class="l-flex-grid l-flex-grid--h-space-between u-width100">
                <span class="u-mt-auto u-mb-auto">
                  Resources
                </span>
                <fa-icon
                  class="u-mt-auto u-mb-auto u-mr1"
                  icon="chevron-right"></fa-icon>
              </div>
            </el-dropdown-item>
          </div>
          <!-- default/initial options end -->

        </el-dropdown-menu>
      </el-dropdown>

    </div>
  </div>
</template>

<script>
import { get } from 'lodash'
import { mapActions, mapState, mapGetters } from 'vuex'
import GetHelpModal from './GetHelpModal'
import SubmitSupportTicketModal from './SubmitSupportTicketModal'
import { ProfileUpdateMixin } from '@/components/users/mixins'
import AccessRoleSelect from '@/pages/access/AccessRolesSelect'
import AccessRoleSwitcher from '@/components/access/AccessRoleSwitcher'
import UserManagementService from '@/api/userManagement'

const PROFILE_PICTURE_LOCAL_STORAGE_KEY = 'briteauth:profile-picture-url'

export default {
  components: {
    GetHelpModal,
    SubmitSupportTicketModal,
    AccessRoleSelect,
    AccessRoleSwitcher
  },
  mixins: [ProfileUpdateMixin],
  data () {
    return {
      gen2Disabled: process.env.VUE_APP_GEN2_DISABLED === 'true',

      prNumber: process.env.VUE_APP_IS_QA,

      getHelpModal: {
        visible: false
      },
      submitSupportTicketModal: {
        visible: false
      },
      phoneChangedWhenSmsMfaEnabledPrompt: '',
      isUserEditMode: false,
      smsMfaEnabled: false,
      showResources: false,
      showSelectRole: false,

      shouldShowFeedbackLink: true
    }
  },
  computed: {
    ...mapState('bcClassic', ['carrier']),
    ...mapState(['sidebarVisible']),
    ...mapState('access', ['effectiveRole']),
    ...mapGetters('authentication', ['isAgentOrAgency']),
    selectedRole () {
      return this.effectiveRole || localStorage.getItem('EffectiveRole')
    },
    profilePictureUrl () {
      return get(this.briteauthUserAttributes, 'picture') || localStorage.getItem(PROFILE_PICTURE_LOCAL_STORAGE_KEY) || ''
    },
    shouldDisplayRoleSwitcher () {
      return process.env.VUE_APP_ENABLED_MODULE_ACCESS
    }
  },
  methods: {
    ...mapActions('authentication', [
      'signOut'
    ]),
    refreshUserProfile: async function () {
      await this.$store.dispatch('authentication/refreshAWSCredentials')

      try {
        const briteauthUser = await UserManagementService.getOwnUser()
        this.briteauthUserAttributes = briteauthUser.data.attributes
      } catch (e) {
        console.error(e)
        this.$message.error('Error while retrieving user\'s attributes')
      }
    },
    updateUserProfile () {
      return
    },
    submitSupportTicket () {
      this.getHelpModal.visible = false
      this.submitSupportTicketModal.visible = true
    },
    async toggleNestedMenu (menu) {
      switch (menu) {
        case 'resources':
          this.showResources = true
          break
        case 'selectRole':
          this.showSelectRole = true
          break
        default:
          this.showResources = this.showSelectRole = false
      }
      // widths could change when switching to different 'menus' which would cause a horizontal overflow.
      // this re-positions the popper menu back inside the viewport
      await this.$nextTick()
      this.$refs.userDropdownMenu.updatePopper()
    },
    hideNestedMenu () {
      if (!this.showResources && !this.showSelectRole) { return }
      const timeout = setTimeout(() => {
        this.showResources = this.showSelectRole = false
        clearTimeout(timeout)
      }, 500)
    },
    toggleSidebar () {
      this.$store.commit('toggleSidebar', this.sidebarVisible ? 'hide' : 'show')
    }
  },
  created () {
    this.$store.dispatch('bcClassic/retrieveCarrierInfo')

    this.$store.dispatch('bcClassic/retrieveSettingsBySectionAndOption', {
      section: 'misc',
      option: 'enable_feedback_link'
    }).then(res => {
      this.shouldShowFeedbackLink = !!get(res, 'data.data', true)
    }).catch(() => {})
  },
  watch: {
    profilePictureUrl (val) {
      localStorage.setItem(PROFILE_PICTURE_LOCAL_STORAGE_KEY, val)
    }
  }
}
</script>
