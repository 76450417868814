import RulesRoutesService from './RulesRoutesService'

const Packages = () => import('@/pages/rules/packages/Packages')
const Package = () => import('@/pages/rules/packages/Package')
const PackageTag = () => import('@/pages/rules/packages/PackageTag')
const Projects = () => import('@/pages/rules/projects/Projects')
const Project = () => import('@/pages/rules/projects/Project')
const ProjectEffectiveDates = () => import('@/pages/rules/project-effectivedates/ProjectEffectiveDates')
const ComponentEditor = () => import('@/pages/rules/component-editor/ComponentEditor')
const CreateComponent = () => import('@/pages/rules/component-editor/CreateComponent')
const CloneComponent = () => import('@/pages/rules/component-editor/CloneComponent')
const NewProject = () => import('@/pages/rules/projects/NewProject')
import { COMPONENT_TYPES } from './constants'

export default [
  {
    path: '',
    name: 'rules',
    redirect: { name: 'rules:projects' }
  },
  {
    path: 'projects',
    name: 'rules:projects',
    component: Projects
  },
  {
    path: 'projects/:projectId',
    name: 'rules:project',
    component: Project,
    props: (route) => ({
      projectId: route.params.projectId,
      effectiveDateId: '',
      path: RulesRoutesService.path2Array(route.query.path),
      componentType: route.query.componentType
    }),
    meta: {
      effectiveDateSibling: 'rules:project:effectiveDate'
    }
  },
  {
    path: 'projects/:projectId/effectiveDates/:effectiveDateId',
    name: 'rules:project:effectiveDate',
    component: Project,
    props: (route) => ({
      projectId: route.params.projectId,
      effectiveDateId: route.params.effectiveDateId,
      path: RulesRoutesService.path2Array(route.query.path),
      componentType: route.query.componentType
    })
  },
  {
    path: 'projects/:projectId/effective-dates',
    name: 'rules:project:effectivedates',
    component: ProjectEffectiveDates,
    props: (route) => ({
      projectId: route.params.projectId
    })
  },
  {
    path: 'newproject',
    name: 'rules:projects:newproject',
    component: NewProject
  },
  {
    path: 'component/:componentId',
    name: 'rules:componentEditor',
    component: ComponentEditor,
    props: true
  },
  {
    path: 'projects/:projectId/effectiveDates/:effectiveDateId/component/:componentId',
    name: 'rules:project:componentEditor',
    component: ComponentEditor,
    props: (route) => {
      return {
        projectId: route.params.projectId,
        componentId: route.params.componentId,
        effectiveDateId: route.params.effectiveDateId
      }
    }
  },
  {
    path: 'packages/:packageUuid/effectiveDates/:effectiveDateId/component/:componentId',
    name: 'rules:package:effectiveDate:componentEditor',
    component: ComponentEditor,
    props: (route) => {
      return {
        packageUuid: route.params.packageUuid,
        componentId: route.params.componentId,
        effectiveDateId: route.params.effectiveDateId
      }
    }
  },
  {
    path: 'packages/:packageUuid/component/:componentId',
    name: 'rules:package:componentEditor',
    component: ComponentEditor,
    props: (route) => {
      return {
        packageUuid: route.params.packageUuid,
        componentId: route.params.componentId
      }
    }
  },
  {
    path: 'createcomponent',
    name: 'rules:createcomponentdefault',
    redirect: 'createcomponent/rule'
  },
  {
    path: 'createcomponent/:componentType',
    name: 'rules:createcomponent',
    component: CreateComponent,
    beforeEnter (to, from, next) {
      if ([
        COMPONENT_TYPES.COMPONENT,
        COMPONENT_TYPES.MODEL,
        COMPONENT_TYPES.RULE,
        COMPONENT_TYPES.TEMPLATE,
        COMPONENT_TYPES.TEST,
        COMPONENT_TYPES.TOOL,
        COMPONENT_TYPES.VALUES_SET
      ].indexOf(to.params.componentType) === -1) {
        next({ name: 'rules:createcomponentdefault', query: to.query })
      } else {
        next()
      }
    },
    props: (route) => {
      return {
        componentType: route.params.componentType,
        packageUuid: route.query.packageUuid,
        projectId: route.query.projectId,
        folder: RulesRoutesService.path2Array(route.query.folder),
        effectiveDateId: route.query.effectiveDateId
      }
    }
  },
  {
    path: 'clonecomponent',
    name: 'rules:clonecomponent',
    component: CloneComponent,
    props: (route) => {
      return {
        componentId: route.query.componentId,
        packageUuid: route.query.packageUuid,
        projectId: route.query.projectId,
        folder: RulesRoutesService.path2Array(route.query.folder),
        effectiveDateId: route.query.effectiveDateId
      }
    }
  },
  {
    path: 'packages',
    name: 'rules:packages',
    component: Packages
  },
  {
    path: 'packages/:packageUuid',
    name: 'rules:package',
    component: Package,
    props: (route) => ({
      packageUuid: route.params.packageUuid,
      path: RulesRoutesService.path2Array(route.query.path),
      activeTab: route.query.activeTab,
      componentType: route.query.componentType
    })
  },
  {
    path: 'packages/:packageUuid/packageTags/:tagId',
    name: 'rules:packageTag',
    component: PackageTag,
    props: (route) => ({
      packageUuid: route.params.packageUuid,
      tagId: route.params.tagId,
      path: RulesRoutesService.path2Array(route.query.path)
    })
  }
]
