import axios from 'axios/index'

/**
 * BaseService is used as a base class for other services.
 */
class BaseService {
  /**
   * Constructor.
   * @param {String} url the url that gets appended to the api url.
   *  e.x `claims`, if no url is provided the service will use only process.env.VUE_APP_API_URL
   */
  constructor (url) {
    if (url !== '') {
      this.baseUrl = `${process.env.VUE_APP_API_URL}/${url}`
    } else {
      this.baseUrl = process.env.VUE_APP_API_URL
    }
  }

  // ----------------------------------
  // Some common api calls.
  // ----------------------------------

  /**
   * Create sends a post request to the route with the data provided.
   * @param {Object} data the data that is sent as a json payload.
   */
  create (data) {
    return this.post('', data)
  }

  /**
   * GetAll sends a get request to the route.
   * @param {Object} config the axios configurations, we use this to send parameters headers etc.
   * @param {String} url the specific entity url for the requests, like `policies` in `/access/policies`.
   */
  getAll (config = null, url = '') {
    return this.get(url, config)
  }

  /**
   * GetById sends a get request to the route with the ID appended.
   * @param {String} id the id of the resource you want to get.
   */
  getByID (id) {
    return this.get(`${id}/`)
  }

  /**
   * Destroy sends a delete request to the route with the ID appended.
   * @param {String} id the id of the resource you want to delete.
   */
  destroy (id) {
    return this.delete(`${id}/`)
  }

  /**
   * Update sends a put request to the route with the ID appended and the data as a json payload.
   * @param {String} id the id of the resource you want to update.
   * @param {Object} updatedData the updated data of the resource.
   */
  update (id, updatedData) {
    return this.put(`${id}/`, updatedData)
  }

  /**
   * PartialUpdate sends a patch request to the route with the ID appended and the data as a json payload.
   * @param {String} id             the id of the resource you want to update.
   * @param {Object} updatedData    the updated data of the resource.
   */
  partialUpdate (id, updatedData) {
    return this.patch(`${id}/`, updatedData)
  }

  // ----------------------------------
  // Basic request
  // ----------------------------------

  /**
   * Get wraps an axios get request and returns the promise.
   * @param {String} url             the url without the base route, the base will be appended afterwards
   *  e.x if you have created the service with `/claims` and you want to get `/claims/something` you only sent
   *  `something` as the url parameter not `/claims/something`.
   * @param {Object} config           the axios configuration object.
   * @returns {Promise<any>}          the get promise.
   */
  get (url, config = null) {
    return new Promise((resolve, reject) => {
      axios.get(`${this.baseUrl}/${url}`, config).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error)
      })
    })
  }

  /**
   * Post wraps an axios post request and returns the promise.
   * @param {String} url              the url without the base route.
   * @param {Object} data             the data that will be sent to the endpoint.
   * @param {Object} config           the axios configuration object.
   * @returns {Promise<any>}          the post promise.
   */
  post (url, data, config = null) {
    return new Promise((resolve, reject) => {
      axios.post(`${this.baseUrl}/${url}`, data, config).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error)
      })
    })
  }

  /**
   * Delete wraps an axios delete request and returns the promise.
   * @param {String} url              the url without the base route.
   * @param {Object} config           the axios configuration object.
   * @returns {Promise<any>}          the delete promise.
   */
  delete (url, config = null) {
    return new Promise((resolve, reject) => {
      axios.delete(`${this.baseUrl}/${url}`, config).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error)
      })
    })
  }

  /**
   * Patch wraps an axios patch request and returns the promise.
   * @param {String} url              the url without the base route.
   * @param {Object} data             the data that will be sent to the endpoint.
   * @param {Object} config           the axios configuration object.
   * @returns {Promise<any>}          the patch promise.
   */
  patch (url, data, config = null) {
    return new Promise((resolve, reject) => {
      axios.patch(`${this.baseUrl}/${url}`, data, config).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error)
      })
    })
  }

  /**
   * Put wraps an axios put request and returns the promise.
   * @param {String} url              the url without the base route.
   * @param {Object} data             the data that will be sent to the endpoint.
   * @param {Object} config           the axios configuration object.
   * @returns {Promise<any>}          the put promise.
   */
  put (url, data, config = null) {
    return new Promise((resolve, reject) => {
      axios.put(`${this.baseUrl}/${url}`, data, config).then(response => {
        resolve(response.data)
      }).catch(function (error) {
        reject(error)
      })
    })
  }
}

export default BaseService
