export default {
  searchPolicies (state) {
    return {
      placeholder: 'Search Policy',
      defaultOption: state.searchPolicy.defaultSearchType,
      options: state.searchPolicy.searchTypeOptions,
      config: {size: 'xl'}
    }
  }
}
