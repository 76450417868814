export const getters = {
  scope (state) {
    return state.scope
  },
  quoteFlowSteps (state) {
    return state.quoteFlowSteps || []
  },
  contextVariables (state) {
    return state.variables || {}
  },
  transactionTypes (state) {
    return state.transactionTypes || []
  }
}
