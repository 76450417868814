import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'

const productExportsModule = {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations
}

export default productExportsModule
