<template>
  <el-button
    :type="type"
    :loading="loading"
    :class="styleClass"
    :disabled="disabled"
    @click="handleDelete">
    <slot>{{ actionVerb }} {{ label }}</slot>
  </el-button>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'DeleteButton',
  props: {
    label: {
      type: String
    },
    data: {
      type: Object
    },
    type: {
      type: String,
      default: 'danger'
    },
    // Capitalized action verb.
    actionVerb: {
      type: String,
      default: 'Delete'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    styleClass () {
      if (this.type === 'text') {
        // set text as red when this is a textual button
        return 'u-text--danger'
      }
      return ''
    }
  },
  methods: {
    handleDelete () {
      this.$confirm(
        `Are you sure you want to ${this.actionVerb.toLowerCase()} this ${this.label}?`,
        `${this.actionVerb} ${this.label}`,
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      ).then(() => {
        this.doDelete()
      }).catch(() => {
        // Do nothing for now
      })
    },
    doDelete () {
      if (this.data && this.data.__typename) {
        this.doGraphQLDelete()
      } else {
        this.$emit('deleted', this.label)
      }
    },
    doGraphQLDelete () {
      let deleteMutation = gql`
        mutation {
          delete${this.data.__typename}(id: "${this.data.id}") {
            deleted
          }
        }
      `

      let component = this
      this.$apollo.mutate({
        mutation: deleteMutation,
        update (store) {
          component.$emit('deleted', component.data, store)
        }
      })
    }
  }
}
</script>
