import * as actions from './actions'
import * as mutations from './mutations'
import * as getters from './getters'
import state from './state'

import contactsModule from './contacts'

const bcClassic = {
  namespaced: true,
  modules: {
    contacts: contactsModule
  },
  actions,
  state,
  getters,
  mutations
}

export default bcClassic
