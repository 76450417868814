import {addNamespace} from '@/store/helpers'
import {MOCK_POLICIES_MAINTENANCE_PATH} from '../../constants'

export const _mockPoliciesMaintenanceActions = {
  GET_POLICIES: 'getPolicies',
  SAVE_POLICY: 'savePolicy',
  GET_POLICY_BY_ID: 'getPolicyById',
  GET_POLICY_TYPES: 'getPolicyTypes',
  GET_INDIVIDUAL_CONTACTS: 'getIndividualContacts',
  GET_AGENCIES: 'getAgencies',
  GET_AGENTS: 'getAgents',
  GET_POLICY_STATUSES: 'getPolicyStatuses',
  GET_DOCUMENT_TYPES: 'getDocumentTypes',
  SAVE_POLICY_TYPE: 'savePolicyType',
  DELETE_POLICY_TYPE: 'deletePolicyType',
  GET_POLICY_TYPE_BY_ID: 'getPolicyTypeById',
  SAVE_POLICY_STATUS: 'savePolicyStatus',
  DELETE_POLICY_STATUS: 'deletePolicyStatus',
  GET_POLICY_STATUS_BY_ID: 'getPolicyStatusById'
}

export const MOCK_POLICIES_MAINTENANCE_ACTIONS = addNamespace(
  MOCK_POLICIES_MAINTENANCE_PATH,
  _mockPoliciesMaintenanceActions
)
