import Vue from 'vue'

const eventBus = new Vue()

const EventBus = {
  $on: (event, fn) => eventBus.$on(event, fn),
  $off: (event, fn) => eventBus.$off(event, fn),
  $emit: (event, data) => eventBus.$emit(event, data)
}

export default EventBus
