import Vue from 'vue'
import { orderBy } from 'lodash'

/**
 * Attempts to create a human readable string from SNS event names, returns the passed value otherwise.
 * @param {string} str - event string
 * @returns {string} corresponding module name(s) based on the prefix or the passed string if there's no match
 */
const parseEventString = str => {
  const prefixModuleMap = {
    'bc.': 'All modules',
    'bc.lines': 'Lines',
    'bc.quote': 'Quote',
    'bc.policies': 'Policies'
  }
  let event
  try {
    event = JSON.parse(`[${str}]`)
      .map(event => event.prefix ? (prefixModuleMap[event.prefix] || event.prefix) : JSON.stringify(event))
      .join(', ')
  } catch (err) {
    event = str
  }
  return event
}

export const mutations = {
  integrations (state, integrations) {
    state.integrations = integrations
  },
  setIntegrationsLoading (state, value) {
    state.integrations.loading = value
  },
  setEventsLoading (state, value) {
    state.events.loading = value
  },
  setSubscriptions (state, subscriptions) {
    state.events.subscriptions = subscriptions.map(subscription => {
      subscription.subscribedEvents = parseEventString(subscription.attributes.events)
      return subscription
    })
    // clear existing messages and create message slots for every subscription
    state.events.subscriptionMessages = {}
    subscriptions.forEach(({ id }) => {
      Vue.set(state.events.subscriptionMessages, id, { loading: false, data: [] })
    })
  },
  removeSubscription (state, subscriptionID) {
    state.events.subscriptions.splice(state.events.subscriptions.findIndex(s => s.id === subscriptionID), 1)
  },
  setSubscriptionMessages (state, { subscriptionID, value }) {
    Vue.set(state.events.subscriptionMessages, subscriptionID, {
      ...(state.events.subscriptionMessages[subscriptionID] || {}),
      ...value
    })
  },
  setPluginsLoading (state, value) {
    state.plugins.loading = value
  },
  setPlugins (state, plugins) {
    state.plugins.registered = plugins.map(plugin => {
      return plugin
    })
  },
  addPlugin (state, plugin) {
    state.plugins.registered.push(plugin)
  },
  removePlugin (state, pluginId) {
    state.plugins.registered.splice(state.plugins.registered.findIndex(p => p.id === pluginId), 1)
  },
  updatePlugin (state, plugin) {
    Vue.set(state.plugins.registered, state.plugins.registered.findIndex(p => p.id === plugin.id), plugin)
  },
  setMappingsLoading (state, value) {
    state.mappings.loading = value
  },
  setMappings (state, mappings) {
    state.mappings.registered = mappings.map(mapping => { return mapping })
  },
  setEditedMapping (state, mapping) {
    state.mappings.edited = mapping
  },
  addMapping (state, mapping) {
    state.mappings.registered.push(mapping)
  },
  updateMapping (state, mapping) {
    Vue.set(state.mappings.registered, state.mappings.registered.findIndex(m => m.id === mapping.id), mapping)
  },
  removeMapping (state, mappingId) {
    state.mappings.registered.splice(state.mappings.registered.findIndex(m => m.id === mappingId), 1)
  },
  setIntegrations (state, integrations) {
    state.integrations.integrations = orderBy(integrations, [
      (integration) => {
        return integration.date_created
      }], ['desc'])
  },
  removeIntegration (state, integrationId) {
    const integrationIndex = state.integrations.integrations.findIndex(i => i.id === integrationId)
    state.integrations.integrations.splice(integrationIndex, 1)
  },
  setCurrentIntegration (state, integration) {
    state.integrations.currentIntegration = integration
  },
  setVendorsLoading (state, value) {
    state.vendors.loading = value
  },
  setVendorAuthUrl (state, url) {
    state.vendors.redirectUrl = url
  },
  setEsignatureConfig (state, config) {
    state.esignatureConfig = config
  }
}
