import _ from 'lodash'

export const mutations = {
  updateMfaPreferences (state, enabledMfaMethods) {
    state.mfaMethods.forEach(mfaMethod => {
      mfaMethod.enabled = _.includes(enabledMfaMethods, mfaMethod.type)
    })
  },
  isAccountLinking (state, isAccountLinking) {
    state.isAccountLinking = isAccountLinking
  },
  accountLinkingError (state, error) {
    state.accountLinkingResult = { error }
  },
  accountLinkingSuccess (state, success) {
    state.accountLinkingResult = { success }
  },
  accountLinkingReset (state) {
    state.isAccountLinking = false
    state.accountLinkingResult = {}
  }
}
