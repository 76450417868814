// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

// These 2 imports are polyfills needed to ensure IE compatibility
import 'whatwg-fetch'

import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import apolloProvider from './apollo'
import router from './router'
import App from './App.vue'
import BriteCoreUI from './components/ui'
import Resource from 'vue-resource'
import Vue2Filters from 'vue2-filters'
import BcDesignSystem from '@britecore/bc-design-system'
import { datadogRum } from '@datadog/browser-rum'
import LogRocket from 'logrocket'
import PortalVue from 'portal-vue'
import VeeValidate from 'vee-validate'
import VueTheMask from 'vue-the-mask'
import store from './store'
import Meta from 'vue-meta'
import axios from 'axios'
import VueScrollTo from 'vue-scrollto'
import moment from 'moment'
import { PermissionDenied } from '@/utilities/errors'
import _ from 'lodash'

import './utilities/fontawesome'

import xray from './utilities/xray'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Sentry.Integrations.Vue({ Vue })],
    environment: process.env.VUE_APP_STAGE
  })
  Sentry.configureScope(function (scope) {
    scope.setTag('client', process.env.VUE_APP_CLIENT_ABBREVIATION)
  })
}

if (process.env.VUE_APP_LOGROCKET_ID) {
  LogRocket.init(process.env.VUE_APP_LOGROCKET_ID, {
    network: {
      requestSanitizer: request => {
        const authzHeader = request.headers && request.headers['Authorization']
        if (authzHeader) {
          const jwtPieces = authzHeader.split('.')
          if (jwtPieces.length === 3) {
            // This should be enough to log usable data on the token without logging the full contents
            // (ie., leaking short-term credentials)
            jwtPieces[2] = 'SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c' // Random signature; will be invalid but keeps the JWT form
            request.headers['Authorization'] = jwtPieces.join('.')
          }
        }

        const SCRUBBED_URLS = [
          {url: 'migrate_gen2_contact', key: 'password'},
          {url: 'set-temporary-password', key: 'data.meta.temporary_password'}
        ]

        try {
          const requestBodyObject = JSON.parse(request.body || '{}')
          for (const {url, key} of SCRUBBED_URLS) {
            if (request.url.toLowerCase().indexOf(url) !== -1) {
              _.get(requestBodyObject, key) && _.set(requestBodyObject, key, '****scrubbed****')
            }
          }

          request.body = requestBodyObject
        } catch (e) {} // We don't want exceptions here to start blowing up API calls

        return request
      }
    }
  })
  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra('LogRocketSessionURL', sessionURL)
    })
  })
}

if (process.env.VUE_APP_DATADOG_ENABLED === 'true') {
  datadogRum.init({
    applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'britecore-ui',
    env: process.env.VUE_APP_DATADOG_ENV,
    sampleRate: 100,
    trackInteractions: true
  })
}

Vue.config.productionTip = false
Vue.config.devtools = true

const xraySamplingRate = parseInt(process.env.VUE_APP_XRAY_SAMPLING_RATE, 10) || 0
if (xraySamplingRate) {
  axios.interceptors.request.use((config) => {
    if (Math.random() < xraySamplingRate / 100) {
      const segment = xray.beginSegment('BriteCore-UI', config.method)
      config.headers['x-amzn-trace-id'] = xray.getTraceHeader(segment)
    }
    return config
  })

  axios.interceptors.response.use((response) => {
    const traceId = response.headers['x-amzn-trace-id']
    if (traceId) {
      xray.endSegment(traceId)
    }
    return response
  })
}

axios.interceptors.request.use(function (config) {
  // Pass `Authorization` header with AWS Cognito's `accessToken` value
  // @TODO: Only pass this header for whitelisted domains.
  let accessToken = store.getters['authentication/accessToken']
  if (accessToken && !config.excludeAuthHeader) {
    config.headers.Authorization = accessToken
  }
  return config
})

axios.interceptors.request.use((config) => {
  // Interceptor for setting axios requests header depending on user Role
  // config.noEffectiveRoleHeader is used when requesting for user roles
  // @TODO: Move to interceptors.js file
  if (store.getters['access/effectiveRole'] && !config.noEffectiveRoleHeader) {
    config.headers['X-BriteAccess-EffectiveRole'] = store.getters['access/effectiveRole']
  }
  return config
})

axios.interceptors.request.use(async (config) => {
  // Interceptor for ensuring axios requests don't happen if session is expired
  // user is logged out of the application
  const isAuthenticated = store.getters['authentication/isAuthenticated']
  if (isAuthenticated) {
    const accessTokenExpiry = store.getters['authentication/accessTokenExpiry']
    const isSessionExpired = (store.state.authentication.sessionTimeout && store.state.authentication.sessionTimeout < moment())
    const isBeingLoggedOut = store.state.authentication.isBeingLoggedOut

    if (isSessionExpired) {
      if (!isBeingLoggedOut) {
        await store.dispatch('authentication/signOut')
      }
    } else if (accessTokenExpiry && accessTokenExpiry < moment().add(30, 'seconds')) {
      // Making an early refresh for the use cases like quote wizard, where the frontend keeps sending requests
      // to sync data but the user is not 'physically' switching pages.
      // However, automatic refresh is not "user activity", so the session-timeout should be kept as is.
      await store.dispatch('authentication/refreshAccessToken')
    }
  }
  return config
})

axios.interceptors.response.use(
  response => response,
  error => {
    // Error may not be request-related.
    const response = error.response
    const missingEffectiveRole = (!store.getters['access/effectiveRole'] && process.env.VUE_APP_ENABLED_MODULE_ACCESS)

    if (response && response.status === 403 && response.headers['x-briteaccess-enforcer-stamp'] === 'Ingress-Denied') {
      error = new PermissionDenied(error)

      if (response.headers['x-briteaccess-enforcer-annotation-ingress-annotation'] === 'user-not-assigned-to-role' || missingEffectiveRole) {
        router.push({
          name: 'select-role',
          query: {
            redirectReason: response.headers['x-briteaccess-enforcer-annotation-ingress-annotation']
          }
        })
      } else {
        // Use `setTimeout` to defer the lookup of the error object, so that modules' error catch blocks have a chance
        // to alter the attributes on the error object so that they can customize the permission denied behavior.
        setTimeout(() => {
          if (error.redirectToForbidden) {
            router.push('/forbidden')
          }
        }, 0)
      }
    }
    return Promise.reject(error)
  }
)

// Claims and Quote are dependent on Notes' components
if (
  process.env.VUE_APP_ENABLED_MODULE_NOTES ||
  process.env.VUE_APP_ENABLED_MODULE_CLAIMS ||
  process.env.VUE_APP_ENABLED_MODULE_QUOTE
) {
  const { default: BriteNotes } = require('@intuitivewebsolutions/britenotes-ui')
  Vue.use(BriteNotes)
}

Vue.use(BriteCoreUI)
Vue.use(BcDesignSystem, { disableTestIdDirective: true })
Vue.use(PortalVue)
Vue.use(Resource)
Vue.use(Vue2Filters)
Vue.use(VueTheMask)
Vue.use(VeeValidate, {
  locale: 'en',
  dictionary: { en: {
    messages: {
      required: () => `This field is required.`,
      min_value: (field, params) => `${field} must be ${params[0]} or more.`,
      max_value: (field, params) => `${field} must be ${params[0]} or less.`,
      decimal: (field, params) => `${field} can not have more than ${params[0]} decimal places.`
    }
  }},
  inject: false,
  fieldsBagName: '$fields',
  errorBagName: '$errors',
  events: 'blur|submit'
})
Vue.use(Meta)
Vue.use(VueScrollTo)

Vue.url.options.root = 'http://localhost:5000'
Vue.http.headers.common['Authorization'] = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJCcml0ZURhdGEiLCJpYXQiOjE1MDQ1OTc3MzEsImVtYWlsIjoia3VqdGltQGJyaXRlY29yZS5jb20iLCJleHAiOjE1MDQ2ODQxMzF9.NHoKsqb50QzJW5TS_j5pZssmfaG6kjy3XMRpgDlzcUg'

/* eslint-disable no-new */
new Vue({
  el: '#js-page-body',
  provide: apolloProvider.provide(),
  router,
  store,
  render: h => h(App),
  mounted: function () {
    setInterval(() => this.$store.dispatch('authentication/forceRefreshSessionIfNeeded'), 60000)
  }
})
